import React, { useState } from 'react';
import { t } from 'i18next';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

const PasswordInput = (props) => {
	const [show, setShow] = useState(false);
	return (
		<>
			<InputGroup>
				<Form.Control
					autoComplete="new-password"
					type={show ? 'text' : 'password'}
					{...props}
				/>
				<Button title={t('GENERAL.TOOGLEPASSWORD')} variant="outline-secondary" onClick={() => setShow(!show)}>
					{show ? <HiOutlineEye /> : <HiOutlineEyeOff />}
				</Button>
			</InputGroup>
		</>
	);
};
export default PasswordInput;
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Footer';
import Header from '../Header';
import Chat from '../Chat';
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';
import { getNotifications } from '../../redux/chat';

const LayoutComponent = ({ children }) => {
	const isMobile = useCheckMobileScreen();
	const { currentHospital } = useSelector(state => state.auth);
	const { hospitalBedInfo } = useSelector(state => state.hospital);
	const { notifications } = useSelector(state => state.hospitalMessages);

	const dispatch = useDispatch();

	useEffect(() => {
		if (currentHospital.value && isEmpty(notifications)) {
			dispatch(getNotifications({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital.value, notifications]);
	return (
		<>
			{!(isMobile) ?
				<div className='page'>
					<div className='page-main'>
						<Header />
						<Container>
							{children}
						</Container>
					</div>
					{!isEmpty(hospitalBedInfo) && <Chat />}
					<Footer />
				</div> :
				<div className='page'>
					<div className='d-flex justify-content-center align-items-center vh-100'>
						<h1 className='text-center text-danger'>{t('GENERAL.SCREENTEXT')}</h1>
					</div>
				</div>}
		</>
	);
};

export default LayoutComponent;
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Col, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { IoMdShareAlt } from 'react-icons/io';
import DynamicForm from '../../components/DynamicForm';
import Loader from '../../components/SplashScreen/loader';
import { reset } from '../../redux/discharge-clinical/slice';
import { dischargeCheckList, getDischargeAdminList, getDischargeCheckListFormFields } from '../../redux/discharge-clinical';
import { resetPatient } from '../../redux/hospital/slice';
import useDebounce from '../../utils/useDebounceHook';
import { getDefaultFormID } from '../../utils/helper';

const DischargeAdmin = () => {
	const [initialValues, setInitialValues] = useState({});
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [successMessage, setSuccessMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [patientId, setPatientId] = useState();
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { dischargeAdminError, dischargeAdminList, dischargeCheckListSuccess, dischargeCheckListErrorMessage, formFields, formFieldsError, Loading } = useSelector(state => state.dischargeClinical);
	const debouncedSearchTerm = useDebounce(filterText, 500);

	useEffect(() => {
		if (!isEmpty(formFields?.form_fields)) {
			let fields = {};
			formFields?.form_fields.map((field) => {
				fields[field.field_name] = field.default_values || '';
			});
			setInitialValues(fields);
		}
	}, [formFields]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && !isEmpty(dischargeCheckListSuccess)) {
			setModalShow(false);
			dispatch(getDischargeAdminList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [dischargeCheckListSuccess]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(dischargeAdminList)) {
			setTotalRows(dischargeAdminList.total);
			setPerPage(dischargeAdminList.per_page);
			setSuccessMessage(dischargeAdminList.message);
		}
	}, [dischargeAdminList]);

	useEffect(() => {
		if (dischargeAdminError) {
			setErrorMessage(dischargeAdminError);
		}
		if (formFieldsError) {
			setErrorMessage(formFieldsError);
		}
		if (!isEmpty(dischargeAdminError) || !isEmpty(formFieldsError) || !isEmpty(dischargeCheckListErrorMessage)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [dischargeAdminError, formFieldsError, dischargeCheckListErrorMessage]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getDischargeAdminList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getDischargeAdminList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	useEffect(() => {
		if (modalShow) {
			dispatch(getDischargeCheckListFormFields({ hospital_id: currentHospital.value, default_form_id: getDefaultFormID('Dischage Checklist'), p_id : patientId }));
		}
	}, [modalShow]);

	const openModal = (row) => {
		setModalShow(true);
		setPatientId(row.p_id);
	};
	const columns = [
		{
			name: t('DISCHARGEADMIN.COLUMNS.NAME'),
			selector: row => row.full_name,
			sortable: true,
			sortField: 'full_name',
		},
		{
			name: t('DISCHARGEADMIN.COLUMNS.REGNO'),
			cell: row => row.reg_no,
			sortable: true,
			sortField: 'reg_no',
		},
		{
			name: t('DISCHARGEADMIN.COLUMNS.MOBILENO'),
			selector: row => row.mobile_number,
			sortable: true,
			sortField: 'mobile_number',
		},
		{
			name: t('DISCHARGEADMIN.COLUMNS.BEDNO'),
			selector: row => row.bed_no,
			sortable: true,
			sortField: 'bed_no',
		},
		{
			name: t('DISCHARGEADMIN.COLUMNS.ACTION'),
			grow: '2',
			cell: row => (
				<Stack direction='horizontal' gap={1}>
					{row.discharge_status == 1 &&
						<Button title={t('DISCHARGEADMIN.BUTTON.DISCHARGECHECKLIST')} variant="danger" size="sm" onClick={() => openModal(row)} >
							<IoMdShareAlt /> {t('DISCHARGEADMIN.BUTTON.DISCHARGECHECKLIST')}
						</Button>}
					{row.discharge_status > 0 && row.ds_id > 0 &&
						<Button title={t('DISCHARGEADMIN.BUTTON.DISCHARGESUMMARY')} variant="link" as={Link} size="sm" to={`/edit-discharge-summary?patientId=${row.p_id}${(row.ds_id) ? '&dsId=' + row.ds_id : ''}`}>
							{t('DISCHARGEADMIN.BUTTON.DISCHARGESUMMARY')}
						</Button>
					}
				</Stack>
			),
		},

	];
	const handlePageChange = page => {
		dispatch(getDischargeAdminList({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getDischargeAdminList({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	const handleSubmitFormData = (values) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', patientId);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			formFields.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
			});
		});
		dispatch(dischargeCheckList(formdata));
		dispatch(resetPatient());
	};

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};

	return (
		<Container className='mt-4'>
			{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			{successMessage && <Alert variant="success">{successMessage}</Alert>}
			<Row>
				<Col>
					<DataTable
						title={t('DISCHARGEADMIN.TITLE')}
						columns={columns}
						data={dischargeAdminList.data}
						progressPending={Loading}
						progressComponent={<Loader />}
						highlightOnHover
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						onSort={handleSort}
						sortServer
					/>
				</Col>
			</Row>
			<Modal size='lg' show={modalShow} onHide={() => setModalShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Discharge Checklist</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{dischargeCheckListErrorMessage &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{dischargeCheckListErrorMessage && dischargeCheckListErrorMessage.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{!isEmpty(initialValues) && <DynamicForm initialValues={initialValues} fields={formFields.form_fields} handleCancel={() => setModalShow(false)} handleSubmit={handleSubmitFormData} parentformuniqueid={formFields.form_unique_id} loading={Loading} />
					}
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default DischargeAdmin;

import React from 'react';
import { t } from 'i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../../redux/auth';
import { forgotPasswordValidationSchema } from '../../../utils/validation';
import { axiaLogo } from '../../../images';

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const { isForgotSuccess, forgotSuccessMessage, isForgotReject, forgotErrorMessage } = useSelector(state => state.auth);
	const initialValues = {
		'username': ''
	};
	const handleForgotPassword = ({ username }) => {
		const body = { username };
		dispatch(forgotPassword({ body }));
	};

	return (
		<Container fluid className='vh-100 d-flex align-items-center justify-content-center'>
			<Row className='justify-content-center'>
				<Col sm={12} md={8} className='p-5 bg-white shadow-lg rounded'>
					<div className='text-center'>
						<img src={axiaLogo} alt={t('GENERAL.LOGOTEXT')} />
						<h1 className='text-primary'>{t('FORGOTPASSWORD.TITLE')}</h1>
						<p className='text-muted fs-6'>{t('FORGOTPASSWORD.SUB_TITLE')}</p>
					</div>
					{(isForgotSuccess) &&
						<Alert variant='success' className='mt-2'>
							{forgotSuccessMessage.data.message}
						</Alert>}
					<Formik initialValues={initialValues} validationSchema={forgotPasswordValidationSchema(t)} onSubmit={handleForgotPassword}>
						{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="formBasicUsername">
									<Form.Label>{t('LOGIN.USERNAME')}</Form.Label>
									<Form.Control
										type="text"
										placeholder={t('FORGOTPASSWORD.PLACEHOLDER.USERNAME')}
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={errors.username && touched.username || isForgotReject} />
									<Form.Control.Feedback type="invalid">
										{isForgotReject ? forgotErrorMessage : errors.username}
									</Form.Control.Feedback>
								</Form.Group>
								<Stack className='pt-3' gap={3}>
									<Button title={t('FORGOTPASSWORD.BUTTON')} size={'lg'} variant="primary" type="submit">
										{t('FORGOTPASSWORD.BUTTON')}
									</Button>
									<Button title={t('LOGIN.BUTTON')} size={'lg'} variant="primary" as={Link} to='/login'>
										{t('LOGIN.BUTTON')}
									</Button>
								</Stack>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</Container>
	);
};

export default ForgotPassword;
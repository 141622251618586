import { useLocation } from 'react-router-dom';

function useQuery() {
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	let params  = {};
	for (const [key, value] of searchParams.entries()) {
		params[key] = value;
	}
	return params;
}
export default useQuery;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const handleLogin = createAsyncThunk(
	'handleLogin',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/login', body: params.body, isAuthenticated: false });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogin();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const forgotPassword = createAsyncThunk(
	'forgotPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/forgotpassword', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const resetPassword = createAsyncThunk(
	'resetPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/password/reset', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const verifyOtp = createAsyncThunk(
	'verifyOtp',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'verifyotp', body: params.body, isAuthenticated: false });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogin();
			}
			return { response };
		}
		catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const handleLogOut = createAsyncThunk(
	'handleLogOut',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/logout', body: params });
			if (response?.data && !response.data.error) {
				params.useAuthContext.onLogout();
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const resendOtp = createAsyncThunk(
	'resendOtp',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'resendotp', body: params.body, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const refreshToken = async() => {
	const authToken = localStorage.getItem('authToken');
	if (!authToken) return false;
	const response = await callPostAPI({ route: 'hospital/refresh/accesstoken' });
	return response;
};

export const changePassword = createAsyncThunk(
	'changePassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/changepassword', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const registerConsultant = createAsyncThunk(
	'registerConsultant',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/register/consultant', body: params, isAuthenticated: false });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const usernameSuggestion = createAsyncThunk(
	'usernameSuggestion',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'username/suggestion', body: params.body, isAuthenticated: true });
			return { response };
		}
		catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const verifyEmail = createAsyncThunk(
	'verifyEmail',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/join/consultant', body: params, isAuthenticated: false });
			return { response };
		}
		catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateProfile = createAsyncThunk(
	'updateProfile',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/updateprofile', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const changeBackup = createAsyncThunk(
	'changeBackup',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/backup/configuration', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getBackup = createAsyncThunk(
	'getBackup',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/backup/configuration', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getBackupAuthURL = createAsyncThunk(
	'getBackupAuthURL',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/backup/auth/url', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
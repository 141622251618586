import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { isEmpty, includes } from 'lodash';
import Loader from '../SplashScreen/loader';


const MonitoringChartPlain = (props) => {
	const { title, loading, errors, data } = props;
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend,
		TimeScale
	);

	return (
		<React.Fragment key={title}>
			<div>
				{loading &&
					<Row className='d-flex justify-content-center mt-4'>
						<Loader />
					</Row>
				}
				{errors &&
					<Alert variant='danger' className='mt-3'>
						<ul className='mb-0'>
							{errors && errors.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{!isEmpty(data) &&
					data.map((chart, chartIndex) => {
						const options = {
							responsive: true,
							title: chart.name,
							spanGaps: true,
							plugins: {
								title: {
									display: true,
									text: chart.label
								},
								legend: {
									onClick: null,
								},
							},
							scales: {
								y: {
									suggestedMin: 10,
									suggestedMax: 110,
								},
								x: {
									ticks: {
										callback: function(chart) {
											const dates = this.getLabelForValue(chart).split(' ');
											const yeardata = dates[0].split('-');
											yeardata.pop();
											return yeardata.join('-') + ' ' + dates[1] + ' ' + dates[2];
										}
									}
								}
							},
							onClick: (event, elements) => {
								let data = [];
								if (!isEmpty(elements)) {
									data[0] = event.chart.data.datasets[0].data[elements[0].index];
									if (includes(['blood_pressure', 'pap'], event.chart.options.title)) {
										data[1] = event.chart.data.datasets[1].data[elements[0].index];
										data[2] = event.chart.data.datasets[2].data[elements[0].index];
									}
								}
								return false;
							},
						};
						return chart.type == 'single' ?
							<Line key={chartIndex} options={options}
								data={{
									title: chart.title,
									datasets: [{
										label: chart.label,
										pointRadius: chart.pointRadius,
										pointBackgroundColor: chart.pointBackgroundColor,
										backgroundColor: chart.backgroundColor,
										borderColor: chart.backgroundColor,
										data: chart.data,
									}]
								}}
							/> :
							<Line key={chartIndex} options={options}
								data={{
									title: chart.title,
									labels: chart.labels,
									datasets: chart.data
								}} />;
					})}
			</div>
		</React.Fragment>
	);
};

export default MonitoringChartPlain;
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutComponent from '../components/LayoutComponent';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgot-password';
import ErrorPage from '../pages/error-page';
import Dashboard from '../pages/dashboard';
import { PublicRoute } from './publicRoute';
import { ProtectedRoute } from './protectedRoute';
import AuthProvider from './AuthProvider';
import Users from '../pages/users';
import AddUser from '../pages/users/add-user';
import EditUser from '../pages/users/edit-user';
import AsssignPermission from '../pages/forms/assignpermission';
import Settings from '../pages/settings';
import Patients from '../pages/patient';
import AddPatient from '../pages/patient/add-patient';
import EditPatient from '../pages/patient/edit-patient';
import DetailPatient from '../pages/patient/detail-patient';
import MonitoringPatient from '../pages/patient/monitoring-patient';
import ClinicalForms from '../pages/clinical-forms';
import AddClinicalForm from '../pages/clinical-forms/add-form';
import HistoryClinicalForm from '../pages/clinical-forms/history-form';
import ViewClinicalForm from '../pages/clinical-forms/view-form';
import EditClinicalForm from '../pages/clinical-forms/edit-form';
import HandOverHistory from '../pages/handoverhistory';
import HandOverHistoryDetail from '../pages/handoverhistory/handoverhistorydetail';
import DischargeClinical from '../pages/discharge/discharge-clinical';
import DischargeSummary from '../pages/discharge/discharge-summary';
import EditDischargeSummary from '../pages/discharge/edit-discharge-summary';
import DischargeAdmin from '../pages/discharge/discharge-admin';
import AdmittedDetailPatient from '../pages/editadmittedpatient/detail-patient';
import ArchivedPatients from '../pages/archived-patients/archived-patients';
import DischargeHistory from '../pages/archived-patients/discharge-history';
import ArchivedForms from '../pages/archived-patients/archived-forms';
import AddClinicalFormDocument from '../pages/clinical-forms/add-form-document';
import AdmittedPatientHistory from '../pages/editadmittedpatient/admittedpatienthistory';
import ResetPassword from '../pages/auth/reset-password';
import ViewDetails from '../pages/patient/viewdetails';
import InvalidLinkPage from '../pages/error-page/invalid-link-page';
import RegisterConsultant from '../pages/auth/register';
import VerifyEmail from '../pages/auth/register/verify-email';
import VideoCall from '../pages/videocall';
import VideoCall2 from '../pages/videocall/videocall2';
const LoadPublicRouteComponent = ({ component }) => <PublicRoute>{component}</PublicRoute>;
const LoadProtectedRouteComponent = ({ component }) => <LayoutComponent><ProtectedRoute>{component}</ProtectedRoute></LayoutComponent>;

const RouterContainer = () => (
	<BrowserRouter>
		<Suspense fallback={null}>
			<AuthProvider>
				<Routes>
					<Route
						path="/login"
						element={<LoadPublicRouteComponent component={<Login />} />}
					/>
					<Route
						path="/forgot-password"
						element={<LoadPublicRouteComponent component={<ForgotPassword />} />}
					/>
					<Route
						path="/reset-password"
						element={<LoadPublicRouteComponent component={<ResetPassword />} />}
					/>
					<Route
						path="/register-consultant"
						element={<LoadPublicRouteComponent component={<RegisterConsultant />} />}
					/>
					<Route
						path="/join-consultant"
						element={<LoadPublicRouteComponent component={<VerifyEmail />} />}
					/>
					<Route
						path="/invalid-link"
						element={<LoadPublicRouteComponent component={<InvalidLinkPage />} />}
					/>
					<Route
						path="/"
						element={<LoadProtectedRouteComponent component={<Dashboard />} />}
					/>
					<Route
						path="/users"
						element={<LoadProtectedRouteComponent component={<Users />} />}
					/>
					<Route
						path="/users/add"
						element={<LoadProtectedRouteComponent component={<AddUser />} />}
					/>
					<Route
						path="/users/edit/:id"
						element={<LoadProtectedRouteComponent component={<EditUser />} />}
					/>
					<Route
						path="/patients"
						element={<LoadProtectedRouteComponent component={<Patients />} />}
					/>
					<Route
						path="/patients/add"
						element={<LoadProtectedRouteComponent component={<AddPatient />} />}
					/>
					<Route
						path="/patients/edit/:id"
						element={<LoadProtectedRouteComponent component={<EditPatient />} />}
					/>
					<Route
						path="/patients/detail/:id"
						element={<LoadProtectedRouteComponent component={<DetailPatient />} />}
					/>
					<Route
						path="/patients/viewdetails/:id"
						element={<LoadProtectedRouteComponent component={<ViewDetails />} />}
					/>
					<Route
						path="/patient-monitoring"
						element={<LoadProtectedRouteComponent component={<MonitoringPatient />} />}
					/>
					<Route
						path="/clinical_forms/form/:formId/list"
						element={<LoadProtectedRouteComponent component={<HistoryClinicalForm />} />}
					/>
					<Route
						path="/clinical_forms/form/:formId/view/:id"
						element={<LoadProtectedRouteComponent component={<ViewClinicalForm />} />}
					/>
					<Route
						path="/clinical_forms/form/:formId/edit/:id"
						element={<LoadProtectedRouteComponent component={<EditClinicalForm />} />}
					/>
					<Route
						path="/clinical_forms/form/:formId/add"
						element={<LoadProtectedRouteComponent component={<AddClinicalForm />} />}
					/>
					<Route
						path="/clinical_forms/upload-document/:formId/add"
						element={<LoadProtectedRouteComponent component={<AddClinicalFormDocument />} />}
					/>
					<Route
						path="/clinical_forms/role/:role"
						element={<LoadProtectedRouteComponent component={<ClinicalForms />} />}
					/>
					<Route
						path="/discharge-admin"
						element={<LoadProtectedRouteComponent component={<DischargeAdmin />} />}
					/>
					<Route
						path="/discharge-clinical"
						element={<LoadProtectedRouteComponent component={<DischargeClinical />} />}
					/>
					<Route
						path="/archived-patients"
						element={<LoadProtectedRouteComponent component={<ArchivedPatients />} />}
					/>
					<Route
						path="/discharge-history/:id"
						element={<LoadProtectedRouteComponent component={<DischargeHistory />} />}
					/>
					<Route
						path="/archived-forms/:id/:ipno"
						element={<LoadProtectedRouteComponent component={<ArchivedForms />} />}
					/>
					<Route
						path="/discharge-summary"
						element={<LoadProtectedRouteComponent component={<DischargeSummary />} />}
					/>
					<Route
						path="/edit-discharge-summary"
						element={<LoadProtectedRouteComponent component={<EditDischargeSummary />} />}
					/>
					<Route
						path="/videocall/"
						element={<LoadProtectedRouteComponent component={<VideoCall2 />} />}
					/>
					<Route
						path="/videocall/:ipno"
						element={<LoadProtectedRouteComponent component={<VideoCall />} />}
					/>
					<Route
						path="/settings/*"
						element={<LoadProtectedRouteComponent component={<Settings />} />}
					/>
					<Route
						path="/users/assign/:role"
						element={<LoadProtectedRouteComponent component={<AsssignPermission />} />}
					/>
					<Route
						path="/viewhandoverhistory/:pid"
						element={<LoadProtectedRouteComponent component={<HandOverHistory />} />}
					/>
					<Route
						path="/viewhandoverhistorydetails/:hid/:pid"
						element={<LoadProtectedRouteComponent component={<HandOverHistoryDetail />} />}
					/>
					<Route
						path="/admittedpatients/detail/:id"
						element={<LoadProtectedRouteComponent component={<AdmittedDetailPatient />} />}
					/>
					<Route
						path="/admittedpatients/admittedpatienthistory/:hid/:pid/:ivno"
						element={<LoadProtectedRouteComponent component={<AdmittedPatientHistory />} />}
					/>
					<Route
						path="*"
						element={<ErrorPage />}
					/>
				</Routes>
			</AuthProvider>
		</Suspense>
	</BrowserRouter>
);

export default RouterContainer;

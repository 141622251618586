import React, { useLayoutEffect, useRef } from 'react';
import { isEmpty, find } from 'lodash';
import { FaHandPointer } from 'react-icons/fa';
import { BiVideoPlus } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { getFormattedDateTimeByUnixTimeStamp } from '../../utils/helper';
import { UserPlaceholder } from '../../images';
import { FiPhone } from 'react-icons/fi';
import { MdCallEnd } from 'react-icons/md';
const ChatMessage = (props) => {
	const { messages } = props;
	const anonymousUser = { first_name: 'Anonymous', last_name: 'User', profile_picture: '', username: '', id: 0 };
	const { hospitalBedInfo } = useSelector(state => state.hospital);
	const { userDetails } = useSelector(state => state.auth);
	const chatwindow = useRef();
	useLayoutEffect(() => {
		if (chatwindow.current) {
			chatwindow.current.scrollTop = chatwindow.current.scrollHeight;
		}
	});
	return (
		<div className='direct-chat-messages' ref={chatwindow}>
			{!isEmpty(messages) &&
				messages.map((msg, index) => {
					let msguser = find(hospitalBedInfo?.users, { 'id': msg?.sender }) || anonymousUser;
					return (
						<React.Fragment key={index}>
							{msg?.sender == userDetails.id ?
								<div className='direct-chat-msg right'>
									<div className='direct-chat-info clearfix'>
										<span className='direct-chat-name pull-right float-end'>{msguser.first_name + ' ' + msguser.last_name} </span>
										<span className='direct-chat-timestamp pull-left float-start'>
											{getFormattedDateTimeByUnixTimeStamp(msg?.timestamp?.seconds)}
										</span>
									</div>
									<img className='direct-chat-img' src={msguser.profile_picture || UserPlaceholder} alt={msguser.username} />
									{msg.type == 'normal' &&
										<div className='direct-chat-text'>
											{msg.message}
										</div>}
									{msg.type == 'nudge' &&
										<div className='direct-chat-text bg-warning border-warning nudge'>
											<FaHandPointer /> {msg.message}
										</div>}
									{msg.type == 'invite_videocall' &&
										<div className='direct-chat-text bg-info border-info videocall text-white'>
											<BiVideoPlus /> {msg.message}
										</div>}
									{msg.type == 'ended_videocall' &&
										<div className='direct-chat-text bg-danger border-danger text-white'>
											<MdCallEnd /> {msg.message}
										</div>}
									{msg.type == 'decline_videocall' &&
										<div className='direct-chat-text bg-danger border-danger videocall'>
											<MdCallEnd /> {msg.message}
										</div>}
									{msg.type == 'accept_videocall' &&
										<div className='direct-chat-text bg-success border-success videocall text-white'>
											<FiPhone /> {msg.message}
										</div>}
									{msg.type == 'cpr' &&
										<div className='direct-chat-text bg-danger border-danger cpr'>
											{msg.message}
										</div>}
								</div>
								:
								<div className='direct-chat-msg left'>
									<div className='direct-chat-info clearfix'>
										<span className='direct-chat-name pull-left float-start'>{msguser.first_name + ' ' + msguser.last_name} </span>
										<span className='direct-chat-timestamp pull-right float-end'>
											{getFormattedDateTimeByUnixTimeStamp(msg?.timestamp?.seconds)}
										</span>
									</div>
									<img className='direct-chat-img' src={msguser.profile_picture || UserPlaceholder} alt={msguser.username} />
									{msg.type == 'normal' &&
										<div className='direct-chat-text'>
											{msg.message}
										</div>}
									{msg.type == 'nudge' &&
										<div className='direct-chat-text bg-warning border-warning nudge text-white'>
											<FaHandPointer /> {msg.message}
										</div>}
									{msg.type == 'invite_videocall' &&
										<div className='direct-chat-text bg-info border-info videocall text-white'>
											<BiVideoPlus /> {msg.message}
										</div>}
									{msg.type == 'ended_videocall' &&
										<div className='direct-chat-text bg-danger border-danger text-white'>
											<MdCallEnd /> {msg.message}
										</div>}
									{msg.type == 'decline_videocall' &&
										<div className='direct-chat-text bg-danger border-danger text-white'>
											<MdCallEnd /> {msg.message}
										</div>}
									{msg.type == 'accept_videocall' &&
										<div className='direct-chat-text bg-success border-success videocall text-white'>
											<FiPhone /> {msg.message}
										</div>}
									{msg.type == 'cpr' &&
										<div className='direct-chat-text bg-danger border-danger cpr text-white'>
											{msg.message}
										</div>}
								</div>
							}
						</React.Fragment>);
				})
			}
		</div>
	);
};

export default ChatMessage;
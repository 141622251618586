import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';
import { changeHospital, updateHospitalPermissions } from '../auth/slice';


export const getUsers = createAsyncThunk(
	'getUsers',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/getusers', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getUserDetail = createAsyncThunk(
	'getUserDetail',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/getuser', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateUser = createAsyncThunk(
	'updateUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/updateuser', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addUser = createAsyncThunk(
	'addUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/adduser', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteUser = createAsyncThunk(
	'deleteUser',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/deleteuser', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const inviteConsultant = createAsyncThunk(
	'inviteConsultant',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/invite/user', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const adminResetPassword = createAsyncThunk(
	'adminResetPassword',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/change/subuser/password', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);


export const sendCPRNotification = createAsyncThunk(
	'sendCPRNotification',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/cpr/sms', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const sendNudgeNotification = createAsyncThunk(
	'sendNudgeNotification',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/nudge/sms', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getVideoCallToken = createAsyncThunk(
	'getVideoCallToken',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/videocall/token', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getModulePermission = createAsyncThunk(
	'getModulePermission',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/module/permission/edit', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updatePermissions = createAsyncThunk(
	'updatePermissions',
	async(params, { rejectWithValue, dispatch, getState }) => {
		try {
			const authState = getState().auth;
			const response = await callPostAPI({ route: 'hospital/module/permission/update', body: params });
			if (authState?.currentHospital?.role === params.role) {
				dispatch(changeHospital({ ...authState?.currentHospital, permissions: params.permissions }));
				let updatedHospitalList = authState?.userDetails.hospital_list.map(hospital => {
					if (hospital.hospital_id === authState?.currentHospital?.value) {
						return { ...hospital, permissions: params.permissions };
					} else {
						return hospital;
					}
				});
				dispatch(updateHospitalPermissions(updatedHospitalList));
			}
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

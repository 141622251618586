import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getNotifications = createAsyncThunk(
	'getNotifications',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/notification/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const markReadNotification = createAsyncThunk(
	'markReadNotification',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({
				route: 'hospital/notification/markread',
				body: params,
			});
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Accordion, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FaUserMd, FaCalendarAlt, FaUserAlt, FaDownload } from 'react-icons/fa';
import BackButton from '../../components/BackButton';
import { callGetAPI } from '../../services/axios';
import { downloadPDFFile, getFormattedDateTime } from '../../utils/helper';

const HandOverHistoryDetail = () => {
	const [historyDetail, setHistoryDetail] = useState({});
	const { currentHospital } = useSelector(state => state.auth);
	const { hid, pid } = useParams();
	const [downloadLoading, setDownloadLoading] = useState(false);
	useEffect(() => {
		if (currentHospital.value) {
			callGetAPI({
				route: 'hospital/patient/handover/detail',
				params: { hospital_id: currentHospital.value, p_id: pid, id: hid, type: currentHospital.role == 'doctor' ? 'mo' : 'nurse' }
			}).then((data) => {
				setHistoryDetail(data.data.data);
			});
		}
	}, [currentHospital.value]);

	const downloadFile = () => {
		setDownloadLoading(true);
		callGetAPI({
			route: 'hospital/patient/handover/detail/download',
			params: { hospital_id: currentHospital.value, p_id: pid, id: hid, type: currentHospital.role == 'doctor' ? 'mo' : 'nurse' },
			options: {
				responseType: 'blob',
			}
		}).then((data) => {
			setDownloadLoading(false);
			downloadPDFFile(data?.data);
		});
	};

	return (
		<Container className='mt-4 p-0'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('HANDOVER.HANDOVER_HISTORY_DETAIL')}
					</h5>
					<div>
						<Button title={t('GENERAL.DOWNLOAD')} disabled={downloadLoading} size='sm' className='me-2' onClick={() => downloadFile()}><FaDownload /> {t('GENERAL.DOWNLOAD')}</Button>
						<BackButton />
					</div>
				</Col>
			</Row>
			<hr />
			{!isEmpty(historyDetail) &&
				<Row>
					<Col>
						<Accordion className='mb-2'>
							<Accordion.Item eventKey="0" className='shadow rounded'>
								<Accordion.Button style={{ backgroundColor: '#dff0d8' }} className='py-2 shadow rounded'>
									<span>
										<FaUserAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.NAME')} : {historyDetail.patient_info.panel_title.name}, <FaCalendarAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.DOA')} : {getFormattedDateTime(historyDetail.patient_info.panel_title.doa)}, <FaUserMd className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.ATTDR')} : {historyDetail.patient_info.panel_title.att_dr}
									</span>
								</Accordion.Button>
								<Accordion.Body>
									<Row xs={1} md={2} lg={3}>
										{!isEmpty(historyDetail.patient_info) && historyDetail?.patient_info?.panel_details.map((field, index) => {
											return (
												<Col key={index}>
													<h6>{field.title} : {field.value}</h6>
												</Col>);
										})}
									</Row>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<hr />
						<Row xs={1} md={2} lg={3}>
							{!isEmpty(historyDetail.clinical_form) && historyDetail?.clinical_form?.form_fields.map((field, index) => {
								if (field.type != 'formdata' && field.type != 'separator') {
									return (
										<Col key={index}>
											<h6><b>{field.title}</b> : <span
												dangerouslySetInnerHTML={{ __html: field.value }}
											/></h6>
										</Col>);
								}
							})}
						</Row>
						<hr />
						<Card>
							<Card.Header as="h5">{t('HANDOVER.HANDOVER_INFO')}</Card.Header>
							<Card.Body>
								<Card.Text as="div">
									<Row xs={1}>
										{!isEmpty(historyDetail.handover_info) && Object.entries(historyDetail.handover_info).map(([field, val]) => {
											return (
												<Col key={val}>
													<b>{field}</b> : {val}
												</Col>);
										})}
									</Row>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			}
		</Container>
	);
};

export default HandOverHistoryDetail;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Accordion, Alert, Button, Card, Col, Container, Modal, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, map, join, isString, compact } from 'lodash';
import moment from 'moment';
import { FaHistory } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import DynamicForm from '../../components/DynamicForm';
import PatientInfo from '../../components/Patient/patient-info';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { changeSubFormList, reset } from '../../redux/forms/slice';
import { getPatientInfo } from '../../redux/hospital';
import { getDailyAssessmentHistory, getFormFields, storeForm } from '../../redux/forms';
import { resetSaveTimerForm } from '../../redux/forms/slice';
import { getDefaultFormID, getFormattedDateTime } from '../../utils/helper';

const AddClinicalForm = () => {
	const [initialValues, setInitialValues] = useState({});
	const [initialValuesView, setInitialValuesView] = useState([]);
	const [showHistoryModal, setShowHistoryModal] = useState(false);
	const { currentPatient, currentPatientDetail, hospitalBedInfo } = useSelector(state => state.hospital);
	const { formFields, formFieldsError, storeFormResponse, storeFormError, saveTimerDataResponse, Loading, dailyAssessmentHistoryLoading, dailyAssessmentHistoryResponse, dailyAssessmentHistoryError } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { formId } = useParams();
	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
			}
			if (!isEmpty(currentHospital)) {
				dispatch(getFormFields({ hospital_id: currentHospital.value, form_id: formId, p_id: currentPatient.p_id, bed_type : currentPatient.bed_type }));
			}
		}
	}, [currentPatient, currentPatientDetail, currentHospital]);

	useEffect(() => {
		if (saveTimerDataResponse) {
			dispatch(resetSaveTimerForm());
			if (!isEmpty(currentHospital)) {
				dispatch(getFormFields({ hospital_id: currentHospital.value, default_form_id: getDefaultFormID('Drug Administration Orders(Nurse)'), p_id: currentPatient.p_id, bed_type : currentPatient.bed_type  }));
			}
		}
	}, [saveTimerDataResponse]);

	useEffect(() => {
		if (!isEmpty(formFields?.form_fields)) {
			// if (formFields.type == 'edit') {
			// 	navigate(`/clinical_forms/form/${formFields.id}/edit/${formFields.edit_id}?action=add`);
			// 	return;
			// }
			let fields = {};
			formFields?.form_fields.map((field) => {
				if (field.type != 'checkbox') {
					fields[field.field_name] = field?.default_values || field?.value || '';
				}
				else {
					fields[field.field_name] = field?.default_values?.split(',') || field?.default_values?.split(',') || [];
				}
			});
			fields['date_time'] = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
			setInitialValues(fields);
		}
	}, [formFields]);

	useEffect(() => {
		if (!isEmpty(storeFormResponse)) {
			navigate(-1);
		}
	}, [storeFormResponse]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(formFieldsError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [formFieldsError]);

	useEffect(() => {
		let arr = [];
		if (!isEmpty(dailyAssessmentHistoryResponse)) {
			dailyAssessmentHistoryResponse.map((record, recordIndex) => {
				if (!isEmpty(record?.form_fields)) {
					let fields = {};
					record.form_fields.map((field) => {
						if (field.type == 'datetime') {
							fields[field.field_name] = moment(field.value, ['YYYY-MM-DD HH:mm']).format('YYYY-MM-DDTHH:mm') || '';
						}
						else if (field.type == 'date') {
							fields[field.field_name] = moment(field.value, ['YYYY-MM-DD HH:mm']).format('YYYY-MM-DD') || '';
						}
						else {
							fields[field.field_name] = field.value || '';
						}
					});
					arr[recordIndex] = fields;
				}
				else {
					arr[recordIndex] = [];
				}
			});
			setInitialValuesView(arr);
		}
	}, [dailyAssessmentHistoryResponse]);

	const handleOpenHistoryModal = () => {
		dispatch(getDailyAssessmentHistory({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, form_id: formId }));
		setShowHistoryModal(true);
	};

	const goBack = () => {
		navigate(-1);
	};

	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changeSubFormList({ values, fieldindex, mode: 'add' }));
	};

	const handleSubmitFormData = (values) => {
		let formdata = new FormData();
		formdata.append('form_id', formFields.id);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('date_time', moment(values?.date_time).format('YYYY-MM-DD HH:mm:ss') || moment().format('YYYY-MM-DD HH:mm:ss'));
		formdata.append('formdata[form_unique_id]', formFields.form_unique_id);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			formFields.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
				else if (formfield.field_name === field[0] && formfield.type === 'select') {
					if (!isString(field[1])) {
						formdata.delete('formdata[' + field[0] + ']');
						if (formfield.possible_values != '*') {
							formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
						}
						else {
							formdata.append('formdata[' + field[0] + ']', join(map(field[1], 'value'), ','));
						}
					}
				}
				else if (formfield.field_name === field[0] && formfield.type === 'checkbox') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', join(compact(field[1]), ','));
				}
				/* else if (formfield.field_name === field[0] && formfield.type === 'text' && formfield.autocomplete != '0') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
				} */
			});
		});
		dispatch(storeForm(formdata));
	};

	return (
		<Container className='mt-4'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{formFields.name}</h5>
					<Stack gap='2' direction='horizontal'>
						{hospitalBedInfo?.form_ids?.dailyassessmentbymo == formId &&
							<Button title={t('CFORMS.TODAYHISTORY')} size='sm' onClick={handleOpenHistoryModal}>
								<FaHistory /> {t('CFORMS.TODAYHISTORY')}</Button>}
						<BackButton />
					</Stack>
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{Loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>
			}
			<Row className='mt-4'>
				<Col>
					{(formFieldsError || storeFormError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{formFieldsError && formFieldsError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{storeFormError && storeFormError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{formFields.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{formFields.name}
						</Card.Header>
						<Card.Body>
							{!isEmpty(initialValues) && <DynamicForm initialValues={initialValues} fields={formFields.form_fields} handleCancel={goBack} handleSubmit={handleSubmitFormData} parentformuniqueid={formFields.form_unique_id} handleUpdateSubFormList={handleUpdateSubFormList} defaultFormId={formFields.default_form_id} loading={Loading} action='add' />
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
			<Modal size='xl' show={showHistoryModal} onHide={() => setShowHistoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CFORMS.TODAYHISTORY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{dailyAssessmentHistoryLoading &&
						<Row className='d-flex justify-content-center mt-4'>
							<Loader />
						</Row>
					}
					{dailyAssessmentHistoryError &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{dailyAssessmentHistoryError && dailyAssessmentHistoryError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{!isEmpty(initialValuesView) &&
						<Accordion defaultActiveKey={['0']} alwaysOpen>
							{!isEmpty(dailyAssessmentHistoryResponse) && dailyAssessmentHistoryResponse?.map((record, recordIndex) => {
								return (<Accordion.Item key={recordIndex} eventKey={recordIndex}>
									<Accordion.Header>{t('CFORMS.COLUMNS.DATETIME')} : {getFormattedDateTime(record?.date_time)}</Accordion.Header>
									<Accordion.Body className='bg-white text-dark'>
										<DynamicForm initialValues={initialValuesView[recordIndex]} fields={record.form_fields} handleCancel={() => setShowHistoryModal(false)} handleSubmit={handleSubmitFormData} parentformuniqueid='' viewMode={true} loading={Loading} />
									</Accordion.Body>
								</Accordion.Item>);

							})}
						</Accordion>
					}
				</Modal.Body>
			</Modal>
		</Container >
	);
};

export default AddClinicalForm;

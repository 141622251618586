import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { has, isEmpty } from 'lodash';
import { Formik } from 'formik';
import DataTable from 'react-data-table-component';
import { Alert, Button, Card, Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { HiPencilAlt, HiPlus } from 'react-icons/hi';
import Loader from '../../components/SplashScreen/loader';
import HelpVideoButton from '../../components/HelpVideoButton';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { deleteHospitalBedType, getHospitalBedTypes, storeHospitalBedType, updateHospitalBedType } from '../../redux/hospital';
import { changeBedTypeValidationSchema } from '../../utils/validation';

const BedTypes = () => {
	const [showModal, setShowModal] = useState(false);
	const [selectedBed, setSelectedBed] = useState(null);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
	const { currentHospital } = useSelector(state => state.auth);
	const { bedTypesResponse, updateBedTypeResponse, storeBedTypeResponse, deleteBedTypeResponse, deleteBedTypeError, storeBedTypeError, updateBedTypeError, bedTypesError } = useSelector(state => state.hospital);
	const { Loading } = useSelector(state => state.hospital);
	const validationMessageRef = useRef();
	const dispatch = useDispatch();
	const columns = [
		{
			name: t('BEDTYPES.COLUMNS.BEDTYPE'),
			selector: row => row.bed_type,
		},
		{
			name: t('BEDTYPES.COLUMNS.COLOR'),
			selector: row => {
				return (
					<div className='d-flex align-items-center'>
						<div className='text-uppercase'>{row.color}</div>
						<div className='ms-2 colorbox' style={{ background: row.color }}></div>
					</div>);
			},
		},
		{
			name: t('GENERAL.ACTION'),
			cell: row => (
				<Stack gap={2} direction='horizontal'>
					<Button title={t('GENERAL.EDIT')} variant="warning" size="sm" className='text-white' onClick={() => handleShow(row)} >
						<HiPencilAlt />
					</Button>
					<Button title={t('GENERAL.DELETE')} variant="danger" size="sm" onClick={() => showDelete(row)} >
						<FaRegTrashAlt />
					</Button>
				</Stack>

			),
		},
	];
	const actions = (
		<>
			<HelpVideoButton videoKey={'manage_bed_labels_types'} />
			<Button title={t('BEDTYPES.BUTTON.ADDBEDTYPE')} variant='success' size='sm' onClick={() => handleShow(null)}>
				<HiPlus /> {t('BEDTYPES.BUTTON.ADDBEDTYPE')}
			</Button>
		</>
	);
	useEffect(() => {
		if (!isEmpty(storeBedTypeError) || !isEmpty(bedTypesError) || !isEmpty(updateBedTypeError) || !isEmpty(deleteBedTypeError)) {
			validationMessageRef.current?.scrollIntoView();
		}
	}, [storeBedTypeError, updateBedTypeError, bedTypesError, deleteBedTypeError]);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getHospitalBedTypes({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(updateBedTypeResponse) || !isEmpty(storeBedTypeResponse) || !isEmpty(deleteBedTypeResponse)) {
			dispatch(getHospitalBedTypes({ hospital_id: currentHospital.value }));
			setDisplayConfirmationModal(false);
		}
	}, [updateBedTypeResponse, storeBedTypeResponse, deleteBedTypeResponse]);

	const handleShow = (bedType) => {
		setSelectedBed(bedType);
		setShowModal(true);
	};

	const handleClose = () => {
		setSelectedBed(null);
		setShowModal(false);
	};
	const handleSubmit = (values) => {
		const payload = {
			'hospital_id': currentHospital.value,
			...values
		};
		if (has(payload, 'id')) {
			dispatch(updateHospitalBedType(payload));
		} else {
			dispatch(storeHospitalBedType(payload));
		}
		handleClose();
	};
	const showDelete = (bedType) => {
		setSelectedBed(bedType);
		setDisplayConfirmationModal(true);
	};
	const handleDelete = () => {
		dispatch(deleteHospitalBedType({ 'hospital_id': currentHospital.value, 'id': selectedBed?.id }));
	};
	return (
		<>
			<Card>
				<Card.Body>
					{storeBedTypeError &&
						<Alert variant="danger" ref={validationMessageRef}>
							<ul className='mb-0'>
								{storeBedTypeError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{updateBedTypeError &&
						<Alert variant="danger" ref={validationMessageRef}>
							<ul className='mb-0'>
								{updateBedTypeError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{bedTypesError &&
						<Alert variant="danger" ref={validationMessageRef}>
							<ul className='mb-0'>
								{bedTypesError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{deleteBedTypeError &&
						<Alert variant="danger" ref={validationMessageRef}>
							<ul className='mb-0'>
								{deleteBedTypeError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Row>
						<Col>
							<DataTable
								title={t('BEDTYPES.TITLE')}
								actions={actions}
								columns={columns}
								data={bedTypesResponse?.data}
								progressPending={Loading}
								progressComponent={<Loader />}
								highlightOnHover
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={() => setDisplayConfirmationModal(false)} id={selectedBed?.id} deleteRecord={selectedBed} message={`Do you really want to delete '${selectedBed?.bed_type}' ?`} title={t('GENERAL.AREUSURE')} buttonText={t('GENERAL.DELETE')} loading={Loading} />
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{selectedBed ? 'Edit Bed Type' : 'Add Bed Type'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={selectedBed || { bed_type: '', color: '#000000' }}
						validationSchema={changeBedTypeValidationSchema(t)}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, handleSubmit }) => (
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="name">
									<Form.Label>{t('GENERAL.BEDTYPE')}</Form.Label>
									<Form.Control
										type="text"
										name="bed_type"
										value={values.bed_type}
										onChange={handleChange}
										isInvalid={errors.bed_type && touched.bed_type}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.bed_type}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group controlId="color" className='mt-2'>
									<Form.Label>{t('GENERAL.COLOR')}</Form.Label>
									<Form.Control
										type="color"
										name="color"
										value={values.color}
										onChange={handleChange}
									/>
								</Form.Group>
								<hr />
								<Row>
									<Col>
										<Button title={t('GENERAL.SUBMIT')} variant="primary" type="submit">{t('GENERAL.SUBMIT')}</Button>
										<Button title={t('GENERAL.CANCEL')} className='ms-2' variant="danger" onClick={handleClose}>{t('GENERAL.CANCEL')}</Button>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>

	);
};

export default BedTypes;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import moment from 'moment';
import DynamicForm from '../../components/DynamicForm';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { editPatient, updatePatient } from '../../redux/patients';
import { reset } from '../../redux/patients/slice';
import { changePatientSubFormList } from '../../redux/patients/slice';
import RequiredAstric from '../../components/DynamicForm/required-astric';

const EditPatient = () => {
	const [initialValues, setInitialValues] = useState({});
	const { currentHospital } = useSelector(state => state.auth);
	const [regNo, setRegNo] = useState('');
	const { patientEditResponse, patientEditError, patientUpdateResponse, patientUpdateError, Loading } = useSelector(state => state.patients);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(editPatient({ hospital_id: currentHospital.value, id, patient_form: 'yes', bed_type: 'icu' }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(patientEditResponse?.form_fields)) {
			let fields = {};
			patientEditResponse?.form_fields.map((field) => {
				fields[field.field_name] = field.value;
			});
			setRegNo(patientEditResponse?.reg_no);
			setInitialValues(fields);
		}
	}, [patientEditResponse]);

	useEffect(() => {
		if (!isEmpty(patientUpdateResponse)) {
			navigate('/patients');
		}
	}, [patientUpdateResponse]);
	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(patientEditError) || !isEmpty(patientUpdateError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [patientEditError, patientUpdateError]);

	const backToPatientList = () => {
		navigate('/patients');
	};
	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changePatientSubFormList({ values, fieldindex, mode: 'edit' }));
	};

	const handleUpdatePatientSubmit = (values) => {
		let formdata = new FormData();
		formdata.append('id', id);
		formdata.append('reg_no', trim(regNo));
		formdata.append('form_id', patientEditResponse.id);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('date_time', moment().format('YYYY-MM-DD HH:mm:ss'));
		formdata.append('patient_form', 'yes');
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			patientEditResponse.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
			});
		});
		formdata.append('formdata[form_unique_id]', patientEditResponse.form_unique_id);
		dispatch(updatePatient(formdata));
	};

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('PATIENTEDIT.TITLE')}
					</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					{(patientEditError || patientUpdateError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{patientEditError && patientEditError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{patientUpdateError && patientUpdateError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{Loading &&
						<Row className='d-flex justify-content-center mt-4'>
							<Loader />
						</Row>}
					{patientEditResponse.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{patientEditResponse.name}
						</Card.Header>
						<Card.Body>
							{patientEditResponse?.reg_no && <Row className='text-center text-primary'>
								<h5>{t('GENERAL.REGNO')} : {patientEditResponse.reg_no}</h5>
							</Row>}
							<Form.Group className="mb-3" controlId={'reg_no'}>
								<Form.Label>
									Reg No. <RequiredAstric />
								</Form.Label>
								<Form.Control
									type='text'
									required
									placeholder='Enter Reg No.'
									name='reg_no'
									value={regNo}
									onChange={(e) => {
										setRegNo(e.target.value);
									}}
									onBlur={(e) => {
										setRegNo(e.target.value);
									}}
								/>
							</Form.Group>
							{!isEmpty(initialValues) &&
								<DynamicForm
									initialValues={initialValues}
									fields={patientEditResponse.form_fields}
									parentformuniqueid={id}
									handleCancel={backToPatientList}
									handleSubmit={handleUpdatePatientSubmit}
									handleUpdateSubFormList={handleUpdateSubFormList}
									loading={Loading}
									isPatientForm={true} />
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
		</Container >
	);
};

export default EditPatient;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI } from '../../services/axios';

export const getArchivedPatientList = createAsyncThunk(
	'getArchivedPatientList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/patients', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDischargeHistory = createAsyncThunk(
	'getDischargeHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/discharge/history', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getArchivedFormList = createAsyncThunk(
	'getArchivedFormList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/patient/forms', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getArchivedFormDownload = createAsyncThunk(
	'getArchivedFormDownload',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/form/download/details', params, options: {
				responseType: 'blob',
			}});
			return { response };
		} catch (err) {
			const errorBlob = err.response.data;
			const errorText = await errorBlob.text();
			const errorData = JSON.parse(errorText);
			return rejectWithValue(errorData);
		}
	},
);

export const getArchivedChartDataDownload = createAsyncThunk(
	'getArchivedChartDataDownload',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/patient/chartdata/download', params, options: {
				responseType: 'blob',
			}});
			return { response };
		} catch (err) {
			const errorBlob = err.response.data;
			const errorText = await errorBlob.text();
			const errorData = JSON.parse(errorText);
			return rejectWithValue(errorData);
		}
	},
);

export const getArchivedChatDataDownload = createAsyncThunk(
	'getArchivedChatDataDownload',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/patient/chatdata/download', params, options: {
				responseType: 'blob',
			}});
			return { response };
		} catch (err) {
			const errorBlob = err.response.data;
			const errorText = await errorBlob.text();
			const errorData = JSON.parse(errorText);
			return rejectWithValue(errorData);
		}
	},
);

export const getArchivedChartGraphDownload = createAsyncThunk(
	'getArchivedChartGraphDownload',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/archived/patient/chartgraph/download', params, options: {
				responseType: 'blob',
			}});
			return { response };
		} catch (err) {
			const errorBlob = err.response.data;
			const errorText = await errorBlob.text();
			const errorData = JSON.parse(errorText);
			return rejectWithValue(errorData);
		}
	},
);
import React, { useEffect, useState } from 'react';
import { Button, Modal, Stack, Table, Form, Alert } from 'react-bootstrap';
import { FaPlay, FaStop } from 'react-icons/fa';
import { isEmpty, includes } from 'lodash';
import { t } from 'i18next';
import { HiDownload, HiPlus } from 'react-icons/hi';
import moment from 'moment';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import RequiredAstric from './required-astric';
import { saveTimerData, } from '../../redux/forms';
import { resetSaveTimerForm } from '../../redux/forms/slice';


function DrugAdminOrders(props) {
	const { field } = props;
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState('');
	const [initialValues, setInitialValues] = useState({});
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { currentPatient } = useSelector(state => state.hospital);
	const { saveTimerDataResponse, saveTimerError, Loading } = useSelector(state => state.forms);
	useEffect(() => {
		if (saveTimerDataResponse) {
			setShow(false);
		}
	}, [saveTimerDataResponse]);

	const modalData = (action, fieldDetail, subformDetail) => {
		let fields = {};
		fields['time'] = moment().format('HH:mm');
		fields['action'] = action;
		fields['timer_type'] = fieldDetail.timer_type;
		fields['id'] = subformDetail?.id;
		fields['default_form_id'] = fieldDetail.default_form_id;
		if (action == 'end') {
			fields['total_volume_given'] = '';
		}
		setTitle(fieldDetail?.title);
		setInitialValues(fields);
		setShow(true);
	};
	const handleSubmit = (values) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('form_datetime', moment().format('YYYY-MM-DD HH:mm:ss'));
		Object.entries(values).map((field) => {
			if (field[0] == 'time') {
				formdata.append(field[0], moment(field[1], ['HH::mm']).format('hh:mm A'));
			}
			else {
				formdata.append(field[0], field[1]);
			}
		});
		dispatch(saveTimerData(formdata));
	};
	const handleSubmitDrugLog = (id, time, field) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('action', 'submission');
		formdata.append('id', id);
		formdata.append('timer_type', 2);
		formdata.append('form_datetime', moment().format('YYYY-MM-DD HH:mm:ss'));
		formdata.append('time', time);
		formdata.append('default_form_id', field.default_form_id);
		dispatch(saveTimerData(formdata));
	};
	return (
		<>
			<Table bordered responsive hover size="sm">
				<thead>
					<tr>
						{field.timer_type == 1 &&
							<>
								<th>{t('GENERAL.STARTTIME')}</th>
								<th>{t('GENERAL.ENDTIME')}</th>
							</>
						}
						{field.timer_type == 2 &&
							<>
								<th></th>
								<th>{t('GENERAL.ACTUALTIME')}</th>
							</>
						}
						{/* <th>#</th> */}
						{field.subform?.form_fields.map((subfromfield, index) => {
							if (!includes(['separator', 'subform', 'formdata', 'regno'], subfromfield.type))
								return (
									<th key={index}>{subfromfield.title} <span dangerouslySetInnerHTML={{ __html: subfromfield?.sub_title?.trim()?`<br>(${subfromfield.sub_title})`:'' }}></span></th>
								);
						})}
					</tr>
				</thead>
				<tbody>
					{!isEmpty(field?.value) && field.value.map((subfromdata, index) => {
						return (
							<tr key={index}>
								{field.timer_type == 1 &&
									<>
										<td>
											{subfromdata?.start_at_time == undefined || subfromdata?.start_at_time == '' ?
												<Button title={t('GENERAL.START')} size='sm' variant="primary" onClick={() => modalData('start', field, subfromdata)}>
													<FaPlay />
												</Button> :
												subfromdata?.start_at_time}
										</td>
										<td>
											<Button title={t('GENERAL.END')} size='sm' variant="danger" disabled={subfromdata?.start_at_time == undefined || subfromdata?.start_at_time == ''} onClick={() => modalData('end', field, subfromdata)}>
												<FaStop />
											</Button>
										</td>
									</>
								}
								{field.timer_type == 2 &&
									<>
										<td>
											<Stack gap='2'>
												{!isEmpty(subfromdata?.dai_data?.times) && subfromdata?.dai_data?.times?.map((dose, doseIndex) => {
													return (
														<Button title={dose.time} className='text-nowrap' disabled={dose.btn_disabled || Loading} key={doseIndex} variant={dose.btn_class} onClick={() => handleSubmitDrugLog(subfromdata.id, dose.time, field)}>
															{dose.time}
														</Button>);
												})}
												<Button title={t('GENERAL.CUSTOMADD')} className='text-nowrap' onClick={() => modalData('custom', field, subfromdata)}>
													<HiPlus />
												</Button>
											</Stack>
										</td>
										<td>
											<Stack gap='2'>
												{!isEmpty(subfromdata?.dai_data?.actual_times) && subfromdata?.dai_data?.actual_times?.map((dose, doseIndex) => {
													return (
														<Button title={dose.time} className='text-nowrap' disabled key={doseIndex} variant='success'>
															{dose.time}
														</Button>);
												})}
											</Stack>
										</td>
									</>
								}
								{/* <td>{subfromdata.id}</td> */}
								{subfromdata?.subform_data && subfromdata?.subform_data.map((subformfield) => {
									return (
										<React.Fragment key={subformfield['field_name']}>
											{(subformfield['type'] == 'file') ?
												<td>
													<a title={t('GENERAL.DOWNLOAD')} className='btn btn-sm btn-success' href={subformfield['file_url']} target='_blank' rel="noreferrer" download>
														<HiDownload />
													</a>
												</td> : <td>{subformfield['value']}</td>}
										</React.Fragment>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>

			<Modal show={show}>
				<Modal.Header onClick={() => { setShow(false); dispatch(resetSaveTimerForm());}} closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!isEmpty(saveTimerError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{saveTimerError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Formik initialValues={initialValues} onSubmit={handleSubmit} >
						{({ values, handleChange, handleBlur, handleSubmit }) => {
							return (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId='formGridDateTime'>
										<Form.Label>
											{values.action == 'start' && t('GENERAL.STARTTIME')}
											{values.action == 'end' && t('GENERAL.ENDTIME')}
											{values.action == 'custom' && t('GENERAL.TIME')} <RequiredAstric />
										</Form.Label>
										<Form.Control
											step="60"
											required
											type='time'
											name='time'
											value={values.time}
											onChange={handleChange}
											onBlur={handleBlur} />
									</Form.Group>
									{values.action == 'end' && values.default_form_id == 9 && <Form.Group className="mb-3" controlId='formGridTotalVolumn'>
										<Form.Label>
											{t('CFORMS.TOTALVOL')} <RequiredAstric /> (ml)
										</Form.Label>
										<Form.Control
											type='number'
											placeholder={t('CFORMS.PLACEHOLDER.TOTALVOL')}
											name='total_volume_given'
											value={values.total_volume_given}
											onChange={handleChange}
											onBlur={handleBlur}
											required />
									</Form.Group>}
									<hr />
									<Stack direction='horizontal' gap={2}>
										<Button title={t('GENERAL.SUBMIT')} variant="primary" type="submit" disabled={Loading}>{t('GENERAL.SUBMIT')}</Button>
										<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={() => { dispatch(resetSaveTimerForm());setShow(false);}}>{t('GENERAL.CANCEL')}</Button>
									</Stack>
								</Form>);
						}}
					</Formik>
				</Modal.Body>
			</Modal>
		</>

	);
}

export default DrugAdminOrders;
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { changeBackup, getBackup, getBackupAuthURL } from '../../redux/auth';
import { changeBackupComplete, resetBackupAuthUrl } from '../../redux/auth/slice';
import { changeBackupValidationSchema } from '../../utils/validation';
import RequiredAstric from '../../components/DynamicForm/required-astric';
import Loader from '../../components/SplashScreen/loader';

const Backup = () => {
	const { loading, backupError, backup, currentHospital, currentBackupSetting, currentBackupSettingError, backupAuthUrl, backupAuthUrlError } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				dispatch(getBackup({ hospital_id: currentHospital.value }));
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			dispatch(changeBackupComplete());
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getBackup({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(backupAuthUrl)) {
			window.open(backupAuthUrl?.url, '_blank');
			dispatch(resetBackupAuthUrl());
		}
	}, [backupAuthUrl]);


	const handleChangeBackupSubmit = (values) => {
		const payload = {
			'hospital_id': currentHospital.value,
			'type': currentHospital.value,
			'app_code': values.refresh_token,
			...values
		};
		dispatch(changeBackup(payload));
	};
	const handleType = (e, setFieldValue) => {
		setFieldValue('type', e.currentTarget.value);
	};

	const connectDrive = (values) => {
		if (!isEmpty(values.type)) {
			dispatch(getBackupAuthURL({ type: values.type, hospital_id: currentHospital.value }));
		}
	};
	return (
		<Card>
			<Card.Header className='bg-white fw-bold'>
				{t('BACKUP.TITLE')}
			</Card.Header>
			<Card.Body>
				{backupError &&
					<Alert variant="danger">
						<ul className='mb-0'>
							{backupError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{currentBackupSettingError &&
					<Alert variant="danger">
						<ul className='mb-0'>
							{currentBackupSettingError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{backupAuthUrlError &&
					<Alert variant="danger">
						<ul className='mb-0'>
							{backupAuthUrlError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{backup?.message &&
					<Alert variant="success">
						{backup?.message}
					</Alert>}
				{loading &&
					<Row className='d-flex justify-content-center mt-4'>
						<Loader />
					</Row>}
				{!isEmpty(currentBackupSetting) && <Formik initialValues={currentBackupSetting} validationSchema={changeBackupValidationSchema(t)} onSubmit={handleChangeBackupSubmit}
				>
					{({ errors, values, handleBlur, handleSubmit, setFieldValue }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mb-3 d-flex align-items-center" as={Col} controlId="formGridType">
								<Form.Label className='mb-0'>{t('BACKUP.FIELDS.TYPE')}<RequiredAstric /></Form.Label>
								<Form.Check
									inline
									checked={values.type === 'dropbox'}
									label={
										<span className='d-flex ml-2 gap-1 mt-1'>
											{'Dropbox'}
										</span>}
									value="dropbox"
									name="type"
									type='radio'
									id='inline-radio-1'
									className='ms-4 d-flex align-items-center mb-0 gap-1'
									onBlur={handleBlur}
									onChange={(e) => { handleType(e, setFieldValue); }}
								/>
								<Form.Check
									inline
									checked={values.type === 'google'}
									label={
										<span className='d-flex ml-2 gap-1 mt-1'>
											{'Google Drive'}
										</span>}
									value="google"
									name="type"
									type='radio'
									id='inline-radio-2'
									className='ms-4 d-flex align-items-center mb-0 gap-1'
									onBlur={handleBlur}
									onChange={(e) => { handleType(e, setFieldValue); }}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.type}
								</Form.Control.Feedback>
							</Form.Group>
							{currentBackupSetting?.email && !isEmpty(currentBackupSetting?.email) &&
								<div>
									<span className=''>{t('BACKUP.CONNECTED')}</span>
									<span className='fw-bold'>{currentBackupSetting?.email}</span>
								</div>
							}
							{(currentBackupSetting?.is_backup_token_expired === 1 || currentBackupSetting?.type != values.type) ?
								<Button title={t('GENERAL.CONNECT')} disabled={loading} variant="primary" type="button" className='mt-2' onClick={() => connectDrive(values)}>
									{t('GENERAL.CONNECT')}
								</Button>
								:
								<Button title={t('GENERAL.CHANGEACCOUNT')} disabled={loading} variant="primary" type="button" className='mt-2' onClick={() => connectDrive(values)}>
									{t('GENERAL.CHANGEACCOUNT')}
								</Button>
							}
						</Form>
					)}
				</Formik>}
			</Card.Body>
		</Card>
	);
};

export default Backup;

import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Loader from '../../components/SplashScreen/loader';
import { getArchivedPatientList } from '../../redux/archived-patients';
import { reset } from '../../redux/archived-patients/slice';
import useDebounce from '../../utils/useDebounceHook';

const ArchivedPatients = () => {
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { archivedPatientListError, archivedPatientList, Loading } = useSelector(state => state.archivedPatient);
	const debouncedSearchTerm = useDebounce(filterText, 500);
	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(archivedPatientList)) {
			setTotalRows(archivedPatientList?.total);
			setPerPage(archivedPatientList.per_page);
		}
	}, [archivedPatientList]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getArchivedPatientList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getArchivedPatientList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);
	const columns = [
		{
			name: t('ARCHIVEDPATIENTS.COLUMNS.REGNO'),
			cell: row => (
				<Link title={t('ARCHIVEDPATIENTS.COLUMNS.REGNO')} to={`/discharge-history/${row.id}`}>
					{row.reg_no}
				</Link>
			),
			sortable: true,
			sortField: 'reg_no',
		},
		{
			name: t('ARCHIVEDPATIENTS.COLUMNS.NAME'),
			selector: row => row.name,
			sortable: true,
			sortField: 'name',
		},
		{
			name: t('ARCHIVEDPATIENTS.COLUMNS.MOBILENO'),
			selector: row => row.mobile,
			sortable: true,
			sortField: 'mobile',
		},

	];

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	const handlePageChange = page => {
		dispatch(getArchivedPatientList({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getArchivedPatientList({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	return (
		<Container className='mt-4'>
			{archivedPatientListError &&
				<Alert variant='danger'>
					<ul className='mb-0'>
						{archivedPatientListError.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</Alert>}
			<Row>
				<Col>
					<DataTable
						title={t('ARCHIVEDPATIENTS.TITLE')}
						columns={columns}
						data={archivedPatientList.data}
						progressPending={Loading}
						progressComponent={<Loader />}
						highlightOnHover
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						onSort={(column, sortDirection) => setColumnSort({ column_name: column.sortField, order: sortDirection })}
						sortServer
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default ArchivedPatients;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Accordion, Col, Container, Row, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HiDownload } from 'react-icons/hi';
import BackButton from '../../components/BackButton';
import { callGetAPI } from '../../services/axios';
import Loader from '../../components/SplashScreen/loader';

const ViewDetails = () => {
	const [patientinfo, setPatientInfo] = useState([]);
	const [loading, setLoading] = useState(false);
	const { currentHospital } = useSelector(state => state.auth);
	const { id } = useParams();

	useEffect(() => {
		if (currentHospital.value) {
			setLoading(true);
			callGetAPI({
				route: 'hospital/patient/info',
				params: { hospital_id: currentHospital.value, p_id: id }
			}).then((data) => {
				setPatientInfo(data.data.data);
				setLoading(false);
			});
		}
	}, [currentHospital]);

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('PATIENTDETAIL.TITLE')}
					</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header className='p-0'>
								<Row className='w-100'>
									<Col>{t('PATIENTS.REGISTRATION_DETAILS')}</Col>
									<Col className='text-end'>
										<Link title={t('PATIENTEDIT.TITLE')} className='btn btn-success btn-sm text-right' to={`/patients/edit/${id}`}> {t('PATIENTEDIT.TITLE')}
										</Link>
									</Col>
								</Row>
							</Accordion.Header>
							<Accordion.Body>
								{!isEmpty(patientinfo) && <Row xs={1} md={2} lg={3}>
									{patientinfo.patient_info.panel_details.map((val, key) => {
										return <Col key={key}><h6>{val.title} : {val.value}</h6></Col>;
									})}
								</Row>}
								{loading ?
									<Row className='d-flex justify-content-center mt-4'>
										<Loader />
									</Row> :
									<Table bordered responsive>
										<thead>
											<tr>
												{patientinfo?.patient_info?.panel_upload_info?.header.map((title, index) => (
													<th key={index}>{title}</th>
												))}
											</tr>
										</thead>
										<tbody>
											{patientinfo?.patient_info?.panel_upload_info?.body?.length ? patientinfo?.patient_info?.panel_upload_info?.body.map((value, index) => (
												<tr key={index}>
													<td>{value[0]}</td>
													<td>{<a title={t('GENERAL.DOWNLOAD')} className='btn btn-sm btn-success' href={value[1]} target='_blank' rel="noreferrer" download>
														<HiDownload />
													</a>}</td>
												</tr>
											)) :
												<tr>
													<td colSpan={2} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
												</tr>
											}
										</tbody>
									</Table>
								}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Col>
			</Row>
		</Container >
	);
};

export default ViewDetails;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import Select from 'react-select';
import { Alert, Button, Card, Col, Container, Form, ListGroup, Modal, Row, Stack, Tab, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty, findIndex, first, find } from 'lodash';
import { FaChevronLeft, FaChevronRight, FaEdit, FaHandHoldingMedical, FaTachometerAlt, FaHistory, FaDownload } from 'react-icons/fa';
import { query, serverTimestamp, addDoc, collection, where, getDocs } from 'firebase/firestore';
import db from '../../services/firebase';
import MonitoringTable2 from '../../components/Monitoring/monitoring-table2';
import MedicinesTable from '../../components/Monitoring/medicines-table';
import InvestigationTable from '../../components/Monitoring/investigation-table';
import CommonModal from '../../components/CommonModal';
import { getPatientInfo } from '../../redux/hospital';
import { getMainChartTableData, getEWSChartTableData, getIOChartTableData, getVentilatorChartTableData, getInvestigationHistory, getMedicinesData } from '../../redux/monitoring';
import { changePatient, changePatientIpno } from '../../redux/hospital/slice';
import { sendCPRNotification } from '../../redux/users';
import { resetChartDataDownload } from '../../redux/monitoring/slice';
import { resetCPRResponse } from '../../redux/users/slice';
import { downloadPDFFile, getBetweenDates, getDateFormat } from '../../utils/helper';
import PatientInfo from '../../components/Patient/patient-info';
import Loader from '../../components/SplashScreen/loader';
import { callGetAPI } from '../../services/axios';
import { storePatientMessage } from '../../redux/chat/slice';

const MonitoringPatient = () => {
	const [tabKey, setTabKey] = useState('#link1');
	const [showMessage, setShowMessage] = useState('');
	const [admittedPatients, setAdmittedPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState({});
	const [selectedPatientIpnos, setSelectedPatientIpnos] = useState([]);
	const [selectedPatientIpno, setSelectedPatientIpno] = useState({});
	const [statDate, setStatDate] = useState(moment().format('YYYY-MM-DD'));
	const [show, setShow] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [admittedDates, setAdmittedDates] = useState([]);
	const { currentHospital, userDetails } = useSelector(state => state.auth);
	const { notificationCPRResponse, notificationCPRErrorMessage, Loading } = useSelector(state => state.users);
	const { currentPatient, currentPatientDetail, hospitalBedInfo, currentPatientIpno } = useSelector(state => state.hospital);
	const { ewsLoading, ewstableData, ewstableDataError, ioLoading, iotableData, iotableDataError, ventilatorLoading, ventilatortableData, ventilatortableDataError, refreshType, storeTableData, /* chartLoading, chartData, chartDataError, */ investigationLoading, investigationData, investigationDataError, medicinesLoading, medicinesData, medicinesDataError, mainChartLoading, mainCharttableData, mainCharttableDataError, chartDataDownload, chartDataDownloadError, medicineModalRateUpdate, refreshTabName } = useSelector(state => state.monitoring);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const changeDate = day => {
		if (day == 'next') {
			setStatDate(moment(statDate).add(1, 'days').format('YYYY-MM-DD'));
		}
		else if (day == 'prev') {
			setStatDate(moment(statDate).subtract(1, 'days').format('YYYY-MM-DD'));
		}
	};

	const getFirebasePatientChat = async() => {
		const q = query(collection(db, currentHospital.postfix), where('patientId', '==', currentPatient.ip_no));
		const querySnapshot = await getDocs(q);
		let oldMsg = [];
		querySnapshot.forEach((change) => {
			oldMsg.push(change.data());
		});
		if (!isEmpty(oldMsg)) {
			oldMsg.sort((x, y) => {
				return x.timestamp?.seconds - y.timestamp?.seconds;
			});
			oldMsg.forEach((doc) => {
				dispatch(storePatientMessage(doc));
			});
		}
	};
	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id) || (currentPatientIpno?.ip_no !== currentPatientDetail?.admission_info?.ip_no)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, ip_no : currentPatientIpno?.ip_no }));
				getFirebasePatientChat();
			}
			// for header patient select dropdown auto selected currect patient
			if (!isEmpty(currentPatient)) {
				setSelectedPatient({ 'value': currentPatient.p_id, 'label': currentPatient.patient_name });
				setSelectedPatientIpno({ 'value': currentPatientIpno?.ip_no, 'label': currentPatientIpno?.ip_no });
				setSelectedPatientIpnos((prevState) =>
					currentPatient?.ip_no_history?.map(ipno => (
						{
							value: ipno?.ip_no,
							label: ipno?.ip_no
						}
					))
				);
				if (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id) {
					setSelectedPatientIpno({ 'value': first(currentPatient?.ip_no_history)?.ip_no, 'label': first(currentPatient?.ip_no_history)?.ip_no });
				}
				setStatDate(currentPatientIpno?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD'));
				setAdmittedDates(getBetweenDates(currentPatientIpno?.discharged_at === null ? currentPatient?.current_date : currentPatientIpno?.discharged_at, currentPatientIpno?.date_of_admission));
			}
			// for header patient select dropdown options
			if (hospitalBedInfo?.available_patients) {
				const options = hospitalBedInfo?.available_patients.map((option) => {
					return { 'value': option.p_id, 'label': option.patient_name };
				});
				setAdmittedPatients(options);
			}
		}
	}, [currentPatient, currentPatientDetail, hospitalBedInfo, currentPatientIpno]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && currentPatient.p_id) {
			if (statDate && tabKey == '#link1') {
				dispatch(getMainChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'other' }));
				dispatch(getEWSChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'vitals_ews' }));
				dispatch(getIOChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'io_chart' }));
				if (currentPatient.bed_type == 'icu') {
					dispatch(getVentilatorChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'ventilator_dashboard' }));
				}
			}
			else if (statDate && tabKey == '#link2') {
				dispatch(getMedicinesData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate }));
			}
			else if (statDate && tabKey == '#link5') {
				dispatch(getInvestigationHistory({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate }));
			}
		}
	}, [statDate, currentPatient, currentPatientIpno, tabKey]);

	useEffect(() => {
		if (!isEmpty(medicineModalRateUpdate)) {
			if (refreshTabName == '#link2') {
				dispatch(getMedicinesData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate }));
			}
		}
	}, [refreshTabName, medicineModalRateUpdate]);

	useEffect(() => {
		if (storeTableData) {
			if (refreshType == 1) {
				dispatch(getMainChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'other' }));
			}
			else if (refreshType == 2) {
				dispatch(getIOChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'io_chart' }));
			}
			else if (refreshType == 3) {
				dispatch(getVentilatorChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'ventilator_dashboard' }));
			}
			else if (refreshType >= 4 || refreshType == 0) {
				dispatch(getEWSChartTableData({ hospital_id: currentHospital.value, ip_no:currentPatientIpno.ip_no, p_id: currentPatient.p_id, date: statDate, search_type: 'vitals_ews' }));
			}
		}
	}, [refreshType, storeTableData]);

	useEffect(() => {
		if (!isEmpty(chartDataDownload) && chartDataDownload?.path != '') {
			const hiddenButton = document.getElementById('pdf');
			hiddenButton?.setAttribute('href', chartDataDownload?.path);
			dispatch(resetChartDataDownload());
			hiddenButton?.click();
		}
	}, [chartDataDownload]);

	useEffect(() => {
		if (!isEmpty(notificationCPRResponse)) {
			if (notificationCPRResponse?.status_code === 200) {
				const payload = {
					'type': 'cpr',
					'timestamp': serverTimestamp(),
					'sender': userDetails.id,
					'message': currentPatient.patient_name + ' patient has gone under CPR',
					'patientId': currentPatient.ip_no
				};
				addDoc(collection(db, currentHospital.postfix), payload);
			}
			setShowMessage(notificationCPRResponse.message);
		}
		else if (!isEmpty(notificationCPRErrorMessage)) {
			setShowMessage(notificationCPRErrorMessage);
		}
	}, [notificationCPRResponse, notificationCPRErrorMessage]);

	const handleMessageClose = () => {
		setShowMessage('');
		dispatch(resetCPRResponse());
	};

	const handleChangePatient = (patient) => {
		let pIndex = findIndex(hospitalBedInfo?.booked_patient, ['p_id', patient?.value]);
		if (pIndex !== -1) {
			dispatch(changePatient(hospitalBedInfo?.booked_patient[pIndex]));
			setSelectedPatientIpno(first(hospitalBedInfo?.booked_patient[pIndex]?.ip_no_history)?.ip_no);
			dispatch(changePatientIpno(first(hospitalBedInfo?.booked_patient[pIndex]?.ip_no_history)));
		}
	};
	const handleChangePatientIpno = (newIpno) => {
		setSelectedPatientIpno(newIpno);
		const newIpnoDetail = find(currentPatient?.ip_no_history, { 'ip_no': newIpno.value });
		dispatch(changePatientIpno(newIpnoDetail));
		setStatDate(newIpnoDetail?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(newIpnoDetail?.date_of_admission).format('YYYY-MM-DD'));
		if (newIpnoDetail?.discharged_at === null) {
			setAdmittedDates(getBetweenDates(currentPatient?.current_date, currentPatient?.date_of_admission));
		}
		else {
			setAdmittedDates(getBetweenDates(newIpnoDetail?.discharged_at, newIpnoDetail?.date_of_admission));
		}
	};
	const sendCPR = () => {
		dispatch(sendCPRNotification({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
	};
	const handleChartData = (date) => {
		setDownloadLoading(true);
		callGetAPI({
			route: 'hospital/icuchart/data/download',
			params: { hospital_id: currentHospital.value, p_id: currentPatient.p_id, ip_no: currentPatientIpno.ip_no, date: moment(date, getDateFormat()).format(moment.HTML5_FMT.DATE) },
			options: {
				responseType: 'blob',
			}
		}).
			then((data) => {
				setDownloadLoading(false);
				downloadPDFFile(data?.data);
			});
	};
	return (
		<Container className='mt-4'>
			<Row className='mt-4'>
				<Col md={8} sm={12}>
					<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
				</Col>
				<Col md={4} sm={12}>
					<Stack gap='2' className='float-end' direction='horizontal'>
						{currentPatientIpno?.discharged_at === null && <Button disabled={Loading} title={t('PATIENTMONITORING.BUTTON.CPR')} size='sm' variant='danger' onClick={() => sendCPR()} >
							<FaHandHoldingMedical style={{ marginBottom: '3px', marginRight: '3px' }} /> {t('PATIENTMONITORING.BUTTON.CPR')}
						</Button>}
						{currentHospital.role != 'co-ordinator' && <Button title={t('PATIENTMONITORING.BUTTON.CLINICALFORMS')} size='sm' variant='warning' className='text-light' as={Link} to={`/clinical_forms/role/${currentHospital.role}`}>
							<FaEdit style={{ marginBottom: '3px', marginRight: '3px' }} /> {t('PATIENTMONITORING.BUTTON.CLINICALFORMS')}
						</Button>}
						<Button title={t('PATIENTMONITORING.BUTTON.DASHBOARD')} size='sm' variant='info' className='text-light' as={Link} to='/'>
							<FaTachometerAlt style={{ marginBottom: '3px', marginRight: '3px' }} /> {t('PATIENTMONITORING.BUTTON.DASHBOARD')}
						</Button>
					</Stack>
				</Col>
			</Row>
			<Card>
				<Card.Body>
					<Row>
						<Col>
							<Select
								styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
								value={selectedPatient}
								placeholder={t('PATIENTMONITORING.SELECTPATIENT')}
								options={admittedPatients}
								onChange={(val) => {
									setSelectedPatient(val);
									handleChangePatient(val);
								}} />
						</Col>
						{currentHospital?.permissions?.archive?.view === true && <Col md={3}>
							<Select
								styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
								value={selectedPatientIpno}
								placeholder={t('PATIENTMONITORING.SELECTPATIENTIPNO')}
								options={selectedPatientIpnos}
								onChange={(val) => {
									handleChangePatientIpno(val);
								}} />
						</Col>}
						<Col>
							<Stack direction='horizontal' gap={2}>
								<Button title={t('GENERAL.PREVDATE')} onClick={() => changeDate('prev')} disabled={statDate == moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD')}>
									<FaChevronLeft />
								</Button>
								<Form.Group className='w-100' controlId='chartDate'>
									<Form.Control
										type='date'
										name='chartDate'
										value={statDate}
										min={moment(currentPatientIpno?.date_of_admission).format(moment.HTML5_FMT.DATE)}
										max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATE) : moment(currentPatientIpno?.discharged_at).format(moment.HTML5_FMT.DATE)}
										onChange={(e) => {
											setStatDate(e.target.value);
										}} />
								</Form.Group>
								<Button title={t('GENERAL.NEXTDATE')} onClick={() => changeDate('next')} disabled={statDate == (currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format('YYYY-MM-DD') : moment(currentPatientIpno?.discharged_at).format('YYYY-MM-DD'))}>
									<FaChevronRight />
								</Button>
							</Stack>
						</Col>
					</Row>
					<Row className='mt-3'>
						<Tab.Container activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
							<Row>
								<Col>
									<ListGroup horizontal>
										<ListGroup.Item action href="#link1">
											{currentPatient.bed_type == 'icu' ? t('PATIENTMONITORING.BUTTON.ICUCHART') : t('PATIENTMONITORING.BUTTON.VITALCHART')}
										</ListGroup.Item>
										<ListGroup.Item action href="#link2">
											{t('PATIENTMONITORING.BUTTON.MEDICINESDASHBOARD')}
										</ListGroup.Item>
										<ListGroup.Item action as={Link} to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.consultantnotes}/list`}>
											{t('PATIENTMONITORING.BUTTON.CONSULTANTNOTES')}
										</ListGroup.Item>
										<ListGroup.Item action as={Link} to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.crossreference}/list`}>
											{t('PATIENTMONITORING.BUTTON.CROSSREFNOTE')}
										</ListGroup.Item>
										<ListGroup.Item action href="#link5">
											{t('PATIENTMONITORING.BUTTON.INVSHISTORY')}
										</ListGroup.Item>
									</ListGroup>
								</Col>
							</Row>
							<Row>
								<Tab.Content>
									<Tab.Pane eventKey="#link1">
										<Row>
											<Col>
												<Button title={t('PATIENTMONITORING.CHARTHISTORY')} size='sm' variant='success' className='float-end mt-2' onClick={() => setShow(true)}>
													<FaHistory /> {t('PATIENTMONITORING.CHARTHISTORY')}
												</Button>
											</Col>
										</Row>
										<MonitoringTable2 type='0' chartid='scrollews' statDate={statDate} title={t('PATIENTMONITORING.EWSPARAMETERS')} loading={ewsLoading} data={ewstableData} errors={ewstableDataError}></MonitoringTable2>

										<MonitoringTable2 type='1' chartid='scrollother' statDate={statDate} title={t('PATIENTMONITORING.OTHERS')} loading={mainChartLoading} data={mainCharttableData} errors={mainCharttableDataError}></MonitoringTable2>

										<MonitoringTable2 type='2' chartid='scrolliochart' statDate={statDate} title={t('PATIENTMONITORING.IOCHART')} loading={ioLoading} data={iotableData} errors={iotableDataError}></MonitoringTable2>

										{currentPatient.bed_type == 'icu' && <MonitoringTable2 type='3' chartid='scrollventilatorchart' statDate={statDate} title={t('PATIENTMONITORING.VENTILATORDASHBOARD')} loading={ventilatorLoading} data={ventilatortableData} errors={ventilatortableDataError}></MonitoringTable2>}

									</Tab.Pane>
									<Tab.Pane eventKey="#link2">
										{medicinesLoading &&
											<Row className='d-flex justify-content-center mt-4'>
												<Loader />
											</Row>
										}
										{!isEmpty(medicinesData) &&
											<MedicinesTable statDate={statDate} loading={medicinesLoading} data={medicinesData} errors={medicinesDataError}></MedicinesTable>
										}
									</Tab.Pane>
									<Tab.Pane eventKey="#link3">
										Tab 3
									</Tab.Pane>
									<Tab.Pane eventKey="#link4">
										Tab 4
									</Tab.Pane>
									<Tab.Pane eventKey="#link5">
										<InvestigationTable statDate={statDate} title={t('PATIENTMONITORING.INVSHISTORY')} loading={investigationLoading} data={investigationData} errors={investigationDataError}></InvestigationTable>
									</Tab.Pane>
								</Tab.Content>
							</Row>
						</Tab.Container>
					</Row>
				</Card.Body>
			</Card>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PATIENTMONITORING.CHARTHISTORY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!isEmpty(chartDataDownloadError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{chartDataDownloadError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
						<Table striped bordered hover responsive>
							<thead>
								<tr>
									<th>{t('GENERAL.DATE')}</th>
									<th>{t('GENERAL.DOWNLOAD')}</th>
								</tr>
							</thead>
							<tbody>
								{admittedDates.map((date, index) => {
									return (
										<tr key={index}>
											<td>{date}</td>
											<td>
												<Button title={t('GENERAL.DOWNLOAD')} size='sm' disabled={downloadLoading} onClick={() => handleChartData(date)}>
													<FaDownload />
												</Button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button title={t('GENERAL.CLOSE')} className='text-white' variant="secondary" onClick={() => setShow(false)}>
						{t('GENERAL.CLOSE')}
					</Button>
				</Modal.Footer>
			</Modal>
			{!isEmpty(showMessage) &&
				<CommonModal
					centered
					size='md'
					title={t('GENERAL.INFORMATION')}
					message={showMessage}
					handleClose={handleMessageClose} />}
		</Container >
	);
};

export default MonitoringPatient;

import React from 'react';
import { Modal, Row } from 'react-bootstrap';

function CommonModal({ message, handleClose, title, size, centered }) {
	return (
		<Modal show={true} onHide={handleClose} size={size} centered={centered}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<h5>{message}</h5>
				</Row>
			</Modal.Body>
		</Modal>
	);
}
export default CommonModal;
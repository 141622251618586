import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Card, Col, Container, Form, Image, ListGroup, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { RiLockPasswordLine, RiHotelBedLine, RiHospitalLine } from 'react-icons/ri';
import { HiPencilAlt } from 'react-icons/hi';
import { MdOutlineBackup } from 'react-icons/md';
import { TbFileInvoice } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { includes, isEmpty } from 'lodash';
import ChangePassword from './change-password';
import BackButton from '../../components/BackButton';
import Ledger from './ledger';
// import VideoCallLedger from './ledger-videocall';
import LedgerStatistics from './ledger-stat';
import Backup from './backup';
import RequiredAstric from '../../components/DynamicForm/required-astric';
import Loader from '../../components/SplashScreen/loader';
import BedConfig from './bed-config';
import HospitalConfig from './hospital-config';
import { updateProfile } from '../../redux/auth';
import { resetUpdateLogin } from '../../redux/auth/slice';
import { UserPlaceholder } from '../../images';
import { editProfileValidationSchema } from '../../utils/validation';
import { checkRoleAccess } from '../../utils/helper';
import { BiCategoryAlt } from 'react-icons/bi';
import BedTypes from './bed-types';

const Settings = () => {
	const [file, setFile] = useState(UserPlaceholder);
	const [img, setImage] = useState();
	const [key, setKey] = useState('connects');
	const [showEditForm, setShowEditForm] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const locationArr = location.pathname.split('/');
	const locationName = (locationArr.length > 2) ? locationArr[2] : 'profile';
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, updateLoginUser, updateLoginUserError, userDetails, currentHospital } = useSelector(state => state.auth);

	if (!includes(['profile', 'change-password', 'ledger', 'backup', 'bedconfig', 'hospitalconfig', 'bedtypes'], locationName)) {
		navigate('/404-page');
	}
	useEffect(() => {
		if (!isEmpty(userDetails)) {
			setInitialValues(userDetails);
			if (userDetails.profile_picture) {
				setFile(userDetails.profile_picture);
			}
		}
		return () => {
			setInitialValues({});
		};
	}, [userDetails]);

	useEffect(() => {
		if (!isEmpty(updateLoginUser)) {
			setShowEditForm(false);
		}
	}, [updateLoginUser]);

	const handleUserUpdateSubmit = (values) => {
		const payload = {
			'first_name': values.first_name,
			'last_name': values.last_name,
			'hospital_id': currentHospital.value
		};
		if (img) {
			payload.profile_picture = img;
		}
		dispatch(updateProfile(payload));
	};
	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
		setFile(URL.createObjectURL(e.target.files[0]));
	};
	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('SETTINGS.TITLE')}
					</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					<Tab.Container id='list-group-tabs-example' activeKey={locationName ?? 'profile'} defaultActiveKey={locationName}>
						<Row>
							<Col sm={3}>
								<Card text='dark' bg='white'>
									<Card.Header className='bg-white'>
										{t('SETTINGS.NAVTITLE')}
									</Card.Header>
									<Card.Body>
										<ListGroup>
											<ListGroup.Item as={Link} action to='/settings' eventKey='profile'>
												<FiSettings />
												<span className='ms-2'>{t('GENERAL.GENERAL')}</span>
											</ListGroup.Item>
											<ListGroup.Item as={Link} action to='/settings/change-password' eventKey='change-password'>
												<RiLockPasswordLine />
												<span className='ms-2'>{t('GENERAL.CHNGPASS')}</span>
											</ListGroup.Item>
											{checkRoleAccess(['owner', 'admin'], currentHospital) &&
												<ListGroup.Item as={Link} action to='/settings/ledger' eventKey='ledger'>
													<TbFileInvoice />
													<span className='ms-2'>{t('GENERAL.LEDGER')}</span>
												</ListGroup.Item>
											}
											{checkRoleAccess(['owner', 'admin'], currentHospital) &&
												<ListGroup.Item as={Link} action to='/settings/backup' eventKey='backup'>
													<MdOutlineBackup />
													<span className='ms-2'>{t('GENERAL.BACKUP')}</span>
												</ListGroup.Item>
											}
											{checkRoleAccess(['owner', 'admin'], currentHospital) &&
												<ListGroup.Item as={Link} action to='/settings/bedtypes' eventKey='bedtypes'>
													<BiCategoryAlt />
													<span className='ms-2'>{t('GENERAL.BEDTYPES')}</span>
												</ListGroup.Item>
											}
											{checkRoleAccess(['owner', 'admin'], currentHospital) &&
												<ListGroup.Item as={Link} action to='/settings/bedconfig' eventKey='bedconfig'>
													<RiHotelBedLine />
													<span className='ms-2'>{t('GENERAL.BEDS')}</span>
												</ListGroup.Item>
											}
											{checkRoleAccess(['owner', 'admin'], currentHospital) &&
												<ListGroup.Item as={Link} action to='/settings/hospitalconfig' eventKey='hospitalconfig'>
													<RiHospitalLine />
													<span className='ms-2'>{t('GENERAL.HOSPITALCONFIGS')}</span>
												</ListGroup.Item>
											}
										</ListGroup>
									</Card.Body>
								</Card>
							</Col>
							<Col sm={9}>
								<Tab.Content>
									<Tab.Pane eventKey='profile'>
										<Card text='dark' bg='white'>
											<Card.Header className='bg-white d-flex align-items-center justify-content-between'>
												<div className='fw-bold'>
													{t('SETTINGS.PROFILE')}
												</div>
												{!showEditForm && <Button title={t('GENERAL.EDIT')} size='sm' onClick={() => setShowEditForm(true)}>
													<HiPencilAlt /> {t('GENERAL.EDIT')}
												</Button>}
											</Card.Header>
											<Card.Body>
												{showEditForm ?
													<Row>
														{updateLoginUserError &&
															<Alert variant='danger'>
																<ul className='mb-0'>
																	{updateLoginUserError.split('|').map((error, index) => (
																		<li key={index}>{error}</li>
																	))}
																</ul>
															</Alert>}
														<Formik initialValues={initialValues} validationSchema={editProfileValidationSchema(t)} onSubmit={handleUserUpdateSubmit} >
															{({ errors, touched, values, handleChange, handleBlur, handleSubmit, resetForm }) => (
																<Form noValidate onSubmit={handleSubmit}>
																	<Row className='mb-3'>
																		<Col md='3'>
																			<Image className='shadow-sm' width={200} height={200} thumbnail src={file} />
																		</Col>
																		<Col md='9'>
																			<Form.Group className='mb-3' controlId='formGridFirstName'>
																				<Form.Label>{t('USEREDIT.FIELDS.FIRSTNAME')}<RequiredAstric /></Form.Label>
																				<Form.Control
																					type='text'
																					placeholder={t('USEREDIT.PLACEHOLDER.FIRSTNAME')}
																					name='first_name'
																					value={values.first_name}
																					onChange={handleChange}
																					onBlur={handleBlur}
																					isInvalid={errors.first_name && touched.first_name} />
																				<Form.Control.Feedback type='invalid'>
																					{errors.first_name}
																				</Form.Control.Feedback>
																			</Form.Group>

																			<Form.Group className='mb-3' controlId='formGridLastName'>
																				<Form.Label>{t('USEREDIT.FIELDS.LASTNAME')}<RequiredAstric /></Form.Label>
																				<Form.Control
																					type='text'
																					placeholder={t('USEREDIT.PLACEHOLDER.LASTNAME')}
																					name='last_name'
																					value={values.last_name}
																					onChange={handleChange}
																					onBlur={handleBlur}
																					isInvalid={errors.last_name && touched.last_name} />
																				<Form.Control.Feedback type='invalid'>
																					{errors.last_name}
																				</Form.Control.Feedback>
																			</Form.Group>

																			<Form.Group className='mb-3' controlId='formGridProfilePicture'>
																				<Form.Label>{t('USEREDIT.FIELDS.PROFILEPIC')}</Form.Label>
																				<Form.Control
																					type='file'
																					accept='image/png, image/jpeg'
																					placeholder={t('USEREDIT.PLACEHOLDER.PROFILEPIC')}
																					name='profile_picture'
																					onChange={handleImageChange}
																					onBlur={handleBlur}
																					isInvalid={errors.profile_picture && touched.profile_picture} />
																				<Form.Control.Feedback type='invalid'>
																					{errors.profile_picture}
																				</Form.Control.Feedback>
																			</Form.Group>
																			<Button title={t('GENERAL.UPDATE')} disabled={loading} variant='success' type='submit'>
																				{loading && <Loader variant='light' size='sm' />} {t('GENERAL.UPDATE')}
																			</Button>
																			<Button title={t('GENERAL.CANCEL')} className='ms-2' variant='danger' onClick={() => {
																				resetForm();
																				setShowEditForm(false);
																				dispatch(resetUpdateLogin());
																				setFile(userDetails.profile_picture || UserPlaceholder);
																			}}>
																				{t('GENERAL.CANCEL')}
																			</Button>
																		</Col>
																	</Row>
																</Form>
															)}
														</Formik>
													</Row>
													:
													<Row>
														<Col md='3'>
															<Image className='shadow-sm' width={200} height={200} thumbnail src={userDetails.profile_picture || UserPlaceholder} />
														</Col>
														<Col md='9'>
															<Table borderless size='sm'>
																<tbody>
																	<tr>
																		<td><span className='fw-bold'>{t('SETTINGS.FIRSTNAME')} : </span> {userDetails.first_name}</td>
																	</tr>
																	<tr>
																		<td><span className='fw-bold'>{t('SETTINGS.LASTNAME')} : </span> {userDetails.last_name}</td>
																	</tr>
																	<tr>
																		<td><span className='fw-bold'>{t('SETTINGS.USERNAME')} : </span> {userDetails.username}</td>
																	</tr>
																	<tr>
																		<td><span className='fw-bold'>{t('SETTINGS.EMAIL')} : </span> {userDetails.email}</td>
																	</tr>
																	<tr>
																		<td><span className='fw-bold'>{t('SETTINGS.MOBILE')} : </span> {userDetails.mobile_number}</td>
																	</tr>
																	<tr>
																		<td className='text-capitalize'><span className='fw-bold'>{t('SETTINGS.ISADMIN')} : </span> {currentHospital.is_admin}</td>
																	</tr>
																	<tr>
																		<td className='text-capitalize'><span className='fw-bold'>{t('SETTINGS.ROLE')} : </span> {currentHospital.role_name}</td>
																	</tr>
																	<tr>
																		<td className='text-capitalize'><span className='fw-bold'>{t('SETTINGS.STATUS')} : </span> {userDetails.status}</td>
																	</tr>
																</tbody>
															</Table>
														</Col>
													</Row>
												}
											</Card.Body>
										</Card>
									</Tab.Pane>
									<Tab.Pane eventKey='change-password'>
										<ChangePassword />
									</Tab.Pane>
									{checkRoleAccess(['owner', 'admin'], currentHospital) &&
										<Tab.Pane eventKey='ledger'>
											{locationName == 'ledger' && <LedgerStatistics />}
										</Tab.Pane>
									}
									{checkRoleAccess(['owner', 'admin'], currentHospital) &&
										<Tab.Pane eventKey='backup'>
											{locationName == 'backup' && <Backup />}
										</Tab.Pane>
									}
									{checkRoleAccess(['owner', 'admin'], currentHospital) &&
										<Tab.Pane eventKey='bedtypes'>
											{locationName == 'bedtypes' && <BedTypes />}
										</Tab.Pane>
									}
									{checkRoleAccess(['owner', 'admin'], currentHospital) &&
										<Tab.Pane eventKey='bedconfig'>
											{locationName == 'bedconfig' && <BedConfig />}
										</Tab.Pane>
									}
									{checkRoleAccess(['owner', 'admin'], currentHospital) &&
										<Tab.Pane eventKey='hospitalconfig'>
											{locationName == 'hospitalconfig' && <HospitalConfig />}
										</Tab.Pane>
									}
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Col>
			</Row>
			{checkRoleAccess(['owner', 'admin'], currentHospital) && locationName == 'ledger' &&
				<Row>
					<Col>
						<hr></hr>
						<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
							<Tab eventKey="connects" title={t('LEDGER.TABS.CONNECTS')}>
								<Ledger />
							</Tab>
							{/* <Tab eventKey="videocalls" title={t('LEDGER.TABS.VIDEOCALLS')}>
								<VideoCallLedger />
							</Tab> */}

						</Tabs>
					</Col>
				</Row>
			}
		</Container >
	);
};

export default Settings;

import { createSlice } from '@reduxjs/toolkit';
import { getUsers, updateUser, addUser, deleteUser, getUserDetail, inviteConsultant, adminResetPassword, sendCPRNotification, sendNudgeNotification, getVideoCallToken, getModulePermission, updatePermissions } from '../users';

const initialState = {
	userList: [],
	userData: [],
	userErrorMessage: '',
	Loading: false,

	userProfileDetails: [],
	userProfileErrorMessage: '',

	isUserUpdateSuccess: false,
	updateUserErrorMessage: '',

	isUserAddedSuccess: false,
	addUserErrorMessage: '',

	isUserDeletedSuccess: false,
	isUserDeleteErrorMessage: '',

	inviteConsultantResponse: {},
	inviteConsultantStatus:0,
	inviteConsultantErrorMessage : '',

	isResetSuccess: false,
	resetErrorMessage: '',

	notificationCPRResponse: {},
	notificationCPRErrorMessage: '',

	notificationNudgeResponse: {},
	notificationNudgeErrorMessage: '',

	videoCallTokenResponse: {},
	videoCallTokenErrorMessage: '',

	modulePermission : [],
	modulePermissionError : '',

	updatePermissionsResponse : {},
	updatePermissionsError : '',
};

const userSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		reset: () => initialState,
		resetCPRResponse: (state) => {
			state.notificationCPRResponse =  {};
			state.notificationCPRErrorMessage = '';
		},
		resetJoinConsultant:(state) => {
			state.inviteConsultantResponse= {};
			state.inviteConsultantErrorMessage = '';
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getUsers.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getUsers.fulfilled, (state, action) => {
			state.Loading = false;
			state.userList = action.payload?.response.data.data.data;
			state.userData = action.payload?.response.data.data;
			state.isUserUpdateSuccess = false;
			state.isUserDeletedSuccess = false;
			state.isUserAddedSuccess = false;
			state.addUserErrorMessage = '';
			state.updateUserErrorMessage = '';
			state.isUserDeleteErrorMessage = '';
			state.userProfileDetails = [];
		});
		builder.addCase(getUsers.rejected, (state) => {
			state.Loading = false;
			state.userErrorMessage = '';
		});

		builder.addCase(getUserDetail.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getUserDetail.fulfilled, (state, action) => {
			state.Loading = false;
			state.userProfileDetails = action.payload?.response.data.data;
		});
		builder.addCase(getUserDetail.rejected, (state, err) => {
			state.Loading = false;
			state.userProfileErrorMessage = err;
		});

		builder.addCase(updateUser.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updateUser.fulfilled, (state) => {
			state.Loading = false;
			state.isUserUpdateSuccess = true;
		});
		builder.addCase(updateUser.rejected, (state, err) => {
			state.Loading = false;
			state.updateUserErrorMessage = err;
		});

		builder.addCase(addUser.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(addUser.fulfilled, (state) => {
			state.Loading = false;
			state.isUserAddedSuccess = true;
		});
		builder.addCase(addUser.rejected, (state, err) => {
			state.Loading = false;
			state.addUserErrorMessage = err?.payload?.message;
		});

		builder.addCase(deleteUser.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(deleteUser.fulfilled, (state) => {
			state.Loading = false;
			state.isUserDeletedSuccess = true;
		});
		builder.addCase(deleteUser.rejected, (state, err) => {
			state.Loading = false;
			state.isUserDeleteErrorMessage = err;
		});

		builder.addCase(inviteConsultant.pending, (state) => {
			state.Loading = true;
			state.inviteConsultantResponse ={};
			state.inviteConsultantErrorMessage = '';
			state.inviteConsultantStatus = 0;
		});
		builder.addCase(inviteConsultant.fulfilled, (state, action) => {
			state.Loading = false;
			state.inviteConsultantStatus = action.payload?.response?.status;
			state.inviteConsultantResponse = action.payload?.response.data;
		});
		builder.addCase(inviteConsultant.rejected, (state, err) => {
			state.Loading = false;
			state.inviteConsultantErrorMessage = err?.payload?.message;
		});

		builder.addCase(adminResetPassword.pending, (state) => {
			state.loading = true;
			state.isResetSuccess = false;
			state.resetErrorMessage = '';
		});
		builder.addCase(adminResetPassword.fulfilled, (state) => {
			state.Loading = false;
			state.isResetSuccess = true;
		});
		builder.addCase(adminResetPassword.rejected, (state, err) => {
			state.Loading = false;
			state.resetErrorMessage = err.payload?.message;
		});

		builder.addCase(sendCPRNotification.pending, (state) => {
			state.Loading = true;
			state.notificationCPRResponse = {};
			state.notificationCPRErrorMessage = '';
		});
		builder.addCase(sendCPRNotification.fulfilled, (state, action) => {
			state.Loading = false;
			state.notificationCPRResponse = action.payload?.response.data;
		});
		builder.addCase(sendCPRNotification.rejected, (state, err) => {
			state.Loading = false;
			state.notificationCPRErrorMessage = err.payload?.message;
		});

		builder.addCase(sendNudgeNotification.pending, (state) => {
			state.Loading = true;
			state.notificationNudgeResponse = {};
			state.notificationNudgeErrorMessage = '';
		});
		builder.addCase(sendNudgeNotification.fulfilled, (state, action) => {
			state.Loading = false;
			state.notificationNudgeResponse = action.payload?.response.data;
		});
		builder.addCase(sendNudgeNotification.rejected, (state, err) => {
			state.Loading = false;
			state.notificationNudgeErrorMessage = err.payload?.message;
		});

		builder.addCase(getVideoCallToken.pending, (state) => {
			state.Loading = true;
			state.videoCallTokenResponse = {};
			state.videoCallTokenErrorMessage = '';
		});
		builder.addCase(getVideoCallToken.fulfilled, (state, action) => {
			state.Loading = false;
			state.videoCallTokenResponse = action.payload?.response.data;
		});
		builder.addCase(getVideoCallToken.rejected, (state, err) => {
			state.Loading = false;
			state.videoCallTokenErrorMessage = err.payload?.message;
		});

		builder.addCase(getModulePermission.pending, (state) => {
			state.Loading = true;
			state.modulePermission = [];
			state.modulePermissionError = '';
			state.updatePermissionsResponse = {};
			state.updatePermissionsError = '';
		});
		builder.addCase(getModulePermission.fulfilled, (state, action) => {
			state.Loading = false;
			state.modulePermission = action.payload?.response.data.data;
		});
		builder.addCase(getModulePermission.rejected, (state, err) => {
			state.Loading = false;
			state.modulePermissionError = err.payload?.message;
		});

		builder.addCase(updatePermissions.pending, (state) => {
			state.Loading = true;
			state.updatePermissionsResponse = {};
			state.updatePermissionsError = '';
		});
		builder.addCase(updatePermissions.fulfilled, (state, action) => {
			state.Loading = false;
			state.updatePermissionsResponse = action.payload?.response.data;
		});
		builder.addCase(updatePermissions.rejected, (state, err) => {
			state.Loading = false;
			state.updatePermissionsError = err.payload?.message;
		});
	},
});
export const { reset, resetCPRResponse, resetJoinConsultant } = userSlice.actions;

export default userSlice.reducer;
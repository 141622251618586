import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import RequiredAstric from '../../../components/DynamicForm/required-astric';
import { registerConsultant, usernameSuggestion } from '../../../redux/auth';
import { registerConsultantValidationSchema } from '../../../utils/validation';
import useQuery from '../../../utils/useQuery';
import { slugify } from '../../../utils/helper';
import useDebounce from '../../../utils/useDebounceHook';
import { resetSuggestion } from '../../../redux/auth/slice';
import PasswordInput from '../../../components/Common/password-input';

const RegisterConsultant = () => {
	const [filterText, setFilterText] = useState('');
	const { usernameSuggestionResponse } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const { registerConsultantResponse, registerConsultantResponseError } = useSelector(state => state.auth);
	const { token } = useQuery();
	const navigate = useNavigate();
	const debouncedSearchTerm = useDebounce(filterText, 500);
	const initialValues = {
		'first_name': '',
		'last_name': '',
		'username': '',
		'password': '',
		'password_confirmation': ''
	};
	useEffect(() => {
		if (isEmpty(token)) {
			navigate('/invalid-link');
		}
	}, [token]);

	useEffect(() => {
		if (!isEmpty(debouncedSearchTerm)) {
			const body = { username: debouncedSearchTerm, /* , first_name: values.first_name, last_name: values.last_name */ };
			dispatch(usernameSuggestion({ body }));
		}
		else {
			dispatch(resetSuggestion());
		}
	}, [debouncedSearchTerm]);

	const userNameSuggestion = (value, values, setFieldValue) => {
		setFieldValue('username', slugify(value));
		setFilterText(slugify(value));
	};
	const handleResetPassword = (values) => {
		dispatch(registerConsultant({ token, ...values }));
	};

	return (
		<Container className='vh-100 d-flex align-items-center justify-content-center'>
			<Row>
				<Col className='p-5 bg-white shadow-lg rounded'>
					<h1 className='text-center text-primary'>{t('REGISTERCONSULTANT.TITLE')}</h1>
					{isEmpty(registerConsultantResponse) && <p className='text-center text-muted fs-6'>{t('REGISTERCONSULTANT.SUB_TITLE')}</p>}
					{(registerConsultantResponseError) &&
						<Alert variant='danger' className='mt-2'>
							{registerConsultantResponseError && registerConsultantResponseError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</Alert>}
					{!isEmpty(registerConsultantResponse) ?
						<>
							<Alert variant='success' className='mt-2'>
								{t('REGISTERCONSULTANT.SUCCESS')}
							</Alert>
							<Button title={t('LOGIN.BUTTON')} className='d-grid' as={Link} to='/login'>
								{t('LOGIN.BUTTON')}
							</Button>
						</>
						:
						<Formik initialValues={initialValues} validationSchema={registerConsultantValidationSchema(t)} onSubmit={handleResetPassword}>
							{({ errors, touched, values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Row className="mb-3">
										<Form.Group className="mb-3" as={Col} controlId="formGridFirstName">
											<Form.Label>{t('USERADD.FIELDS.FIRSTNAME')}<RequiredAstric /></Form.Label>
											<Form.Control
												type="text"
												placeholder={t('USERADD.PLACEHOLDER.FIRSTNAME')}
												name="first_name"
												value={values.first_name}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.first_name && touched.first_name} />
											<Form.Control.Feedback type="invalid">
												{errors.first_name}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group className="mb-3" as={Col} controlId="formGridLastName">
											<Form.Label>{t('USERADD.FIELDS.LASTNAME')}<RequiredAstric /></Form.Label>
											<Form.Control
												type="text"
												placeholder={t('USERADD.PLACEHOLDER.LASTNAME')}
												name="last_name"
												value={values.last_name}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.last_name && touched.last_name} />
											<Form.Control.Feedback type="invalid">
												{errors.last_name}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>

									<Row className="mb-3">
										<Form.Group as={Col} controlId="formGridUsername">
											<Form.Label>{t('USERADD.FIELDS.USERNAME')}<RequiredAstric /></Form.Label>
											<Form.Control
												type="text"
												placeholder={t('USERADD.PLACEHOLDER.USERNAME')}
												name="username"
												value={values.username}
												onChange={(e) => {
													userNameSuggestion(e.target.value, values, setFieldValue);
												}}
												onBlur={handleBlur}
												isInvalid={(errors.username && touched.username) || (!isEmpty(values.username) && !(usernameSuggestionResponse?.current?.available) === true)}
												isValid={usernameSuggestionResponse?.current?.available && !isEmpty(values.username)} />
											<Form.Control.Feedback type="invalid">
												{errors.username || (!(usernameSuggestionResponse?.current?.available) && t('USERADD.ERRORS.ALREADYUSERNAME'))}
											</Form.Control.Feedback>
											<Form.Control.Feedback>
												{t('USERADD.AVAILABLEUSERNAME')}
											</Form.Control.Feedback>
											{!isEmpty(usernameSuggestionResponse?.other_usernames) &&
												<span className="">
													{t('GENERAL.AVAILABLE') + ': '}
													{usernameSuggestionResponse?.other_usernames?.map((name, index) => {
														return <Button title={name} size='sm' variant='link' key={index} onClick={() => userNameSuggestion(name, values, setFieldValue)}> {name} </Button>;
													})}
												</span>}
										</Form.Group>

										<Form.Group as={Col} controlId="formGridMobile">
											<Form.Label>{t('USERADD.FIELDS.MOBILE')}<RequiredAstric /></Form.Label>
											<Form.Control
												type="mobile_number"
												placeholder={t('USERADD.PLACEHOLDER.MOBILE')}
												name="mobile_number"
												value={values.mobile_number}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.mobile_number && touched.mobile_number} />
											<Form.Control.Feedback type="invalid">
												{errors.mobile_number}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Group className="mb-3" as={Col} controlId="formGridPassword">
											<Form.Label>{t('REGISTERCONSULTANT.FIELDS.PASSWORD')}<RequiredAstric /></Form.Label>
											<PasswordInput
												placeholder={t('REGISTERCONSULTANT.PLACEHOLDER.PASSWORD')}
												name="password"
												value={values.password}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.password && touched.password} />
											<Form.Control.Feedback type="invalid">
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group className="mb-3" as={Col} controlId="formGridPasswordConfirm">
											<Form.Label>{t('REGISTERCONSULTANT.FIELDS.PASSWORDCONFIRM')}<RequiredAstric /></Form.Label>
											<PasswordInput
												placeholder={t('REGISTERCONSULTANT.PLACEHOLDER.PASSWORDCONFIRM')}
												name="password_confirmation"
												value={values.password_confirmation}
												onChange={handleChange}
												onBlur={handleBlur}
												isInvalid={errors.password_confirmation && touched.password_confirmation} />
											<Form.Control.Feedback type="invalid">
												{errors.password_confirmation}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Stack>
										<Button title={t('REGISTERCONSULTANT.RESETPASSWORD_BUTTON')} size={'lg'} variant="primary" type="submit">
											{t('REGISTERCONSULTANT.REGISTER_BUTTON')}
										</Button>
									</Stack>
								</Form>
							)}
						</Formik>
					}
				</Col>
			</Row>
		</Container>
	);
};

export default RegisterConsultant;

import React, { useEffect, useRef } from 'react';
import { isEmpty, find, groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { addDoc, collection, limit, onSnapshot, orderBy, query, serverTimestamp } from 'firebase/firestore';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import ChatBox from './chat-box';
import db from '../../services/firebase';
import { /* storeHospitalMessages, */ storePatientMessage, storePatientToast } from '../../redux/chat/slice';
import 'react-toastify/dist/ReactToastify.css';
import { MdVideoCall } from 'react-icons/md';
import { Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ringtone } from '../../audio';

const Chat = () => {
	const { currentPatient } = useSelector(state => state.hospital);
	const { currentHospital, userDetails } = useSelector(state => state.auth);
	const { hospitalBedInfo, admittedPatients } = useSelector(state => state.hospital);
	const { toasts } = useSelector(state => state.hospitalMessages);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ringAudioRef = useRef(null);
	let firstTime = false;
	useEffect(() => {
		let unsubscribe = {};
		if (!isEmpty(currentHospital) && !isEmpty(admittedPatients)) {
			if ('Notification' in window) {
				Notification.requestPermission();
			}
			const q = query(collection(db, currentHospital.postfix), orderBy('timestamp', 'desc'), limit(1));
			unsubscribe = onSnapshot(q, (querySnapshot) => {
				if (firstTime) {
					querySnapshot.docChanges().forEach((change) => {
						if (change.type === 'added' && change.doc.data()?.sender !== userDetails.id && (currentPatient?.ip_no != change.doc.data()?.patientId)) {
							let patient = find(admittedPatients, { 'ip_no': change.doc.data()?.patientId });
							if (!isEmpty(patient)) {
								dispatch(storePatientMessage(change.doc.data()));
								dispatch(storePatientToast(change.doc.data()));
							}
						}
						else if (change.type === 'added' && change.doc.data()?.sender === userDetails.id && change.doc.data()?.timestamp !== null) {
							let patient = find(admittedPatients, { 'ip_no': change.doc.data()?.patientId });
							if (!isEmpty(patient)) {
								dispatch(storePatientMessage(change.doc.data()));
							}
						}
						else if (change.type === 'modified' && change.doc.data()?.sender === userDetails.id && (currentPatient?.ip_no != change.doc.data()?.patientId)) {
							let patient = find(admittedPatients, { 'ip_no': change.doc.data()?.patientId });
							if (!isEmpty(patient)) {
								dispatch(storePatientMessage(change.doc.data()));
							}
						}
					});
				}
				else {
					let oldMsg = [];
					querySnapshot.docChanges().forEach((change) => {
						oldMsg.push(change.doc.data());
					});
					if (!isEmpty(oldMsg)) {
						oldMsg.sort((x, y) => {
							return x.timestamp?.seconds - y.timestamp?.seconds;
						});
						oldMsg = groupBy(oldMsg, 'patientId');
					}
					// dispatch(storeHospitalMessages(oldMsg));
					firstTime = true;
				}
			});
		}
		return () => {
			if (typeof (unsubscribe) == 'function') {
				unsubscribe();
			}
			firstTime = false;
		};
	}, [currentHospital, admittedPatients]);

	useEffect(() => {
		if (!isEmpty(toasts)) {
			let msguser = find(hospitalBedInfo?.users, { 'id': toasts.sender });
			let patient = find(admittedPatients, { 'ip_no': toasts.patientId });
			if (patient && toasts.type == 'normal') {
				toast.success(t('DASHBOARD.TOASTS.MESSAGE', { patient_name: patient.patient_name, sender_firstname: msguser?.first_name, sender_lastname: msguser?.last_name }));
			}
			else if (patient && toasts.type == 'cpr') {
				let text = t('DASHBOARD.TOASTS.CPR', { patient_name: patient.patient_name, sender_firstname: msguser?.first_name, sender_lastname: msguser?.last_name });
				if ('Notification' in window) {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							new Notification(text);
						}
					});
				}
				toast.error(text);
			}
			else if (patient && toasts.type == 'invite_videocall') {
				let text = t('DASHBOARD.TOASTS.INVITECALL', { patient_name: patient.patient_name, sender_firstname: msguser?.first_name, sender_lastname: msguser?.last_name });
				if ('Notification' in window) {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							new Notification(text);
						}
					});
				}
				toast.error(<Msg patientId={toasts.patientId} text={text} handleDecline={handleDecline} handleAccept={handleAccept} />, {
					autoClose: 28000,
					icon: <MdVideoCall />,
					transition: Zoom,
					closeOnClick: false,
					pauseOnFocusLoss: false,
					onClose: () => {
						ringAudioRef.current.pause();
						ringAudioRef.current.currentTime = 0;
					}
				});
				ringAudioRef.current.play();
			}
			else if (patient && toasts.type == 'nudge') {
				let text = t('DASHBOARD.TOASTS.NUDGE', { patient_name: patient.patient_name, sender_firstname: msguser?.first_name, sender_lastname: msguser?.last_name });
				if ('Notification' in window) {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							new Notification(text);
						}
					});
				}
				toast.warn(text, {
					position: 'top-center',
				});
			}
		}
	}, [toasts]);

	const handleDecline = (patientId) => {
		ringAudioRef.current.pause();
		ringAudioRef.current.currentTime = 0;
		const payload = {
			'type': 'decline_videocall',
			'timestamp': serverTimestamp(),
			'sender': userDetails.id,
			'message': 'Video Call Declined',
			'patientId': patientId
		};
		addDoc(collection(db, currentHospital.postfix), payload);
	};
	const handleAccept = (patientId) => {
		ringAudioRef.current.pause();
		ringAudioRef.current.currentTime = 0;
		const payload = {
			'type': 'accept_videocall',
			'timestamp': serverTimestamp(),
			'sender': userDetails.id,
			'message': 'Video Call Accepted',
			'patientId': patientId
		};
		addDoc(collection(db, currentHospital.postfix), payload);
		navigate(`/videocall/${patientId}`);
	};
	return (
		<>
			<audio ref={ringAudioRef} src={ringtone} />
			<ToastContainer />
			{!isEmpty(currentPatient) && <ChatBox />}
		</>
	);
};
const Msg = ({ text, patientId, handleAccept, handleDecline, closeToast }) => {
	return (
		<div>
			<h6>{text}</h6>
			<Stack gap={2} direction='horizontal'>
				<Button variant='success' size='sm' onClick={() => {
					handleAccept(patientId);
					closeToast();
				}}>{t('DASHBOARD.BUTTON.ACCEPT')}</Button>
				<Button variant='danger' size='sm' onClick={() => {
					handleDecline(patientId);
					closeToast();
				}
				}>{t('DASHBOARD.BUTTON.DECLINE')}</Button>
			</Stack>
		</div>
	);
};
export default Chat;
import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader(props) {
	const { variant  } = props;
	return (
		<Spinner animation="border" variant={variant} {...props} />
	);
}
Loader.defaultProps = {
	variant : 'success'
};

export default Loader;
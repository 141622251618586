import React from 'react';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import { logo } from '../../images/index';

const SplashScreen = () => {
	return (
		<Container fluid className='d-flex flex-column align-items-center justify-content-center vh-100'>
			<img src={logo} style={{
				width: '200px',
				height: 'auto',
				marginRight: '16px'
			}} className=' w-200 mr-3' />
			<h2 className='text-primary'>{t('GENERAL.LOGOTEXT')}</h2>
		</Container>
	);
};

export default SplashScreen;
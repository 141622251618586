import React from 'react';
import { Accordion, Alert, Button, Row, Stack, Table } from 'react-bootstrap';
import { isEmpty, includes } from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { HiPencilAlt } from 'react-icons/hi';
import { t } from 'i18next';
import Loader from '../SplashScreen/loader';

const InvestigationTable = (props) => {
	const { data, loading, errors, title } = props;
	const { hospitalBedInfo, currentPatientIpno } = useSelector(state => state.hospital);
	return (
		<React.Fragment key={title}>
			{loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>
			}
			{errors &&
				<Alert variant='danger'>
					<ul className='mb-0'>
						{errors && errors.split('|').map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				</Alert>}
			<div className='d-flex justify-content-end mt-2'>
				<Stack gap='2' direction='horizontal'>
					{currentPatientIpno?.discharged_at === null && hospitalBedInfo?.form_ids?.investigationsheet != '' && <Button title={t('GENERAL.ADD')} as={Link} size='sm' to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.investigationsheet}/add`}><FaPlus /> {t('GENERAL.ADD')}</Button>}
					{currentPatientIpno?.discharged_at === null && hospitalBedInfo?.form_ids?.investigationsheet != '' && data?.edit_id != '' && <Button title={t('GENERAL.EDIT')} as={Link} size='sm' to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.investigationsheet}/edit/${data?.edit_id}`}><HiPencilAlt /> {t('GENERAL.EDIT')}</Button>}
				</Stack>
			</div>
			<Accordion defaultActiveKey="0" flush className='my-4'>
				{!loading && errors == '' && !isEmpty(data) &&
					data?.data?.map((row, rowIndex) => {
						return (
							<React.Fragment key={rowIndex}>
								{row.type == 'label' &&
									<h5 className='my-4 ms-3 text-decoration-underline'>{row.title}</h5>
								}
								{row.type == 'subform' && <Accordion.Item className='my-2' eventKey={rowIndex} key={rowIndex}>
									<Accordion.Button className='py-1' style={{ borderColor: 'none !important', backgroundColor: 'white', border: '0px', boxShadow: '0px' }}>
										<span className='bg-success text-light py-1 px-3 rounded'>{row.title}</span>
									</Accordion.Button>
									<Accordion.Body className='bg-white'>
										<Table striped hover bordered responsive size="sm" className='insv-table mt-3'>
											<tbody>
												{!isEmpty(row?.values) && row?.values?.map((field, fieldIndex) => {
													return (
														<React.Fragment key={fieldIndex}>
															{field?.type == 'label' &&
																<tr className='bg-warning text-white fw-bold'>
																	<td className='text-white'>{field?.title}</td>
																	<td colSpan={field?.['values'].length}></td>
																</tr>
															}
															{field?.type == 'date_time' &&
																<>
																	<tr>
																		<td className='table-success fw-bold'>Date</td>
																		{field?.values?.map((hour, hourIndex) => {
																			return (<td colSpan={hour?.time?.length} className='bg-secondary bg-opacity-10 fw-bold text-center' key={hourIndex}>
																				{hour?.date}
																			</td>);
																		})}
																	</tr>
																	<tr>
																		<td className='table-success fw-bold'>Time</td>
																		{field?.values?.map((hour, hourIndex) => {
																			return (<React.Fragment key={hourIndex}>
																				{hour?.time?.map((time, timeIndex) => {
																					return (<td className='bg-secondary bg-opacity-10 fw-bold' key={timeIndex}>
																						{time}
																					</td>);
																				})
																				}
																			</React.Fragment>);
																		})}
																	</tr>
																</>
															}
															{field?.type == 'subform' && field?.one_time_field == false &&
																<>
																	<tr className='bg-primary text-white fw-bold'>
																		<td className='text-white'>{field?.title}</td>
																		<td colSpan={field?.['values'][0]?.length}></td>
																	</tr>
																</>
															}
															{field?.type == 'subform' && field?.one_time_field == true &&
																<>
																	<tr className='bg-primary'>
																		<td colSpan='2' className='text-white fw-bold'>{field?.title}</td>
																	</tr>
																	{field?.values && Object.entries(field?.values).map(([formKey, formVal], formIndex) => {
																		return <tr key={formIndex}>
																			<td className='bg-success bg-opacity-25'>{formKey}</td>
																			<td>{formVal}</td>
																		</tr>;
																	})}
																</>
															}
															{field?.type == 'lab-report-values' &&
																<>
																	<tr>
																		<td className='table-success fw-bold'>{field?.title}
																			<span style={{ fontSize: '12px' }} className='text-danger fst-italic' dangerouslySetInnerHTML={{
																				__html: ' ( ' + field?.sub_title + ' )'
																			}}></span>
																		</td>
																		{field?.values?.map((hour, hourIndex) => {
																			return (<td key={hourIndex}>
																				{hour}
																			</td>);
																		})}
																	</tr>
																</>
															}
															{!includes(['date_time_original', 'date_time', 'subform', 'lab-report-values', 'label'], field?.type) &&
																<>
																	<tr>
																		<td className='table-success fw-bold'>{field?.title}
																		</td>
																		{field?.values?.map((hour, hourIndex) => {
																			return (<td key={hourIndex}>
																				{hour}
																			</td>);
																		})}
																	</tr>
																</>
															}
														</React.Fragment>);
												})}
											</tbody>
										</Table>
									</Accordion.Body>
								</Accordion.Item>}
							</React.Fragment>
						);
					})
				}
			</Accordion>
		</React.Fragment>

	);
};

export default InvestigationTable;
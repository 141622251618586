import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Dropdown, Image, Row } from 'react-bootstrap';
import { HiOutlineBell } from 'react-icons/hi';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Loader from '../SplashScreen/loader';
import { UserPlaceholder } from '../../images';
import { getFormattedDateTime } from '../../utils/helper';
import { getNotifications, markReadNotification } from '../../redux/chat';

const Notification = () => {
	const [position, setPosition] = useState();
	const mounted = useRef();
	const listElem = useRef();
	const { loading, notifications, notificationsDetail, notificationsError } = useSelector(state => state.hospitalMessages);
	const { currentHospital } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!mounted.current) {
			mounted.current = true;
		} else if (position && listElem.current) {
			listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
		}
	}, [position]);

	const handleScroll = (event) => {
		const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
		if (listboxNode && (notificationsDetail?.current_page < notificationsDetail?.last_page)) {
			setPosition(event.target.scrollTop + event.target.clientHeight);
			dispatch(getNotifications({ hospital_id: currentHospital.value, page: notificationsDetail?.current_page + 1 }));
		}
	};
	const handleToggle = (visible) => {
		if (!visible) {
			setPosition(0);
			listElem.current.scrollTop = 0;
		}
	};
	const handleMarkRead = (notification) => {
		if (notification === 'all') {
			dispatch(markReadNotification({ hospital_id: currentHospital.value }));
		}
		else {
			dispatch(markReadNotification({ hospital_id: currentHospital.value, notification_id: notification }));
		}
	};
	return (
		<Dropdown onToggle={handleToggle}>
			<Dropdown.Toggle size='lg' variant='link' className='text-dark px-1' bsPrefix='dropdown-toggle1'>
				<HiOutlineBell />
			</Dropdown.Toggle>
			<Dropdown.Menu style={{ position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 50px)' }} className='py-0 notification'>
				<div className='dropdown-header bg-light d-flex justify-content-between align-middle border-bottom border-3'>
					<span className='h6 fw-bold mt-1'>{t('GENERAL.NOTIFICATIONS')}</span>
					{!isEmpty(notifications) && <Button title={t('GENERAL.MARKALLREAD')} variant='link' size='sm' onClick={() => handleMarkRead('all')}>{t('GENERAL.MARKALLREAD')}</Button>}
				</div>
				<div className='overflow-auto' style={{ maxHeight: '300px' }} ref={listElem} onScroll={handleScroll}>
					{notificationsError &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{notificationsError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{!isEmpty(notifications) ? notifications.map((notification, key) => {
						return (
							<a key={key} style={{ minWidth: '500px' }} className={`dropdown-item  border-bottom border-2 ${(!notification?.read) && 'bg-secondary bg-opacity-10'}`} >
								<div className='message p-2'>
									<div className='py-0 me-3 float-start'>
										<div className='avatar'>
											<Image roundedCircle src={notification.user?.profile_picture || UserPlaceholder} width='50'
												height='50' className='d-inline-block align-top' alt={notification.user?.first_name} />
										</div>
									</div>
									<div className='d-flex justify-content-between'>
										<div className='mt-1'>
											<span className='text-medium-emphasis'>
												{notification.user?.first_name + ' ' + notification?.user?.last_name}
											</span>
										</div>
										<div>
											{(!notification?.read) && <Button title={t('GENERAL.MARKREAD')} type='button' variant='link' className='py-0 px-2' onClick={() => handleMarkRead(notification?.id)}>
												<span className='fs-5'>
													<MdOutlineMarkEmailRead />
												</span>
											</Button>}
											<small className='text-medium-emphasis float-end mt-2'>
												{getFormattedDateTime(notification?.created_at)}
											</small>
										</div>
									</div>
									<div className='small text-medium-emphasis text-truncate'>
										{notification?.message}
									</div>
								</div>
							</a>
						);
					})
						: <a className='dropdown-item' style={{ minWidth: '500px' }}>
							<div className='text-center p-3'>{t('GENERAL.NONOTIFICATIONFOUND')}</div>
						</a>
					}
					{loading &&
						<Row className='d-flex justify-content-center mt-4'>
							<Loader />
						</Row>}
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Notification;
import { createSlice } from '@reduxjs/toolkit';
import { getArchivedChartDataDownload, getArchivedChartGraphDownload, getArchivedChatDataDownload,  getArchivedFormDownload, getArchivedFormList, getArchivedPatientList, getDischargeHistory } from '../archived-patients';

const initialState = {
	Loading: false,

	archivedPatientList: [],
	archivedPatientListError: '',

	dischargeHistoryList : [],
	dischargeHistoryListError : '',

	archivedPatientFormList: [],
	archivedPatientFormListError: '',

	archivedFormDownloadLoading: false,
	archivedFormDownload : {},
	archivedFormDownloadError : '',

	archivedChartDataDownloadLoading : false,
	archivedChartDataDownload : {},
	archivedChartDataDownloadError : '',

	archivedChartGraphDownloadLoading : false,
	archivedChartGraphDownload : {},
	archivedChartGraphDownloadError : '',

	archivedChatDataDownloadLoading : false,
	archivedChatDataDownload : {},
	archivedChatDataDownloadError : '',
};

const archivedPatient = createSlice({
	name: 'archived-patients',
	initialState,
	reducers: {
		reset: () => initialState,
		resetArchivedFormDownload: (state) => {
			state.archivedFormDownloadLoading =  false;
			state.archivedFormDownload =  { };
			state.archivedFormDownloadError =  '';
		},
		resetArchivedChartDataDownload: (state) => {
			state.archivedChartDataDownloadLoading = false;
			state.archivedChartDataDownload = {};
			state.archivedChartDataDownloadError = '';
		},
		resetArchivedChartGraphDownload: (state) => {
			state.archivedChartGraphDownloadLoading = false;
			state.archivedChartGraphDownload = {};
			state.archivedChartGraphDownloadError = '';
		},
		resetArchivedChatDataDownload: (state) => {
			state.archivedChatDataDownloadLoading = false;
			state.archivedChatDataDownload = {};
			state.archivedChatDataDownloadError = '';
		},
	},
	extraReducers: (builder) => {

		builder.addCase(getArchivedPatientList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getArchivedPatientList.fulfilled, (state, action) => {
			state.Loading = false;
			state.archivedPatientList = action.payload?.response.data.data;
		});
		builder.addCase(getArchivedPatientList.rejected, (state, err) => {
			state.Loading = false;
			state.archivedPatientListError = err?.payload?.message;
		});

		builder.addCase(getDischargeHistory.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getDischargeHistory.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeHistoryList = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeHistory.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeHistoryListError = err?.payload?.message;
		});

		builder.addCase(getArchivedFormList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getArchivedFormList.fulfilled, (state, action) => {
			state.Loading = false;
			state.archivedPatientFormList = action.payload?.response.data.data;
		});
		builder.addCase(getArchivedFormList.rejected, (state, err) => {
			state.Loading = false;
			state.archivedPatientFormListError = err?.payload?.message;
		});

		builder.addCase(getArchivedFormDownload.pending, (state) => {
			state.archivedFormDownloadLoading = true;
			state.archivedFormDownload = {};
			state.archivedFormDownloadError = '';
		});
		builder.addCase(getArchivedFormDownload.fulfilled, (state, action) => {
			state.archivedFormDownloadLoading = false;
			state.archivedFormDownload = action.payload?.response;
		});
		builder.addCase(getArchivedFormDownload.rejected, (state, err) => {
			state.archivedFormDownloadLoading = false;
			state.archivedFormDownloadError = err.payload.message;
		});

		builder.addCase(getArchivedChartDataDownload.pending, (state) => {
			state.archivedChartDataDownloadLoading = true;
			state.archivedChartDataDownload = {};
			state.archivedChartDataDownloadError = '';
		});
		builder.addCase(getArchivedChartDataDownload.fulfilled, (state, action) => {
			state.archivedChartDataDownloadLoading = false;
			state.archivedChartDataDownload = action.payload?.response;
		});
		builder.addCase(getArchivedChartDataDownload.rejected, (state, err) => {
			state.archivedChartDataDownloadLoading = false;
			state.archivedChartDataDownloadError = err.payload.message;
		});

		builder.addCase(getArchivedChartGraphDownload.pending, (state) => {
			state.archivedChartGraphDownloadLoading = true;
			state.archivedChartGraphDownload = {};
			state.archivedChartGraphDownloadError = '';
		});
		builder.addCase(getArchivedChartGraphDownload.fulfilled, (state, action) => {
			state.archivedChartGraphDownloadLoading = false;
			state.archivedChartGraphDownload = action.payload?.response;
		});
		builder.addCase(getArchivedChartGraphDownload.rejected, (state, err) => {
			state.archivedChartGraphDownloadLoading = false;
			state.archivedChartGraphDownloadError = err.payload.message;
		});

		builder.addCase(getArchivedChatDataDownload.pending, (state) => {
			state.archivedChatDataDownloadLoading = true;
			state.archivedChatDataDownload = {};
			state.archivedChatDataDownloadError = '';
		});
		builder.addCase(getArchivedChatDataDownload.fulfilled, (state, action) => {
			state.archivedChatDataDownloadLoading = false;
			state.archivedChatDataDownload = action.payload?.response;
		});
		builder.addCase(getArchivedChatDataDownload.rejected, (state, err) => {
			state.archivedChatDataDownloadLoading = false;
			state.archivedChartDataDownloadError = err.payload.message;
		});
	},
});
export const { reset, resetArchivedFormDownload, resetArchivedChartDataDownload, resetArchivedChatDataDownload, resetArchivedChartGraphDownload } = archivedPatient.actions;

export default archivedPatient.reducer;
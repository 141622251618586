import { isEqual } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { deleteHospitalBedType, getHospitalBedInfo, getHospitalBedLayout, getHospitalBedTypes, getHospitalBedsLabel, getHospitalLedger, getHospitalLedgerStatistics, getHospitalPrefix, getHospitalRoleInfo, getHospitalVideoCallLedger, getPatientInfo, shiftPatientBed, storeHospitalBedType, updateHospitalBedType, updateHospitalBedsLabel, updateHospitalPrefix } from '.';
const initialState = {
	Loading: false,
	hospitalBedInfo: {},
	hospitalBedInfoError: '',
	hospitalBedLayout : {},
	hospitalBedLayoutError : '',
	roles: {},
	currentPatient: {},
	currentPatientIpno: {},
	currentPatientDetail: {},
	currentPatientError: '',
	admittedPatients:[],
	ledgerDetails:[],
	ledgerError: '',
	videoCallLedgerDetails : [],
	videoCallLedgerError : '',
	ledgerStatistics : {},
	ledgerStatisticsError : '',
	bedList:[],
	bedListError:'',
	bedListUpdate:[],
	bedListUpdateError:'',
	hospitalConfigPrefix:{},
	hospitalConfigPrefixError:'',
	hospitalConfigPrefixUpdate:{},
	hospitalConfigPrefixUpdateError:'',
	shiftPatientBedResponse : {},
	shiftPatientBedError : '',
	currentBedType : 'icu',
	currentBedView : 'grid',
	currentBedTypeFilter : 'all',

	bedTypesResponse : {},
	bedTypesError : '',

	storeBedTypeResponse : {},
	storeBedTypeError : '',

	updateBedTypeResponse : {},
	updateBedTypeError : '',
};

const hospitalSlice = createSlice({
	name: 'hospital',
	initialState,
	reducers: {
		reset: () => initialState,
		resetPatient: (state) => {
			state.currentPatient = {};
			state.currentPatientDetail = {};
			state.currentPatientError = '';
		},
		changePatient: (state, { payload }) => {
			state.currentPatient = payload;
		},
		changePatientIpno: (state, { payload }) => {
			state.currentPatientIpno = payload;
		},
		changeBedType: (state, { payload }) => {
			state.currentBedType = payload;
			state.currentBedTypeFilter = 'all';
		},
		changeBedView: (state, { payload }) => {
			state.currentBedView = payload;
		},
		changeBedTypeFilter: (state, { payload }) => {
			state.currentBedTypeFilter = payload;
		},
		resetBedConfigComplete: (state) => {
			state.bedList = [];
			state.bedListError = '';
			state.bedListUpdate =[];
			state.bedListUpdateError ='';
		},
		resetHospitalPrefixConfigComplete: (state) => {
			state.hospitalConfigPrefix = {};
			state.hospitalConfigPrefixError = '';
			state.hospitalConfigPrefixUpdate ={};
			state.hospitalConfigPrefixUpdateError ='';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getHospitalBedInfo.pending, (state) => {
			state.hospitalBedInfoError = '';
		});
		builder.addCase(getHospitalBedInfo.fulfilled, (state, action) => {
			if (!isEqual(current(state.hospitalBedInfo), action.payload?.response.data.data)) {
				state.hospitalBedInfo = action.payload?.response.data.data;
			}
			if (!isEqual(current(state.admittedPatients), action.payload?.response?.data?.data?.booked_patient)) {
				state.admittedPatients = action.payload?.response?.data?.data?.booked_patient;
			}
		});
		builder.addCase(getHospitalBedInfo.rejected, (state, err) => {
			state.hospitalBedInfoError = err?.payload?.message;
			state.hospitalBedInfo = {};
			state.admittedPatients = [];
		});

		builder.addCase(getHospitalBedLayout.pending, (state) => {
			state.hospitalBedLayoutError = '';
			state.hospitalBedLayout = {};
			state.shiftPatientBedResponse = {};
			state.shiftPatientBedError = '';
		});
		builder.addCase(getHospitalBedLayout.fulfilled, (state, action) => {
			state.hospitalBedLayout = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalBedLayout.rejected, (state, err) => {
			state.hospitalBedLayoutError = err?.payload?.message;
			state.hospitalBedLayout = {};
		});

		builder.addCase(getHospitalRoleInfo.fulfilled, (state, action) => {
			state.roles = action.payload?.response.data.data;
		});

		builder.addCase(getPatientInfo.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getPatientInfo.fulfilled, (state, action) => {
			state.Loading = false;
			state.currentPatientDetail = action.payload?.response.data.data;
		});
		builder.addCase(getPatientInfo.rejected, (state, err) => {
			state.Loading = false;
			state.currentPatientError = err?.payload?.message;
		});

		builder.addCase(getHospitalLedger.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getHospitalLedger.fulfilled, (state, action) => {
			state.Loading = false;
			state.ledgerDetails = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalLedger.rejected, (state, err) => {
			state.Loading = false;
			state.ledgerError = err?.payload?.message;
		});

		builder.addCase(getHospitalVideoCallLedger.pending, (state) => {
			state.Loading = true;
			state.videoCallLedgerDetails = [];
			state.videoCallLedgerError = '';
		});
		builder.addCase(getHospitalVideoCallLedger.fulfilled, (state, action) => {
			state.Loading = false;
			state.videoCallLedgerDetails = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalVideoCallLedger.rejected, (state, err) => {
			state.Loading = false;
			state.videoCallLedgerError = err?.payload?.message;
		});

		builder.addCase(getHospitalLedgerStatistics.pending, (state) => {
			state.Loading = true;
			state.ledgerStatistics = {};
			state.ledgerStatisticsError = '';
		});
		builder.addCase(getHospitalLedgerStatistics.fulfilled, (state, action) => {
			state.Loading = false;
			state.ledgerStatistics = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalLedgerStatistics.rejected, (state, err) => {
			state.Loading = false;
			state.ledgerStatisticsError = err?.payload?.message;
		});

		builder.addCase(getHospitalBedsLabel.pending, (state) => {
			state.Loading = true;
			state.bedList = [];
			state.bedListError = '';
			state.bedListUpdate = {};
			state.bedListUpdateError = '';
		});
		builder.addCase(getHospitalBedsLabel.fulfilled, (state, action) => {
			state.Loading = false;
			state.bedList = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalBedsLabel.rejected, (state, err) => {
			state.Loading = false;
			state.bedListError = err?.payload?.message;
		});

		builder.addCase(updateHospitalBedsLabel.pending, (state) => {
			state.Loading = true;
			state.bedListUpdate = [];
			state.bedListUpdateError = '';
		});
		builder.addCase(updateHospitalBedsLabel.fulfilled, (state, action) => {
			state.Loading = false;
			state.bedListUpdate = action.payload?.response.data;
		});
		builder.addCase(updateHospitalBedsLabel.rejected, (state, err) => {
			state.Loading = false;
			state.bedListUpdateError = err?.payload?.message;
		});

		builder.addCase(getHospitalPrefix.pending, (state) => {
			state.Loading = true;
			state.hospitalConfigPrefix = {};
			state.hospitalConfigPrefixError = '';
		});
		builder.addCase(getHospitalPrefix.fulfilled, (state, action) => {
			state.Loading = false;
			state.hospitalConfigPrefix = action.payload?.response.data.data;
		});
		builder.addCase(getHospitalPrefix.rejected, (state, err) => {
			state.Loading = false;
			state.hospitalConfigPrefixError = err?.payload?.message;
		});

		builder.addCase(updateHospitalPrefix.pending, (state) => {
			state.Loading = true;
			state.hospitalConfigPrefixUpdate = {};
			state.hospitalConfigPrefixUpdateError = '';
		});
		builder.addCase(updateHospitalPrefix.fulfilled, (state, action) => {
			state.Loading = false;
			state.hospitalConfigPrefixUpdate = action.payload?.response.data;
		});
		builder.addCase(updateHospitalPrefix.rejected, (state, err) => {
			state.Loading = false;
			state.hospitalConfigPrefixUpdateError = err?.payload?.message;
		});

		builder.addCase(shiftPatientBed.pending, (state) => {
			state.Loading = true;
			state.shiftPatientBedResponse = {};
			state.shiftPatientBedError = '';
		});
		builder.addCase(shiftPatientBed.fulfilled, (state, action) => {
			state.Loading = false;
			state.shiftPatientBedResponse = action.payload?.response.data;
		});
		builder.addCase(shiftPatientBed.rejected, (state, err) => {
			state.Loading = false;
			state.shiftPatientBedError = err?.payload?.message;
		});

		builder.addCase(getHospitalBedTypes.pending, (state) => {
			state.Loading = true;
			state.bedTypesResponse = {};
			state.storeBedTypeResponse = {};
			state.updateBedTypeResponse = {};
			state.deleteBedTypeResponse = {};

			state.bedTypesError = '';
			state.storeBedTypeError = '';
			state.updateBedTypeError = '';
			state.deleteBedTypeError = '';
		});
		builder.addCase(getHospitalBedTypes.fulfilled, (state, action) => {
			state.Loading = false;
			state.bedTypesResponse = action.payload?.response.data;
		});
		builder.addCase(getHospitalBedTypes.rejected, (state, err) => {
			state.Loading = false;
			state.bedTypesError = err?.payload?.message;
		});

		builder.addCase(storeHospitalBedType.pending, (state) => {
			state.Loading = true;
			state.storeBedTypeResponse = {};
			state.storeBedTypeError = '';
		});
		builder.addCase(storeHospitalBedType.fulfilled, (state, action) => {
			state.Loading = false;
			state.storeBedTypeResponse = action.payload?.response.data;
		});
		builder.addCase(storeHospitalBedType.rejected, (state, err) => {
			state.Loading = false;
			state.storeBedTypeError = err?.payload?.message;
		});

		builder.addCase(updateHospitalBedType.pending, (state) => {
			state.Loading = true;
			state.updateBedTypeResponse = {};
			state.updateBedTypeError = '';
		});
		builder.addCase(updateHospitalBedType.fulfilled, (state, action) => {
			state.Loading = false;
			state.updateBedTypeResponse = action.payload?.response.data;
		});
		builder.addCase(updateHospitalBedType.rejected, (state, err) => {
			state.Loading = false;
			state.updateBedTypeError = err?.payload?.message;
		});

		builder.addCase(deleteHospitalBedType.pending, (state) => {
			state.Loading = true;
			state.deleteBedTypeResponse = {};
			state.deleteBedTypeError = '';
		});
		builder.addCase(deleteHospitalBedType.fulfilled, (state, action) => {
			state.Loading = false;
			state.deleteBedTypeResponse = action.payload?.response.data;
		});
		builder.addCase(deleteHospitalBedType.rejected, (state, err) => {
			state.Loading = false;
			state.deleteBedTypeError = err?.payload?.message;
		});

	},
});
export const { reset, resetPatient, changePatient, changePatientIpno, changeBedTypeFilter, changeBedType, changeBedView, resetBedConfigComplete, resetHospitalPrefixConfigComplete } = hospitalSlice.actions;
export default hospitalSlice.reducer;
import React from 'react';
import { t } from 'i18next';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { FaUserMd, FaCalendarAlt, FaUserAlt } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { getFormattedDateTime } from '../../utils/helper';

function PatientInfoAccord(props) {
	const { currentPatient, currentPatientDetail, patientUploadDetails, patientShfitLogDetails } = props;
	return (
		<Accordion className='mb-2'>
			<Accordion.Item eventKey="0" className='shadow rounded'>
				<Accordion.Button style={{ backgroundColor: '#dff0d8' }} className='py-2 shadow rounded'>
					{!isEmpty(currentPatient) ?
						<span>
							<FaUserAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.NAME')} : {currentPatient.name}, <FaCalendarAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.DOA')} : {getFormattedDateTime(currentPatient.doa)}, <FaUserMd className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.ATTDR')} : {currentPatient.att_dr}
						</span> :
						<span>
							{t('GENERAL.PATIENTINFO')}
						</span>}
				</Accordion.Button>
				<Accordion.Body>
					<Row xs={1} md={2} lg={3}>
						{!isEmpty(currentPatientDetail) && currentPatientDetail.map((field, index) => {
							return (
								<Col key={index}>
									<h6><b>{field.title}</b> : {field.value}</h6>
								</Col>);
						})}
					</Row>
					<Table bordered responsive>
						<thead>
							<tr>
								{patientUploadDetails?.header.map((title, index) => (
									<th key={index}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{patientUploadDetails?.body?.length ? patientUploadDetails?.body.map((value, index) => (
								<tr key={index}>
									<td>{value[0]}</td>
									<td>{<a title={t('GENERAL.DOWNLOAD')} className='btn btn-sm btn-success' href={value[1]} target='_blank' rel="noreferrer" download>
										<HiDownload />
									</a>}</td>
								</tr>
							)) :
								<tr>
									<td colSpan={2} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
								</tr>
							}
						</tbody>
					</Table>
					<Table bordered responsive>
						<thead>
							<tr>
								{patientShfitLogDetails?.header.map((title, index) => (
									<th key={index}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{patientShfitLogDetails?.body?.length ? patientShfitLogDetails?.body.map((value, index) => (
								<tr key={index}>
									<td>{value[0]}</td>
									<td>{value[1]}</td>
									<td>{value[2]}</td>
									<td>{value[3]}</td>
									<td>{value[4]}</td>
								</tr>
							)) :
								<tr>
									<td colSpan={5} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
								</tr>
							}
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default PatientInfoAccord;
import { createSlice } from '@reduxjs/toolkit';
import { addDischargeSummary, dischargeCheckList, dischargePatient, cancelDischargePatient,  getDischargeAdminList, getDischargeCheckListFormFields, getDischargeClinicalList, getDischargeSummaryDownload, getDischargeSummaryFormFields, getDischargeSummaryInfo, getPatientInfoDischarge, updateDischargeSummary, verifyDischargePatient } from '../discharge-clinical';

const initialState = {
	Loading: false,

	dischargeClinicalList: [],
	dischargeClinicalError: '',

	dischargeSummaryFormDetail : {},
	dischargeSummaryFormDetailError : '',

	dischargeSummaryAddResponse: {},
	dischargeSummaryAddError: '',

	currentPatientDetail: {},
	currentPatientError: '',

	dischargeSummaryEditDetail: {},
	dischargeSummaryEditDetailError: '',

	dischargeSummaryUpdateResponse : {},
	dischargeSummaryUpdateError : '',

	isDischargePatientSuccess: false,
	isDischargePatientErrorMessage: '',

	isCancelDischargePatientSuccess: false,
	isCancelDischargePatientErrorMessage: '',

	isVerifyDischargeSuccess : false,
	isVerifyDischargeErrorMessage : '',

	dischargeAdminList: [],
	dischargeAdminError: '',

	formFields: {},
	formFieldsError: '',

	dischargeCheckListSuccess : {},
	dischargeCheckListErrorMessage : '',

	dischargeSummaryDownloadLoading: false,
	dischargeSummaryDownload: {},
	dischargeSummaryDownloadError: ''

};

const dischargeClinical = createSlice({
	name: 'discharge-clinical',
	initialState,
	reducers: {
		reset: () => initialState,
		changeDischargeSubFormList: (state, { payload }) => {
			if (payload.mode == 'edit') {
				state.dischargeSummaryEditDetail['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
			else if (payload.mode == 'add') {
				state.dischargeSummaryFormDetail['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
		},
		resetDischargeSummaryDownload: (state) => {
			state.dischargeSummaryDownloadLoading = false;
			state.dischargeSummaryDownload = {};
			state.dischargeSummaryDownloadError = '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getDischargeClinicalList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getDischargeClinicalList.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeClinicalList = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeClinicalList.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeClinicalError = err?.payload?.message;
		});

		builder.addCase(getDischargeSummaryFormFields.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getDischargeSummaryFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeSummaryFormDetail = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeSummaryFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeSummaryFormDetailError = err?.payload?.message;
		});

		builder.addCase(addDischargeSummary.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(addDischargeSummary.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeSummaryAddResponse = action.payload?.response.data.data;
		});
		builder.addCase(addDischargeSummary.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeSummaryAddError = err?.payload?.message;
		});

		builder.addCase(getPatientInfoDischarge.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getPatientInfoDischarge.fulfilled, (state, action) => {
			state.Loading = false;
			state.currentPatientDetail = action.payload?.response.data.data;
		});
		builder.addCase(getPatientInfoDischarge.rejected, (state, err) => {
			state.Loading = false;
			state.currentPatientError = err?.payload?.message;
		});

		builder.addCase(getDischargeSummaryInfo.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getDischargeSummaryInfo.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeSummaryEditDetail = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeSummaryInfo.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeSummaryEditDetailError = err?.payload?.message;
		});

		builder.addCase(updateDischargeSummary.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updateDischargeSummary.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeSummaryUpdateResponse = action.payload?.response.data.data;
		});
		builder.addCase(updateDischargeSummary.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeSummaryUpdateError = err?.payload?.message;
		});

		builder.addCase(dischargePatient.pending, (state) => {
			state.Loading = true;
			state.isDischargePatientSuccess = false;
			state.isDischargePatientErrorMessage = '';
		});
		builder.addCase(dischargePatient.fulfilled, (state) => {
			state.Loading = false;
			state.isDischargePatientSuccess = true;
		});
		builder.addCase(dischargePatient.rejected, (state, err) => {
			state.Loading = false;
			state.isDischargePatientErrorMessage = err;
		});

		builder.addCase(verifyDischargePatient.pending, (state) => {
			state.Loading = true;
			state.isVerifyDischargeSuccess = false;
			state.isVerifyDischargeErrorMessage = '';
		});
		builder.addCase(verifyDischargePatient.fulfilled, (state) => {
			state.Loading = false;
			state.isVerifyDischargeSuccess = true;
		});
		builder.addCase(verifyDischargePatient.rejected, (state, err) => {
			state.Loading = false;
			state.isVerifyDischargeErrorMessage = err;
		});

		builder.addCase(cancelDischargePatient.pending, (state) => {
			state.Loading = true;
			state.isCancelDischargePatientSuccess = false;
			state.isCancelDischargePatientErrorMessage = '';
		});
		builder.addCase(cancelDischargePatient.fulfilled, (state) => {
			state.Loading = false;
			state.isCancelDischargePatientSuccess = true;
		});
		builder.addCase(cancelDischargePatient.rejected, (state, err) => {
			state.Loading = false;
			state.isCancelDischargePatientErrorMessage = err;
		});

		builder.addCase(getDischargeAdminList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getDischargeAdminList.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeAdminList = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeAdminList.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeAdminError = err?.payload?.message;
		});


		builder.addCase(getDischargeCheckListFormFields.pending, (state) => {
			state.Loading = true;
			state.formFieldsError = '';
			state.dischargeCheckListErrorMessage = '';
		});
		builder.addCase(getDischargeCheckListFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.formFields = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeCheckListFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.formFieldsError = err?.payload?.message;
		});

		builder.addCase(dischargeCheckList.pending, (state) => {
			state.Loading = true;
			state.dischargeCheckListErrorMessage = '';
		});
		builder.addCase(dischargeCheckList.fulfilled, (state, action) => {
			state.Loading = false;
			state.dischargeCheckListSuccess = action.payload?.response.data.data;
		});
		builder.addCase(dischargeCheckList.rejected, (state, err) => {
			state.Loading = false;
			state.dischargeCheckListErrorMessage = err?.payload?.message;
		});

		builder.addCase(getDischargeSummaryDownload.pending, (state) => {
			state.dischargeSummaryDownloadLoading = true;
			state.dischargeSummaryDownload = {};
			state.dischargeSummaryDownloadError = '';
		});
		builder.addCase(getDischargeSummaryDownload.fulfilled, (state, action) => {
			state.dischargeSummaryDownloadLoading = false;
			state.dischargeSummaryDownload = action.payload?.response.data.data;
		});
		builder.addCase(getDischargeSummaryDownload.rejected, (state, err) => {
			state.dischargeSummaryDownloadLoading = false;
			state.dischargeSummaryDownloadError = err?.payload?.message;
		});
	},
});
export const { reset, changeDischargeSubFormList, resetDischargeSummaryDownload } = dischargeClinical.actions;

export default dischargeClinical.reducer;
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { t } from 'i18next';

function PatientDetailsModal({ show, handlePatientClose, patientData }) {
	return (
		<Modal show={show} onHide={handlePatientClose} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t('PATIENTDETAIL.TITLE')} </Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row md={3} lg={3}>
					{patientData.map((val, key) => {
						return <Col key={key}><small><b>{val.title}</b>: {val.value}</small></Col>;
					})}
				</Row>
			</Modal.Body>
		</Modal>
	);
}
export default PatientDetailsModal;
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import PatientInfoAccord from '../../components/Patient/patient-info-accord';
import { callGetAPI } from '../../services/axios';
import { downloadPDFFile } from '../../utils/helper';

const AdmittedPatientHistory = () => {
	const [patientdata, setPatientData] = useState([]);
	const { hid, pid, ivno } = useParams();
	const [downloadLoader, setDownloadLoader] = useState(false);
	useEffect(() => {
		callGetAPI({
			route: 'hospital/patient/admission/history',
			params: {
				hospital_id: hid,
				p_id: pid,
				ip_no: ivno
			}
		}).then((data) => {
			setPatientData(data.data.data);
		});
	}, []);

	const downloadFile = (version) => {
		setDownloadLoader(true);
		callGetAPI({
			route: 'hospital/patient/admission/history/download',
			params: {
				hospital_id: hid,
				p_id: pid,
				ip_no: ivno,
				version: version
			},
			options : {
				responseType : 'blob'
			}
		}).then((data) => {
			downloadPDFFile(data?.data);
		}).finally(() => {
			setDownloadLoader(false);
		});
	};

	return (
		<Container className='mt-4'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{t('PATIENTS.PATIENT_ADMISSION_HISTORY')}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			{!isEmpty(patientdata) &&
				<>
					<Row className='mt-4'>
						<PatientInfoAccord currentPatient={patientdata.patient_info.panel_title} currentPatientDetail={patientdata.patient_info.panel_details} patientUploadDetails={patientdata.patient_info.panel_upload_info} patientShfitLogDetails={patientdata.patient_info.panel_shiftlog} />
					</Row>
					<Row>
						<Col>
							{patientdata?.history_info.map((val, key) => {
								return <Card key={key} className='mt-2'>
									<Card.Header>
										<b>{t('GENERAL.VERSION')} {val.version}</b>
										<Button title={t('GENERAL.DOWNLOAD')}
											disabled={downloadLoader}
											variant="primary"
											className='btn-sm float-end'
											onClick={() => downloadFile(val.version)}>
											{t('GENERAL.DOWNLOAD')}
										</Button>
									</Card.Header>
									<Card.Body>
										<Row xs={1} md={2} lg={3}>
											<Col>
												<h6><b>{t('GENERAL.IPNO')}</b>: {val.ip_no} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.DATE_TIME_OF_ADMISSION')}</b>: {val.date_of_admission} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.ACCOMPANIED_BY')}</b>: {val.accompanied_by} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.RELATIONSHIP')}</b>: {val.relationship} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.COMPANION_MOBILE')}</b>: {val.companion_mobile_no} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.EMERGENCY_CONTACT_NO')} </b>: {val.alternate_contact_no} </h6>
											</Col>
											<Col>
												<h6><b>{t('PATIENTS.REFERRING_DOCTOR')} </b>: {val.referring_doctor_name} </h6>
											</Col>
										</Row>
										<Card>
											<Card.Header>{t('PATIENTS.ALLOCATION_OF_DOCTOR')} :</Card.Header>
											<Card.Body>
												<Row>
													<Col>
														<h6><b>{t('PATIENTS.ATTENDING_CONSULTANT')} </b>: {val.attending_consultant_id.map((cval) => {
															return cval.first_name + ' ' + cval.last_name + ' (' + cval.mobile_number + ')';
														})} </h6>
													</Col>
													<Col>
														<h6><b>{t('PATIENTS.VISITING_CONSULTANT')} </b>: {val.visiting_consultant.map((cval) => {
															return cval.first_name + ' ' + cval.last_name + ' (' + cval.mobile_number + ')';
														})} </h6>
													</Col>
												</Row>
											</Card.Body>
										</Card>
									</Card.Body>
								</Card>;
							})}
						</Col>
					</Row>
				</>}
		</Container>
	);
};

export default AdmittedPatientHistory;

import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { t } from 'i18next';
import moment from 'moment';
import { Accordion, Alert, Button, Col, Form, Modal, Row, Stack, Table } from 'react-bootstrap';
import { HiInformationCircle } from 'react-icons/hi';
import { isEmpty, isArray, kebabCase, split, has } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import RequiredAstric from '../DynamicForm/required-astric';
import Loader from '../SplashScreen/loader';
import { refreshTable, resetStoreTable } from '../../redux/monitoring/slice';
import { getChartData, storeChartData, storeChartDataMultiple } from '../../redux/monitoring';
import { chartTableValidationSchema } from '../../utils/validation';
import './style.scss';
import { RiLineChartFill } from 'react-icons/ri';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import MonitoringChartPlain from './monitoring-chart-plain';

const MonitoringTable2 = (props) => {
	const startTime = '07:00:00';
	const endTime = '07:00:00';
	const { data, loading, errors, type, title/* , chartid */ } = props;
	const { currentHospital } = useSelector(state => state.auth);
	const { currentPatient, currentPatientIpno } = useSelector(state => state.hospital);
	const { storeLoading, /* storeTableData, */ storeTableDataError, chartLoading, chartData, chartDataError } = useSelector(state => state.monitoring);
	const [firstTime, setFirstTime] = useState(false);
	const [showChartModal, setShowChartModal] = useState(false);
	const [currentType, setCurrentType] = useState('');
	const [modalData, setModalData] = useState({});
	const [valueModalData, setValueModalData] = useState({});
	const [showToolTip, setShowToolTip] = useState(false);
	const [showValueModal, setShowValueModal] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [statDate, setStatDate] = useState(currentPatientIpno?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD'));
	const dateRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (storeTableDataError == '') {
			handleValueModalClose();
		}
	}, [storeTableDataError]);

	/* useEffect(() => {
		if (!isEmpty(data)) {
			handleValueModalClose();
			const el = document.getElementById(chartid);
			const currentTimeLeft = document.getElementById('table2_' + moment(moment().format(moment.HTML5_FMT.DATETIME_LOCAL)).startOf('hour'));
			if (el && currentTimeLeft) {
				el.scrollTo({
					left: currentTimeLeft.offsetLeft - 230,
					behavior: 'smooth'
				});
			}
		}
	}, [data]); */
	useEffect(() => {
		if (!isEmpty(currentPatient) && !isEmpty(currentPatientIpno)) {
			setStatDate(currentPatientIpno?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD'));
		}
	}, [currentPatient, currentPatientIpno]);
	useEffect(() => {
		if (!isEmpty(statDate) && !isEmpty(currentType) && firstTime) {
			dispatch(getChartData({
				hospital_id: currentHospital.value,
				p_id: currentPatient.p_id,
				ip_no: currentPatientIpno.ip_no,
				date_from: moment(statDate + ' ' + startTime).format('YYYY-MM-DD HH:mm:ss'),
				date_to: moment(moment(statDate).add(1, 'days').format('YYYY-MM-DD') + ' ' + endTime).format('YYYY-MM-DD HH:mm:ss'),
				diff: '900',
				type: currentType
			}));
		}
	}, [statDate, currentType]);

	const showAllData = () => {
		setStatDate('');
		setFirstTime(true);
		dispatch(getChartData({
			hospital_id: currentHospital.value,
			p_id: currentPatient.p_id,
			ip_no: currentPatientIpno.ip_no,
			show_all: 1,
			diff: '900',
			type: currentType
		}));
	};
	const changeDate = day => {
		if (isEmpty(statDate) || statDate == '' || statDate === '') {
			setStatDate(() => currentPatientIpno?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD'));
		}
		else if (day == 'next') {
			setStatDate(moment(statDate).add(1, 'days').format('YYYY-MM-DD'));
		}
		else if (day == 'prev') {
			setStatDate(moment(statDate).subtract(1, 'days').format('YYYY-MM-DD'));
		}
		setFirstTime(true);
	};
	const handleOpenChartModal = (type) => {
		dispatch(getChartData({ hospital_id: currentHospital.value, p_id: currentPatient.p_id, ip_no: currentPatientIpno?.ip_no, date_from: moment(statDate + ' ' + startTime).format('YYYY-MM-DD HH:mm:ss'), date_to: moment(moment(statDate).add(1, 'days').format('YYYY-MM-DD') + ' ' + endTime).format('YYYY-MM-DD HH:mm:ss'), diff: '900', type }));
		setShowChartModal(true);
		setCurrentType(type);
	};
	const handleCloseChartModal = () => {
		setStatDate(() => currentPatientIpno?.discharged_at === null ? moment().format('YYYY-MM-DD') : moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD'));
		setShowChartModal(false);
	};
	const handleClose = () => setShowToolTip(false);
	const handleValueModalClose = () => {
		dispatch(resetStoreTable());
		setInitialValues({});
		setShowValueModal(false);
	};
	const handleShow = (data) => {
		setModalData(data);
		setShowToolTip(true);
	};
	const handleValueModal = (row, hourIndex, hour) => {
		setValueModalData(row);
		let fields = {};
		fields['date_time'] = moment(hourIndex, ('DD-MM-YYYY hh:mm A')).format(moment.HTML5_FMT.DATETIME_LOCAL);
		fields['field_name'] = row.field_name;
		if (row.field_name == 'ews_temperature') {
			fields['value'] = ['Celcius', hour];
		}
		else if (row.field_name == 'ews_gcs') {
			fields['value'] = hour;
		} else {
			fields['value'] = [hour];
		}
		setInitialValues(fields);
		setShowValueModal(true);
	};

	const handleSubmit = (values) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('type', type);
		formdata.append('field_name', values?.field_name);
		formdata.append('date_time', moment(values?.date_time).format('YYYY-MM-DD HH:mm:ss'));
		if (values.field_name == 'ews_temperature') {
			values?.value?.map((field, fieldIndex) => {
				if (field !== '') {
					if (fieldIndex == 0) {
						if (field == 'Farenheit') {
							field = parseFloat((values?.value[1] - 32) * (5 / 9)).toFixed(2);
							formdata.append('value', field);
						}
						else {
							formdata.append('value', values?.value[1]);
						}
					}
				}
			});

		} else {
			values?.value?.map((field) => {
				if (field !== '') {
					formdata.append('value', field);
				}
			});
		}
		dispatch(refreshTable({ 'type': type }));
		dispatch(storeChartData(formdata));
		// resetForm();
		handleValueModalClose();
	};
	const handleSubmitMultiple = (values, { resetForm }) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		formdata.append('type', type);
		formdata.append('date_time', moment(values?.date_time).format('YYYY-MM-DD HH:mm:ss'));
		for (const [key, value] of Object.entries(values?.fields)) {
			formdata.append('fields[' + key + ']', value);
		}
		dispatch(refreshTable({ 'type': type }));
		dispatch(storeChartDataMultiple(formdata));
		resetForm();
		//handleValueModalClose();
	};

	return (
		<React.Fragment key={title}>
			<Accordion defaultActiveKey="0" flush className='my-4'>
				<Accordion.Item eventKey="0">
					<Accordion.Button className='py-1' style={{ borderColor: 'none !important', backgroundColor: 'white', border: '0px', boxShadow: '0px' }}>
						<span className='bg-success text-light py-1 px-3 rounded'>{title}</span>
					</Accordion.Button>
					<Accordion.Body className='bg-white'>
						{loading &&
							<Row className='d-flex justify-content-center mt-4'>
								<Loader />
							</Row>
						}
						{errors &&
							<Alert variant='danger'>
								<ul className='mb-0'>
									{errors && errors.split('|').map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</Alert>}
						{!loading && data &&
							<Formik initialValues={initialValues} onSubmit={handleSubmitMultiple} /* validationSchema={chartTableValidationSchema(t, 1)} */>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
									return (
										<Form noValidate onSubmit={handleSubmit}>
											<Table responsive striped bordered hover size="sm" className={`table-chart ${type == 2 ? 'io-chart' : ''}`} style={{ width: 'auto' }}>
												<thead className='align-middle'>
													<tr className='table-primary text-primary'>
														<th style={{ maxWidth: '300px', width: '300px' }}>{data[0]?.title}</th>
														{currentPatientIpno?.discharged_at === null &&
															<th style={{ maxWidth: '300px', width: '300px' }}>
																<Form.Control
																	type="datetime-local"
																	name='date_time'
																	value={values.date_time || moment().format('YYYY-MM-DD HH:mm:ss')}
																	ref={dateRef}
																	min={moment(currentPatient?.date_of_admission).format(moment.HTML5_FMT.DATETIME_LOCAL)}
																	max={moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATETIME_LOCAL)}
																	required step={900}
																	onChange={handleChange}
																	onBlur={handleBlur} />
															</th>}
														{data[0]?.values?.map((hour, hourIndex) => {
															let hourText = split(hour, ' ');
															return (
																<th
																	id={'table2_' + moment(hour, 'DD-MM-YYYY hh:mm A').startOf('hour')}
																	key={hourIndex}
																	className={type == 2 && hourIndex == data[0]?.values.length - 1 ? 'bg-primary text-white' : undefined}
																	style={{ minWidth: '60px!important' }}>
																	{hourText[0]}
																	<br />
																	{hourText.length == 3 ? hourText[1] + ' ' + hourText[2] : ''}
																</th>
															);
														})}
													</tr>
												</thead>
												<tbody>
													{!isEmpty(data) && data?.map((row, rowIndex) => {
														if (rowIndex === 0) {
															return null;
														}
														return (
															<React.Fragment key={rowIndex}>
																{(row.editable || row.field_type == 'readonly') &&
																	<tr className={row.field_type == 'readonly' ? 'fw-bold' : ''}>
																		<td className='table-primary text-priamry'>
																			<Stack gap={2} direction='horizontal'>
																				<div>
																					<span className={has(row, 'sub') && row.sub ? 'ms-4' : null} dangerouslySetInnerHTML={{ __html: row.title }} />
																					{row?.tooltip_modal &&
																						<Button title={t('GENERAL.LABREPORTVALUE')} variant="link" style={{ padding: '0px 0px 5px 5px' }} onClick={() => handleShow(row.tooltip_data)}>
																							<HiInformationCircle />
																						</Button>}
																				</div>
																				{row?.chart && <div className='ms-auto'>
																					<Button
																						title={t('GENERAL.VIEWCHART')} size='sm'
																						variant='success'
																						className='mx-2'
																						onClick={() => handleOpenChartModal(row?.field_name)}>
																						<RiLineChartFill />
																					</Button>
																				</div>}
																			</Stack>
																		</td>
																		{currentPatientIpno?.discharged_at === null && <td>
																			{row.field_type !== 'readonly' && has(row, 'fields') && !isEmpty(row.fields) &&
																				row.fields?.map((field, fieldIndex) => {
																					return (
																						<React.Fragment key={row.field_name + fieldIndex}>
																							{field.field_type == 'number' &&
																								<Form.Control
																									type='number'
																									min='0'
																									step={row.decimal ? '0.01' : '1'}
																									name={'fields[' + row.field_name + ']'}
																									value={values['fields[' + row.field_name + ']']}
																									onChange={handleChange}
																									onKeyDown={(e) => {
																										if ((!row.decimal) && (
																											e.key === 'e' ||
																											e.key === 'E' ||
																											e.key === '-' ||
																											e.key === '+' ||
																											e.key === '.')
																										) {
																											e.preventDefault();
																										}
																										if (row.decimal) {
																											const currentValue = e.target.value;
																											const key = e.key;
																											if (key === '.' && currentValue.includes('.')) {
																												e.preventDefault();
																											} else if (key.match(/[0-9]/)) {
																												const decimalIndex = currentValue.indexOf('.');
																												if (decimalIndex !== -1 && currentValue.substring(decimalIndex + 1).length >= 2) {
																													e.preventDefault();
																												}
																											}
																										}

																									}}
																									onBlur={handleBlur}
																								/>}
																							{field.field_type == 'text' &&
																								<Form.Control
																									type='text'
																									name={'fields[' + row.field_name + ']'}
																									onChange={handleChange}
																									onBlur={handleBlur}
																								/>}
																							{field.field_type == 'select' &&
																								<Form.Select
																									placeholder={row.title}
																									aria-label={row.title}
																									name={'fields[' + row.field_name + ']'}
																									onChange={handleChange}
																									onBlur={handleBlur}
																									isInvalid={errors.field_name && touched.field_name}>
																									<option value=''>{t('GENERAL.SELECT')}</option>
																									{field?.options && field.options.map((option, optionIndex) => {
																										return (
																											<option key={optionIndex} value={option.value}>{option.label}</option>
																										);
																									})}
																								</Form.Select>
																							}
																						</React.Fragment>
																					);
																				})
																			}
																		</td>}
																		{row?.values?.map((hour, hourIndex) => {
																			return (
																				<td key={hourIndex}
																					className={type == 2 && hourIndex == row?.values.length - 1 ? 'bg-primary text-white' : undefined}
																					onClick={() => {
																						if (!row.editable || (type == 2 && hourIndex == row?.values.length - 1)) {
																							return false;
																						}
																						handleValueModal(row, data[0]['values'][hourIndex], hour);
																					}}>
																					{hour}
																				</td>);
																		})}
																	</tr>}
																{row.field_type == 'label' &&
																	<tr className='bg-warning'>
																		<td className='text-white fw-bold'>{row.title}</td>
																		{currentPatientIpno?.discharged_at === null && <td colSpan={data[0]?.values?.length}></td>}
																		<td className={type == 2 ? 'bg-primary text-white' : undefined}></td>
																	</tr>}
															</React.Fragment>

														);
													})}
													{currentPatientIpno?.discharged_at === null && <tr>
														<td></td>
														<td>
															<Button
																title={t('GENERAL.SUBMIT')}
																disabled={storeLoading}
																variant="primary"
																size='sm'
																type="submit">
																{t('GENERAL.SUBMIT')}
															</Button>
															<Button
																title={t('GENERAL.CANCEL')}
																className='ms-2'
																variant="danger"
																size='sm'
																type="reset"
																/* onClick={handleChartValueModalClose} */>
																{t('GENERAL.CANCEL')}
															</Button>
														</td>
														{data[0]?.values?.map((hour, hourIndex) => {
															return (
																<td key={hourIndex}>
																</td>
															);
														})}
													</tr>}
												</tbody>
											</Table>
										</Form>);
								}}
							</Formik>
						}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Modal show={showToolTip} onHide={handleClose} size={kebabCase(modalData?.title) == 'clinical-risk' ? 'lg' : 'xl'}>
				<Modal.Header closeButton>
					<Modal.Title>{modalData?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table bordered responsive className={`fw-bold tooltip-modal ${kebabCase(modalData?.title)}`}>
						<tbody className='align-middle text-center'>
							{!isEmpty(modalData?.body) && isArray(modalData.body) && modalData.body.map((row, rowIndex) => {
								return (<tr key={rowIndex}>
									{row && row?.map((cell, cellIndex) => {
										return (
											<td key={cellIndex}
												className={`${cell.class}`} dangerouslySetInnerHTML={{ __html: cell.value }}>
											</td>);
									})}
								</tr>);
							})}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button title={t('GENERAL.CLOSE')} variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showValueModal} onHide={handleValueModalClose}>
				<Modal.Header>
					<Modal.Title>{valueModalData.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{(storeTableDataError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{storeTableDataError && storeTableDataError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={chartTableValidationSchema(t, valueModalData?.fields?.length)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
							return (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId='formGridDateTime'>
										<Form.Label>
											{t('PATIENTMONITORING.DATETIME')}
										</Form.Label>
										<Form.Control
											required
											step={3600}
											type="datetime-local"
											name='date_time'
											value={values.date_time}
											min={moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD')}
											max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format('YYYY-MM-DD') : moment(currentPatientIpno?.discharged_at).format('YYYY-MM-DD')}
											onChange={handleChange}
											onBlur={handleBlur} />
									</Form.Group>
									{!isEmpty(valueModalData?.fields) && valueModalData.fields.map((field, fieldIndex) => {
										return (
											<React.Fragment key={fieldIndex}>
												{
													<span className='text-danger'>
														{isArray(errors.value) ? errors.value[fieldIndex] : (errors.value ?? '')}
													</span>
												}
												{field.field_type == 'select' &&
													<Form.Group className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
														<Form.Label>
															{t('PATIENTMONITORING.VALUE')} <RequiredAstric />
														</Form.Label>
														<Form.Select
															placeholder={field.title}
															aria-label={field.title}
															name={`value[${fieldIndex}]`}
															onChange={handleChange}
															onBlur={handleBlur}
															isInvalid={errors.field_name && touched.field_name}>
															<option value=''>{t('GENERAL.SELECT')}</option>
															{field?.options && field.options.map((option, optionIndex) => {
																return (
																	<option key={optionIndex} value={option.value} selected={values.value == option.value}>
																		{option.label}
																	</option>
																);
															})}
														</Form.Select>
													</Form.Group>
												}
												{
													field.field_type == 'text' &&
													<Form.Group className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
														<Form.Label>
															{t('PATIENTMONITORING.VALUE')} <RequiredAstric />
														</Form.Label>
														<Form.Control
															type='text'
															name={`value[${fieldIndex}]`}
															value={values.value}
															onChange={handleChange}
															onBlur={handleBlur}
															isInvalid={errors.value && touched.value}
															required />
													</Form.Group>
												}
												{
													field.field_type == 'number' &&
													<Form.Group className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
														<Form.Label>
															{t('PATIENTMONITORING.VALUE')} <RequiredAstric />
														</Form.Label>
														<Form.Control
															type='number'
															min='0'
															name={`value[${fieldIndex}]`}
															value={values.value[fieldIndex]}
															onChange={handleChange}
															onBlur={handleBlur}
															isInvalid={errors.value && touched.value}
															required />
													</Form.Group>
												}
											</React.Fragment>);

									})}
									<hr />
									<Row>
										<Col>
											<Button title={t('GENERAL.SUBMIT')} disabled={storeLoading} variant="primary" type="submit">{t('GENERAL.SUBMIT')}</Button>
											<Button title={t('GENERAL.CANCEL')} className='ms-2' variant="danger" onClick={handleValueModalClose}>{t('GENERAL.CANCEL')}</Button>
										</Col>
									</Row>
								</Form>);
						}}
					</Formik>
				</Modal.Body>
			</Modal>
			<Modal centered size='xl' show={showChartModal} onHide={handleCloseChartModal}>
				<Modal.Header closeButton>
					<Modal.Title>{!(isEmpty(chartData)) && chartData[0]?.label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<Stack direction='horizontal' gap={2}>
								<Button title={t('GENERAL.PREVDATE')} onClick={() => changeDate('prev')} disabled={statDate == moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD')}>
									<FaChevronLeft />
								</Button>
								<Form.Group controlId='chartDate'>
									<Form.Control
										className='me-auto'
										type='date'
										name='chartDate'
										value={statDate}
										min={moment(currentPatientIpno?.date_of_admission).format('YYYY-MM-DD')}
										max={currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format('YYYY-MM-DD') : moment(currentPatientIpno?.discharged_at).format('YYYY-MM-DD')}
										onChange={(e) => {
											setStatDate(e.target.value);
										}} />
								</Form.Group>
								<Button title={t('GENERAL.NEXTDATE')} onClick={() => changeDate('next')} disabled={statDate == (currentPatientIpno?.discharged_at === null ? moment(currentPatient?.current_date).format('YYYY-MM-DD') : moment(currentPatientIpno?.discharged_at).format('YYYY-MM-DD'))}>
									<FaChevronRight />
								</Button>
								<Button title={t('GENERAL.SHOWALL')} variant='success' onClick={() => showAllData()}>
									{t('GENERAL.SHOWALL')}
								</Button>
								{currentPatientIpno?.discharged_at === null && <Button title={t('GENERAL.SHOWTODAY')} variant='success' onClick={() => setStatDate(moment().format('YYYY-MM-DD'))}>
									{t('GENERAL.SHOWTODAY')}
								</Button>}
							</Stack>
						</Col>
					</Row>
					<MonitoringChartPlain type='4' statDate={statDate} title='' loading={chartLoading} data={chartData} errors={chartDataError}></MonitoringChartPlain>
				</Modal.Body>
				<Modal.Footer>
					<Button className='text-white' title={t('GENERAL.CLOSE')} variant="secondary" onClick={handleCloseChartModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
};

export default MonitoringTable2;
import { createSlice } from '@reduxjs/toolkit';
import { handleLogin, forgotPassword, handleLogOut, changePassword, resetPassword, registerConsultant, usernameSuggestion, verifyEmail, updateProfile, changeBackup, getBackup, getBackupAuthURL } from '../auth';

const initialState = {
	isLoggedIn: false,
	loginSuccessMessage: '',
	loginErrorMessage: '',
	error: false,
	loading: false,
	loginResponse: {},
	forgotSuccessMessage: {},
	isForgotSuccess: false,
	forgotErrorMessage: '',
	setToken: '',
	userDetails: [],
	currentHospital : [],

	changePasswordResponse: {},
	isChangePasswordSuccess: false,
	changePasswordErrorMessage:'',

	resetSuccess : {},
	resetErrorMessage : '',

	registerConsultantResponse : {},
	registerConsultantResponseError : '',

	usernameSuggestionResponse : {},
	usernameSuggestionResponseError : '',

	verifyEmailResponse: {},
	verifyEmailResponseError: '',

	updateLoginUser: {},
	updateLoginUserError: '',

	currentBackupSetting : {},
	currentBackupSettingError : '',

	backup : {},
	backupError : '',

	backupAuthUrl : {},
	backupAuthUrlError: ''
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loggedUserInfo: (state, { payload }) => {
			state.userDetails = payload;
		},
		changeHospital: (state, { payload }) => {
			state.currentHospital = payload;
		},
		updateHospitalPermissions: (state, { payload }) => {
			state.userDetails.hospital_list = payload;
		},
		setLogginError: (state, { payload }) => {
			state.loginErrorMessage = payload.message;
		},
		resetSuggestion: (state) => {
			state.usernameSuggestionResponse = {};
			state.usernameSuggestionResponseError = '';
		},
		changePasswordComplete: (state) => {
			state.isChangePasswordSuccess = false;
			state.changePasswordErrorMessage = '';
			state.changePasswordResponse = {};
		},
		changeBackupComplete: (state) => {
			state.backup = false;
			state.backupError = '';
		},
		resetInviteConsultant : (state) => {
			state.registerConsultantResponse = {};
			state.registerConsultantResponseError = '';
		},
		resetBackupAuthUrl: (state) => {
			state.backupAuthUrl =  { };
			state.backupAuthUrlError =  '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(handleLogin.pending, (state) => {
			state.loading = true;
			state.loginErrorMessage = '';
		});
		builder.addCase(handleLogin.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.loginResponse = payload.response;
			state.loginSuccessMessage = payload.response?.data.message;
			state.loginErrorMessage = '';
			state.userDetails = payload.response?.data.data;
			localStorage.setItem('authToken', payload.response?.data.data.access_token);
		});
		builder.addCase(handleLogin.rejected, (state, err) => {
			state.error = true;
			state.loading = false;
			state.loginErrorMessage = err.payload?.message;
		});

		builder.addCase(forgotPassword.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.isForgotSuccess = true;
			state.forgotSuccessMessage = payload.response;
			state.loginErrorMessage = '';
		});
		builder.addCase(forgotPassword.rejected, (state, err) => {
			state.loading = false;
			state.isForgotReject = true;
			state.forgotErrorMessage = err.payload?.message;
			state.loginErrorMessage = '';
		});

		builder.addCase(resetPassword.pending, (state) => {
			state.loading = true;
			state.resetSuccess = {};
			state.resetErrorMessage = '';
		});
		builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.resetSuccess = payload.response?.data;
		});
		builder.addCase(resetPassword.rejected, (state, err) => {
			state.loading = false;
			state.resetErrorMessage = err.payload?.message;
		});

		builder.addCase(handleLogOut.fulfilled, () => initialState);
		builder.addCase(changePassword.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(changePassword.fulfilled, (state, { payload }) => {
			state.loading = false;
			state.isChangePasswordSuccess = true;
			state.changePasswordResponse = payload.response?.data;
		});
		builder.addCase(changePassword.rejected, (state, err) => {
			state.loading = false;
			state.isChangePasswordSuccess = false;
			state.changePasswordErrorMessage = err.payload?.message;
		});

		builder.addCase(registerConsultant.pending, (state) => {
			state.loading = true;
			state.registerConsultantResponse = {};
			state.registerConsultantResponseError = '';
		});
		builder.addCase(registerConsultant.fulfilled, (state, action) => {
			state.loading = false;
			state.registerConsultantResponse = action.payload?.response.data.data;
		});
		builder.addCase(registerConsultant.rejected, (state, err) => {
			state.loading = false;
			state.registerConsultantResponseError = err?.payload?.message;
		});

		builder.addCase(usernameSuggestion.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(usernameSuggestion.fulfilled, (state, action) => {
			state.loading = false;
			state.usernameSuggestionResponse = action.payload?.response.data.data;
		});
		builder.addCase(usernameSuggestion.rejected, (state, err) => {
			state.loading = false;
			state.usernameSuggestionResponseError = err?.payload?.message;
		});

		builder.addCase(verifyEmail.pending, (state) => {
			state.loading = true;
			state.verifyEmailResponse = {};
			state.verifyEmailResponseError = '';
		});
		builder.addCase(verifyEmail.fulfilled, (state, action) => {
			state.loading = false;
			state.verifyEmailResponse = action.payload?.response.data;
		});
		builder.addCase(verifyEmail.rejected, (state, err) => {
			state.loading = false;
			state.verifyEmailResponseError = err?.payload?.message;
		});

		builder.addCase(updateProfile.pending, (state) => {
			state.loading = true;
			state.updateLoginUser = {};
			state.updateLoginUserError = '';
		});
		builder.addCase(updateProfile.fulfilled, (state, action) => {
			state.loading = false;
			state.updateLoginUser = action.payload?.response?.data;
			state.userDetails['first_name'] = action.payload?.response?.data?.data?.first_name;
			state.userDetails['last_name'] = action.payload?.response?.data?.data?.last_name;
			state.userDetails['profile_picture'] = action.payload?.response?.data?.data?.profile_picture;
		});
		builder.addCase(updateProfile.rejected, (state, err) => {
			state.loading = false;
			state.updateLoginUserError = err?.payload?.message;
		});

		builder.addCase(changeBackup.pending, (state) => {
			state.loading = true;
			state.backup = {};
			state.backupError = '';
		});
		builder.addCase(changeBackup.fulfilled, (state, action) => {
			state.loading = false;
			state.backup = action.payload?.response?.data;
		});
		builder.addCase(changeBackup.rejected, (state, err) => {
			state.loading = false;
			state.backupError = err?.payload?.message;
		});

		builder.addCase(getBackup.pending, (state) => {
			state.loading = true;
			state.currentBackupSetting = {};
			state.currentBackupSettingError = '';
		});
		builder.addCase(getBackup.fulfilled, (state, action) => {
			state.loading = false;
			state.currentBackupSetting = action.payload?.response?.data?.data;
		});
		builder.addCase(getBackup.rejected, (state, err) => {
			state.loading = false;
			state.currentBackupSettingError = err?.payload?.message;
		});

		builder.addCase(getBackupAuthURL.pending, (state) => {
			state.loading = true;
			state.backupAuthUrl = {};
			state.backupAuthUrlError = '';
		});
		builder.addCase(getBackupAuthURL.fulfilled, (state, action) => {
			state.loading = false;
			state.backupAuthUrl = action.payload?.response?.data?.data;
		});
		builder.addCase(getBackupAuthURL.rejected, (state, err) => {
			state.loading = false;
			state.backupAuthUrlError = err?.payload?.message;
		});
	},
});

export const { loggedUserInfo, changeHospital, updateHospitalPermissions, changePasswordComplete, resetSuggestion, resetUpdateLogin, setLogginError, changeBackupComplete, resetInviteConsultant, resetBackupAuthUrl } = authSlice.actions;
export default authSlice.reducer;
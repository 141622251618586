import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, Image, Row, Stack } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import RequiredAstric from '../../components/DynamicForm/required-astric';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { reset } from '../../redux/users/slice';
import { updateUser, getUserDetail } from '../../redux/users';
import { editUserValidationSchema } from '../../utils/validation';
import { UserPlaceholder } from '../../images';
import './edit-user.scss';

const EditUser = () => {
	const { currentHospital } = useSelector(state => state.auth);
	const { userProfileDetails, updateUserErrorMessage, userProfileErrorMessage, isUserUpdateSuccess, Loading } = useSelector(state => state.users);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [file, setFile] = useState(UserPlaceholder);
	const [img, setImage] = useState();
	const [serverErrors, setServerErrors] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const { roles } = useSelector(state => state.hospital);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getUserDetail({ user_id: id, hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (userProfileErrorMessage) {
			const errArray = userProfileErrorMessage?.payload?.message.split('|');
			setServerErrors(errArray);
		}

		if (updateUserErrorMessage) {
			const errArray = updateUserErrorMessage?.payload?.message.split('|');
			setServerErrors(errArray);
		}
		if (userProfileDetails) {
			setInitialValues(userProfileDetails);
			if (userProfileDetails.profile_picture) {
				setFile(userProfileDetails.profile_picture);
			}
		}
		if (isUserUpdateSuccess) {
			navigate('/users');
		}
	}, [userProfileDetails, updateUserErrorMessage, userProfileErrorMessage, isUserUpdateSuccess]);

	const backToUserList = () => {
		navigate('/users');
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
		setFile(URL.createObjectURL(e.target.files[0]));
	};

	const handleUserUpdateSubmit = (values) => {
		const payload = {
			'first_name': values.first_name,
			'last_name': values.last_name,
			'email': values.email,
			'mobile_number': values.mobile_number,
			'status': values.status,
			'user_id': id,
			'hospital_id': currentHospital.value
		};
		if (img) {
			payload.profile_picture = img;
		}
		dispatch(updateUser(payload));
	};

	return (
		<Container className='mt-4 p-0 edit-user-form'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{t('USEREDIT.TITLE')}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					<Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{t('USEREDIT.FORMTITLE')}
						</Card.Header>
						<Card.Body>
							{serverErrors.length > 0 &&
								<Alert variant="danger">
									<ul className='mb-0'>
										{serverErrors.map((error, index) => (
											<li key={index}>{error}</li>
										))}
									</ul>
								</Alert>}

							{!isEmpty(initialValues) && <Formik initialValues={initialValues} validationSchema={editUserValidationSchema(t)}
								onSubmit={handleUserUpdateSubmit}
							>
								{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
									<Form noValidate onSubmit={handleSubmit}>
										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridFirstName">
												<Form.Label>{t('USEREDIT.FIELDS.FIRSTNAME')}<RequiredAstric /></Form.Label>
												<Form.Control
													type="text"
													placeholder={t('USEREDIT.PLACEHOLDER.FIRSTNAME')}
													name="first_name"
													value={values.first_name}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.first_name && touched.first_name} />
												<Form.Control.Feedback type="invalid">
													{errors.first_name}
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridLastName">
												<Form.Label>{t('USEREDIT.FIELDS.LASTNAME')}<RequiredAstric /></Form.Label>
												<Form.Control
													type="text"
													placeholder={t('USEREDIT.PLACEHOLDER.LASTNAME')}
													name="last_name"
													value={values.last_name}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.last_name && touched.last_name} />
												<Form.Control.Feedback type="invalid">
													{errors.last_name}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>

										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridEmail">
												<Form.Label>{t('USEREDIT.FIELDS.EMAIL')}<RequiredAstric /></Form.Label>
												<Form.Control
													type="email"
													placeholder={t('USEREDIT.PLACEHOLDER.EMAIL')}
													name="email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.email && touched.email} />
												<Form.Control.Feedback type="invalid">
													{errors.email}
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridMobile">
												<Form.Label>{t('USEREDIT.FIELDS.MOBILE')}<RequiredAstric /></Form.Label>
												<Form.Control
													type="mobile_number"
													placeholder={t('USEREDIT.PLACEHOLDER.MOBILE')}
													name="mobile_number"
													value={values.mobile_number}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.mobile_number && touched.mobile_number} />
												<Form.Control.Feedback type="invalid">
													{errors.mobile_number}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>

										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridUsername">
												<Form.Label>{t('USEREDIT.FIELDS.USERNAME')}<RequiredAstric /></Form.Label>
												<Form.Control
													disabled
													type="text"
													placeholder={t('USEREDIT.PLACEHOLDER.USERNAME')}
													name="username"
													value={values.username}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.username && touched.username} />
												<Form.Control.Feedback type="invalid">
													{errors.username}
												</Form.Control.Feedback>
											</Form.Group>

											<Form.Group as={Col} controlId="formGridUserRole">
												<Form.Label>{t('USEREDIT.FIELDS.USERROLE')}<RequiredAstric /></Form.Label>
												<Form.Select
													disabled
													placeholder={t('USEREDIT.PLACEHOLDER.USERROLE')}
													aria-label={t('USEREDIT.PLACEHOLDER.USERROLE')}
													name="user_role"
													value={values.user_role}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.user_role && touched.user_role}>
													<option value={''}>{t('USERADD.PLACEHOLDER.USERROLE')}</option>
													{!isEmpty(roles) && Object.entries(roles).map(([key, val], i) => {
														return <option key={i} value={key}>{val}</option>;

													})}
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													{errors.user_role}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridProfilePicture">
												<Form.Label>{t('USEREDIT.FIELDS.PROFILEPIC')}</Form.Label>
												<Form.Control
													type="file"
													accept="image/png, image/jpeg"
													placeholder={t('USEREDIT.PLACEHOLDER.PROFILEPIC')}
													name="profile_picture"
													onChange={handleImageChange}
													onBlur={handleBlur}
													isInvalid={errors.profile_picture && touched.profile_picture} />
												<Form.Control.Feedback type="invalid">
													{errors.profile_picture}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} controlId="formGridUserStatus">
												<Form.Label>{t('USERADD.FIELDS.USERSTATUS')}<RequiredAstric /></Form.Label>
												<Form.Select
													placeholder={t('USERADD.PLACEHOLDER.USERSTATUS')}
													aria-label={t('USERADD.PLACEHOLDER.USERSTATUS')}
													name="status"
													value={values.status}
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={errors.status && touched.status}>
													<option value={''}>{t('USERADD.PLACEHOLDER.USERSTATUS')}</option>
													{Object.entries({ 'active': 'Active', 'inactive': 'Inactive' }).map(([key, val], i) => {
														return <option key={i} value={key}>{val}</option>;

													})}
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													{errors.status}
												</Form.Control.Feedback>
											</Form.Group>
										</Row>
										<Row className="mb-3">
											<Form.Group as={Col} controlId="formGridProfilePicturePreview">
												<Form.Label>{t('USEREDIT.FIELDS.PROFILEPICPREVIEW')}</Form.Label>
												<div>
													{file && <Image width={100} height={100} thumbnail src={file} />}
												</div>
											</Form.Group>
										</Row>
										<Stack direction='horizontal' gap={2}>
											<Button title={t('GENERAL.UPDATE')} disabled={Loading} variant="success" type="submit">
												{Loading && <Loader variant='light' size="sm" /> } {t('GENERAL.UPDATE')}
											</Button>
											<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={backToUserList}>
												{t('GENERAL.CANCEL')}
											</Button>
										</Stack>
									</Form>
								)}
							</Formik>}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container >
	);
};

export default EditUser;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getSubFormFields = createAsyncThunk(
	'getSubFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/sub/form/create', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addSubFormFields = createAsyncThunk(
	'addSubFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/sub/form/store', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getRoleForms = createAsyncThunk(
	'getRoleForms',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormFields = createAsyncThunk(
	'getFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/create', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const storeForm = createAsyncThunk(
	'storeForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/form/store', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormHistory = createAsyncThunk(
	'getFormHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/history/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormHistoryView = createAsyncThunk(
	'getFormHistoryView',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/view/details', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormHistoryEdit = createAsyncThunk(
	'getFormHistoryEdit',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/edit', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateForm = createAsyncThunk(
	'updateForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/form/update', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getSubFormList = createAsyncThunk(
	'getSubFormList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/sub/form/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getEditSubFormFields = createAsyncThunk(
	'getEditSubFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/sub/form/edit', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const updateSubFormFields = createAsyncThunk(
	'updateSubFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/sub/form/update', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteSubFormRecord = createAsyncThunk(
	'deleteSubFormRecord',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/sub/form/delete', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const storeHandoverForm = createAsyncThunk(
	'storeHandoverForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/patient/handover/store', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const signInHandoverForm = createAsyncThunk(
	'signInHandoverForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/patient/handover/accept', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const storeDocumentForm = createAsyncThunk(
	'storeDocumentForm',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/document/form/upload', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getFormDocumentHistory = createAsyncThunk(
	'getFormDocumentHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/documents/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const deleteDocument = createAsyncThunk(
	'deleteDocument',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/document/delete', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const downloadFormRecord = createAsyncThunk(
	'downloadFormRecord',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/download/details', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const saveTimerData = createAsyncThunk(
	'saveTimerData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/form/save/timer/data', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getMedicinesHistory = createAsyncThunk(
	'getMedicinesHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/medicines/history', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDailyAssessmentHistory = createAsyncThunk(
	'getDailyAssessmentHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/today/history', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
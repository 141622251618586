import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Nav, Row } from 'react-bootstrap';
import BackButton from '../../components/BackButton';
import { callGetAPI, callPostAPI } from '../../services/axios.js';
import './styles.scss';
import './dx.light.scss';
import Loader from '../../components/SplashScreen/loader.js';

function renderHtml(task) {
	return !isEmpty(task.sub_forms) && task.sub_forms.map((subform) => {
		return (<div style={{ paddingLeft: '20px' }}
			key={subform.id}
			className="card-subject my-1 py-1 border shadow-sm rounded-1">
			{subform.form_name}
		</div>);
	});
}

function Card({ task }) {
	return <div className="card shadow-sm m-3 p-3">
		<div className="card-subject">
			<b>{task.form_name}</b>
		</div>
		{renderHtml(task)}
	</div>;
}

function List({ title, index, tasks, onTaskDragStart, onTaskDrop }) {
	return (
		<div className="list my-4">
			<div className="list-title ms-2 mt-2 text-primary">{title == 'noaccess' ? t('FORMS.ALL_FORMS') : title == 'readonly' ? t('FORMS.READ_ONLY_FROMS') : t('FORMS.FULL_ACCESS_FORMS')}</div>
			<ScrollView
				className="scrollable-list"
				direction="vertical"
				showScrollbar="always">
				<Sortable
					className="sortable-cards"
					group="cardsGroup"
					data={index}
					onDragStart={onTaskDragStart}
					onAdd={onTaskDrop}>
					{tasks.map((task) =>
						<Card
							key={task.id}
							task={task}>
						</Card>)}
				</Sortable>
			</ScrollView>
		</div>
	);
}

const AsssignPermission = () => {
	const [forms, setForms] = useState([]);
	const [lists, setList] = useState([]);
	const [Loading, setLoading] = useState(false);
	const { currentHospital } = useSelector(state => state.auth);
	const { hospitalBedInfo } = useSelector(state => state.hospital);
	const { role } = useParams();
	const location = useLocation();
	const mystatuses = ['noaccess', 'readonly', 'full_access'];
	const [key, setKey] = useState('icu');
	useEffect(() => {
		if (currentHospital.value) {
			setLoading(true);
			callGetAPI({
				route: 'hospital/form/permission/edit',
				params: {
					hospital_id: currentHospital.value,
					bed_type: key,
					role: role
				}
			}).then((data) => {
				setLoading(false);
				setForms(data.data.data.forms);
			});
		}
	}, [currentHospital.value, key]);

	useEffect(() => {
		if (!(isEmpty(forms))) {
			let mylist = [];
			mystatuses.forEach((status) => {
				mylist.push(forms.filter((form) => form.access === status));
			});
			mylist[0].sort((a, b) => a.form_name.localeCompare(b.form_name));
			mylist[1].sort((a, b) => a.form_name.localeCompare(b.form_name));
			mylist[2].sort((a, b) => a.form_name.localeCompare(b.form_name));
			setList(mylist);
		}
	}, [forms]);

	const onTaskDragStart = (e) => {
		e.itemData = lists[e.fromData][e.fromIndex];
	};

	const onTaskDrop = (e) => {
		updateTask(e.fromData, e.itemData, e.fromIndex, -1);
		updateTask(e.toData, e.itemData, -1, e.toIndex);
		updateFormPermissions(e.itemData.id, mystatuses[e.toData]);
	};

	const reorder = (items, item, fromIndex, toIndex) => {
		let result = items;
		if (fromIndex >= 0) {
			result = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];
		}

		if (toIndex >= 0) {
			result = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
		}
		return result;
	};

	const updateTask = (listIndex, itemData, fromIndex, toIndex) => {
		lists[listIndex] = reorder(lists[listIndex], itemData, fromIndex, toIndex).sort((a, b) => a.form_name.localeCompare(b.form_name));
		setList([...lists]);
	};

	const updateFormPermissions = (formid, access) => {
		callPostAPI({
			route: 'hospital/form/assignpermission',
			body: {
				hospital_id: currentHospital.value,
				role: role,
				bed_type: key,
				form_id: formid,
				access: access
			}
		});
	};

	return (
		<>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary text-capitalize'>{location?.state?.role || role}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Nav variant="tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
				{hospitalBedInfo?.total_icu_beds > 0 && <Nav.Item>
					<Nav.Link eventKey="icu">{t('GENERAL.ICU')}</Nav.Link>
				</Nav.Item>}
				{hospitalBedInfo?.total_ward_beds > 0 && <Nav.Item>
					<Nav.Link eventKey="ward">{t('GENERAL.GENERALWARD')}</Nav.Link>
				</Nav.Item>}
			</Nav>
			{Loading ?
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row> :
				<div id="kanban">
					<ScrollView
						className="scrollable-board"
						direction="horizontal"
						showScrollbar="always">
						<Sortable
							className="sortable-lists"
							itemOrientation="horizontal"
							handle=".list-title">
							{!isEmpty(lists) && lists.map((tasks, listIndex) => {
								const status = mystatuses[listIndex];
								return <List
									key={status}
									title={status}
									index={listIndex}
									tasks={tasks}
									onTaskDragStart={onTaskDragStart}
									onTaskDrop={onTaskDrop}>
								</List>;
							})}
						</Sortable>
					</ScrollView>
				</div>}
		</>
	);
};

export default AsssignPermission;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI } from '../../services/axios';

export const getChartData = createAsyncThunk(
	'getChartData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/data', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getMainChartTableData = createAsyncThunk(
	'getMainChartTableData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/table/data', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getEWSChartTableData = createAsyncThunk(
	'getEWSChartTableData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/table/data', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getIOChartTableData = createAsyncThunk(
	'getIOChartTableData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/table/data', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getVentilatorChartTableData = createAsyncThunk(
	'getVentilatorChartTableData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/table/data', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getInvestigationHistory = createAsyncThunk(
	'getInvestigationHistory',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/investigation/history', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getMedicinesData = createAsyncThunk(
	'getMedicinesData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/medicines/dashboard', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const storeChartData = createAsyncThunk(
	'storeChartData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/save/icuchart', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const storeChartDataMultiple = createAsyncThunk(
	'storeChartDataMultiple',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/multiple/save/icuchart', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const downloadChartData = createAsyncThunk(
	'downloadChartData',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/icuchart/data/download', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const updateMedicineRate = createAsyncThunk(
	'updateMedicineRate',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/rate/field/update', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
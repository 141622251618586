import React, { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillPrinterFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import DataTable from 'react-data-table-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DynamicForm from '../../components/DynamicForm';
import BackButton from '../../components/BackButton';
import PatientInfo from '../../components/Patient/patient-info';
import Loader from '../../components/SplashScreen/loader';
import { getFormHistoryView } from '../../redux/forms';
import { reset } from '../../redux/forms/slice';
import { getPatientInfo } from '../../redux/hospital';
import { downloadPDFFile, getFormattedDateTime } from '../../utils/helper';
import { callGetAPI } from '../../services/axios';

const ViewClinicalForm = () => {
	const [initialValues, setInitialValues] = useState({});
	const [localState, setLocalState] = useState(false);
	const iframeRef = useRef(null);
	const { currentPatient, currentPatientDetail } = useSelector(state => state.hospital);
	const { formHistoryViewResponse, formHistoryViewError, downloadFormRecordDetail, downloadFormRecordDetailError, Loading } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { formId, id } = useParams();

	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
			}
		}
	}, [currentPatient, currentPatientDetail]);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getFormHistoryView({ hospital_id: currentHospital.value, form_id: formId, id, bed_type : currentPatient.bed_type }));
		}
	}, [currentHospital]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (formHistoryViewError || downloadFormRecordDetailError) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [formHistoryViewError, downloadFormRecordDetailError]);

	useEffect(() => {
		if (!isEmpty(formHistoryViewResponse?.form_fields)) {
			let fields = {};
			formHistoryViewResponse?.form_fields.map((field) => {
				if (field.type != 'checkbox') {
					fields[field.field_name] = field?.value || '';
				}
				else {
					fields[field.field_name] = field?.value?.split(',') || [];
				}
			});
			setInitialValues(fields);
		}
	}, [formHistoryViewResponse]);

	useEffect(() => {
		if (localState && !isEmpty(downloadFormRecordDetail) && downloadFormRecordDetail?.path != '') {
			const hiddenButton = document.getElementById('pdf');
			hiddenButton?.setAttribute('href', downloadFormRecordDetail?.path);
			hiddenButton?.click();
			setLocalState(false);
		}
	}, [downloadFormRecordDetail]);

	const goBack = () => {
		navigate(-1);
	};

	const handleSubmitFormData = () => {
	};

	const columns = [
		{
			name: t('CFORMS.COLUMNS.FIELDTITLE'),
			selector: row => row.field_title,
		},
		{
			name: t('CFORMS.COLUMNS.PREVAL'),
			selector: row => row.old_value,
		},
		{
			name: t('CFORMS.COLUMNS.NEWVAL'),
			selector: row => row.new_value,
		},
		{
			name: t('CFORMS.COLUMNS.DATETIME'),
			selector: row => getFormattedDateTime(row.created_at),
		},
		{
			name: t('CFORMS.COLUMNS.USER'),
			selector: row => row.users_name,
		},
	];

	const handleDownloadFormRecord = (isDownload) => {
		setLocalState(true);
		callGetAPI({
			route: 'hospital/form/download/details',
			params: { hospital_id: currentHospital.value, p_id: currentPatient.p_id, form_id: formId, id: id, bed_type: currentPatient.bed_type },
			options: {
				responseType: 'blob'
			}
		}).then((data) => {
			if (isDownload) {
				downloadPDFFile(data.data);
				return;
			}
			const blob = new Blob([data?.data], { type: 'application/pdf' });
			const pdfBlobUrl = URL.createObjectURL(blob);

			if (iframeRef.current) {
				iframeRef.current.src = pdfBlobUrl;

				setTimeout(() => {
					if (iframeRef.current && iframeRef.current.contentWindow) {
						iframeRef.current.contentWindow.print();
					}
				}, 500);
			}
		}).finally(() => {
			setLocalState(false);
		});
	};
	return (
		<Container className='mt-4'>
			<iframe
				ref={iframeRef}
				style={{ display: 'none' }}
				title="PDF"
			/>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{formHistoryViewResponse?.name}</h5>
					<Stack direction='horizontal' gap='2'>
						<Button title={t('GENERAL.DOWNLOAD')} size="sm" onClick={() => handleDownloadFormRecord(true)}>
							<FaDownload /> {t('GENERAL.DOWNLOAD')}
						</Button>
						<Button title={t('GENERAL.PRINT')} size="sm" onClick={() => handleDownloadFormRecord()}>
							<BsFillPrinterFill /> {t('GENERAL.PRINT')}
						</Button>
						<Button title={t('GENERAL.FILE')} className="d-none" as={Link} to='' id='pdf' rel="noreferrer" target='_blank'>
							File
						</Button>
						<BackButton />
					</Stack>
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{Loading ?
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row> :
				<Row className='mt-4'>
					<Col>
						{(formHistoryViewError || downloadFormRecordDetailError) &&
							<Alert variant='danger'>
								<ul className='mb-0'>
									{formHistoryViewError && formHistoryViewError.split('|').map((error, index) => (
										<li key={index}>{error}</li>
									))}
									{downloadFormRecordDetailError && downloadFormRecordDetailError.split('|').map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</Alert>
						}
						<Container className='mb-3'>
							{t('CFORMS.COLUMNS.DATETIME')} : {getFormattedDateTime(formHistoryViewResponse?.date_time)}
						</Container>
						{formHistoryViewResponse?.form_fields && <Card text='dark' bg='white'>
							<Card.Header className='text-uppercase'>
								{formHistoryViewResponse.name}
							</Card.Header>
							<Card.Body>
								{!isEmpty(initialValues) && <DynamicForm initialValues={initialValues} fields={formHistoryViewResponse.form_fields} handleCancel={goBack} handleSubmit={handleSubmitFormData} parentformuniqueid='' viewMode={true} />
								}
							</Card.Body>
						</Card>}
						<hr></hr>
						<h5 className='text-primary mb-3'>{t('CFORMS.AUDITLOG')} : </h5>
						<DataTable
							columns={columns}
							data={formHistoryViewResponse?.audit_log}
							progressPending={Loading}
							progressComponent={<Loader />}
							highlightOnHover
						/>
					</Col>
				</Row>}
		</Container >
	);
};

export default ViewClinicalForm;

import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import DynamicForm from '../../components/DynamicForm';
import PatientInfo from '../../components/Patient/patient-info';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { changeSubFormList, reset } from '../../redux/forms/slice';
import { getPatientInfo } from '../../redux/hospital';
import { getFormFields, storeDocumentForm } from '../../redux/forms';
import { getDefaultFormID } from '../../utils/helper';

const AddClinicalFormDocument = () => {
	const [initialValues, setInitialValues] = useState({});
	const { currentPatient, currentPatientDetail } = useSelector(state => state.hospital);
	const { formFields, formFieldsError, storeDocumentFormResponse, storeDocumentFormError, Loading } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { formId } = useParams();

	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
			}
		}
		if (!isEmpty(currentHospital)) {
			dispatch(getFormFields({ hospital_id: currentHospital.value, default_form_id: getDefaultFormID('Upload Form Documents'), p_id: currentPatient.p_id, bed_type : currentPatient.bed_type }));
		}
	}, [currentPatient, currentPatientDetail, currentHospital]);

	useEffect(() => {
		if (!isEmpty(formFields?.form_fields)) {
			let fields = {};
			formFields?.form_fields.map((field) => {
				fields[field.field_name] = field.default_values || '';
			});
			setInitialValues(fields);
		}
	}, [formFields]);

	useEffect(() => {
		if (!isEmpty(storeDocumentFormResponse)) {
			navigate(-1);
		}
	}, [storeDocumentFormResponse]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (formFieldsError) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [formFieldsError]);

	const goBack = () => {
		navigate(-1);
	};

	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changeSubFormList({ values, fieldindex, mode: 'add' }));
	};
	const handleSubmitFormData = (values) => {
		let formdata = new FormData();
		formdata.append('form_id', formId);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', currentPatient.p_id);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			formFields.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
			});
		});
		dispatch(storeDocumentForm(formdata));
	};

	return (
		<Container className='mt-4'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{location?.state?.uploadFormName}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{Loading &&
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row>
			}
			<Row className='mt-4'>
				<Col>
					{(formFieldsError || storeDocumentFormError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{formFieldsError && formFieldsError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{storeDocumentFormError && storeDocumentFormError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{formFields.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{formFields.name}
						</Card.Header>
						<Card.Body>
							{!isEmpty(initialValues) && <DynamicForm initialValues={initialValues} fields={formFields.form_fields} handleCancel={goBack} handleSubmit={handleSubmitFormData} parentformuniqueid={formFields.form_unique_id} handleUpdateSubFormList={handleUpdateSubFormList} loading={Loading} />
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
		</Container >
	);
};

export default AddClinicalFormDocument;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';
import { MdLogout/* , MdVideoCall */ } from 'react-icons/md';
import { HiPencilAlt, HiUserGroup } from 'react-icons/hi';
import { AiFillSetting } from 'react-icons/ai';
import { FaHome, FaChartPie, FaUserInjured, FaShare, FaArchive } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { Nav, Navbar, Container, Image, Stack, Dropdown, NavDropdown } from 'react-bootstrap';
import Select from 'react-select';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Notification from '../Notification';
import { handleLogOut } from '../../redux/auth';
import { changeHospital } from '../../redux/auth/slice';
import { getHospitalRoleInfo } from '../../redux/hospital';
import { changeBedType, changeBedTypeFilter, changeBedView, resetPatient } from '../../redux/hospital/slice';
import { checkMenuAccess, GetClinicalSubMenus, useAuth } from '../../utils/helper';
import { UserPlaceholder, axiaLogo } from '../../images';
import './style.scss';

const Header = () => {
	const [hospitals, setHospitals] = useState([]);
	const [hospital, setHospital] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userDetails, currentHospital } = useSelector(state => state.auth);
	const useAuthContext = useAuth();
	useEffect(() => {
		if (userDetails?.hospital_list?.length > 0) {
			const options = userDetails.hospital_list.map((option) => {
				return { 'value': option.hospital_id, 'label': option.hospital_name, 'role': option.user_role, 'is_admin': option.is_admin, 'postfix': option.postfix, 'role_name': option.role_name, 'permissions': option.permissions };
			});
			setHospitals(options);
			if (isEmpty(hospital) && isEmpty(currentHospital)) {
				dispatch(changeHospital(options[0]));
				setHospital(options[0]);
			}
			else if (!isEmpty(currentHospital) && currentHospital.value !== hospital.value) {
				setHospital(currentHospital);
			}
			dispatch(getHospitalRoleInfo());
		}
	}, [userDetails]);
	const handleLogOutSubmit = () => {
		dispatch(handleLogOut({ useAuthContext }));
	};
	const locationArr = location.pathname.split('/');
	const locationName = (locationArr.length) ? '/' + locationArr[1] : '/';
	const handleChange = (event) => {
		setHospital(event);
		dispatch(changeHospital(event));
		dispatch(resetPatient());
		dispatch(changeBedType('icu'));
		dispatch(changeBedTypeFilter('all'));
		dispatch(changeBedView('grid'));
		navigate('/');
	};
	const topMenu = [
		{
			key: 'dashboard',
			link: '/',
			icon: <FaHome />,
			text: t('HEADER.MENUS.DASHBOARD'),
			submenu: []
		},
		{
			key: 'patients',
			link: '/patients',
			icon: <FaUserInjured />,
			text: t('HEADER.MENUS.PATIENTS'),
			submenu: []
		},
		{
			key: 'patient_monitoring',
			link: '/patient-monitoring',
			icon: <FaChartPie />,
			text: t('HEADER.MENUS.PATIENT_MONITORING'),
			submenu: []
		},
		{
			key: 'clinical_forms',
			link: '',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'nurse_clinical_forms',
			link: '/clinical_forms/role/nurse',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'mo_clinical_forms',
			link: '/clinical_forms/role/doctor',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'consultant_clinical_forms',
			link: '/clinical_forms/role/consultant',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'physiotherapist_clinical_forms',
			link: '/clinical_forms/role/physiotherapist',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'dietitian_clinical_forms',
			link: '/clinical_forms/role/dietitian',
			icon: <HiPencilAlt />,
			text: t('HEADER.MENUS.CLINICALFORMS'),
			submenu: []
		},
		{
			key: 'discharge_clinical',
			link: '/discharge-clinical',
			icon: <FaShare />,
			text: t('HEADER.MENUS.DISCHARGE_CLINICAL'),
			submenu: []
		},
		{
			key: 'discharge_admin',
			link: '/discharge-admin',
			icon: <FaShare />,
			text: t('HEADER.MENUS.DISCHARGE_ADMIN'),
			submenu: []
		},
		{
			key: 'archived_patients',
			link: '/archived-patients',
			icon: <FaArchive />,
			text: t('HEADER.MENUS.ARCHIVED_PATIENTS'),
			submenu: []
		},
		{
			key: 'users',
			link: '/users',
			icon: <HiUserGroup />,
			text: t('HEADER.MENUS.USERS'),
			submenu: []
		},
		{
			key: 'settings',
			link: '/settings',
			icon: <AiFillSetting />,
			text: t('HEADER.MENUS.SETTINGS'),
			submenu: []
		},
		/* {
			key: 'videocall',
			link: '/videocall',
			icon: <MdVideoCall />,
			text: t('HEADER.MENUS.VIDEOCALL'),
			submenu: []
		} */
	];

	return (
		<Navbar collapseOnSelect expand="lg" bg="white" variant="primary" className='flex-column'>
			<Container className='d-flex p-2'>
				<Navbar.Brand as={Link} to="/">
					<img src={axiaLogo} alt={t('GENERAL.LOGOTEXT')} style={{ height: '40px' }} />
				</Navbar.Brand>
				<Stack className='dropdown-option' direction='horizontal' gap={3}>
					<Select value={hospital} options={hospitals} placeholder={t('DASHBOARD.HOSPITALLIST')} onChange={handleChange} />
					<Notification />
					<Nav className='me-auto nav-dropdown'>
						<Dropdown>
							<Dropdown.Toggle as={Stack} direction='horizontal' gap={2}>
								<Image roundedCircle
									src={userDetails.profile_picture || UserPlaceholder}
									width="50"
									height="50"
									className="d-inline-block align-top"
									alt={userDetails.first_name + ' ' + userDetails.last_name}
								/>
								<span>
									{userDetails.first_name + ' ' + userDetails.last_name}
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ 'position': 'absolute' }} align="end">
								<Dropdown.Item onClick={handleLogOutSubmit}>
									{<MdLogout />}&nbsp;
									<span>
										{t('HEADER.MENUS.LOGOUT')}
									</span>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</Stack>
				<Navbar.Toggle aria-controls="responsive-navbar-nav hmaburger-menu-btn" />
			</Container>
			<Container className='border-top p-0 navbar-inner'>
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav activeKey={locationName} className='flex-wrap'>
						{topMenu.map((menu) => {
							return (
								<React.Fragment key={menu.key}>
									{checkMenuAccess(menu.key) && <LinkContainer key={menu.key} to={menu.link}>
										{
											(menu.key !== 'clinical_forms') ?
												<Nav.Link>
													{menu.icon}
													<span className='ms-2'>{menu.text}</span>
												</Nav.Link>
												:
												<NavDropdown title={[
													<span key='roles' className={`${locationName === '/clinical_forms' ? 'text-primary' : ''}`}>
														{menu.icon}
													</span>,
													<span key={menu.key} className={`ms-2 ${locationName === '/clinical_forms' ? 'text-primary' : ''}`}>
														{menu.text}
													</span>]} renderMenuOnMount={true}>
													<GetClinicalSubMenus />
												</NavDropdown>
										}
									</LinkContainer>}
								</React.Fragment>);
						})}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar >
	);
};

export default Header;
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';
import { HiInformationCircle } from 'react-icons/hi';
import { getHelpVideoDetail } from '../../utils/helper';
import isEmpty from 'lodash/isEmpty';

function HelpVideoButton({ videoKey, mode }) {
	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);
	const [videoDetail, setVideoDetail] = useState({});
	useEffect(() => {
		if (!isEmpty(videoKey)) {
			setVideoDetail(getHelpVideoDetail(videoKey));
		}
	}, [videoKey]);

	return (
		<>
			<Modal size='xl' show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>{videoDetail?.videoTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='ratio ratio-21x9'>
						<iframe src={videoDetail?.videoURL} title={videoDetail?.videoTitle} allowFullScreen></iframe>
					</div>
				</Modal.Body>
			</Modal>
			<Button title={t('GENERAL.HELP')} className={mode === 'dark' ? 'text-white' : null} variant='link' size='sm' onClick={handleShowModal}><HiInformationCircle /></Button>
		</>
	);
}

export default HelpVideoButton;
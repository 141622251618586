import React, { useEffect } from 'react';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Loader from '../../../components/SplashScreen/loader';
import { verifyEmail } from '../../../redux/auth';
import useQuery from '../../../utils/useQuery';

const VerifyEmail = () => {
	const dispatch = useDispatch();
	const { verifyEmailResponse, verifyEmailResponseError, loading } = useSelector(state => state.auth);
	const { token } = useQuery();
	const navigate = useNavigate();
	useEffect(() => {
		if (isEmpty(token)) {
			navigate('/invalid-link');
		}
		else {
			dispatch(verifyEmail({ token }));
		}
	}, [token]);

	return (
		<Container fluid className='vh-100 d-flex align-items-center justify-content-center'>
			<Row>
				{loading ? <Loader /> :
					<Col className='p-5 bg-white shadow-lg rounded'>
						<h1 className='text-center text-primary'>{t('VERIFYEMAIL.TITLE')}</h1>
						<p className='text-center text-muted fs-6'>{t('VERIFYEMAIL.SUB_TITLE')}</p>
						{!isEmpty(verifyEmailResponseError) &&
							<Alert variant='danger' className='mt-2'>
								{verifyEmailResponseError && verifyEmailResponseError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</Alert>}
						{!isEmpty(verifyEmailResponse) &&
							<>
								<Alert variant='success' className='mt-2'>
									{verifyEmailResponse?.message}
								</Alert>
								<Button title={t('LOGIN.BUTTON')} className='d-grid mt-2' as={Link} to='/login'>
									{t('LOGIN.BUTTON')}
								</Button>
							</>
						}
					</Col>
				}
			</Row>
		</Container>
	);
};

export default VerifyEmail;

import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DataTable from 'react-data-table-component';
import { /* Alert, Button, */ Card, Col, Form, Row } from 'react-bootstrap';
import { getHospitalLedger } from '../../redux/hospital';
import Loader from '../../components/SplashScreen/loader';
import useDebounce from '../../utils/useDebounceHook';
import { getFormattedDateTime } from '../../utils/helper';

const Ledger = () => {
	const [perPage, setPerPage] = useState(10);
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const { currentHospital } = useSelector(state => state.auth);
	const { ledgerDetails, Loading } = useSelector(state => state.hospital);
	const [totalRows, setTotalRows] = useState(0);
	const dispatch = useDispatch();
	const debouncedSearchTerm = useDebounce(filterText, 500);
	// const navigate = useNavigate();
	const columns = [
		{
			name: t('LEDGER.COLUMNS.TYPE'),
			selector: row => row.type,
			sortable: true,
			sortField: 'type',
		},
		{
			name: t('LEDGER.COLUMNS.CONNECTS'),
			selector: row => row.connect,
			sortable: false,
			sortField: 'connect',
		},
		{
			name: t('LEDGER.COLUMNS.PLAN'),
			selector: row => row.plan_id ? row.plan.plan_name : '',
			wrap:true,
			sortable: false,
			sortField: 'plan',
		},
		{
			name: t('LEDGER.COLUMNS.DESCRIPTION'),
			selector: row => row.description,
			grow:3,
			wrap:true,
			sortable: false,
			sortField: 'plan',
		},
		{
			name: t('LEDGER.COLUMNS.IPNO'),
			selector: row => row.ip_no,
			grow:2,
			sortable: false,
			sortField: 'ip_no',
		},
		{
			name: t('LEDGER.COLUMNS.PATIENT'),
			selector: row => row.patient_name,
			grow:2,
			wrap:true,
			sortable: false,
			sortField: 'patient_name',
		},
		{
			name: t('LEDGER.COLUMNS.DATE'),
			selector: row => getFormattedDateTime(row.created_at),
			sortable: true,
			grow: 2,
			wrap:true,
			sortField: 'created_at',
		},
	];

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getHospitalLedger({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getHospitalLedger({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	useEffect(() => {
		if (!isEmpty(ledgerDetails)) {
			setTotalRows(ledgerDetails?.total);
			setPerPage(ledgerDetails.per_page);
		}
	}, [ledgerDetails]);
	/* const backToDashboard = () => {
		navigate('/');
	}; */
	const handlePageChange = page => {
		dispatch(getHospitalLedger({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getHospitalLedger({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	return (
		<Card>
			<Card.Body>
				<Row>
					<Col>
						<DataTable
							columns={columns}
							data={ledgerDetails.data}
							progressPending={Loading}
							progressComponent={<Loader />}
							highlightOnHover
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							subHeader
							subHeaderComponent={subHeaderComponentMemo}
							onSort={(column, sortDirection) => setColumnSort({ column_name: column.sortField, order: sortDirection })}
							sortServer
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default Ledger;

import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Form, Stack } from 'react-bootstrap';
import { changePassword } from '../../redux/auth';
import { changePasswordComplete } from '../../redux/auth/slice';
import { changePasswordValidationSchema } from '../../utils/validation';
import PasswordInput from '../../components/Common/password-input';


const ChangePassword = () => {
	const { isChangePasswordSuccess, changePasswordErrorMessage, changePasswordResponse, loading } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const initialValues = {
		'oldpassword': '',
		'password': '',
		'password_confirmation': ''
	};

	useEffect(() => {
		return () => {
			dispatch(changePasswordComplete());
		};
	}, []);

	const backToDashboard = () => {
		navigate('/');
	};

	const handleChangePasswordSubmit = (values, { resetForm }) => {
		dispatch(changePassword({ ...values }));
		resetForm();
	};

	return (
		<Card>
			<Card.Header className='fw-bold'>
				{t('CHANGEPASSWORD.FORMTITLE')}
			</Card.Header>
			<Card.Body>
				{!isChangePasswordSuccess && changePasswordErrorMessage &&
					<Alert variant="danger">
						<ul className='mb-0'>
							{changePasswordErrorMessage.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{isChangePasswordSuccess && changePasswordResponse?.message &&
					<Alert variant="success">
						{changePasswordResponse?.message}
					</Alert>}
				<Formik initialValues={initialValues} validationSchema={changePasswordValidationSchema(t)}
					onSubmit={handleChangePasswordSubmit}
				>
					{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mb-3" as={Col} controlId="formGridOldPassword">
								<Form.Label>{t('CHANGEPASSWORD.FIELDS.CURRENTPASSWORD')}</Form.Label>
								<PasswordInput
									placeholder={t('CHANGEPASSWORD.PLACEHOLDER.CURRENTPASSWORD')}
									name="oldpassword"
									value={values.oldpassword}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={errors.oldpassword && touched.oldpassword} />
								<Form.Control.Feedback type="invalid">
									{errors.oldpassword}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" as={Col} controlId="formGridPassword">
								<Form.Label>{t('CHANGEPASSWORD.FIELDS.PASSWORD')}</Form.Label>
								<PasswordInput
									placeholder={t('CHANGEPASSWORD.PLACEHOLDER.PASSWORD')}
									name="password"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={errors.password && touched.password} />
								<Form.Control.Feedback type="invalid">
									{errors.password}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3" as={Col} controlId="formGridPasswordConfirm">
								<Form.Label>{t('CHANGEPASSWORD.FIELDS.PASSWORDCONFIRM')}</Form.Label>
								<PasswordInput
									placeholder={t('CHANGEPASSWORD.PLACEHOLDER.PASSWORDCONFIRM')}
									name="password_confirmation"
									value={values.password_confirmation}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={errors.password_confirmation && touched.password_confirmation} />
								<Form.Control.Feedback type="invalid">
									{errors.password_confirmation}
								</Form.Control.Feedback>
							</Form.Group>
							<Stack direction='horizontal' gap={2}>
								<Button title={t('GENERAL.UPDATE')} disabled={loading} variant="primary" type="submit">{t('GENERAL.UPDATE')}</Button>
								<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={backToDashboard}>{t('GENERAL.CANCEL')}</Button>
							</Stack>
						</Form>
					)}
				</Formik>
			</Card.Body>
		</Card>
	);
};

export default ChangePassword;

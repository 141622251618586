import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'react-bootstrap';
import { store, persistor } from './redux/store';
import RouterContainer from './router';
import './locales/i18n';
import './App.scss';

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider>
					<RouterContainer />
				</ThemeProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;

import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Alert, Button, ButtonGroup, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BsGrid } from 'react-icons/bs';
import DashboardBed from '../../components/DashboardBed';
import FirstTimePassword from './firsttime-password';
import { getHospitalBedInfo, getHospitalBedTypes } from '../../redux/hospital';
import { resetNotifications } from '../../redux/chat/slice';
import { changeBedType, changeBedTypeFilter, changeBedView, resetPatient } from '../../redux/hospital/slice';
import { FaBars } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';

const Dashboard = () => {
	const { userDetails, currentHospital } = useSelector(state => state.auth);
	const { bedTypesResponse, hospitalBedInfo, hospitalBedInfoError, currentBedType, currentBedView, currentBedTypeFilter } = useSelector(state => state.hospital);
	const dispatch = useDispatch();
	useEffect(() => {
		if (currentHospital.value) {
			dispatch(getHospitalBedTypes({ hospital_id: currentHospital.value }));
			dispatch(getHospitalBedInfo({ hospital_id: currentHospital.value, bed_type: currentBedType }));
			dispatch(resetNotifications());
		}
	}, [currentHospital.value, currentBedType]);

	return (
		<Container>
			<Row className='my-3 d-flex justify-content-between'>
				<Col md='3'>
					<h5>{t('DASHBOARD.HELLO')} {userDetails.first_name + ' ' + userDetails.last_name}</h5>
				</Col>
				{hospitalBedInfo.total_beds > 1 &&
					<Col md='7'>
						<Stack gap={3} direction='horizontal' className='float-md-end w-100'>
							<Button title={t('GENERAL.OCCUPIED_BEDS')} variant="grey" className="btn border w-100" style={{ cursor: 'unset' }}>{t('GENERAL.OCCUPIED_BEDS')}: {hospitalBedInfo.booked_bed_numbers.length}/{hospitalBedInfo.total_beds}</Button>
							<div className="vr" />
							{hospitalBedInfo.total_ward_beds > 1 &&
								<>
									<ButtonGroup>
										<Button title={t('GENERAL.ICU')} className='border border-1' variant={currentBedType == 'icu' ? 'primary' : 'grey'} onClick={() => {
											dispatch(resetPatient());
											dispatch(changeBedType('icu'));
										}}>{t('GENERAL.ICU')}</Button>
										<Button title={t('GENERAL.GENERALWARD')} className='border border-1' variant={currentBedType == 'ward' ? 'primary' : 'grey'} onClick={() => {
											dispatch(resetPatient());
											dispatch(changeBedType('ward'));
										}}>{t('GENERAL.GENERALWARD')}</Button>
									</ButtonGroup>
									<div className="vr" />
								</>
							}
							{
								<>
									<Form.Label>
										<FiFilter />
									</Form.Label>
									<Form.Select
										name='bedTypeFilter'
										value={currentBedTypeFilter}
										onChange={(e) => {
											dispatch(changeBedTypeFilter(e.target.value));
										}}
									>
										<option value='all'>All</option>
										{bedTypesResponse && bedTypesResponse?.data && bedTypesResponse?.data?.map((type, typeIndex) => {
											return (
												<option key={typeIndex} value={type?.bed_type}>{type?.bed_type}</option>
											);
										})}
									</Form.Select>
								</>
							}
							<div className="vr" />
							<Button title={t('GENERAL.LIST')} size='sm' variant={currentBedView == 'list' ? 'primary' : 'light'} onClick={() => dispatch(changeBedView('list'))}><FaBars /></Button>
							<Button title={t('GENERAL.GRID')} size='sm' variant={currentBedView == 'grid' ? 'primary' : 'light'} onClick={() => dispatch(changeBedView('grid'))}><BsGrid /></Button>
						</Stack>
					</Col>}
			</Row>
			<Row className="justify-content-md-center">
				{hospitalBedInfoError &&
					<Alert variant='danger'>
						<ul className='mb-0'>
							{hospitalBedInfoError && hospitalBedInfoError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				<DashboardBed hospitalBedInfo={hospitalBedInfo} bedType={currentBedType} />
			</Row>
			<FirstTimePassword />
		</Container >
	);
};

export default Dashboard;

import React, { useEffect, useState, useRef } from 'react';
import { t } from 'i18next';
import { Alert, Button, Card, Col, Container, Form, Image, InputGroup, Row, Stack, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { RiSendPlaneFill } from 'react-icons/ri';
import BackButton from '../../components/BackButton';
import RequiredAstric from '../../components/DynamicForm/required-astric';
import Loader from '../../components/SplashScreen/loader';
import PasswordInput from '../../components/Common/password-input';
import { addUser, inviteConsultant } from '../../redux/users';
import { reset, resetJoinConsultant } from '../../redux/users/slice';
import { resetInviteConsultant, resetSuggestion } from '../../redux/auth/slice';
import { registerConsultant, usernameSuggestion } from '../../redux/auth';
import { addUserValidationSchema, inviteConsultantValidationSchema, registerConsultantValidationSchema } from '../../utils/validation';
import { slugify } from '../../utils/helper';
import useDebounce from '../../utils/useDebounceHook';
import { UserPlaceholder } from '../../images';

const AddUser = () => {
	const [file, setFile] = useState(UserPlaceholder);
	const [img, setImage] = useState();
	const [filterText, setFilterText] = useState('');
	const [filterTextInvite, setFilterTextInvite] = useState('');
	const consultantEmail = useRef();
	const { currentHospital, usernameSuggestionResponse, /* usernameSuggestionResponseError, */ } = useSelector(state => state.auth);
	const { isUserAddedSuccess, addUserErrorMessage, inviteConsultantErrorMessage, inviteConsultantResponse, inviteConsultantStatus, Loading } = useSelector(state => state.users);
	const { registerConsultantResponse, registerConsultantResponseError, loading } = useSelector(state => state.auth);
	const { roles } = useSelector(state => state.hospital);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const debouncedSearchTerm = useDebounce(filterText, 500);
	const debouncedSearchTermInvite = useDebounce(filterTextInvite, 500);
	const initialValues = {
		'first_name': '',
		'last_name': '',
		'username': '',
		'email': '',
		'mobile_number': '',
		'user_role': '',
		'status': '',
		'profile_picture': '',
		'password': '',
		'password_confirmation': ''
	};
	const inviteConsultantValues = {
		'email': '',
	};
	const registerConsultantValues = {
		'first_name': '',
		'last_name': '',
		'username': '',
		'mobile_number': '',
		'password': '',
		'password_confirmation': ''
	};

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (isUserAddedSuccess) {
			navigate('/users');
		}
	}, [isUserAddedSuccess]);

	useEffect(() => {
		if (!isEmpty(debouncedSearchTerm)) {
			const body = { username: debouncedSearchTerm, hospital_id: currentHospital.value };
			dispatch(usernameSuggestion({ body }));
		}
		else {
			dispatch(resetSuggestion());
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!isEmpty(debouncedSearchTermInvite)) {
			const body = { username: debouncedSearchTermInvite };
			dispatch(usernameSuggestion({ body }));
		}
		else {
			dispatch(resetSuggestion());
		}
	}, [debouncedSearchTermInvite]);

	const backToUserList = () => {
		navigate('/users');
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
		setFile(URL.createObjectURL(e.target.files[0]));
	};

	const handleUserAddSubmit = (values) => {
		dispatch(addUser({ ...values, username: values.username + '.' + currentHospital?.postfix, profile_picture: img, hospital_id: currentHospital.value }));
	};

	/* const fetchOptions = (searchValue, callback) => {
		let filterOptions = [];
		return callGetAPI({
			route: 'hospital/getuser/details',
			params: { hospital_id: currentHospital.value, email: searchValue }
		}).then((data) => {
			filterOptions = data?.data?.data.map((option) => {
				return { value: option.id, label: option.first_name + ' ' + option.last_name };
			});
			return filterOptions;
		});
		callback(filterOptions);
	}; */

	const userNameSuggestion = (value, values, setFieldValue) => {
		setFieldValue('username', slugify(value));
		setFilterText(slugify(value));
	};

	const userNameSuggestionInvite = (value, values, setFieldValue) => {
		setFieldValue('username', slugify(value));
		setFilterTextInvite(slugify(value));
	};

	const handleInvite = (values) => {
		dispatch(resetInviteConsultant());
		dispatch(inviteConsultant({ email: values.email, hospital_id: currentHospital.value }));
	};
	const handleRegisterConsultant = (values) => {
		dispatch(resetJoinConsultant());
		dispatch(registerConsultant({ hospital_id: currentHospital.value, email: consultantEmail.current.value, ...values }));
	};

	return (
		<Container className='mt-4'>
			<Row className='my-3'>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>{t('USERADD.TITLE')}</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<div className='border border-2 border-top-0 shadow'>
				<Tabs defaultActiveKey="add-user" fill>
					<Tab eventKey="add-user" title={t('USERADD.TITLE')}>
						<Container>
							<Row className='my-4 mx-1'>
								<Col>
									<Card text='dark' bg='white'>
										<Card.Header className='text-uppercase'>
											{t('USERADD.FORMTITLE')}
										</Card.Header>
										<Card.Body>
											{addUserErrorMessage &&
												<Alert variant="danger">
													<ul className='mb-0'>
														{addUserErrorMessage.split('|').map((error, index) => (
															<li key={index}>{error}</li>
														))}
													</ul>
												</Alert>}

											<Formik initialValues={initialValues} validationSchema={addUserValidationSchema(t)}
												onSubmit={handleUserAddSubmit}
											>
												{({ errors, touched, values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
													<Form noValidate onSubmit={handleSubmit} autoComplete="off">
														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridFirstName">
																<Form.Label>{t('USERADD.FIELDS.FIRSTNAME')}<RequiredAstric /></Form.Label>
																<Form.Control
																	type="text"
																	placeholder={t('USERADD.PLACEHOLDER.FIRSTNAME')}
																	name="first_name"
																	value={values.first_name}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.first_name && touched.first_name} />
																<Form.Control.Feedback type="invalid">
																	{errors.first_name}
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group as={Col} controlId="formGridLastName">
																<Form.Label>{t('USERADD.FIELDS.LASTNAME')}<RequiredAstric /></Form.Label>
																<Form.Control
																	type="text"
																	placeholder={t('USERADD.PLACEHOLDER.LASTNAME')}
																	name="last_name"
																	value={values.last_name}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.last_name && touched.last_name} />
																<Form.Control.Feedback type="invalid">
																	{errors.last_name}
																</Form.Control.Feedback>
															</Form.Group>
														</Row>

														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridEmail">
																<Form.Label>{t('USERADD.FIELDS.EMAIL')}<RequiredAstric /></Form.Label>
																<Form.Control
																	type="email"
																	placeholder={t('USERADD.PLACEHOLDER.EMAIL')}
																	name="email"
																	value={values.email}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.email && touched.email} />
																<Form.Control.Feedback type="invalid">
																	{errors.email}
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group as={Col} controlId="formGridMobile">
																<Form.Label>{t('USERADD.FIELDS.MOBILE')}<RequiredAstric /></Form.Label>
																<Form.Control
																	type="mobile_number"
																	placeholder={t('USERADD.PLACEHOLDER.MOBILE')}
																	name="mobile_number"
																	value={values.mobile_number}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.mobile_number && touched.mobile_number} />
																<Form.Control.Feedback type="invalid">
																	{errors.mobile_number}
																</Form.Control.Feedback>
															</Form.Group>
														</Row>

														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridPassword">
																<Form.Label>{t('USERADD.FIELDS.PASSWORD')}<RequiredAstric /></Form.Label>
																<PasswordInput
																	autoComplete="new-password"
																	placeholder={t('USERADD.PLACEHOLDER.PASSWORD')}
																	name="password"
																	value={values.password}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.password && touched.password} />
																<Form.Control.Feedback type="invalid">
																	{errors.password}
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group as={Col} controlId="formGridPasswordConfirm">
																<Form.Label>{t('USERADD.FIELDS.PASSWORDCONFIRM')}<RequiredAstric /></Form.Label>
																<PasswordInput
																	placeholder={t('USERADD.PLACEHOLDER.PASSWORDCONFIRM')}
																	name="password_confirmation"
																	value={values.password_confirmation}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.password_confirmation && touched.password_confirmation} />
																<Form.Control.Feedback type="invalid">
																	{errors.password_confirmation}
																</Form.Control.Feedback>
															</Form.Group>
														</Row>

														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridUsername">
																<Form.Label>{t('USERADD.FIELDS.USERNAME')}<RequiredAstric /></Form.Label>
																<InputGroup className="mb-2">
																	<Form.Control
																		autoComplete='off'
																		type="text"
																		placeholder={t('USERADD.PLACEHOLDER.USERNAME')}
																		name="username"
																		value={values.username}
																		onChange={(e) => {
																			userNameSuggestion(e.target.value, values, setFieldValue);
																		}}
																		onBlur={handleBlur}
																		isInvalid={(errors.username && touched.username) || (!isEmpty(values.username) && !(usernameSuggestionResponse?.current?.available) === true)}
																		isValid={usernameSuggestionResponse?.current?.available && !isEmpty(values.username)} />
																	<InputGroup.Text>{'.' + currentHospital?.postfix}</InputGroup.Text>
																	<Form.Control.Feedback type="invalid">
																		{errors.username || (!(usernameSuggestionResponse?.current?.available) && t('USERADD.ERRORS.ALREADYUSERNAME'))}
																	</Form.Control.Feedback>
																	<Form.Control.Feedback>
																		{t('USERADD.AVAILABLEUSERNAME')}
																	</Form.Control.Feedback>
																</InputGroup>
																{!isEmpty(usernameSuggestionResponse?.other_usernames) &&
																	<span className="">
																		{t('GENERAL.AVAILABLE') + ': '}
																		{usernameSuggestionResponse?.other_usernames?.map((name, index) => {
																			return <Button title={name} size='sm' variant='link' key={index} onClick={() => userNameSuggestion(name, values, setFieldValue)}> {name} </Button>;
																		})}
																	</span>}
															</Form.Group>

															<Form.Group as={Col} controlId="formGridUserRole">
																<Form.Label>{t('USERADD.FIELDS.USERROLE')}<RequiredAstric /></Form.Label>
																<Form.Select
																	placeholder={t('USERADD.PLACEHOLDER.USERROLE')}
																	aria-label={t('USERADD.PLACEHOLDER.USERROLE')}
																	name="user_role"
																	///value={values.user_role}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.user_role && touched.user_role}>
																	<option value={''}>
																		{t('USERADD.PLACEHOLDER.USERROLE')}
																	</option>
																	{!isEmpty(roles) && Object.entries(roles).map(([key, val], i) => {
																		if (key == 'consultant') {
																			return false;
																		}
																		return <option key={i} value={key}>{val}</option>;

																	})}
																</Form.Select>
																<Form.Control.Feedback type="invalid">
																	{errors.user_role}
																</Form.Control.Feedback>
															</Form.Group>
														</Row>
														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridProfilePicture">
																<Form.Label>{t('USERADD.FIELDS.PROFILEPIC')}</Form.Label>
																<Form.Control
																	type="file"
																	accept="image/png, image/jpeg"
																	placeholder={t('USERADD.PLACEHOLDER.PROFILEPIC')}
																	name="profile_picture"
																	value={values.profile_picture}
																	onChange={handleImageChange}
																	onBlur={handleBlur}
																	isInvalid={errors.profile_picture && touched.profile_picture} />
																<Form.Control.Feedback type="invalid">
																	{errors.profile_picture}
																</Form.Control.Feedback>
															</Form.Group>

															<Form.Group as={Col} controlId="formGridUserStatus">
																<Form.Label>{t('USERADD.FIELDS.USERSTATUS')}<RequiredAstric /></Form.Label>
																<Form.Select
																	placeholder={t('USERADD.PLACEHOLDER.USERSTATUS')}
																	aria-label={t('USERADD.PLACEHOLDER.USERSTATUS')}
																	name="status"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.status && touched.status}>
																	<option value={''}>{t('USERADD.PLACEHOLDER.USERSTATUS')}</option>
																	{Object.entries({ 'active': 'Active', 'inactive': 'Inactive' }).map(([key, val], i) => {
																		return <option key={i} value={key}>{val}</option>;

																	})}
																</Form.Select>
																<Form.Control.Feedback type="invalid">
																	{errors.status}
																</Form.Control.Feedback>
															</Form.Group>
														</Row>
														<Row className="mb-3">
															<Form.Group as={Col} controlId="formGridProfilePicturePreview">
																<Form.Label>{t('USERADD.FIELDS.PROFILEPICPREVIEW')}</Form.Label>
																<div>
																	{file && <Image width={100} height={100} thumbnail src={file} />}
																</div>
															</Form.Group>
														</Row>
														<Stack direction='horizontal' gap={2}>
															<Button title={t('GENERAL.SUBMIT')} disabled={Loading} variant="success" type="submit">
																{Loading && <Loader variant='white' size='sm' />}
																{t('GENERAL.SUBMIT')}</Button>
															<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={backToUserList}>{t('GENERAL.CANCEL')}</Button>
														</Stack>
													</Form>
												)}
											</Formik>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</Tab>
					<Tab eventKey="invite-consultant" title={t('USERADD.INVITECONSULTANT')}>
						<Container>
							<Row className='my-4 mx-1'>
								<Col>
									<Card text='dark' bg='white'>
										<Card.Header className='text-uppercase'>
											{t('USERADD.INVITECONSULTANT')}
										</Card.Header>
										<Card.Body>
											{(inviteConsultantStatus === 201 || (inviteConsultantStatus !== 201 && !isEmpty(inviteConsultantResponse))) && inviteConsultantResponse?.message &&
												<Alert variant="success">
													<ul className='mb-0'>
														{inviteConsultantResponse?.message?.split('|').map((error, index) => (
															<li key={index}>{error}</li>
														))}
													</ul>
												</Alert>}
											{inviteConsultantErrorMessage &&
												<Alert variant="danger">
													<ul className='mb-0'>
														{inviteConsultantErrorMessage.split('|').map((error, index) => (
															<li key={index}>{error}</li>
														))}
													</ul>
												</Alert>}
											<Formik initialValues={inviteConsultantValues} validationSchema={inviteConsultantValidationSchema(t)} onSubmit={handleInvite} >
												{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => {
													return <Form noValidate onSubmit={handleSubmit}>
														<Form.Group as={Row} controlId="formGridInviteEmail">
															<Form.Label column lg="2">{t('USERADD.FIELDS.CONSULTANTEMAIL')}
																<RequiredAstric />
															</Form.Label>
															<Col lg="7">
																<Form.Control
																	ref={consultantEmail}
																	required
																	type="email"
																	placeholder={t('USERADD.PLACEHOLDER.EMAIL')}
																	name='email'
																	value={values.email}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	isInvalid={errors.email && touched.email} />
																<span className='text-danger'>
																	{errors.email}
																</span>
															</Col>
															<Col lg="3">
																<Button title={t('USERADD.BUTTONS.INVITE')} disabled={Loading} variant="success" type="submit">
																	{Loading ? <Loader variant='white' size='sm' /> : <RiSendPlaneFill />} {t('USERADD.BUTTONS.INVITE')}
																</Button>
															</Col>
														</Form.Group>
													</Form>;
												}}
											</Formik>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							{inviteConsultantStatus === 201 &&
								<Row className='my-4 mx-1'>
									<Col>
										<Card text='dark' bg='white'>
											<Card.Header className='text-uppercase'>
												{t('REGISTERCONSULTANT.TITLE')}
											</Card.Header>
											<Card.Body>
												{registerConsultantResponseError &&
													<Alert variant='danger' className='mt-2'>
														{registerConsultantResponseError && registerConsultantResponseError.split('|').map((error, index) => (
															<li key={index}>{error}</li>
														))}
													</Alert>}
												{!isEmpty(registerConsultantResponse) ?
													<Alert variant='success' className='mt-2'>
														{t('REGISTERCONSULTANT.SUCCESS2')}
													</Alert>
													:
													<Formik initialValues={registerConsultantValues} validationSchema={registerConsultantValidationSchema(t)} onSubmit={handleRegisterConsultant}>
														{({ errors, touched, values, setFieldValue, handleChange, handleBlur, handleSubmit }) => (
															<Form noValidate onSubmit={handleSubmit}>
																<Row className="mb-3">
																	<Form.Group className="mb-3" as={Col} controlId="formGridFirstName">
																		<Form.Label>{t('USERADD.FIELDS.FIRSTNAME')}<RequiredAstric /></Form.Label>
																		<Form.Control
																			type="text"
																			placeholder={t('USERADD.PLACEHOLDER.FIRSTNAME')}
																			name="first_name"
																			value={values.first_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			isInvalid={errors.first_name && touched.first_name} />
																		<Form.Control.Feedback type="invalid">
																			{errors.first_name}
																		</Form.Control.Feedback>
																	</Form.Group>

																	<Form.Group className="mb-3" as={Col} controlId="formGridLastName">
																		<Form.Label>{t('USERADD.FIELDS.LASTNAME')}<RequiredAstric /></Form.Label>
																		<Form.Control
																			type="text"
																			placeholder={t('USERADD.PLACEHOLDER.LASTNAME')}
																			name="last_name"
																			value={values.last_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			isInvalid={errors.last_name && touched.last_name} />
																		<Form.Control.Feedback type="invalid">
																			{errors.last_name}
																		</Form.Control.Feedback>
																	</Form.Group>
																</Row>

																<Row className="mb-3">
																	<Form.Group as={Col} controlId="formGridUsername">
																		<Form.Label>{t('USERADD.FIELDS.USERNAME')}<RequiredAstric /></Form.Label>
																		<Form.Control
																			autoComplete="off"
																			type="text"
																			placeholder={t('USERADD.PLACEHOLDER.USERNAME')}
																			name="username"
																			value={values.username}
																			onChange={(e) => {
																				userNameSuggestionInvite(e.target.value, values, setFieldValue);
																			}}
																			onBlur={handleBlur}
																			isInvalid={(errors.username && touched.username) || (!isEmpty(values.username) && !(usernameSuggestionResponse?.current?.available) === true)}
																			isValid={usernameSuggestionResponse?.current?.available && !isEmpty(values.username)} />
																		<Form.Control.Feedback type="invalid">
																			{errors.username || (!(usernameSuggestionResponse?.current?.available) && t('USERADD.ERRORS.ALREADYUSERNAME'))}
																		</Form.Control.Feedback>
																		<Form.Control.Feedback>
																			{t('USERADD.AVAILABLEUSERNAME')}
																		</Form.Control.Feedback>
																		{!isEmpty(usernameSuggestionResponse?.other_usernames) &&
																			<span className="">
																				{t('GENERAL.AVAILABLE') + ': '}
																				{usernameSuggestionResponse?.other_usernames?.map((name, index) => {
																					return <Button title={name} size='sm' variant='link' key={index} onClick={() => userNameSuggestion(name, values, setFieldValue)}> {name} </Button>;
																				})}
																			</span>}
																	</Form.Group>

																	<Form.Group as={Col} controlId="formGridMobile">
																		<Form.Label>{t('USERADD.FIELDS.MOBILE')}<RequiredAstric /></Form.Label>
																		<Form.Control
																			type="mobile_number"
																			placeholder={t('USERADD.PLACEHOLDER.MOBILE')}
																			name="mobile_number"
																			value={values.mobile_number}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			isInvalid={errors.mobile_number && touched.mobile_number} />
																		<Form.Control.Feedback type="invalid">
																			{errors.mobile_number}
																		</Form.Control.Feedback>
																	</Form.Group>
																</Row>
																<Row className="mb-3">
																	<Form.Group className="mb-3" as={Col} controlId="formGridPassword">
																		<Form.Label>{t('REGISTERCONSULTANT.FIELDS.PASSWORD')}<RequiredAstric /></Form.Label>
																		<PasswordInput
																			autoComplete="new-password"
																			placeholder={t('REGISTERCONSULTANT.PLACEHOLDER.PASSWORD')}
																			name="password"
																			value={values.password}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			isInvalid={errors.password && touched.password} />
																		<Form.Control.Feedback type="invalid">
																			{errors.password}
																		</Form.Control.Feedback>
																	</Form.Group>

																	<Form.Group className="mb-3" as={Col} controlId="formGridPasswordConfirm">
																		<Form.Label>{t('REGISTERCONSULTANT.FIELDS.PASSWORDCONFIRM')}<RequiredAstric /></Form.Label>
																		<PasswordInput
																			placeholder={t('REGISTERCONSULTANT.PLACEHOLDER.PASSWORDCONFIRM')}
																			name="password_confirmation"
																			value={values.password_confirmation}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			isInvalid={errors.password_confirmation && touched.password_confirmation} />
																		<Form.Control.Feedback type="invalid">
																			{errors.password_confirmation}
																		</Form.Control.Feedback>
																	</Form.Group>
																</Row>
																<Button title={t('REGISTERCONSULTANT.RESETPASSWORD_BUTTON')} disabled={loading} variant="primary" type="submit" className='mt-3'>
																	{loading && <Loader size='sm' variant='white' />}  {t('REGISTERCONSULTANT.REGISTER_BUTTON')}
																</Button>
															</Form>
														)}
													</Formik>
												}
											</Card.Body>
										</Card>
									</Col>
								</Row>}
						</Container>
					</Tab>
				</Tabs>
			</div>
		</Container >
	);
};

export default AddUser;

import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Alert, Button, Card, Form, Nav, Row, Stack, Table } from 'react-bootstrap';
import Loader from '../../components/SplashScreen/loader';
import HelpVideoButton from '../../components/HelpVideoButton';
import { getHospitalBedsLabel, updateHospitalBedsLabel } from '../../redux/hospital';
import { resetBedConfigComplete } from '../../redux/hospital/slice';
import { callGetAPI } from '../../services/axios';

const BedConfig = () => {
	const [bedTypes, setBedTypes] = useState([]);
	const { currentHospital } = useSelector(state => state.auth);
	const { Loading, bedList, bedListError, bedListUpdate, bedListUpdateError, hospitalBedInfo } = useSelector(state => state.hospital);
	const [key, setKey] = useState('icu');
	const validationMessageRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		return () => {
			dispatch(resetBedConfigComplete());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(bedListUpdateError) || !isEmpty(bedListError) || !isEmpty(bedListUpdate?.message)) {
			validationMessageRef.current?.scrollIntoView();
		}
	}, [bedListUpdateError, bedListUpdate, bedListError]);

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			const callBedAPIS = async() => {
				await callGetAPI({
					route: 'hospital/get/bedtypes',
					params: {
						hospital_id: currentHospital.value,
					}
				}).then((data) => {
					setBedTypes(data.data.data);
				});
				dispatch(getHospitalBedsLabel({ hospital_id: currentHospital.value, bed_type: key }));
			};
			callBedAPIS();
		}
	}, [currentHospital, key]);

	const backToDashboard = () => {
		navigate('/');
	};

	const handleChangeBackupSubmit = (values) => {
		const payload = {
			'hospital_id': currentHospital.value,
			bed_type: key,
			...values
		};
		dispatch(updateHospitalBedsLabel(payload));
	};
	return (
		<Card>
			<Card.Header className='bg-white fw-bold'>
				{t('BEDCONFIG.TITLE')}
				<HelpVideoButton videoKey={'manage_bed_labels_types'} />
			</Card.Header>
			<Card.Body>
				<Nav variant="tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
					{hospitalBedInfo?.total_icu_beds > 0 && <Nav.Item>
						<Nav.Link eventKey="icu">{t('GENERAL.ICU')}</Nav.Link>
					</Nav.Item>}
					{hospitalBedInfo?.total_ward_beds > 0 && <Nav.Item>
						<Nav.Link eventKey="ward">{t('GENERAL.GENERALWARD')}</Nav.Link>
					</Nav.Item>}
				</Nav>
				{bedListUpdateError &&
					<Alert variant="danger" ref={validationMessageRef}>
						<ul className='mb-0'>
							{bedListUpdateError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{bedListError &&
					<Alert variant="danger" ref={validationMessageRef}>
						<ul className='mb-0'>
							{bedListError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{bedListUpdate?.message &&
					<Alert variant="success" ref={validationMessageRef}>
						{bedListUpdate?.message}
					</Alert>}
				{Loading &&
					<Row className='d-flex justify-content-center mt-4'>
						<Loader />
					</Row>}
				{!isEmpty(bedList) && <Formik initialValues={{ bed_details: bedList }} onSubmit={handleChangeBackupSubmit}
				>
					{({ handleChange, handleBlur, handleSubmit }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Table striped bordered responsive hover>
								<thead>
									<tr>
										<th className='text-center'>{t('BEDCONFIG.FIELDS.BEDNO')}</th>
										<th>{t('BEDCONFIG.FIELDS.BEDNAME')}</th>
										<th>{t('BEDCONFIG.FIELDS.BEDTYPE')}</th>
									</tr>
								</thead>
								<tbody>
									{bedList.map((bed, bedIndex) => (
										<tr key={bedIndex}>
											<td className='text-center'>
												<span className="fw-bold mt-2">
													{bed.bed_no}
												</span>
											</td>
											<td>
												<Form.Group controlId='formGridBedName'>
													<Form.Control
														type='text'
														placeholder={t('BEDCONFIG.PLACEHOLDER.BEDNAME')}
														name={`bed_details[${bedIndex}]['bed_name']`}
														defaultValue={bed.bed_name}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
												</Form.Group>
											</td>
											<td>
												<Form.Group controlId='formGridBedType'>
													<Form.Select
														defaultValue={bed.bed_type_id}
														placeholder={t('BEDCONFIG.PLACEHOLDER.BEDNAME')}
														name={`bed_details[${bedIndex}]['bed_type_id']`}
														onChange={handleChange}
														onBlur={handleBlur}>
														<option value='0'>{t('BEDCONFIG.PLACEHOLDER.BEDTYPE')}</option>
														{bedTypes?.map((type, typeIndex) => {
															return (
																<option key={typeIndex} value={type?.id}>{type?.bed_type}</option>
															);
														})}
													</Form.Select>
												</Form.Group>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							<Stack direction='horizontal' gap={2}>
								<Button title={t('GENERAL.UPDATE')} disabled={Loading} variant="primary" type="submit">{t('GENERAL.UPDATE')}</Button>
								<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={backToDashboard}>{t('GENERAL.CANCEL')}</Button>
							</Stack>
						</Form>
					)}
				</Formik>}
			</Card.Body>
		</Card>
	);
};

export default BedConfig;

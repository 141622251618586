import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Card, Col, Modal, Row, Stack/* , Image */ } from 'react-bootstrap';
import { FaBed, FaCheck, FaCircle } from 'react-icons/fa';
import { TbArrowsRightLeft } from 'react-icons/tb';
import { findIndex, isEmpty, first } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import HelpVideoButton from '../HelpVideoButton';
import HandOverModal from '../HandOverModal';
import CommonModal from '../CommonModal';
import Loader from '../../components/SplashScreen/loader';
import { changePatient, changePatientIpno } from '../../redux/hospital/slice';
import { callGetAPI } from '../../services/axios';
import { getDefaultFormID, getFormattedDate } from '../../utils/helper';
import './style.scss';
import { getHospitalBedInfo, getHospitalBedLayout, shiftPatientBed } from '../../redux/hospital';

const DashboardBed = ({ hospitalBedInfo, bedType }) => {
	const [show, setShow] = useState(false);
	const [showMessage, setShowMessage] = useState('');
	const [patient, setPatientData] = useState({});
	const [handoverform, setHandOverForm] = useState({});
	const [viewMode, setViewMode] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const { Loading, shiftPatientBedResponse, shiftPatientBedError, hospitalBedLayout, hospitalBedLayoutError, currentBedView, currentBedTypeFilter } = useSelector(state => state.hospital);
	const [activeBed, setActiveBed] = useState();
	const handleCloseModal = () => {
		setShowModal(false);
		setActiveBed('');
	};
	const handleShowModal = () => setShowModal(true);
	const { currentHospital } = useSelector(state => state.auth);
	const handleClose = () => {
		setShow(false);
		setHandOverForm({});
		setViewMode(false);
	};
	const handleMessageClose = () => setShowMessage('');
	const handleShow = (patientId, type, bedType) => {
		callGetAPI({
			route: 'hospital/patient/info',
			params: {
				hospital_id: currentHospital.value,
				p_id: patientId
			}
		}).then((data) => {
			setPatientData(data.data.data);
			setShow(true);
		});
		if (type == 'add') {
			callGetAPI({
				route: 'hospital/form/create',
				params: {
					hospital_id: currentHospital.value,
					default_form_id: currentHospital.role == 'nurse' ? getDefaultFormID('Handover Form Nurse') : getDefaultFormID('Handover Form MO'),
					bed_type: bedType,
					p_id: patientId
				}
			}).then((data) => {
				setHandOverForm(data.data.data);
			}).catch((err) => {
				setShowMessage(err.response.data.message);
			});
		}
		else if (type == 'signin') {
			callGetAPI({
				route: 'hospital/patient/handover/edit',
				params: {
					p_id: patientId,
					hospital_id: currentHospital.value,
					type: currentHospital.role == 'doctor' ? 'mo' : 'nurse'
				}
			}).then((data) => {
				setHandOverForm(data.data.data);
				setViewMode(true);
			});
		}
		else {
			callGetAPI({
				route: 'hospital/patient/handover/edit',
				params: {
					p_id: patientId,
					hospital_id: currentHospital.value,
					type: currentHospital.role == 'doctor' ? 'mo' : 'nurse'
				}
			}).then((data) => {
				setHandOverForm(data.data.data);
			});
		}

	};
	const bedinfo = currentHospital.role == 'consultant' ? hospitalBedInfo.total_beds && [...hospitalBedInfo.booked_bed_numbers].sort((a, b) => {
		return a - b;
	}) : hospitalBedInfo.total_beds && [...hospitalBedInfo.available_bed_numbers, ...hospitalBedInfo.booked_bed_numbers].sort((a, b) => {
		return a - b;
	});
	const bedsdata = bedinfo?.filter((bedNo) => {
		const bedType = hospitalBedInfo?.bed_details[bedNo]?.bed_type;
		return (currentBedTypeFilter == 'all' || bedType === currentBedTypeFilter);
	});
	const handleChangePatient = (patient) => {
		if (patient.handover.type == 'signin_pending') {
			setShowMessage(patient.handover.message);
		} else {
			dispatch(changePatient(patient));
			dispatch(changePatientIpno(first(patient?.ip_no_history)));
			navigate('/patient-monitoring');
		}
	};
	const handleShiftToClick = (patientInfo) => {
		setPatientData(patientInfo);
		dispatch(getHospitalBedLayout({ hospital_id: currentHospital.value }));
		handleShowModal(true);
	};
	const handleTransferSubmit = () => {
		dispatch(shiftPatientBed({ hospital_id: currentHospital.value, p_id: patient.p_id, reg_no: patient.reg_no, ip_no: patient.ip_no, bed_no: activeBed, bed_type: (bedType == 'icu') ? 'ward' : 'icu' }));
		setActiveBed('');
	};
	const columns = [
		{
			name: t('DASHBOARD.COLUMNS.BEDS'),
			center: true,
			compact: true,
			cell: row => {
				return <Stack title={hospitalBedInfo?.bed_details[row]?.bed_name || row} direction='horizontal' gap={2}>
					<span className={`fs-5 ${hospitalBedInfo.booked_bed_numbers.indexOf(row) > -1 ? 'text-occupied' : 'text-vacant'}`}>
						<FaBed />
					</span>
					<div className='fs-6 fw-semibold'>
						{row}
					</div>
				</Stack>;
			},
		},
		{
			name: t('DASHBOARD.COLUMNS.PATIENTNAME'),
			compact: true,
			grow: 2,
			wrap: true,
			selector: row => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return (<span className='btn btn-link ps-0' onClick={() => {
						if (currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') && hospitalBedInfo.booked_patient[bedindex]?.handover?.type == 'not_accessible') {
							setShowMessage(`${hospitalBedInfo.booked_patient[bedindex].handover.message}`);
							return false;
						}
						handleChangePatient(hospitalBedInfo.booked_patient[bedindex]);
					}}>
						{hospitalBedInfo?.booked_patient[bedindex]['patient_name']}
					</span>);
				}
				else {
					return '';
				}
			},
		},
		{
			name: t('DASHBOARD.COLUMNS.REGNO'),
			compact: true,
			center: true,
			selector: row => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return hospitalBedInfo?.booked_patient[bedindex]['reg_no'];
				}
				else {
					return '';
				}
			},
		},
		{
			name: t('DASHBOARD.COLUMNS.DOA'),
			center: true,
			compact: true,
			selector: row => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return getFormattedDate(hospitalBedInfo.booked_patient[bedindex]['date_of_admission']);
				}
				else {
					return '';
				}
			},
		},
		{
			name: t('DASHBOARD.COLUMNS.ATTDR'),
			wrap: true,
			compact: true,
			grow: 2,
			selector: row => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return hospitalBedInfo.booked_patient[bedindex]['attending_consultant'];
				}
				else {
					return '';
				}
			},
		},
		{
			name: t('DASHBOARD.COLUMNS.BEDTYPE'),
			compact: true,
			wrap: true,
			selector: row => {
				return hospitalBedInfo?.bed_details[row]?.bed_type;
			},
		},
		/* {
			name: t('DASHBOARD.COLUMNS.STATUS'),
			center: true,
			compact:true,
			wrap:true,
			selector: row => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return <span className='btn btn-outline-danger btn-occupied btn-sm fs-6 rounded-pill' >{t('GENERAL.OCCUPIED')}</span>;
				}
				else {
					return <span className='btn btn-outline-success btn-vacant btn-sm fs-6 rounded-pill' >{t('GENERAL.VACANT')}</span>;
				}
			},
		}, */
		{
			name: t('GENERAL.ACTION'),
			center: true,
			grow: 2,
			cell: (row, rowIndex) => {
				const bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', row]);
				if (bedindex >= 0) {
					return (<>
						<Row>
							<Col className='mt-2 text-center position-relative'>
								{currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') &&
									<>
										{hospitalBedInfo.booked_patient[bedindex].handover.type == 'signed_out'
											&& <Button title={t('DASHBOARD.BUTTON.HANDOVERSIGNOUT')} className='my-1' size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'edit', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVERSIGNOUT')}</Button>}
										{hospitalBedInfo.booked_patient[bedindex].handover.type == 'hand_over'
											&& <Button title={t('DASHBOARD.BUTTON.HANDOVER')} className='my-1' size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'add', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER')} {hospitalBedInfo.booked_patient[bedindex].handover.signed_in ? <span className='btn btn-success btn-sm'>{t('DASHBOARD.BUTTON.HANDOVER_SIGNED_IN')}</span> : ''}</Button>}
										{hospitalBedInfo.booked_patient[bedindex].handover.type == 'signin_pending'
											&& <Button title={t('DASHBOARD.BUTTON.HANDOVER_SIGNIN_PENDING')} className='my-1' size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'signin', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER_SIGNIN_PENDING')}</Button>}
										{hospitalBedInfo.booked_patient[bedindex].handover.type == 'not_accessible'
											&& <Button title={t('DASHBOARD.BUTTON.HANDOVER')} className='my-1' size='sm' onClick={() => { setShowMessage(`${hospitalBedInfo.booked_patient[bedindex].handover.message}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER')}</Button>}
									</>
								}
								<div className='position-relative d-flex justify-content-center'>
									{bedType == 'icu' && hospitalBedInfo?.total_ward_beds > 0 && <Button title={t('DASHBOARD.BUTTON.SHIFTTO')} className='mx-2' variant='success' size='sm' onClick={() => handleShiftToClick(hospitalBedInfo.booked_patient[bedindex])}> <FaBed /> {t('DASHBOARD.BUTTON.SHIFTTO')}</Button>}
									{bedType == 'ward' && hospitalBedInfo?.total_icu_beds > 0 && <Button title={t('DASHBOARD.BUTTON.SHIFTTO')} className='mx-2' variant='success' size='sm' onClick={() => handleShiftToClick(hospitalBedInfo.booked_patient[bedindex])}> <FaBed /> {t('DASHBOARD.BUTTON.SHIFTTO')}</Button>}
									{(bedindex === 0 || rowIndex ===0) &&
										<div style={{ position : 'absolute', marginLeft : '110px' }}>
											<HelpVideoButton videoKey={'shift_patient'} />
										</div>
									}
								</div>
							</Col>
							{currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') && <Link title={t('DASHBOARD.BUTTON.VIEWHANDOVERHISTORY')} to={`/viewhandoverhistory/${hospitalBedInfo.booked_patient[bedindex]['p_id']}`} className='mt-1 mb-2 text-danger text-center'>{t('DASHBOARD.BUTTON.VIEWHANDOVERHISTORY')}</Link>}
						</Row>
					</>);
				}
				else {
					return null;
				}
			}
		},

	];
	useEffect(() => {
		if (!isEmpty(shiftPatientBedResponse) && !(shiftPatientBedResponse?.error)) {
			dispatch(getHospitalBedInfo({ hospital_id: currentHospital.value, bed_type: bedType }));
			setShowModal(false);
		}
	}, [shiftPatientBedResponse]);

	return (
		<>
			{currentBedView == 'grid' && hospitalBedInfo.total_beds &&
				bedinfo.map((bedno, key) => {
					let isfree = hospitalBedInfo.booked_bed_numbers.indexOf(bedno) > -1 ? true : false;
					let bedindex = findIndex(hospitalBedInfo.booked_patient, ['bed_no', bedno]);
					if (currentBedTypeFilter != 'all' && currentBedTypeFilter != hospitalBedInfo?.bed_details[bedno]?.bed_type) {
						return null;
					}
					return (
						<Col xs={12} sm={6} md={4} key={key} className={`my-1 dashboard_bed text-white bg-${isfree ? 'occupied' : 'vacant'}`}>
							{isfree ?
								<>
									<Row className={`p-2 patient-detail${(currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') && hospitalBedInfo.booked_patient[bedindex]?.handover?.type == 'not_accessible') ? '-disabled' : ''}`} onClick={() => {
										if (currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') && hospitalBedInfo.booked_patient[bedindex]?.handover?.type == 'not_accessible') {
											setShowMessage(`${hospitalBedInfo.booked_patient[bedindex].handover.message}`);
											return false;
										}
										handleChangePatient(hospitalBedInfo.booked_patient[bedindex]);
									}}>
										<Row className='pt-2 pb-1 px-2'>
											<Col md="auto">
												<h5 className='text-start'>
													<span className='px-2 badge rounded-pill' style={{ background: `${hospitalBedInfo?.bed_details[bedno]?.color || 'grey'} ` }}>{hospitalBedInfo?.bed_details[bedno]?.bed_type} ({hospitalBedInfo?.bed_details[bedno]?.bed_name || bedno})</span>
												</h5>
											</Col>
											<Col>
												<h5 className="text-end"><FaBed /></h5>
											</Col>
										</Row>
										<Row>
											<Col>
												<Row>
													<h6 className='text-center'>
														{hospitalBedInfo.booked_patient[bedindex]['patient_name']}
													</h6>
												</Row>
												<Row>
													<h6 className='text-center'>{`
														${t('GENERAL.REGNO')} : ${hospitalBedInfo.booked_patient[bedindex]['reg_no']}`}
													</h6>
												</Row>
												<Row>
													<h6 className='text-center'>{`
														${t('GENERAL.DOA')} : ${getFormattedDate(hospitalBedInfo.booked_patient[bedindex]['date_of_admission'])}`}</h6>
												</Row>
												<Row>
													<h6 className='text-center'>{`
														${t('GENERAL.ATTDR')} : ${hospitalBedInfo.booked_patient[bedindex]['attending_consultant']}`}</h6>
												</Row>
											</Col>
										</Row>
									</Row>
									<Row className={`p-2 mt-1 ${((bedType == 'icu' && hospitalBedInfo?.total_ward_beds > 0) || (bedType == 'ward' && hospitalBedInfo?.total_icu_beds > 0) || (currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse'))) ? 'bg-dark-red' : ''}`}>
										<div className='text-center'>
											{currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') &&
												<>
													{hospitalBedInfo.booked_patient[bedindex].handover.type == 'signed_out'
														&& <Button title={t('DASHBOARD.BUTTON.HANDOVERSIGNOUT')} size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'edit', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVERSIGNOUT')}</Button>}
													{hospitalBedInfo.booked_patient[bedindex].handover.type == 'hand_over'
														&& <Button title={t('DASHBOARD.BUTTON.HANDOVER')} size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'add', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER')} {hospitalBedInfo.booked_patient[bedindex].handover.signed_in ? <span className='btn btn-success btn-sm'>{t('DASHBOARD.BUTTON.HANDOVER_SIGNED_IN')}</span> : ''}</Button>}
													{hospitalBedInfo.booked_patient[bedindex].handover.type == 'signin_pending'
														&& <Button title={t('DASHBOARD.BUTTON.HANDOVER_SIGNIN_PENDING')} size='sm' onClick={() => { handleShow(`${hospitalBedInfo.booked_patient[bedindex]['p_id']}`, 'signin', `${hospitalBedInfo.booked_patient[bedindex]['bed_type']}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER_SIGNIN_PENDING')}</Button>}
													{hospitalBedInfo.booked_patient[bedindex].handover.type == 'not_accessible'
														&& <Button title={t('DASHBOARD.BUTTON.HANDOVER')} size='sm' onClick={() => { setShowMessage(`${hospitalBedInfo.booked_patient[bedindex].handover.message}`); }}> <TbArrowsRightLeft /> {t('DASHBOARD.BUTTON.HANDOVER')}</Button>}
												</>
											}
											{bedType == 'icu' && hospitalBedInfo?.total_ward_beds > 0 && <Button title={t('DASHBOARD.BUTTON.SHIFTTO')} className='mx-2' variant='success' size='sm' onClick={() => handleShiftToClick(hospitalBedInfo.booked_patient[bedindex])}> <FaBed /> {t('DASHBOARD.BUTTON.SHIFTTO')}</Button>}
											{bedType == 'ward' && hospitalBedInfo?.total_icu_beds > 0 && <Button title={t('DASHBOARD.BUTTON.SHIFTTO')} className='mx-2' variant='success' size='sm' onClick={() => handleShiftToClick(hospitalBedInfo.booked_patient[bedindex])}> <FaBed /> {t('DASHBOARD.BUTTON.SHIFTTO')}</Button>}
											{(bedindex === 0 || key === 0 ) && <HelpVideoButton videoKey={'shift_patient'} mode='dark' />}
										</div>
										{currentHospital.is_admin == 'no' && (currentHospital.role == 'doctor' || currentHospital.role == 'nurse') && <Link title={t('DASHBOARD.BUTTON.VIEWHANDOVERHISTORY')} to={`/viewhandoverhistory/${hospitalBedInfo.booked_patient[bedindex]['p_id']}`} className='text-center text-white'>{t('DASHBOARD.BUTTON.VIEWHANDOVERHISTORY')}</Link>}
									</Row>
								</>
								:
								<>
									<Row className='pt-2 pb-1 px-2'>
										<Col md="auto">
											<h5 className='text-start'>
												<span className='px-2 badge rounded-pill' style={{ background: hospitalBedInfo?.bed_details[bedno]?.color || 'grey' }}>
													{hospitalBedInfo?.bed_details[bedno]?.bed_type} ({hospitalBedInfo?.bed_details[bedno]?.bed_name || bedno})</span>
											</h5>
										</Col>
										<Col>
											<h5 className="text-end"><FaBed /></h5>
										</Col>
									</Row>
									<Row className='d-flex align-items-center' style={{ minHeight: 'calc(100% - 54px)' }}>
										<Col>
											<Row>
												<h3 className='text-center'>{t('GENERAL.VACANT')}</h3>
											</Row>
										</Col>
									</Row>
								</>
							}
						</Col>
					);
				})
			}
			{currentBedView == 'list' && hospitalBedInfo.total_beds &&
				<Card>
					<Card.Body>
						<Row>
							<Col>
								<DataTable
									columns={columns}
									data={bedsdata}
									progressPending={Loading}
									progressComponent={<Loader />}
									highlightOnHover
								/>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}
			{<Modal size='lg' show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('DASHBOARD.TRANSFERPATIENT')} {bedType == 'icu' ? t('GENERAL.GENERALWARD') : t('GENERAL.ICU')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Header className='text-uppercase d-flex'>
							{t('PATIENTS.BED_ALLOCATION')}
							<Stack direction='horizontal' gap={2} className='ms-2'>
								<FaCircle className='text-success'></FaCircle> {t('PATIENTS.BED_AVAILABLE')}
								<FaCircle className='text-danger'></FaCircle> {t('PATIENTS.BED_NOT_AVAILABLE')}
							</Stack>
						</Card.Header>
						<Card.Body>
							<Row className="justify-content-md-center">
								{shiftPatientBedError &&
									<Alert variant='danger'>
										<ul className='mb-0'>
											{shiftPatientBedError && shiftPatientBedError.split('|').map((error, index) => (
												<li key={index}>{error}</li>
											))}
										</ul>
									</Alert>}
								{hospitalBedLayoutError &&
									<Alert variant='danger'>
										<ul className='mb-0'>
											{hospitalBedLayoutError && hospitalBedLayoutError.split('|').map((error, index) => (
												<li key={index}>{error}</li>
											))}
										</ul>
									</Alert>}
							</Row>
							<Row className="mb-3">
								<Col className='mx-auto text-center' md={8}>
									{bedType == 'icu' && !isEmpty(hospitalBedLayout) && !isEmpty(hospitalBedLayout.ward) && hospitalBedLayout.ward.map((val, key) => {
										return val.booked ?
											<Button title={val.bed_name}
												onClick={() => { setActiveBed(val.bed_no); }}
												key={key}
												variant="danger"
												size="lg"
												className={'m-1'}
												disabled>
												<FaBed /><br></br>{val.bed_no}<br></br>{val.bed_name}<br></br>
											</Button> :
											<Button title={val.bed_name}
												onClick={() => { setActiveBed(val.bed_no); }}
												key={key}
												variant={activeBed === val.bed_no ? 'warning' : 'success'} size="lg"
												className={`m-1 ${activeBed === val.bed_no ? 'active ' : ''}`}>
												<FaBed /><br></br>{val.bed_no}<br></br>{val.bed_name}<br></br>
												{activeBed === val.bed_no ? <FaCheck /> : ''}
											</Button>;
									})}
									{bedType == 'ward' && !isEmpty(hospitalBedLayout) && !isEmpty(hospitalBedLayout.icu) && hospitalBedLayout.icu.map((val, key) => {
										return val.booked ?
											<Button title={val.bed_name}
												onClick={() => { setActiveBed(val.bed_no); }}
												key={key}
												variant="danger"
												size="lg"
												className={'m-1'}
												disabled>
												<FaBed /><br></br>{val.bed_no}<br></br>{val.bed_name}<br></br>
											</Button> :
											<Button title={val.bed_name}
												onClick={() => { setActiveBed(val.bed_no); }}
												key={key}
												variant={activeBed === val.bed_no ? 'warning' : 'success'} size="lg"
												className={`m-1 ${activeBed === val.bed_no ? 'active ' : ''}`}>
												<FaBed /><br></br>{val.bed_no}<br></br>{val.bed_name}<br></br>
												{activeBed === val.bed_no ? <FaCheck /> : ''}
											</Button>;
									})}
									{bedType == 'icu' && !isEmpty(hospitalBedLayout) && isEmpty(hospitalBedLayout.ward) &&
										<h4>{t('GENERAL.NOBEDSFOUND')}</h4>
									}
									{bedType == 'ward' && !isEmpty(hospitalBedLayout) && isEmpty(hospitalBedLayout.icu) &&
										<h4>{t('GENERAL.NOBEDSFOUND')}</h4>
									}
								</Col>
							</Row>
							<hr />
							<Row>
								<Col className='mx-auto text-end'>
									<Button title={t('GENERAL.SUBMIT')} disabled={Loading} variant="primary" onClick={handleTransferSubmit}>{t('GENERAL.SUBMIT')}</Button>
									<Button title={t('GENERAL.CANCEL')} className='ms-2' variant="danger" onClick={handleCloseModal}>{t('GENERAL.CANCEL')}</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Modal.Body>
			</Modal>}
			{show && !isEmpty(handoverform) &&
				<HandOverModal
					viewMode={viewMode}
					show={show}
					handleClose={handleClose}
					patientData={patient}
					handoverformData={handoverform}
					defaultFormId={currentHospital.role == 'nurse' ? getDefaultFormID('Handover Form Nurse') : getDefaultFormID('Handover Form MO')} />}
			{showMessage &&
				<CommonModal
					message={showMessage}
					handleClose={handleMessageClose} />}
		</>
	);
};
export default DashboardBed;
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import PasswordInput from '../../../components/Common/password-input';
import { resetPassword } from '../../../redux/auth';
import { resetPasswordValidationSchema } from '../../../utils/validation';
import useQuery from '../../../utils/useQuery';
import { axiaLogo } from '../../../images';

const ResetPassword = () => {
	const dispatch = useDispatch();
	const { resetSuccess, resetErrorMessage } = useSelector(state => state.auth);
	const { email, token, username } = useQuery();
	const navigate = useNavigate();
	const initialValues = {
		'password': '',
		'password_confirmation': ''
	};
	useEffect(() => {
		if (isEmpty(email) || isEmpty(token) || isEmpty(username)) {
			navigate('/invalid-link');
		}
	}, [email, token, username]);

	const handleResetPassword = (values, { resetForm }) => {
		const body = { username, email, token, ...values };
		dispatch(resetPassword({ body }));
		resetForm();
	};

	return (
		<Container fluid className='vh-100 d-flex align-items-center justify-content-center'>
			<Row>
				<Col className='p-5 bg-white shadow-lg rounded'>
					<div className='text-center'>
						<img src={axiaLogo} alt={t('GENERAL.LOGOTEXT')} />
						<h1 className='text-center text-primary'>{t('RESETPASSWORD.TITLE')}</h1>
						<p className='text-center text-muted fs-6'>{t('RESETPASSWORD.SUB_TITLE')}</p>
					</div>
					{(resetErrorMessage) &&
						<Alert variant='danger' className='mt-2'>
							{resetErrorMessage && resetErrorMessage.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</Alert>}
					{!isEmpty(resetSuccess) ?
						<>
							<Alert variant='success' className='mt-2'>
								{resetSuccess?.message}
							</Alert>
							<Button title={t('LOGIN.BUTTON')} className='d-grid' as={Link} to='/login'>
								{t('LOGIN.BUTTON')}
							</Button>
						</>
						:
						<Formik initialValues={initialValues} validationSchema={resetPasswordValidationSchema(t)} onSubmit={handleResetPassword}>
							{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Group className="mb-3" as={Col} controlId="formGridPassword">
										<Form.Label>{t('RESETPASSWORD.FIELDS.PASSWORD')}</Form.Label>
										<PasswordInput
											placeholder={t('RESETPASSWORD.PLACEHOLDER.PASSWORD')}
											name="password"
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={errors.password && touched.password} />
										<Form.Control.Feedback type="invalid">
											{errors.password}
										</Form.Control.Feedback>
									</Form.Group>

									<Form.Group className="mb-3" as={Col} controlId="formGridPasswordConfirm">
										<Form.Label>{t('RESETPASSWORD.FIELDS.PASSWORDCONFIRM')}</Form.Label>
										<PasswordInput
											placeholder={t('RESETPASSWORD.PLACEHOLDER.PASSWORDCONFIRM')}
											name="password_confirmation"
											value={values.password_confirmation}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={errors.password_confirmation && touched.password_confirmation} />
										<Form.Control.Feedback type="invalid">
											{errors.password_confirmation}
										</Form.Control.Feedback>
									</Form.Group>
									<Stack className='pt-3'>
										<Button title={t('RESETPASSWORD.RESETPASSWORD_BUTTON')} size={'lg'} variant="primary" type="submit">
											{t('RESETPASSWORD.RESETPASSWORD_BUTTON')}
										</Button>
									</Stack>
								</Form>
							)}
						</Formik>
					}
				</Col>
			</Row>
		</Container>
	);
};

export default ResetPassword;

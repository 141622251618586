import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PasswordInput from '../../../components/Common/password-input';
import { handleLogin } from '../../../redux/auth';
import { useAuth } from '../../../utils/helper';
import { signInValidationSchema } from '../../../utils/validation';
import { setLogginError } from '../../../redux/auth/slice';
import { axiaLogo } from '../../../images';
function Login() {
	const dispatch = useDispatch();
	const useAuthContext = useAuth();
	const { loginErrorMessage } = useSelector(state => state.auth);
	const initialValues = {
		'username': '',
		'password': ''
	};
	const handleLoginSubmit = ({ username, password }) => {
		dispatch(setLogginError({ message: '' }));
		if (!isEmpty(localStorage.getItem('authToken'))) {
			setTimeout(() => {
				dispatch(setLogginError({ message: 'You already logged in from another tab. You will be redirected to home page witin 3 seconds...' }));
			}, 500);
			setTimeout(() => {
				window.location.href = '/';
			}, 3000);
		}
		else {
			const body = { username, password };
			dispatch(handleLogin({ body, useAuthContext }));
		}
	};
	return (
		<Container fluid className='vh-100 d-flex align-items-center justify-content-center'>
			<Row className='shadow-lg rounded px-5 py-2 bg-white' style={{ maxWidth: '570px' }}>
				<Col className='py-5'>
					<div className='text-center'>
						<img src={axiaLogo} alt={t('GENERAL.LOGOTEXT')} />
						<h1 className='text-primary'>{t('LOGIN.TITLE')}</h1>
						<p className='text-muted'>{t('LOGIN.SUB_TITLE')}</p>
					</div>
					<Formik initialValues={initialValues} validationSchema={signInValidationSchema(t)}
						onSubmit={handleLoginSubmit}
					>
						{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group className="mb-3" controlId="formBasicUsername">
									<Form.Label>{t('LOGIN.USERNAME')}</Form.Label>
									<Form.Control
										type="text"
										placeholder={t('LOGIN.PLACEHOLDER.USERNAME')}
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={errors.username && touched.username} />
									<Form.Control.Feedback type="invalid">
										{errors.username}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>{t('LOGIN.PASSWORD')}</Form.Label>
									<PasswordInput
										placeholder={t('LOGIN.PLACEHOLDER.PASSWORD')}
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={errors.password && touched.password} />
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>
								<p className='text-danger'>{loginErrorMessage}</p>
								<Stack gap={3}>
									<Link title={t('FORGOTPASSWORD.LINKTEXT')} className="ms-auto" to="/forgot-password">{t('FORGOTPASSWORD.LINKTEXT')}</Link>
									<Button title={t('LOGIN.BUTTON')} size={'lg'} variant="primary" type="submit">
										{t('LOGIN.BUTTON')}
									</Button>
								</Stack>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</Container>
	);
}

export default Login;
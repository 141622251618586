import React, { useEffect, useState } from 'react';
import Participant from './Participant';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { MdCallEnd } from 'react-icons/md';
import { isEmpty, random } from 'lodash';
import { FiMic, FiMicOff, FiVideo, FiVideoOff } from 'react-icons/fi';

const Room = ({ room, handleLogout }) => {
	const [participants, setParticipants] = useState([]);
	const [muteAudioFlag, setMuteAudioFlag] = useState(false);
	const [muteVideoFlag, setMuteVideoFlag] = useState(false);
	const [currentParticipant, setCurrentParticipant] = useState(room.localParticipant);

	useEffect(() => {
		const participantConnected = (participant) => {
			setParticipants((prevParticipants) => [...prevParticipants, participant]);
			if (!isEmpty(participants)) {
				setCurrentParticipant(participants[0]);
			}
			else {
				setCurrentParticipant(room.localParticipant);
			}
		};

		const participantDisconnected = (participant) => {
			setParticipants((prevParticipants) =>
				prevParticipants.filter((p) => p !== participant)
			);
			if (!isEmpty(participants)) {
				setCurrentParticipant(participants[0]);
			}
			else {
				setCurrentParticipant(room.localParticipant);
			}
		};

		room.on('participantConnected', participantConnected);
		room.on('participantDisconnected', participantDisconnected);
		room.participants.forEach(participantConnected);
		return () => {
			room.off('participantConnected', participantConnected);
			room.off('participantDisconnected', participantDisconnected);
		};
	}, [room]);

	const muteAudio = () => {
		room.localParticipant.audioTracks.forEach(publication => {
			if (muteAudioFlag) {
				publication.track.enable();
			}
			else {
				publication.track.disable();
			}
		});
		setMuteAudioFlag((prev) => !prev);
	};

	const muteVideo = () => {
		room.localParticipant.videoTracks.forEach(publication => {
			if (muteVideoFlag) {
				publication.track.enable();
			}
			else {
				publication.track.disable();
			}
		});
		setMuteVideoFlag((prev) => !prev);
	};
	return (
		<>
			<Row>
				<Col sm={12} md={10}>
					<Participant key={random(1000, true)} participant={currentParticipant} />
				</Col>
				<Col sm={12} md={2}>
					<Stack direction='vertical' gap={2}>
						<Participant key={room.localParticipant.sid} participant={room.localParticipant}  changeCurrentParticipant={ () => setCurrentParticipant(room.localParticipant) } />
						{
							participants && participants.map((participant) => {
								return (
									<Participant key={participant.sid} participant={participant} changeCurrentParticipant={ () => setCurrentParticipant(participant)} />
								);
							})
						}
					</Stack>
				</Col>
			</Row>
			<Row className='mt-2 text-center'>
				<Col>
					<div className="d-flex justify-content-center p-3 border border-2 gap-3">
						<Button variant={muteAudioFlag ? 'danger' : 'light'} className="border border-1 rounded-circle" onClick={muteAudio}>
							{muteAudioFlag ? <FiMicOff /> : <FiMic />}
						</Button>
						<Button variant={muteVideoFlag ? 'danger' : 'light'} className="border border-1 rounded-circle" onClick={muteVideo}>
							{muteVideoFlag ? <FiVideoOff /> : <FiVideo />}
						</Button>
						<Button variant='danger' className="border border-1 rounded-circle" onClick={handleLogout}>
							<MdCallEnd />
						</Button>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Room;

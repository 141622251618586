import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, groupBy, sortBy } from 'lodash';
import { FaCloudUploadAlt, FaPlus } from 'react-icons/fa';
import PatientInfo from '../../components/Patient/patient-info';
import Loader from '../../components/SplashScreen/loader';
import { getPatientInfo } from '../../redux/hospital';
import { getRoleForms } from '../../redux/forms';

const ClinicalForms = () => {
	const [formList, setFormList] = useState([]);
	const { currentPatient, currentPatientDetail, roles, currentPatientIpno } = useSelector(state => state.hospital);
	const { roleForms, Loading } = useSelector(state => state.forms);
	const { currentHospital } = useSelector(state => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { role } = useParams();
	useEffect(() => {
		if (isEmpty(currentPatient)) {
			navigate('/');
		}
		else {
			if (isEmpty(currentPatientDetail) || (currentPatient.p_id !== currentPatientDetail?.patient_info?.panel_title?.id)) {
				dispatch(getPatientInfo({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
			}
		}
		if (!isEmpty(currentHospital)) {
			dispatch(getRoleForms({ hospital_id: currentHospital.value, role, bed_type : currentPatient.bed_type }));
		}
	}, [currentHospital, currentPatient, currentPatientDetail, role]);

	useEffect(() => {
		if (!isEmpty(roleForms)) {
			setFormList(groupBy(sortBy(roleForms, ['access']), 'access'));
		}
		else {
			setFormList([]);
		}
	}, [roleForms]);

	return (
		<Container>
			<Row className='m-3'>
				<Col className='d-flex justify-content-center'>
					<h5 className='text-primary'>
						{t('CFORMS.TITLE')} - <span className='text-capitalize'>{roles[role]}</span>
					</h5>
				</Col>
			</Row>
			<hr />
			<Row>
				<PatientInfo currentPatient={currentPatient} currentPatientDetail={currentPatientDetail} />
			</Row>
			{Loading ?
				<Row className='d-flex justify-content-center mt-4'>
					<Loader />
				</Row> :
				<>
					{!isEmpty(formList) && Object.keys(formList).map(function(key) {
						return <React.Fragment key={key}>
							<Row className='mt-4' xs={1} md={2} lg={3}>
								{!isEmpty(formList) && formList[key].map((form, index) => {
									return (
										<Col key={index}>
											<div className='d-flex flex-wrap align-items-end border rounded-2 shadow-sm bg-white mb-3' style={{ minHeight: '160px' }}>
												<Col xs={12} className='p-3 d-inline-block'>
													<h6 className='text-center'>{form.form_name}</h6>
												</Col>
												<Col xs={12} className='p-3 d-flex justify-content-center bg-light border-top'>
													<Stack direction='horizontal' gap='3'>
														{currentPatientIpno?.discharged_at === null && form.access == 'full_access' &&
															<Button title={t('GENERAL.UPLOAD')}
																size='sm'
																as={Link}
																to={`/clinical_forms/upload-document/${form.id}/add`} state={{ uploadFormId: form.id, uploadFormName: form.form_name }}>
																<FaCloudUploadAlt /> {t('GENERAL.UPLOAD')}
															</Button>}
														{currentPatientIpno?.discharged_at === null && form.access == 'full_access' &&
															<Button title={t('GENERAL.CREATENEW')}
																size='sm'
																variant='success'
																as={Link}
																to={`/clinical_forms/form/${form.id}/add`}>
																<FaPlus /> {t('GENERAL.CREATENEW')}
															</Button>}
														<Button title={t('GENERAL.VIEWHISTORY')}
															size='sm'
															as={Link}
															variant='link'
															to={`/clinical_forms/form/${form.id}/list`}
															state={{ formId: form.id, formName: form.form_name, defaultId: form.default_form_id }}>
															{t('GENERAL.VIEWHISTORY')}
														</Button>
													</Stack>
												</Col>
											</div>
										</Col>
									);
								})}
							</Row>
							<hr />
						</React.Fragment>;

					})}
				</>}
		</Container >
	);
};

export default ClinicalForms;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Accordion, Button, Card, Col, Form, Modal, Row, Stack, Table } from 'react-bootstrap';
import { isEmpty, split, includes } from 'lodash';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HiPencilAlt } from 'react-icons/hi';
import { updateMedicineRate } from '../../redux/monitoring';
import { checkRoleAccess, getFormattedDateTime } from '../../utils/helper';
import RequiredAstric from '../DynamicForm/required-astric';
import { Formik } from 'formik';
import { refreshTab } from '../../redux/monitoring/slice';

const MedicinesTable = (props) => {
	const { data  } = props;
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { medicineModalRateUpdate } = useSelector(state => state.monitoring);
	const { hospitalBedInfo, currentPatient, currentPatientIpno } = useSelector(state => state.hospital);
	const rateMlFields = checkRoleAccess(['nurse'], currentHospital) ? ['ef6e1828d00b8acb4844787c929f192f', '3611ea99675a4e1665c03044ca2e532a'] : [];
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (isEmpty(medicineModalRateUpdate)) {
			dispatch(refreshTab({ 'tab': '#link2' }));
			handleCloseModal();
		}
	}, [medicineModalRateUpdate]);

	useEffect(() => {
		return () => {
			dispatch(refreshTab({ 'tab': '#link2' }));
		};
	}, []);

	const handleCloseModal = () => {
		setShowModal(false);
		setCurrentModalRecord({});
	};
	const [currentModalRecord, setCurrentModalRecord] = useState({});
	const openModal = (fieldName, value, recordIndex, fieldLabel) => {
		setCurrentModalRecord({
			fieldLabel,
			fieldName,
			value,
			recordIndex
		});
		setShowModal(true);
	};
	const handleModalSubmit = (formdata) => {
		const payload = {
			hospital_id: currentHospital.value,
			subform_data_id:formdata.recordIndex,
			p_id: currentPatient.p_id,
			field_name:formdata.fieldName,
			value:formdata.value
		};
		dispatch(updateMedicineRate(payload));
	};
	return (
		<React.Fragment>
			<Modal centered show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>{currentModalRecord.fieldLabel}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik initialValues={currentModalRecord} onSubmit={handleModalSubmit}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId={`formGrid${currentModalRecord.fieldName?.replace(/ /g, '')}`}>
										<Form.Label>
											{t('PATIENTMONITORING.VALUE')} <RequiredAstric />
										</Form.Label>
										<Form.Control
											type='number'
											min='0'
											name='value'
											value={values.value}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={errors.value && touched.value}
											required />
									</Form.Group>
									<hr />
									<Row>
										<Col>
											<Button title={t('GENERAL.SUBMIT')} /* disabled={storeLoading}  */variant="primary" type="submit">{t('GENERAL.SUBMIT')}</Button>
											<Button title={t('GENERAL.CANCEL')} className='ms-2' variant="danger" onClick={handleCloseModal}>{t('GENERAL.CANCEL')}</Button>
										</Col>
									</Row>
								</Form>);
						}}
					</Formik>
				</Modal.Body>
			</Modal>
			<Accordion defaultActiveKey="0" flush className='my-4'>
				<Accordion.Item eventKey="0">
					<Accordion.Button className='py-1' style={{ backgroundColor: 'white', border: '0px', boxShadow: '0px' }}>
						<span className='fw-bold'>{'Last Updated On : '}{data?.last_updated ? getFormattedDateTime(data?.last_updated) : '-'}</span>
					</Accordion.Button>
					<Accordion.Body className='bg-white'>
						{!isEmpty(data) && data?.history?.map((blocks, blocksIndex) =>
							<React.Fragment key={blocksIndex}>
								<Card className="mb-4 shadow-sm rounded-0">
									<Card.Header className='d-flex justify-content-between'>
										<span>
											{blocks?.title}
										</span>
										<Stack gap='2' direction='horizontal'>
											{currentPatientIpno?.discharged_at === null && hospitalBedInfo?.form_ids?.investigationtreatmentordersmo != '' && <Button title={t('GENERAL.ADD')} as={Link} size='sm' to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.investigationtreatmentordersmo}/add`}><FaPlus /> {t('GENERAL.ADD')}</Button>}
											{currentPatientIpno?.discharged_at === null && hospitalBedInfo?.form_ids?.investigationtreatmentordersmo != '' && data?.edit_id != '' && <Button title={t('GENERAL.EDIT')} as={Link} size='sm' to={`/clinical_forms/form/${hospitalBedInfo?.form_ids?.investigationtreatmentordersmo}/edit/${data?.edit_id}`}><HiPencilAlt /> {t('GENERAL.EDIT')}</Button>}
										</Stack>
									</Card.Header>
									<Card.Body>
										{!isEmpty(blocks.values) &&
											<Table bordered hover responsive size="sm" className='medicine-table'>
												<thead>
													<tr>
														{Object.entries(blocks.values[0])?.map(([header, headerVal], headerIndex) =>
															<th key={headerIndex} data={headerVal}>{header}</th>
														)}
													</tr>
												</thead>
												<tbody>
													{!isEmpty(blocks.values) && blocks.values?.map((row, rowIndex) =>
														<tr key={rowIndex}>
															{!isEmpty(row) && Object.entries(row)?.map(([recordKey, recordValue], recordIndex) =>
																<td key={recordIndex} data={recordKey}
																	{...(includes(rateMlFields, blocks.labels[recordIndex]?.field_name)
																		? { className: 'd-flex justify-content-between' }
																		: {})}>
																	{
																		split(recordValue, ',')?.map((val, rvkey) => {
																			return (
																				<React.Fragment key={rvkey}>
																					{val + ' '}
																					{includes(rateMlFields, blocks.labels[recordIndex]?.field_name) ?
																						<Button size='sm' onClick={() => openModal(blocks.labels[recordIndex]?.field_name, val, blocks.subform_data_ids[rowIndex], recordKey)}>
																							<HiPencilAlt />
																						</Button> : <br />
																					}
																				</React.Fragment>
																			);
																		})}
																</td>
															)}
														</tr>
													)}
												</tbody>
											</Table>}
									</Card.Body>
								</Card>
							</React.Fragment>
						)}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</React.Fragment>
	);
};

export default MedicinesTable;
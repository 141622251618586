import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { IoMdClose, IoMdShareAlt } from 'react-icons/io';
import { FaCheck, FaTimes } from 'react-icons/fa';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import Loader from '../../components/SplashScreen/loader';
import { reset } from '../../redux/discharge-clinical/slice';
import { cancelDischargePatient, dischargePatient, getDischargeClinicalList, verifyDischargePatient } from '../../redux/discharge-clinical';
import useDebounce from '../../utils/useDebounceHook';
import { checkRoleAccess } from '../../utils/helper';

const DischargeClinical = () => {
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [successMessage, setSuccessMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [id, setId] = useState(null);
	const [dischargeRecord, setDischargeRecord] = useState(null);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
	const [dischargeMessage, setDischargeMessage] = useState(null);
	const [cancelId, setCancelId] = useState(null);
	const [cancelDischargeRecord, setCancelDischargeRecord] = useState(null);
	const [cancelDisplayConfirmationModal, setCancelDisplayConfirmationModal] = useState(false);
	const [cancelDischargeMessage, setCancelDischargeMessage] = useState(null);
	const [verificationId, setVerificationId] = useState(null);
	const [verificationRecord, setVerificationRecord] = useState(null);
	const [displayVerificationConfirmationModal, setDisplayVerificationConfirmationModal] = useState(false);
	const [verificationMessage, setVerificationMessage] = useState(null);
	const [verificationFlag, setVerificationFlag] = useState(false);
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { dischargeClinicalError, dischargeClinicalList, isDischargePatientSuccess, isDischargePatientErrorMessage, isCancelDischargePatientSuccess, isCancelDischargePatientErrorMessage, isVerifyDischargeSuccess, isVerifyDischargeErrorMessage, Loading } = useSelector(state => state.dischargeClinical);
	const debouncedSearchTerm = useDebounce(filterText, 500);

	useEffect(() => {
		if (!isEmpty(currentHospital) && isDischargePatientSuccess) {
			setSuccessMessage(`The '${dischargeRecord.full_name}' patient is discharged successfully.`);
			setDisplayConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (!isEmpty(currentHospital) && isDischargePatientErrorMessage) {
			setErrorMessage(isDischargePatientErrorMessage?.payload?.message);
			setDisplayConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (!isEmpty(currentHospital) && isCancelDischargePatientSuccess) {
			setCancelDisplayConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (!isEmpty(currentHospital) && isCancelDischargePatientErrorMessage) {
			setErrorMessage(isCancelDischargePatientErrorMessage?.payload?.message);
			setCancelDisplayConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (!isEmpty(currentHospital) && isVerifyDischargeSuccess) {
			setDisplayVerificationConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (!isEmpty(currentHospital) && isVerifyDischargeErrorMessage) {
			setErrorMessage(isVerifyDischargeErrorMessage?.payload?.message);
			setDisplayVerificationConfirmationModal(false);
			dispatch(getDischargeClinicalList({ page: 1, hospital_id: currentHospital.value, ...columnSort }));
		}
	}, [isDischargePatientSuccess, isDischargePatientErrorMessage, isCancelDischargePatientSuccess, isCancelDischargePatientErrorMessage, isVerifyDischargeSuccess, isVerifyDischargeErrorMessage]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(dischargeClinicalList)) {
			setTotalRows(dischargeClinicalList.total);
			setPerPage(dischargeClinicalList.per_page);
			setSuccessMessage(dischargeClinicalList.message);
		}
	}, [dischargeClinicalList]);

	useEffect(() => {
		if (dischargeClinicalError) {
			setErrorMessage(dischargeClinicalError);
		}
	}, [dischargeClinicalError]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getDischargeClinicalList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getDischargeClinicalList({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	const showDischargeModal = (data) => {
		setId(data.id);
		setDischargeRecord(data);
		setSuccessMessage(null);
		setDischargeMessage(`Do you really want to discharge '${data.full_name}' patient ?`);
		setDisplayConfirmationModal(true);
	};
	const showCancelDischargeModal = (data) => {
		setCancelId(data.id);
		setCancelDischargeRecord(data);
		setSuccessMessage(null);
		setCancelDischargeMessage(`Do you really want to cancel clinical discharge for '${data.full_name}' patient ?`);
		setCancelDisplayConfirmationModal(true);
	};
	// Hide the modal
	const hideConfirmationModal = () => {
		setDisplayConfirmationModal(false);
	};
	// Hide the modal
	const hideCancelDischargeConfirmationModal = () => {
		setCancelDisplayConfirmationModal(false);
	};
	// Handle the actual deletion of the item
	const submitDischarge = (dischargeRecord) => {
		dispatch(dischargePatient({ ds_id: dischargeRecord.ds_id, p_id: dischargeRecord.p_id, hospital_id: currentHospital.value }));
	};
	const submitCancelDischarge = (dischargeRecord) => {
		dispatch(cancelDischargePatient({ ds_id: dischargeRecord.ds_id, p_id: dischargeRecord.p_id, hospital_id: currentHospital.value }));
	};
	const showVerificationModal = (data, verify) => {
		setVerificationId(data.id);
		setVerificationRecord(data);
		setSuccessMessage(null);
		setVerificationMessage(`Do you really want to ${!verify ? 'unverify' : 'verify'} '${data.full_name}' patient discharge summary ?`);
		setDisplayVerificationConfirmationModal(true);
		setVerificationFlag(verify);
	};
	const submitVerification = () => {
		dispatch(verifyDischargePatient({ ds_id: verificationRecord.ds_id, p_id: verificationRecord.p_id, hospital_id: currentHospital.value, verified: verificationFlag === true ? 1 : 0 }));
	};
	const hideVerificationConfirmationModal = () => {
		setDisplayVerificationConfirmationModal(false);
	};
	const columns = [
		{
			name: t('DISCHARGECLINICAL.COLUMNS.NAME'),
			grow: '2',
			selector: row => row.full_name,
			sortable: true,
			sortField: 'full_name',
		},
		{
			name: t('DISCHARGECLINICAL.COLUMNS.REGNO'),
			cell: row => row.reg_no,
			sortable: true,
			sortField: 'reg_no',
		},
		{
			name: t('DISCHARGECLINICAL.COLUMNS.MOBILENO'),
			selector: row => row.mobile_number,
			sortable: true,
			sortField: 'mobile_number',
		},
		{
			name: t('DISCHARGECLINICAL.COLUMNS.BEDNO'),
			selector: row => row.bed_no,
			wrap: true,
			sortable: true,
			sortField: 'bed_no',
		},
		{
			name: t('DISCHARGECLINICAL.COLUMNS.VERIFIED'),
			selector: row => row.verified === 1 ? 'Yes' : 'No',
			wrap: true,
			sortable: true,
			sortField: 'verified',
		},
		{
			name: t('DISCHARGECLINICAL.COLUMNS.ACTION'),
			grow: '3',
			cell: row => (
				<Stack direction='horizontal' gap={1}>
					{!checkRoleAccess(['consultant'], currentHospital) && row.discharge_status == 0 && row?.ds_id > 0 && row.verified === 0 &&
						<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Consultant need to verify before the patient can be discharged</Tooltip>}>
							<span className="d-inline-block">
								<Button title={t('DISCHARGECLINICAL.BUTTON.DISCHARGE')} variant="danger" size="sm" onClick={() => showDischargeModal(row)} disabled style={{ pointerEvents: 'none' }}>
									<IoMdShareAlt />  {t('DISCHARGECLINICAL.BUTTON.DISCHARGE')}
								</Button>
							</span>
						</OverlayTrigger>
					}
					{!checkRoleAccess(['consultant'], currentHospital) && row.discharge_status == 0 && row?.ds_id > 0 && row.verified === 1 &&
						<Button title={t('DISCHARGECLINICAL.BUTTON.DISCHARGE')} variant="danger" size="sm" onClick={() => showDischargeModal(row)}>
							<IoMdShareAlt />  {t('DISCHARGECLINICAL.BUTTON.DISCHARGE')}
						</Button>
					}
					{checkRoleAccess(['consultant'], currentHospital) && row.verified === 0 && row.discharge_status == 0 && row?.ds_id > 0 &&
						<Button title={t('DISCHARGECLINICAL.BUTTON.VERIFIED')} variant="success" size="sm" onClick={() => showVerificationModal(row, true)}>
							<FaCheck />  {t('DISCHARGECLINICAL.BUTTON.VERIFIED')}
						</Button>}
					{checkRoleAccess(['consultant'], currentHospital) && row.verified === 1 && row.discharge_status == 0 && row?.ds_id > 0 &&
						<Button title={t('DISCHARGECLINICAL.BUTTON.UNVERIFIED')} variant="danger" size="sm" onClick={() => showVerificationModal(row, false)}>
							<FaTimes />  {t('DISCHARGECLINICAL.BUTTON.UNVERIFIED')}
						</Button>}
					{!checkRoleAccess(['consultant'], currentHospital) && row.discharge_status == 1 &&
						<Button title={t('DISCHARGECLINICAL.BUTTON.CANCELDISCHARGE')} variant="danger" size="sm" onClick={() => showCancelDischargeModal(row)}>
							<IoMdClose /> {t('DISCHARGECLINICAL.BUTTON.CANCELDISCHARGE')}
						</Button>}
					{(row.discharge_status == 0 && row.ds_id > 0) || row.ds_id > 0 ?
						<Button title={t('DISCHARGECLINICAL.BUTTON.DISCHARGESUMMARY')} as={Link} size="sm" to={`/edit-discharge-summary?patientId=${row.p_id}${(row.ds_id) ? '&dsId=' + row.ds_id : ''}`}>
							{t('DISCHARGECLINICAL.BUTTON.DISCHARGESUMMARY')} <IoMdShareAlt />
						</Button> :
						<Button title={t('DISCHARGECLINICAL.BUTTON.DISCHARGESUMMARY')} as={Link} size="sm" to={`/discharge-summary?patientId=${row.p_id}`}>
							{t('DISCHARGECLINICAL.BUTTON.DISCHARGESUMMARY')} <IoMdShareAlt />
						</Button>
					}
				</Stack>
			),
		},

	];
	const handlePageChange = page => {
		if (!isEmpty(currentHospital)) {
			dispatch(getDischargeClinicalList({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		if (!isEmpty(currentHospital)) {
			dispatch(getDischargeClinicalList({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};

	return (
		<Container className='mt-4'>
			{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			{successMessage && <Alert variant="success">{successMessage}</Alert>}
			<Row>
				<Col>
					<DataTable
						title={t('DISCHARGECLINICAL.TITLE')}
						columns={columns}
						data={dischargeClinicalList.data}
						progressPending={Loading}
						progressComponent={<Loader />}
						highlightOnHover
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						onSort={handleSort}
						sortServer
					/>
				</Col>
			</Row>
			<DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDischarge} hideModal={hideConfirmationModal} id={id} deleteRecord={dischargeRecord} message={dischargeMessage} title={t('GENERAL.AREUSURE')} buttonText={t('DISCHARGECLINICAL.BUTTON.DISCHARGE')} loading={Loading} />

			<DeleteConfirmation showModal={cancelDisplayConfirmationModal} confirmModal={submitCancelDischarge} hideModal={hideCancelDischargeConfirmationModal} id={cancelId} deleteRecord={cancelDischargeRecord} message={cancelDischargeMessage} title={t('GENERAL.AREUSURE')} buttonText={t('DISCHARGECLINICAL.BUTTON.CANCELDISCHARGE')} loading={Loading} />

			<DeleteConfirmation showModal={displayVerificationConfirmationModal} confirmModal={submitVerification} hideModal={hideVerificationConfirmationModal} id={verificationId} deleteRecord={verificationRecord} message={verificationMessage} title={t('GENERAL.AREUSURE')} buttonText={t('DISCHARGECLINICAL.BUTTON.SUBMIT')} loading={Loading} />
		</Container>
	);
};

export default DischargeClinical;
import { createSlice, current } from '@reduxjs/toolkit';
import { has, isEmpty, findIndex, isEqual } from 'lodash';
import { getNotifications, markReadNotification } from '.';

const initialState = {
	messages: [],
	toasts: {},
	loading:false,
	notifications: [],
	notificationsDetail : {},
	notificationsError:'',
};

const hospitalMessages = createSlice({
	name: 'hospital-messages',
	initialState,
	reducers: {
		reset: () => initialState,
		resetToast: (state) => {
			state.toasts = {};
		},
		storeHospitalMessages: (state, { payload }) => {
			state.messages = payload;
		},
		storePatientMessage: (state, { payload }) => {
			if (!isEmpty(state.messages) && has(state.messages, payload.patientId)) {
				const patientMessages = state.messages[payload.patientId];

				// Check if a message with the same timestamp already exists
				const messageExists = patientMessages.some(
					msg => msg.timestamp.seconds === payload.timestamp.seconds &&
							msg.timestamp.nanoseconds === payload.timestamp.nanoseconds
				);

				// If the message does not exist, add it to the patient's message array
				if (!messageExists) {
					state.messages[payload.patientId] = [...patientMessages, payload];
				}
				// state.messages[payload.patientId] = [...state.messages[payload.patientId], payload];
			}
			else {
				let tmpObj = {};
				tmpObj[payload.patientId] = [payload];
				state.messages = { ...state.messages, ...tmpObj };
			}
		},
		storePatientToast: (state, { payload }) => {
			state.toasts = payload;
		},
		resetNotifications: (state) => {
			state.notifications = [];
			state.notificationsDetail = { };
			state.notificationsError = '';
		},

	},
	extraReducers: (builder) => {
		builder.addCase(getNotifications.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getNotifications.fulfilled, (state, action) => {
			state.loading = false;
			state.notificationsDetail = action.payload?.response.data?.data;
			if (isEmpty(state.notifications) && !isEmpty(action.payload?.response.data?.data?.data)) {
				state.notifications = action.payload?.response.data?.data?.data;
			}
			else if (!isEqual(current(state.notifications), action.payload.response.data.data.data)) {
				state.notifications = [
					...state.notifications,
					...action.payload.response.data.data.data
				];
			}
		});
		builder.addCase(getNotifications.rejected, (state, err) => {
			state.loading = false;
			state.notificationsError = err?.payload?.message;
		});

		builder.addCase(markReadNotification.fulfilled, (state, action) => {
			if (has(action.meta.arg, 'notification_id')) {
				let notificationIndex = findIndex(state.notifications, ['id', action.meta.arg.notification_id]);
				state.notifications[notificationIndex]['read'] = true;
			}
			else {
				state.notifications.forEach((notification, index, selfArray) => {
					selfArray[index]['read'] = true;
				});
			}
		});
	}
});
export const { reset, resetToast, storeHospitalMessages, storePatientMessage, storePatientToast, resetNotifications } = hospitalMessages.actions;

export default hospitalMessages.reducer;
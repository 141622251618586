import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card, Col, Row } from 'react-bootstrap';
import { getHospitalLedgerStatistics } from '../../redux/hospital';
import Loader from '../../components/SplashScreen/loader';
import { FaBed } from 'react-icons/fa';
// import { BiVideo } from 'react-icons/bi';
import { HiServerStack } from 'react-icons/hi2';
import './style.scss';
import { getFormattedDate } from '../../utils/helper';

const LedgerStatistics = () => {
	const { currentHospital } = useSelector(state => state.auth);
	const { ledgerStatistics, Loading } = useSelector(state => state.hospital);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getHospitalLedgerStatistics({ hospital_id: currentHospital.value }));
		}
	}, [currentHospital]);

	return (
		<Card>
			<Card.Header className='bg-white fw-bold'>
				{t('LEDGER.STATISTICS.TITLE')}
			</Card.Header>
			<Card.Body>
				<Row>
					{/* <Col>
						<div className="stat-card">
							<div className="stat-card__content">
								<h3 className="text-warning">
									{Loading && <Loader variant='warning' />}
									{ledgerStatistics && ledgerStatistics?.twilio_balance}
								</h3>
								<p className="mb-1 text-muted">{t('LEDGER.STATISTICS.TWILIOBALANCE')}</p>
								<div>
									<span className="text-muted">&nbsp;</span>
								</div>
							</div>
							<div className="stat-card__icon stat-card__icon--warning">
								<div className="stat-card__icon-circle">
									<BiVideo />
								</div>
							</div>
						</div>
					</Col> */}
					<Col>
						<div className="stat-card">
							<div className="stat-card__content">
								<h3 className="text-success">
									{Loading && <Loader />}
									{ledgerStatistics && ledgerStatistics?.balance}
								</h3>
								<p className="mb-1 text-muted">{t('LEDGER.STATISTICS.CONNECTS')}</p>
								<div>
									<span className="text-muted">&nbsp;</span>
								</div>
							</div>
							<div className="stat-card__icon stat-card__icon--success">
								<div className="stat-card__icon-circle">
									<FaBed />
								</div>
							</div>
						</div>
					</Col>
					<Col>
						<div className="stat-card">
							{Loading ?
								<Loader variant='danger' /> :
								<div className="stat-card__content">
									<h3 className="text-danger">
										{ledgerStatistics && ledgerStatistics?.active_plan && ledgerStatistics?.active_plan?.plan_name || 'No Active Plan'}
									</h3>
									{ledgerStatistics && ledgerStatistics?.active_plan && ledgerStatistics?.active_plan?.plan_name &&
										<>
											<p className="mb-1 text-muted">{t('LEDGER.STATISTICS.CURRENTPLAN')}</p>
											<div>
												<span className="text-muted">{t('LEDGER.STATISTICS.EXPIREDAT')} : {ledgerStatistics && ledgerStatistics?.active_plan && !isEmpty(ledgerStatistics?.active_plan?.expire_at) ? getFormattedDate(ledgerStatistics?.active_plan?.expire_at) : '-'}</span>
											</div>
										</>}
								</div>}
							<div className="stat-card__icon stat-card__icon--danger">
								<div className="stat-card__icon-circle">
									<HiServerStack />
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default LedgerStatistics;

import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, map, join, isString, findIndex, includes } from 'lodash';
import { HiPencilAlt } from 'react-icons/hi';
import moment from 'moment';
import { FaDownload } from 'react-icons/fa';
import BackButton from '../../components/BackButton';
import DynamicForm from '../../components/DynamicForm';
import PatientInfoAccord from '../../components/Patient/patient-info-accord';
import Loader from '../../components/SplashScreen/loader';
import { updateDischargeSummary, getDischargeSummaryInfo, getPatientInfoDischarge } from '../../redux/discharge-clinical';
import { reset, changeDischargeSubFormList, resetDischargeSummaryDownload } from '../../redux/discharge-clinical/slice';
import useQuery from '../../utils/useQuery';
import { checkMenuAccess, downloadPDFFile } from '../../utils/helper';
import { callGetAPI } from '../../services/axios';
const EditDischargeSummary = () => {
	const viewMode = !checkMenuAccess('discharge_clinical');
	const { patientId, dsId } = useQuery();
	const [editMode, setEditMode] = useState(dsId > 0 && checkMenuAccess('discharge_clinical'));
	const [initialValues, setInitialValues] = useState({});
	const { currentPatientDetail, dischargeSummaryEditDetail, dischargeSummaryEditDetailError, dischargeSummaryUpdateResponse, dischargeSummaryUpdateError, dischargeSummaryDownload, dischargeSummaryDownloadError, dischargeSummaryDownloadLoading, Loading } = useSelector(state => state.dischargeClinical);
	const { currentHospital } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isEmpty(currentHospital) && patientId > 0) {
			dispatch(getPatientInfoDischarge({ hospital_id: currentHospital.value, p_id: patientId }));
		}
		if (!isEmpty(currentHospital) && dsId > 0) {
			dispatch(getDischargeSummaryInfo({ hospital_id: currentHospital.value, p_id: patientId, ds_id: dsId }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(dischargeSummaryEditDetail?.form_fields)) {
			let fields = {};
			dischargeSummaryEditDetail?.form_fields.map((field) => {
				if (field.type =='datetime') {
					fields[field.field_name] = moment(field.value, ['YYYY-MM-DD HH:mm']).format('YYYY-MM-DDTHH:mm') || '';
				}
				else if (field.type == 'date') {
					fields[field.field_name] = moment(field.value, ['YYYY-MM-DD HH:mm']).format('YYYY-MM-DD') || '';
				}
				else {
					fields[field.field_name] = field.value || '';
				}
			});
			setInitialValues(fields);
		}
	}, [dischargeSummaryEditDetail]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(dischargeSummaryUpdateResponse) || !(patientId > 0) || !(dsId > 0)) {
			navigate('/discharge-clinical');
		}
	}, [dischargeSummaryUpdateResponse, patientId, dsId]);

	useEffect(() => {
		if (!isEmpty(dischargeSummaryEditDetailError) || !isEmpty(dischargeSummaryUpdateError) || !isEmpty(dischargeSummaryDownloadError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [dischargeSummaryEditDetailError, dischargeSummaryUpdateError, dischargeSummaryDownloadError]);

	useEffect(() => {
		if (!isEmpty(dischargeSummaryDownload) && dischargeSummaryDownload?.path != '') {
			const hiddenButton = document.getElementById('pdf');
			hiddenButton?.setAttribute('href', dischargeSummaryDownload?.path);
			dispatch(resetDischargeSummaryDownload());
			hiddenButton?.click();
		}
	}, [dischargeSummaryDownload]);

	const backToList = () => {
		navigate('/discharge-clinical');
	};

	const handleUpdateDischargeSummarySubmit = (values) => {
		let formdata = new FormData();
		// formdata.append('form_id', dischargeSummaryFormDetail.id);
		// formdata.append('date_time', moment().format('YYYY-MM-DD HH:mm:ss'));
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', patientId);
		formdata.append('ds_id', dsId);
		formdata.append('formdata[form_unique_id]', dischargeSummaryEditDetail.form_unique_id);
		Object.entries(values).map((field) => {
			let fieldindex = findIndex(dischargeSummaryEditDetail?.form_fields, { 'field_name': field[0] });
			let fieldrule = [];
			if (fieldindex > -1) {
				fieldrule = dischargeSummaryEditDetail?.form_fields[fieldindex]?.field_rule?.split('|') || [];
			}
			formdata.append('formdata[' + field[0] + ']', field[1]);
			dischargeSummaryEditDetail.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
				else if (formfield.field_name === field[0] && formfield.type === 'select') {
					if (!isString(field[1])) {
						formdata.delete('formdata[' + field[0] + ']');
						if (formfield.possible_values != '*') {
							formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
						}
						else {
							if (fieldrule && includes(fieldrule, 'split_pipe')) {
								formdata.append('formdata[' + field[0] + ']', join(field[1], '|'));
							}
							else {
								formdata.append('formdata[' + field[0] + ']', join(map(field[1], 'value'), ','));
							}
						}
					}
				}
				/* else if (formfield.field_name === field[0] && formfield.type === 'text' && formfield.autocomplete != '0') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + ']', field[1]?.value || '');
				} */
			});
		});
		dispatch(updateDischargeSummary(formdata));
	};

	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changeDischargeSubFormList({ values, fieldindex, mode: 'edit' }));
	};

	const downloadHistory = () => {
		callGetAPI({
			route: 'hospital/discharge/clinical/download',
			params: { p_id: patientId, ds_id: dsId, hospital_id: currentHospital.value },
			options: {
				responseType: 'blob'
			}
		}).then((data) => {
			downloadPDFFile(data?.data);
		});
	};

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('EDITDISCHARGESUMMARY.TITLE')}
					</h5>
					<Stack gap='2' direction='horizontal'>
						{editMode && <Button title={t('GENERAL.EDIT')}
							size='sm'
							onClick={() => setEditMode(!editMode)}>
							<HiPencilAlt /> {t('GENERAL.EDIT')}
						</Button>}
						<Button title={t('GENERAL.DOWNLOAD')}
							size='sm'
							disabled={dischargeSummaryDownloadLoading}
							onClick={() => downloadHistory()}>
							<FaDownload /> {t('GENERAL.DOWNLOAD')}
						</Button>
						<a title={t('GENERAL.FILE')} className="d-none" href='#' id='pdf' rel="noreferrer" target='_blank'>
							File
						</a>
						<BackButton />
					</Stack>
				</Col>
			</Row>
			<hr />
			{!Loading && !isEmpty(currentPatientDetail) &&
				<Row className='mt-4'>
					<PatientInfoAccord currentPatient={currentPatientDetail.patient_info.panel_title} currentPatientDetail={currentPatientDetail.patient_info.panel_details}  patientUploadDetails={currentPatientDetail.patient_info.panel_upload_info} patientShfitLogDetails={currentPatientDetail.patient_info.panel_shiftlog} />
				</Row>
			}
			<Row className='mt-4'>
				<Col>
					{(dischargeSummaryEditDetailError || dischargeSummaryUpdateError || dischargeSummaryDownloadError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{dischargeSummaryEditDetailError && dischargeSummaryEditDetailError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{dischargeSummaryUpdateError && dischargeSummaryUpdateError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{dischargeSummaryDownloadError && dischargeSummaryDownloadError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{Loading &&
						<Row className='d-flex justify-content-center mt-4'>
							<Loader />
						</Row>}
					{dischargeSummaryEditDetail.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{dischargeSummaryEditDetail.name}
						</Card.Header>
						<Card.Body>
							{!isEmpty(initialValues) && <DynamicForm ReInitialize={false} initialValues={initialValues} fields={dischargeSummaryEditDetail.form_fields} handleCancel={backToList} handleSubmit={handleUpdateDischargeSummarySubmit} parentformuniqueid={dsId} viewMode={editMode || viewMode} handleUpdateSubFormList={handleUpdateSubFormList} loading={Loading} isClinicalDischargeForm={true} />
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
		</Container >
	);
};

export default EditDischargeSummary;
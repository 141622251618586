import { createSlice } from '@reduxjs/toolkit';
import { addPatient, editPatient, getPatientFormFields, getPatientList, updatePatient, admitPatient, getHandOverHistory, updateAdmitPatient, getAdmittedPatientList } from '../patients';

const initialState = {
	patientFormDetail: [],
	patientFormDetailError: '',
	Loading: false,

	patientList: [],
	patientListError: '',

	admittedPatientList: [],
	admittedPatientListError: '',

	patientAddResponse: {},
	patientAddError: '',
	patientAdmitResponse: {},
	patientAdmitError: '',

	patientEditResponse: {},
	patientEditError: '',

	patientUpdateResponse: {},
	patientUpdateError: '',

	patientHandoverHistory: {}

};

const patientSlice = createSlice({
	name: 'patients',
	initialState,
	reducers: {
		reset: () => initialState,
		changePatientSubFormList: (state, { payload }) => {
			if (payload.mode == 'edit') {
				state.patientEditResponse['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
			else if (payload.mode == 'add') {
				state.patientFormDetail['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPatientFormFields.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getPatientFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientFormDetail = action.payload?.response.data.data;
		});
		builder.addCase(getPatientFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.patientFormDetailError = err?.payload?.message;
		});

		builder.addCase(getPatientList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getPatientList.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientList = action.payload?.response.data.data;
		});
		builder.addCase(getPatientList.rejected, (state, err) => {
			state.Loading = false;
			state.patientListError = err?.payload?.message;
		});

		builder.addCase(getAdmittedPatientList.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getAdmittedPatientList.fulfilled, (state, action) => {
			state.Loading = false;
			state.admittedPatientList = action.payload?.response.data.data;
		});
		builder.addCase(getAdmittedPatientList.rejected, (state, err) => {
			state.Loading = false;
			state.admittedPatientListError = err?.payload?.message;
		});

		builder.addCase(addPatient.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(addPatient.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientAddResponse = action.payload?.response.data.data;
		});
		builder.addCase(addPatient.rejected, (state, err) => {
			state.Loading = false;
			state.patientAddError = err?.payload?.message;
		});

		builder.addCase(editPatient.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(editPatient.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientEditResponse = action.payload?.response.data.data;
		});
		builder.addCase(editPatient.rejected, (state, err) => {
			state.Loading = false;
			state.patientEditError = err?.payload?.message;
		});

		builder.addCase(updatePatient.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updatePatient.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientUpdateResponse = action.payload?.response.data.data;
		});
		builder.addCase(updatePatient.rejected, (state, err) => {
			state.Loading = false;
			state.patientUpdateError = err?.payload?.message;
		});

		builder.addCase(admitPatient.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(admitPatient.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientAdmitResponse = action.payload?.response.data.data;
		});
		builder.addCase(admitPatient.rejected, (state, err) => {
			state.Loading = false;
			state.patientAdmitError = err?.payload?.message;
		});
		builder.addCase(getHandOverHistory.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientHandoverHistory = action.payload?.response.data.data;
		});
		builder.addCase(updateAdmitPatient.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updateAdmitPatient.fulfilled, (state, action) => {
			state.Loading = false;
			state.patientAdmitResponse = action.payload?.response.data.data;
		});
		builder.addCase(updateAdmitPatient.rejected, (state, err) => {
			state.Loading = false;
			state.patientAdmitError = err?.payload?.message;
		});
	},
});
export const { reset, changePatientSubFormList } = patientSlice.actions;

export default patientSlice.reducer;
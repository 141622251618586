import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Stack } from 'react-bootstrap';
import { FaHandPointer, FaMinus, FaPaperPlane, FaPlus } from 'react-icons/fa';
import { GoPrimitiveDot } from 'react-icons/go';
import { BiVideoPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import ChatMessage from './chat-message';
import db from '../../services/firebase';
import { sendNudgeNotification } from '../../redux/users';
import { resetToast } from '../../redux/chat/slice';
import './chatbox.scss';
import { checkRoleAccess } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

const ChatBox = () => {
	const [showChatWindow, setShowChatWindow] = useState(false);
	const [showNewMessage, setShowNewMessage] = useState(false);
	const [Height, setHeight] = useState('47px');
	const [chatMessages, setChatMessages] = useState([]);
	const [sendText, setSendText] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { currentPatient } = useSelector(state => state.hospital);
	const { currentHospital, userDetails } = useSelector(state => state.auth);
	const { messages, toasts } = useSelector(state => state.hospitalMessages);
	useEffect(() => {
		(showChatWindow) ? setHeight('415px') : setHeight('50px');
	}, [showChatWindow]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && !isEmpty(currentPatient)) {
			setChatMessages(messages[currentPatient.ip_no]);
			dispatch(resetToast());
		}
		if (!showChatWindow && !isEmpty(toasts) && (toasts?.patientId == currentPatient.ip_no)) {
			setShowNewMessage(true);
		}
	}, [currentPatient, messages]);

	const sendMessage = async(event) => {
		event.preventDefault();
		if (!isEmpty(sendText)) {
			addDocument(sendText, 'normal');
			setSendText('');
		}
	};
	const handleInviteVideoCall = () => {
		const payload = {
			'type': 'invite_videocall',
			'timestamp': serverTimestamp(),
			'sender': userDetails.id,
			'message': 'Invite to Join Video Call',
			'patientId': currentPatient.ip_no
		};
		addDoc(collection(db, currentHospital.postfix), payload);
		navigate(`/videocall/${currentPatient.ip_no}`);

	};
	const addDocument = async(message, type) => {
		const payload = {
			'type': type,
			'timestamp': serverTimestamp(),
			'sender': userDetails.id,
			'message': message,
			'patientId': currentPatient.ip_no
		};
		await addDoc(collection(db, currentHospital.postfix), payload);
		if (type == 'nudge') {
			dispatch(sendNudgeNotification({ hospital_id: currentHospital.value, p_id: currentPatient.p_id }));
		}
	};
	return (
		<div style={{ height: Height, zIndex: 9 }} className='min-vh-25 box box-success direct-chat direct-chat-success shadow shadow-lg'>
			<div className='box-header with-border' onClick={() => { setShowChatWindow(!showChatWindow); setShowNewMessage(false); }} data-widget='collapse'>
				<Stack direction='horizontal' gap='2'>
					<div className='box-title h3 pb-2'>{`Chat for ${currentPatient.patient_name}`}
						{showNewMessage && !showChatWindow &&
							<span className='text-danger'>
								<GoPrimitiveDot />
							</span>}
					</div>
					<div className='box-tools ms-auto'>
						<Button title={t('GENERAL.HIDE')} size='sm' variant='link' className='btn btn-box-tool text-secondary' onClick={() => { setShowChatWindow(!showChatWindow); setShowNewMessage(false); }} data-widget='collapse'>
							{showChatWindow ? <FaMinus /> : <FaPlus />}
						</Button>
					</div>
				</Stack>
			</div>
			<div className='box-body'>
				<ChatMessage messages={chatMessages} />
			</div>
			<div className='box-footer'>
				<form onSubmit={(event) => sendMessage(event)}>
					<InputGroup className='mb-3'>
						<Form.Control
							type='text'
							autoComplete='off'
							onChange={(e) => setSendText(e.target.value)}
							required
							name='message'
							placeholder={t('GENERAL.TYPEMSG')}
							className='form-control'
							value={sendText}
							aria-describedby='send'
						/>
						<Button title={t('GENERAL.SEND')} type='submit' variant='success'>
							<FaPaperPlane /> {t('GENERAL.SEND')}
						</Button>
						<Button title={t('GENERAL.SENDNUDGE')} variant='warning' className='text-white' onClick={() => addDocument('Nudge', 'nudge')}>
							<FaHandPointer />
						</Button>
						{false && !checkRoleAccess(['consultant'], currentHospital) && <Button title={t('GENERAL.MAKEVIDEOCALL')} variant='danger' className='text-white' onClick={() => handleInviteVideoCall()}>
							<BiVideoPlus />
						</Button>}
					</InputGroup>
				</form>
			</div>
		</div>
	);
};
export default ChatBox;
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FaRegEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import PatientInfoAccord from '../../components/Patient/patient-info-accord';
import { getHandOverHistory } from '../../redux/patients';
import { callGetAPI } from '../../services/axios';
import useDebounce from '../../utils/useDebounceHook';
import { getFormattedDateTime } from '../../utils/helper';
const HandOverHistory = () => {
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [patientData, setPatientData] = useState({});
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const { pid } = useParams();
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { patientHandoverHistory } = useSelector(state => state.patients);
	const debouncedSearchTerm = useDebounce(filterText, 500);

	useEffect(() => {
		if (!isEmpty(patientHandoverHistory)) {
			setTotalRows(patientHandoverHistory.total);
			setPerPage(patientHandoverHistory.per_page);
			setLoading(false);
		}
	}, [patientHandoverHistory]);

	useEffect(() => {
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getHandOverHistory({ page: 1, per_page: perPage, hospital_id: currentHospital.value, p_id: pid, type: currentHospital.role == 'nurse' ? 'nurse' : 'mo', ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getHandOverHistory({ page: 1, per_page: perPage, hospital_id: currentHospital.value, p_id: pid, type: currentHospital.role == 'nurse' ? 'nurse' : 'mo', search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	useEffect(() => {
		if (currentHospital.value) {
			callGetAPI({
				route: 'hospital/patient/info',
				params: {
					hospital_id: currentHospital.value,
					p_id: pid
				}
			}).then((data) => {
				setPatientData(data.data.data);
			});
		}

	}, [currentHospital.value]);

	const actions = (
		<BackButton />
	);

	const columns = [
		{
			name: t('HANDOVER.HANDOVER_GIVEN_BY'),
			selector: row => row.hand_given_by,
			sortable: true,
			sortField: 'hand_given_by',
		},
		{
			name: t('HANDOVER.HANDOVER_DATE_TIME'),
			selector: row => getFormattedDateTime(row.given_by_datetime),
			sortable: true,
			sortField: 'given_by_datetime',
		},
		{
			name: t('HANDOVER.HANDOVER_TAKEN_BY'),
			selector: row => row.hand_taken_by,
			sortable: true,
			sortField: 'hand_taken_by',
		},
		{
			name: t('HANDOVER.HANDOVER_DATE_TIME'),
			selector: row => getFormattedDateTime(row.hand_taken_by_date),
			sortable: true,
			sortField: 'hand_taken_by_date',
		},
		{
			name: t('GENERAL.ACTION'),
			cell: row => (
				<Button title={t('GENERAL.VIEW')} as={Link} to={`/viewhandoverhistorydetails/${row.id}/${row.p_id}`} variant='success' size='sm'>
					<FaRegEye /> {t('GENERAL.VIEW')}
				</Button>
			),
		},

	];
	const handlePageChange = page => {
		dispatch(getHandOverHistory({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const handlePerRowsChange = async(newPerPage, page) => {
		dispatch(getHandOverHistory({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
	};

	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};

	return (
		<Container className='mt-4 p-0'>
			{!isEmpty(patientData) &&
			<Row>
				<Col>
					<PatientInfoAccord currentPatient={patientData.patient_info.panel_title} currentPatientDetail={patientData.patient_info.panel_details}  patientUploadDetails={patientData.patient_info.panel_upload_info} patientShfitLogDetails={patientData.patient_info.panel_shiftlog} />
				</Col>
			</Row>
			}
			<Row className='mt-3'>
				<Col>
					{ !isEmpty(patientHandoverHistory) && <DataTable
						title={t('HANDOVER.HANDOVER_HISTORY')}
						actions={actions}
						columns={columns}
						data={patientHandoverHistory.data}
						progressPending={loading}
						progressComponent={<Loader />}
						highlightOnHover
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						subHeader
						subHeaderComponent={subHeaderComponentMemo}
						onSort={handleSort}
						sortServer
					/> }
				</Col>
			</Row>
		</Container>
	);
};

export default HandOverHistory;

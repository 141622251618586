import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { FiMic, FiMicOff, FiPhone, FiVideo, FiVideoOff } from 'react-icons/fi';
import { MdCallEnd } from 'react-icons/md';
import './style.scss';
import { ringtone } from '../../audio';

function VideoCall2() {
	const [stream, setStream] = useState(null);
	const videoRef = useRef(null);
	const videoRef2 = useRef(null);
	const videoRef3 = useRef(null);
	const [muteAudioFlag, setMuteAudioFlag] = useState(false);
	const [muteVideoFlag, setMuteVideoFlag] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const ringAudioRef = useRef(null);
	const startWebcam = async() => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: true,
			});
			setStream(mediaStream);
			videoRef.current.srcObject = mediaStream;
			videoRef2.current.srcObject = mediaStream;
			videoRef3.current.srcObject = mediaStream;
		} catch (error) {
			// console.error('Error accessing webcam:', error);
		}
	};

	const muteAudio = () => {
		const audioTracks = stream.getAudioTracks();
		audioTracks.forEach(track => {
			track.enabled = !track.enabled;
			setMuteAudioFlag(!track.enabled);
		});
	};

	const muteVideo = () => {
		const videoTracks = stream.getVideoTracks();
		videoTracks.forEach(track => {
			track.enabled = !track.enabled;
			setMuteVideoFlag(!track.enabled);
		});
	};
	const playAudio = () => {
		if (isPlaying) {
			ringAudioRef.current.pause();
			ringAudioRef.current.currentTime = 0;
		} else {
			ringAudioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};
	const closeWebcam = () => {
		const tracks = stream.getTracks();
		tracks.forEach(track => track.stop());
		setStream(null);
	};

	useEffect(() => {
		return () => {
			if (stream) {
				closeWebcam();
			}
		};
	}, []);

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('VIDEOCALL.TITLE')}
					</h5>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col sm={12} md={10}>
					<Card>
						<video ref={videoRef} autoPlay playsInline muted />
						<Card.Body className='p-2'>
							<Card.Title className='mb-0'>(You)</Card.Title>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={2}>
					<Stack direction='vertical' gap={2}>
						<Card>
							<video ref={videoRef2} autoPlay playsInline muted />
							<Card.Body className='p-2'>
								<Card.Title className='mb-0'>(You)</Card.Title>
							</Card.Body>
						</Card>
						<Card>
							<video ref={videoRef2} autoPlay playsInline muted />
							<Card.Body className='p-2'>
								<Card.Title className='mb-0'>(You)</Card.Title>
							</Card.Body>
						</Card>
						<Card>
							<video ref={videoRef3} autoPlay playsInline muted />
							<Card.Body className='p-2'>
								<Card.Title className='mb-0'>(You)</Card.Title>
							</Card.Body>
						</Card>
					</Stack>
				</Col>
			</Row>
			{/* <Row xs={1} md={3} className="g-4 screen-open mt-2">
				<Col>
					<Card>
						<video ref={videoRef} autoPlay playsInline muted />
						<Card.Body className='p-2'>
							<Card.Title className='mb-0'>(You)</Card.Title>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card>
						<video ref={videoRef2} autoPlay playsInline muted />
						<Card.Body className='p-2'>
							<Card.Title className='mb-0'>(You)</Card.Title>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card>
						<video ref={videoRef3} autoPlay playsInline muted />
						<Card.Body className='p-2'>
							<Card.Title className='mb-0'>(You)</Card.Title>
						</Card.Body>
					</Card>
				</Col>
			</Row> */}
			<audio ref={ringAudioRef} src={ringtone} />
			<Row className='mt-2 text-center'>
				<Col>
					<div className="d-flex justify-content-between p-3 border border-2">
						<div></div>
						<Stack gap={2} direction='horizontal'>
							{!stream && <Button variant='success' className="border border-1 rounded-circle" onClick={startWebcam}>
								<FiPhone />
							</Button>}
							<Button variant={muteAudioFlag ? 'danger' : 'light'} className="border border-1 rounded-circle" onClick={muteAudio}>
								{muteAudioFlag ? <FiMicOff /> : <FiMic />}
							</Button>
							<Button variant={muteVideoFlag ? 'danger' : 'light'} className="border border-1 rounded-circle" onClick={muteVideo}>
								{muteVideoFlag ? <FiVideoOff /> : <FiVideo />}
							</Button>
							<Button variant={muteVideoFlag ? 'danger' : 'light'} className="border border-1 rounded-circle" onClick={playAudio}>
								{muteVideoFlag ? <FiVideoOff /> : <FiVideo />}
							</Button>
						</Stack>
						<div>
							{stream &&
								<Button variant='danger' className="border border-1 rounded-circle" onClick={closeWebcam}>
									<MdCallEnd />
								</Button>}
						</div>
					</div>
				</Col>
			</Row>
		</Container >
	);
}
export default VideoCall2;
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Video from 'twilio-video';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import db from '../../services/firebase';
import Loader from '../../components/SplashScreen/loader';
import Room from '../../components/LayoutComponent/Room';
import { getVideoCallToken } from '../../redux/users';
import './style.scss';
function VideoCall() {
	const { ipno } = useParams();
	const { currentHospital, userDetails } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [room, setRoom] = useState(null);
	const { videoCallTokenResponse } = useSelector(state => state.users);
	useEffect(() => {
		dispatch(getVideoCallToken({ room: ipno }));
		return () => {
			handleLogout();
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(videoCallTokenResponse) && !isEmpty(videoCallTokenResponse?.data?.accessToken)) {
			Video.connect(videoCallTokenResponse?.data?.accessToken, {
				name: ipno,
			}).then((room) => {
				setRoom(room);
			});
		}
	}, [videoCallTokenResponse]);

	const handleLogout = useCallback(() => {
		setRoom((prevRoom) => {
			if (prevRoom) {
				prevRoom.localParticipant.tracks.forEach((trackPub) => {
					trackPub.track.stop();
				});
				prevRoom.disconnect();
			}
			const payload = {
				'type': 'ended_videocall',
				'timestamp': serverTimestamp(),
				'sender': userDetails.id,
				'message': 'Video Call Ended',
				'patientId': ipno
			};
			addDoc(collection(db, currentHospital.postfix), payload);
			return null;
		});
		navigate('/');
	}, []);
	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('VIDEOCALL.TITLE')}
					</h5>
				</Col>
			</Row>
			<hr />
			{(room && room?.localParticipant) ?
				<Room roomName={ipno} room={room} handleLogout={handleLogout} />
				:
				<Row className='d-flex justify-content-center mt-4'>
					<Loader variant='primary' />
				</Row>}
		</Container>
	);
}

export default VideoCall;
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { t } from 'i18next';

function DeleteConfirmation({ showModal, hideModal, confirmModal, deleteRecord, message, title, buttonText, loading }) {
	return (
		<Modal show={showModal} onHide={hideModal} centered>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
			<Modal.Footer>
				<Button title={t('GENERAL.CANCEL')} variant="default" onClick={hideModal}>
					{t('GENERAL.CANCEL')}
				</Button>
				<Button title={buttonText} variant="danger" onClick={() => confirmModal(deleteRecord)} disabled={loading} >
					{buttonText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default DeleteConfirmation;
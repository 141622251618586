import React from 'react';
import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment/moment';

const Footer = () => {
	return (
		<footer className="footer mt-2">
			<Container>
				<Row>
					<Col className='d-flex align-items-center justify-content-between'>
						<span>{t('FOOTER.COPYRIGHT', { year: moment().year() })}</span>
						<span className='float-end'>{t('FOOTER.APPVER', { version : process.env.REACT_APP_VERSION } )}</span>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
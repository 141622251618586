import React from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { Button, Col, Container, Row } from 'react-bootstrap';

const InvalidLinkPage = () => {
	const navigate = useNavigate();
	const navigateToHome = () => {
		navigate('/');
	};

	return (
		<Container fluid className='vh-100 d-flex align-items-center justify-content-center'>
			<Row className='shadow-lg rounded p-5 py-1 bg-white'>
				<Col className='p-5 text-center'>
					<h1 className='text-danger' style={{ fontSize: '100px' }}>
						{t('ERRORPAGE.499PAGE')}
					</h1>
					<p style={{ fontSize: '20px' }}>
						{t('ERRORPAGE.INVALIDLINK')}
					</p>
					<p className='text-muted' style={{ fontSize: '18px' }}>
						{t('ERRORPAGE.TEXT')}
					</p>
					<Button title={t('ERRORPAGE.BACKTOHOME')} variant="primary" onClick={navigateToHome}>
						{t('ERRORPAGE.BACKTOHOME')}
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

export default InvalidLinkPage;
import { createSlice } from '@reduxjs/toolkit';
import { storeChartData, getEWSChartTableData, getIOChartTableData, getVentilatorChartTableData, getChartData, getInvestigationHistory, getMedicinesData, getMainChartTableData, downloadChartData, storeChartDataMultiple, updateMedicineRate } from '../monitoring';

const initialState = {
	chartLoading: false,
	chartData: {},
	chartDataError: '',

	mainChartLoading : true,
	mainCharttableData : {},
	mainCharttableDataError : '',

	ewsLoading: false,
	ewstableData:{},
	ewstableDataError:'',

	ioLoading: false,
	iotableData: {},
	iotableDataError: '',

	ventilatorLoading: false,
	ventilatortableData: {},
	ventilatortableDataError: '',

	investigationLoading: false,
	investigationData: {},
	investigationDataError: '',

	medicinesLoading: false,
	medicinesData: {},
	medicinesDataError: '',

	storeLoading : false,
	storeTableData : false,
	storeTableDataError : '',

	refreshType : null,
	refreshTabName : null,

	chartDataDownloadLoading: false,
	chartDataDownload: {},
	chartDataDownloadError: '',

	medicineModalRateUpdateLoading : false,
	medicineModalRateUpdate : {},
	medicineModalRateUpdateError : '',

};

const monitoringSlice = createSlice({
	name: 'monitoring',
	initialState,
	reducers: {
		reset: () => initialState,
		resetStoreTable: (state) => {
			state.storeLoading = false;
			state.storeTableData = false;
			state.storeTableDataError = '';
		},
		refreshTable: (state, { payload }) => {
			state.refreshType = payload?.type;
		},
		refreshTab: (state, { payload }) => {
			state.refreshTabName = payload?.tab;
		},
		resetChartDataDownload: (state) => {
			state.chartDataDownloadLoading = false;
			state.chartDataDownload = {};
			state.chartDataDownloadError = '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getChartData.pending, (state) => {
			state.chartLoading = true;
			state.chartData = {};
			state.chartDataError = '';
		});
		builder.addCase(getChartData.fulfilled, (state, action) => {
			state.chartLoading = false;
			state.chartData = action.payload?.response.data.data;
		});
		builder.addCase(getChartData.rejected, (state, err) => {
			state.chartLoading = false;
			state.chartDataError = err?.payload?.message;
		});

		builder.addCase(getMainChartTableData.pending, (state) => {
			state.mainChartLoading = true;
			state.mainCharttableData = {};
			state.mainCharttableDataError = '';
		});
		builder.addCase(getMainChartTableData.fulfilled, (state, action) => {
			state.mainChartLoading = false;
			state.mainCharttableData = action.payload?.response.data.data;
		});
		builder.addCase(getMainChartTableData.rejected, (state, err) => {
			state.mainChartLoading = false;
			state.mainCharttableDataError = err?.payload?.message;
		});

		builder.addCase(getEWSChartTableData.pending, (state) => {
			state.ewsLoading = true;
			state.ewstableData ={};
			state.ewstableDataError= '';
		});
		builder.addCase(getEWSChartTableData.fulfilled, (state, action) => {
			state.ewsLoading = false;
			state.ewstableData = action.payload?.response.data.data;
		});
		builder.addCase(getEWSChartTableData.rejected, (state, err) => {
			state.ewsLoading = false;
			state.ewstableDataError = err?.payload?.message;
		});

		builder.addCase(getIOChartTableData.pending, (state) => {
			state.ioLoading = true;
			state.iotableData = {};
			state.iotableDataError = '';
		});
		builder.addCase(getIOChartTableData.fulfilled, (state, action) => {
			state.ioLoading = false;
			state.iotableData = action.payload?.response.data.data;
		});
		builder.addCase(getIOChartTableData.rejected, (state, err) => {
			state.ewsLoading = false;
			state.iotableDataError = err?.payload?.message;
		});

		builder.addCase(getVentilatorChartTableData.pending, (state) => {
			state.ventilatorLoading = true;
			state.ventilatortableData = {};
			state.ventilatortableDataError = '';
		});
		builder.addCase(getVentilatorChartTableData.fulfilled, (state, action) => {
			state.ventilatorLoading = false;
			state.ventilatortableData = action.payload?.response.data.data;
		});
		builder.addCase(getVentilatorChartTableData.rejected, (state, err) => {
			state.ewsLoading = false;
			state.ventilatortableDataError = err?.payload?.message;
		});

		builder.addCase(getInvestigationHistory.pending, (state) => {
			state.investigationLoading = true;
			state.investigationData = {};
			state.investigationDataError = '';
		});
		builder.addCase(getInvestigationHistory.fulfilled, (state, action) => {
			state.investigationLoading = false;
			state.investigationData = action.payload?.response.data.data;
		});
		builder.addCase(getInvestigationHistory.rejected, (state, err) => {
			state.investigationLoading = false;
			state.investigationDataError = err?.payload?.message;
		});

		builder.addCase(getMedicinesData.pending, (state) => {
			state.medicinesLoading = true;
			state.medicinesData = {};
			state.medicinesDataError = '';
		});
		builder.addCase(getMedicinesData.fulfilled, (state, action) => {
			state.medicinesLoading = false;
			state.medicinesData = action.payload?.response.data.data;
		});
		builder.addCase(getMedicinesData.rejected, (state, err) => {
			state.medicinesLoading = false;
			state.medicinesDataError = err?.payload?.message;
		});

		builder.addCase(storeChartData.pending, (state) => {
			state.storeLoading = true;
			state.storeTableData = false;
			state.storeTableDataError = '';
		});
		builder.addCase(storeChartData.fulfilled, (state) => {
			state.storeLoading = false;
			state.storeTableData = true;
		});
		builder.addCase(storeChartData.rejected, (state, err) => {
			state.storeLoading = false;
			state.storeTableDataError = err?.payload?.message;
		});

		builder.addCase(storeChartDataMultiple.pending, (state) => {
			state.storeLoading = true;
			state.storeTableData = false;
			state.storeTableDataError = '';
		});
		builder.addCase(storeChartDataMultiple.fulfilled, (state) => {
			state.storeLoading = false;
			state.storeTableData = true;
		});
		builder.addCase(storeChartDataMultiple.rejected, (state, err) => {
			state.storeLoading = false;
			state.storeTableDataError = err?.payload?.message;
		});

		builder.addCase(downloadChartData.pending, (state) => {
			state.chartDataDownloadLoading = true;
			state.chartDataDownload = {};
			state.chartDataDownloadError = '';
		});
		builder.addCase(downloadChartData.fulfilled, (state, action) => {
			state.chartDataDownloadLoading = false;
			state.chartDataDownload = action.payload?.response.data.data;
		});
		builder.addCase(downloadChartData.rejected, (state, err) => {
			state.chartDataDownloadLoading = false;
			state.chartDataDownloadError = err?.payload?.message;
		});

		builder.addCase(updateMedicineRate.pending, (state) => {
			state.medicineModalRateUpdateLoading = true;
			state.medicineModalRateUpdate = {};
			state.medicineModalRateUpdateError = '';
		});
		builder.addCase(updateMedicineRate.fulfilled, (state, action) => {
			state.medicineModalRateUpdateLoading = false;
			state.medicineModalRateUpdate = action.payload?.response.data;
		});
		builder.addCase(updateMedicineRate.rejected, (state, err) => {
			state.medicineModalRateUpdateLoading = false;
			state.medicineModalRateUpdateError = err?.payload?.message;
		});

	},
});
export const { reset, resetStoreTable, refreshTable, resetChartDataDownload, refreshTab } = monitoringSlice.actions;

export default monitoringSlice.reducer;
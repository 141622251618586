import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import moment from 'moment';
import DynamicForm from '../../components/DynamicForm';
import BackButton from '../../components/BackButton';
import Loader from '../../components/SplashScreen/loader';
import { addPatient, getPatientFormFields } from '../../redux/patients';
import { changePatientSubFormList } from '../../redux/patients/slice';
import { reset } from '../../redux/patients/slice';

const AddPatient = () => {
	const [initialValues, setInitialValues] = useState({});
	const [regNo, setRegNo] = useState('');
	const { currentHospital } = useSelector(state => state.auth);
	const { patientFormDetail, patientFormDetailError, patientAddResponse, patientAddError, Loading } = useSelector(state => state.patients);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isEmpty(currentHospital)) {
			dispatch(getPatientFormFields({ hospital_id: currentHospital.value, default_form_id: 1, bed_type: 'icu' }));
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(patientFormDetail?.form_fields)) {
			let fields = {};
			patientFormDetail?.form_fields.map((field) => {
				fields[field.field_name] = field.default_values || '';
			});
			setInitialValues(fields);
		}
	}, [patientFormDetail]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(patientAddResponse)) {
			navigate('/patients');
		}
	}, [patientAddResponse]);

	useEffect(() => {
		if (!isEmpty(patientAddError) || !isEmpty(patientFormDetailError)) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [patientAddError, patientFormDetailError]);

	const backToPatientList = () => {
		navigate('/patients');
	};

	const handleAddPatientSubmit = (values) => {
		let formdata = new FormData();
		formdata.append('form_id', patientFormDetail.id);
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('reg_no', trim(regNo));
		formdata.append('date_time', moment().format('YYYY-MM-DD HH:mm:ss'));
		formdata.append('formdata[form_unique_id]', patientFormDetail.form_unique_id);
		formdata.append('patient_form', 'yes');
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
			patientFormDetail.form_fields.map((formfield) => {
				if (formfield.field_name === field[0] && formfield.type === 'subform') {
					formdata.delete('formdata[' + field[0] + ']');
					formdata.append('formdata[' + field[0] + '][id]', formfield.sub_form_unique_id);
				}
			});
		});
		dispatch(addPatient(formdata));
	};

	const handleUpdateSubFormList = (values, fieldindex) => {
		dispatch(changePatientSubFormList({ values, fieldindex, mode: 'add' }));
	};

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('PATIENTADD.TITLE')}
					</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					{(patientFormDetailError || patientAddError) &&
						<Alert variant='danger'>
							<ul className='mb-0'>
								{patientFormDetailError && patientFormDetailError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
								{patientAddError && patientAddError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					{Loading &&
						<Row className='d-flex justify-content-center mt-4'>
							<Loader />
						</Row>}
					{patientFormDetail.form_fields && <Card text='dark' bg='white'>
						<Card.Header className='text-uppercase'>
							{patientFormDetail.name}
						</Card.Header>
						<Card.Body>
							{!isEmpty(initialValues) &&
								<>
									<Form.Group className="mb-3" controlId={'reg_no'}>
										<Form.Label>
											Reg No.
										</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Reg No. (Optional)'
											name='reg_no'
											value={regNo}
											onChange={(e) => {
												setRegNo(e.target.value);
											}}
											onBlur={(e) => {
												setRegNo(e.target.value);
											}}
											title='Reg No'
										/>
									</Form.Group>
									<DynamicForm initialValues={initialValues}
										fields={patientFormDetail.form_fields}
										handleCancel={backToPatientList}
										handleSubmit={handleAddPatientSubmit}
										parentformuniqueid={patientFormDetail.form_unique_id}
										isPatientForm={true}
										loading={Loading}
										handleUpdateSubFormList={handleUpdateSubFormList} />
								</>
							}
						</Card.Body>
					</Card>}
				</Col>
			</Row>
		</Container >
	);
};

export default AddPatient;
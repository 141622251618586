import React, { useEffect, useState, useRef } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { FaUserAlt, FaCalendarAlt, FaUserMd, FaAngleDoubleRight } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import DynamicForm from '../DynamicForm';
import PatientDetailsModal from '../PatientDetailsModal';
import { callGetAPI } from '../../services/axios';
import { storeHandoverForm, signInHandoverForm } from '../../redux/forms';
import { reset } from '../../redux/forms/slice';
import { getHospitalBedInfo } from '../../redux/hospital';
import { getFormattedDateTime } from '../../utils/helper';
function HandOverModal({ show, handleClose, patientData, handoverformData, viewMode, defaultFormId }) {
	const [patientModal, setPatinetModal] = useState(false);
	const [handoverUsers, setHandOverUsers] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const validationMessageRef = useRef();
	const dispatch = useDispatch();
	const { userDetails: { id: userId }, currentHospital } = useSelector(state => state.auth);
	const { storeHandoverFormError, signInHandoverFormError, signInHandoverFormResponse, storeHandoverFormResponse, Loading } = useSelector(state => state.forms);
	const { currentBedType } = useSelector(state => state.hospital);

	useEffect(() => {
		callGetAPI({
			route: 'hospital/userlist/list',
			params: {
				hospital_id: currentHospital.value,
				'role': currentHospital.role,
				'admin': 'no'
			}
		}).then((data) => {
			setHandOverUsers(data.data.data.filter(val => val.id !== userId));
		});
		let fields = {};
		handoverformData.form_fields?.map((field) => {
			fields[field.field_name] = field?.value || '';
		});
		fields['given_to'] = handoverformData.given_to?.id || '';
		setInitialValues(fields);
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (signInHandoverFormResponse || storeHandoverFormResponse) {
			dispatch(getHospitalBedInfo({ hospital_id: currentHospital.value, bed_type: currentBedType  }));
			handleClose();
		}
	}, [signInHandoverFormResponse, storeHandoverFormResponse]);

	useEffect(() => {
		if (!isEmpty(storeHandoverFormError)) {
			validationMessageRef.current?.scrollIntoView();
		}
	}, [storeHandoverFormError]);

	const showModal = () => {
		setPatinetModal(true);
	};
	const handleHandoverFormSubmit = (values) => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', patientData.patient_info.panel_title.id);
		formdata.append('type', currentHospital.role == 'doctor'?'mo':'nurse');
		formdata.append('given_to', values.given_to);
		Object.entries(values).map((field) => {
			formdata.append('formdata[' + field[0] + ']', field[1]);
		});
		dispatch(storeHandoverForm(formdata));
		dispatch(getHospitalBedInfo({ hospital_id: currentHospital.value, bed_type: currentBedType  }));
	};
	const handleHandoverSignIn = () => {
		let formdata = new FormData();
		formdata.append('hospital_id', currentHospital.value);
		formdata.append('p_id', patientData.patient_info.panel_title.id);
		formdata.append('type', currentHospital.role == 'doctor'?'mo':'nurse');
		dispatch(signInHandoverForm(formdata));
	};
	const handlePatientClose = () => setPatinetModal(false);

	return (<>
		<Modal show={show} onHide={handleClose} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t('PATIENTS.PATIENT_HANDOVER')} - {patientData.patient_info.panel_title.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert key={'success'} variant={'success'} className="text-center mb-2 shadow rounded py-2" onClick={showModal}>
					<FaUserAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.NAME')} : {patientData.patient_info.panel_title.name}, <FaCalendarAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.DOA')} : {getFormattedDateTime(patientData.admission_info.date_of_admission)}, <FaUserMd className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.ATTDR')} : {patientData.patient_info.panel_title?.att_dr} <FaAngleDoubleRight className='text-success' style={{  marginBottom: '2px', marginRight: '5px' }} />
				</Alert>
				{storeHandoverFormError &&
					<Alert variant='danger' ref={validationMessageRef}>
						<ul className='mb-0'>
							{storeHandoverFormError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{signInHandoverFormError &&
					<Alert variant='danger' ref={validationMessageRef}>
						<ul className='mb-0'>
							{signInHandoverFormError.split('|').map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>}
				{ !isEmpty(initialValues) && <DynamicForm viewMode={viewMode} initialValues={initialValues} fields={handoverformData.form_fields} handleSubmit={viewMode?handleHandoverSignIn:handleHandoverFormSubmit} handleCancel={handleClose} handoverUsersData={handoverUsers} loading={Loading}  defaultFormId={defaultFormId} />}
			</Modal.Body>
		</Modal>
		<PatientDetailsModal show={patientModal} handlePatientClose={handlePatientClose} patientData={patientData.patient_info.panel_details} />
	</>
	);
}
export default HandOverModal;
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { Accordion, Card, Col, Container, Row, Form, Button, Stack, Alert, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { FaBed, FaCheck, FaCircle, FaDownload } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import RequiredAstric from '../../components/DynamicForm/required-astric';
import BackButton from '../../components/BackButton';
import { updateAdmitPatient } from '../../redux/patients';
import { getHospitalBedInfo } from '../../redux/hospital';
import { reset } from '../../redux/patients/slice';
import { callGetAPI } from '../../services/axios';
import { admitPatientValidationSchema } from '../../utils/validation';
import { getFormattedDateTime } from '../../utils/helper';
import Loader from '../../components/SplashScreen/loader';

const AdmittedDetailPatient = () => {
	const [consultant, setConsultant] = useState([]);
	const [selectedAttendId, setSelectedAttendId] = useState([]);
	const [selectedVisitId, setSelectedVisitId] = useState([]);
	const { hospitalBedInfo } = useSelector(state => state.hospital);
	const [patientinfo, setPatientInfo] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [admimissionHistory, setAdmissionHistory] = useState([]);
	const [activeBed, setActiveBed] = useState();
	const [loading, setLoading] = useState(false);
	const { currentHospital } = useSelector(state => state.auth);
	const { patientAdmitError, patientAdmitResponse, Loading } = useSelector(state => state.patients);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (currentHospital.value) {
			setLoading(true);
			const callPatientAPIS = async() => {
				await callGetAPI({
					route: 'hospital/userlist/list',
					params: {
						hospital_id: currentHospital.value,
						'role': 'consultant'
					}
				}).then((data) => {
					setConsultant(data.data.data);
				});

				await callGetAPI({
					route: 'hospital/patient/info',
					params: {
						hospital_id: currentHospital.value,
						p_id: id
					}
				}).then((data) => {
					setPatientInfo(data.data.data);
				});

				await callGetAPI({
					route: 'hospital/admitted/patient/information',
					params: {
						hospital_id: currentHospital.value,
						p_id: id
					}
				}).then((data) => {
					setAdmissionHistory(data.data.data.patient_admission_history);
					const patientData = data.data.data.patient_admission_info;
					setInitialValues({
						'id': patientData.id,
						'date_of_admission': moment(patientData.date_of_admission).format(moment.HTML5_FMT.DATETIME_LOCAL),
						'accompanied_by': patientData?.accompanied_by,
						'relationship': patientData?.relationship,
						'companion_mobile_no': patientData?.companion_mobile_no,
						'alternate_contact_no': patientData?.alternate_contact_no,
						'referring_doctor_name': patientData?.referring_doctor_name,
						'authorise_person': patientData?.authorise_person,
						'attending_consultant_id': patientData?.attending_consultant_id.map(visiting => visiting.id),
						'visiting_consultant_id': patientData?.visiting_consultant.map(visiting => visiting.id)
					});
					setActiveBed(patientData.bed_no);
					dispatch(getHospitalBedInfo({ hospital_id: currentHospital.value, bed_type: patientData.bed_type }));
				});
				setLoading(false);
			};
			callPatientAPIS();
		}
	}, [currentHospital]);

	useEffect(() => {
		if (!isEmpty(initialValues)) {
			const defaultOptions1 = consultant.filter((option) =>
				initialValues.attending_consultant_id.includes(option.id)
			);

			const defaultOptions2 = consultant.filter((option) =>
				initialValues.visiting_consultant_id.includes(option.id)
			);
			setSelectedAttendId(
				defaultOptions1.map((option) => ({
					value: option,
					label: `${option.first_name} ${option.last_name} (${option.mobile_number}) ${option.role == 'doctor' ? '(Owner)' : ''}`,
				}))
			);
			setSelectedVisitId(
				defaultOptions2.map((option) => ({
					value: option,
					label: `${option.first_name} ${option.last_name} (${option.mobile_number}) ${option.role == 'doctor' ? '(Owner)' : ''}`,
				}))
			);
		}
	}, [initialValues]);

	useEffect(() => {
		if (patientAdmitError) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [patientAdmitError]);

	useEffect(() => {
		if (!isEmpty(patientAdmitResponse)) {
			navigate('/patients');
		}
	}, [patientAdmitResponse]);

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	const bedinfo = hospitalBedInfo.total_beds && [...hospitalBedInfo.available_bed_numbers, ...hospitalBedInfo.booked_bed_numbers].sort(function(a, b) {
		return a - b;
	});

	const handleActiveBed = (val) => {
		setActiveBed(val);
	};

	const handlePatientSubmit = (values) => {
		const formvalues = { ...values, attending_consultant_id: selectedAttendId.map(item => item.value.id), visiting_consultant_id: selectedVisitId.map(item => item.value.id), hospital_id: currentHospital.value, p_id: id, reg_no: patientinfo.patient_info?.panel_title.reg_no, bed_no: activeBed };
		dispatch(updateAdmitPatient(formvalues));
	};

	const handleAttendChange = (selectedOptions) => {
		setSelectedAttendId(selectedOptions);
		setSelectedVisitId(
			selectedVisitId.filter(
				(option) => !selectedOptions.some((selected) => selected.value.id === option.id)
			)
		);
	};

	const handleVisitChange = (selectedOptions) => {
		setSelectedVisitId(selectedOptions);
		setSelectedAttendId(
			selectedAttendId.filter(
				(option) => !selectedOptions.some((selected) => selected.value.id === option.id)
			)
		);
	};
	const availableOptionsForAttendSelect = consultant.filter(
		(option) =>
			!selectedVisitId.some((selected) => selected.value.id === option.id)
	);

	const availableOptionsForVisitSelect = consultant.filter(
		(option) =>
			!selectedAttendId.some((selected) => selected.value.id === option.id)
	);
	const selectOptions = consultant.map((option) => ({
		value: option,
		label: `${option.first_name} ${option.last_name} (${option.mobile_number}) ${option.role == 'doctor' ? '(Owner)' : ''}`
	}));

	return (
		<Container className='mt-4'>
			<Row>
				<Col className='d-flex justify-content-between'>
					<h5 className='text-primary'>
						{t('PATIENTDETAIL.TITLE')}
					</h5>
					<BackButton />
				</Col>
			</Row>
			<hr />
			<Row className='mt-4'>
				<Col>
					{patientAdmitError &&
						<Alert variant="danger">
							<ul className='mb-0'>
								{patientAdmitError.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header className='p-0'>
								<Row className='w-100'>
									<Col>{t('PATIENTS.REGISTRATION_DETAILS')}</Col>
									<Col className='text-end'>
										<Link title={t('PATIENTEDIT.TITLE')}
											className='btn btn-success btn-sm text-right'
											to={`/patients/edit/${id}`}>
											{t('PATIENTEDIT.TITLE')}
										</Link>
									</Col>
								</Row>
							</Accordion.Header>
							{loading ?
								<Row className='d-flex justify-content-center mt-4'>
									<Loader />
								</Row> :
								<Accordion.Body>
									{!isEmpty(patientinfo) && <Row xs={1} md={2} lg={3}>
										{patientinfo.patient_info.panel_details.map((val, key) => {
											return <Col key={key}><h6>{val.title} : {val.value}</h6></Col>;
										})}
									</Row>}
									<Table bordered responsive>
										<thead>
											<tr>
												{patientinfo?.patient_info?.panel_upload_info?.header.map((title, index) => (
													<th key={index}>{title}</th>
												))}
											</tr>
										</thead>
										<tbody>
											{patientinfo?.patient_info?.panel_upload_info?.body?.length ? patientinfo?.patient_info?.panel_upload_info?.body.map((value, index) => (
												<tr key={index}>
													<td>{value[0]}</td>
													<td>{<a title={t('GENERAL.DOWNLOAD')} className='btn btn-sm btn-success' href={value[1]} target='_blank' rel="noreferrer" download>
														<HiDownload />
													</a>}</td>
												</tr>
											)) :
												<tr>
													<td colSpan={2} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
												</tr>
											}
										</tbody>
									</Table>
									<Table bordered responsive>
										<thead>
											<tr>
												{patientinfo?.patient_info?.panel_shiftlog?.header.map((title, index) => (
													<th key={index}>{title}</th>
												))}
											</tr>
										</thead>
										<tbody>
											{patientinfo?.patient_info?.panel_shiftlog?.body?.length ? patientinfo?.patient_info?.panel_shiftlog?.body.map((value, index) => (
												<tr key={index}>
													<td>{value[0]}</td>
													<td>{value[1]}</td>
													<td>{value[2]}</td>
													<td>{value[3]}</td>
													<td>{value[4]}</td>
												</tr>
											)) :
												<tr>
													<td colSpan={5} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
												</tr>
											}
										</tbody>
									</Table>
								</Accordion.Body>}
						</Accordion.Item>
					</Accordion>
					{!isEmpty(initialValues) &&
						<Formik initialValues={initialValues} onSubmit={handlePatientSubmit} validationSchema={admitPatientValidationSchema(t)}>
							{({ errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Card text='dark' bg='white' className='mt-4'>
										<Card.Header className='text-uppercase'>
											{t('PATIENTDETAIL.SUBTITLE')}
										</Card.Header>
										<Card.Body>
											<Row className="mb-3">
												<Form.Group as={Col} controlId="date_of_admission">
													<Form.Label>{t('PATIENTS.DATE_TIME_OF_ADMISSION')}<RequiredAstric /></Form.Label>
													<Form.Control
														type="datetime-local"
														name="date_of_admission"
														value={values.date_of_admission}
														onChange={handleChange}
														onBlur={handleBlur}
														isInvalid={errors.date_of_admission && touched.date_of_admission}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.date_of_admission}
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group as={Col} controlId="accompanied_by">
													<Form.Label>{t('PATIENTS.ACCOMPANIED_BY')}<RequiredAstric /></Form.Label>
													<Form.Control
														type="text"
														name="accompanied_by"
														value={values.accompanied_by}
														onChange={handleChange}
														onBlur={handleBlur}
														isInvalid={errors.accompanied_by && touched.accompanied_by} />
													<Form.Control.Feedback type="invalid">
														{errors.accompanied_by}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
											<Row className="mb-3">
												<Form.Group as={Col} controlId="relationship">
													<Form.Label>{t('PATIENTS.RELATIONSHIP')}<RequiredAstric /></Form.Label>
													<Form.Control
														type="text"
														name="relationship"
														value={values.relationship}
														onChange={handleChange}
														onBlur={handleBlur}
														isInvalid={errors.relationship && touched.relationship} />
													<Form.Control.Feedback type="invalid">
														{errors.relationship}
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group as={Col} controlId="companion_mobile_no">
													<Form.Label>{t('PATIENTS.COMPANION_MOBILE')}<RequiredAstric /></Form.Label>
													<Form.Control
														type="text"
														maxLength={10}
														name="companion_mobile_no"
														value={values.companion_mobile_no}
														onChange={(e) => {
															setFieldValue('companion_mobile_no', e.target.value.replace(/[^0-9.]/g, ''));
														}}
														onBlur={handleBlur}
														isInvalid={errors.companion_mobile_no && touched.companion_mobile_no} />
													<Form.Control.Feedback type="invalid">
														{errors.companion_mobile_no}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
											<Row className="mb-3">
												<Form.Group as={Col} controlId="alternate_contact_no">
													<Form.Label>{t('PATIENTS.EMERGENCY_CONTACT_NO')}</Form.Label>
													<Form.Control
														type="text"
														maxLength={10}
														name="alternate_contact_no"
														value={values.alternate_contact_no}
														onChange={(e) => {
															setFieldValue('alternate_contact_no', e.target.value.replace(/[^0-9.]/g, ''));
														}}
														onBlur={handleBlur}
														isInvalid={errors.alternate_contact_no && touched.alternate_contact_no} />
													<Form.Control.Feedback type="invalid">
														{errors.alternate_contact_no}
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group as={Col} controlId="referring_doctor_name">
													<Form.Label>{t('PATIENTS.REFERRING_DOCTOR')}</Form.Label>
													<Form.Control
														type="text"
														name="referring_doctor_name"
														value={values.referring_doctor_name}
														onChange={handleChange}
														onBlur={handleBlur}
														isInvalid={errors.referring_doctor_name && touched.referring_doctor_name} />
													<Form.Control.Feedback type="invalid">
														{errors.referring_doctor_name}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
											<Row className="mb-3">
												<Form.Group as={Col} md="6" controlId="formGridFirstName">
													<Form.Label>{t('PATIENTS.AUTHORIZE_PERSON_NAME')}</Form.Label>
													<Form.Control
														type="text"
														name="authorise_person"
														value={values.authorise_person}
														onChange={handleChange}
														onBlur={handleBlur}
														isInvalid={errors.authorise_person && touched.authorise_person} />
													<Form.Control.Feedback type="invalid">
														{errors.authorise_person}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
									<Card text='dark' bg='white' className='mt-4'>
										<Card.Header className='text-uppercase'>
											{t('PATIENTS.ALLOCATION_OF_DOCTOR')}
										</Card.Header>
										<Card.Body>
											<Row className="mb-3">
												<Form.Group as={Col} controlId="attending_consultant_id">
													<Form.Label>{t('PATIENTS.ATTENDING_CONSULTANT')}<RequiredAstric /></Form.Label>
													<Select
														isMulti
														isSearchable
														name="attending_consultant_id"
														options={selectOptions.filter((option) =>
															availableOptionsForAttendSelect.some((availableOption) => availableOption.id === option.value.id)
														)}
														// defaultValue={selectedAttendId}
														value={selectedAttendId}
														onChange={handleAttendChange}
														onBlur={handleBlur}
														isInvalid={errors.attending_consultant_id && touched.attending_consultant_id} />
													<Form.Control.Feedback type="invalid">
														{errors.attending_consultant_id}
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group as={Col} controlId="visiting_consultant_id">
													<Form.Label>{t('PATIENTS.VISITING_CONSULTANT')}</Form.Label>
													<Select
														isMulti
														isSearchable
														name="visiting_consultant_id"
														options={selectOptions.filter((option) =>
															availableOptionsForVisitSelect.some((availableOption) => availableOption.id === option.value.id)
														)}
														// defaultValue={selectedVisitId}
														value={selectedVisitId}
														onChange={handleVisitChange}
														onBlur={handleBlur}
														isInvalid={errors.visiting_consultant_id && touched.visiting_consultant_id}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.visiting_consultant_id}
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
									<Card text='dark' bg='white' className='mt-4'>
										<Card.Header className='text-uppercase d-flex'>
											{t('PATIENTS.BED_ALLOCATION')}
											<Stack direction='horizontal' gap={2} className='ms-2'>
												<FaCircle className='text-success'></FaCircle> {t('PATIENTS.BED_AVAILABLE')}
												<FaCircle className='text-danger'></FaCircle> {t('PATIENTS.BED_NOT_AVAILABLE')}
											</Stack>
										</Card.Header>
										<Card.Body>
											<Row className="mb-3">
												<Col className='mx-auto text-center' md={8}>
													{hospitalBedInfo.total_beds && bedinfo.map((val, key) => {
														return hospitalBedInfo.available_bed_numbers.indexOf(val) > -1 ?
															<Button title={hospitalBedInfo.bed_list[val]}
																onClick={() => { handleActiveBed(val); }}
																key={key}
																variant={activeBed === val ? 'warning' : 'success'} size="lg"
																className={`m-1 ${activeBed === val ? 'active' : ''}`}>
																<FaBed /><br></br>{val}<br></br>{hospitalBedInfo.bed_list[val]}<br></br>
																{activeBed === val ? <FaCheck /> : ''}
															</Button> :
															<Button title={hospitalBedInfo.bed_list[val]}
																onClick={() => { handleActiveBed(val); }}
																key={key}
																variant="danger"
																size="lg"
																className={`m-1 ${activeBed === val ? 'active' : ''}`}
																disabled={activeBed === val ? false : true}>
																<FaBed /><br></br>{val}<br></br>{hospitalBedInfo.bed_list[val]}<br></br>
															</Button>;
													})}
												</Col>
											</Row>
										</Card.Body>
									</Card>
									<Stack direction='horizontal' className='mt-3 flex-row-reverse' gap={2}>
										<Button title={t('GENERAL.SUBMIT')} disabled={Loading} variant="success" type="submit">{t('GENERAL.SUBMIT')}</Button>
										<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={() => navigate('/patients')}>{t('GENERAL.CANCEL')}</Button>
									</Stack>
								</Form>
							)}
						</Formik>}

				</Col>
			</Row>
			{!isEmpty(admimissionHistory) && <Card text='dark' bg='white' className='mt-4'>
				<Card.Header className='text-uppercase d-flex'>
					{t('PATIENTS.PATIENT_ADMISSION_HISTORY')}
				</Card.Header>
				<Card.Body>
					<Row className="mb-3">
						<Col className='mx-auto text-center'>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>#</th>
										<th>{t('DISCHARGEHISTORY.COLUMNS.DOA')}</th>
										<th>{t('DISCHARGEHISTORY.COLUMNS.DOD')}</th>
										<th>{t('GENERAL.ADMISSION_FORM')}</th>
										<th>{t('GENERAL.ACTION')}</th>
									</tr>
								</thead>
								<tbody>
									{admimissionHistory.map((val, key) => {
										return <tr key={key}>
											<td>{++key}</td>
											<td>{getFormattedDateTime(val.date_of_admission)}</td>
											<td>{val.discharged_at ? getFormattedDateTime(val.discharged_at) : ''}</td>
											<td>{val.ip_no}</td>
											<td>
												<Link title={t('GENERAL.DOWNLOAD')} className='btn btn-success btn-sm' to={`/admittedpatients/admittedpatienthistory/${currentHospital.value}/${val.p_id}/${val.ip_no}`}>
													<FaDownload /> {t('GENERAL.DOWNLOAD')}
												</Link>
											</td>
										</tr>;
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>}
		</Container >
	);
};

export default AdmittedDetailPatient;

import { createSlice } from '@reduxjs/toolkit';
import { addSubFormFields, getFormFields, getFormHistory, getFormHistoryEdit, getFormHistoryView, getRoleForms, getSubFormFields, getSubFormList, storeForm, updateForm, storeHandoverForm, deleteSubFormRecord, getEditSubFormFields, updateSubFormFields, signInHandoverForm, storeDocumentForm, getFormDocumentHistory, deleteDocument, downloadFormRecord, saveTimerData, getMedicinesHistory, getDailyAssessmentHistory } from '../forms';
const initialState = {
	Loading: false,
	subFormLoading: false,

	subFormDetail: [],
	subFormDetailError: '',

	subFormAddResponse: {},
	subFormAddError: '',

	roleForms: {},
	roleFormsError: '',

	formFields: {},
	formFieldsError: '',

	storeFormResponse: {},
	storeFormError: '',

	storeDocumentFormResponse : {},
	storeDocumentFormError : '',

	formHistoryResponse: {},
	formHistroyError: '',

	formDocumentHistoryResponse: {},
	formDocumentHistroyError: '',

	formHistoryViewResponse: {},
	formHistoryViewError: '',

	formHistoryEditResponse: {},
	formHistoryEditError: '',

	updateFormResponse: {},
	updateFormError: '',

	storeHandoverFormResponse: '',
	storeHandoverFormError: '',

	subFormList: [],
	subFormListError: '',

	subFormUpdateResponse: {},
	subFormUpdateError: '',

	subFormDeleteMessage: '',
	subFormDeleteError: '',

	signInHandoverFormResponse: '',
	signInHandoverFormError: '',

	formDocumentDeletedSuccess : false,
	formDocumentDeleteErrorMessage : '',

	downloadFormRecordDetail : {},
	downloadFormRecordDetailError : '',

	saveTimerDataResponse:false,
	saveTimerError:{},

	medicinesHistory:{},
	medicinesHistoryError:'',

	dailyAssessmentHistoryLoading : false,
	dailyAssessmentHistoryResponse : {},
	dailyAssessmentHistoryError: ''
};

const formSlice = createSlice({
	name: 'forms',
	initialState,
	reducers: {
		reset: () => initialState,
		resetSubForm: (state) => {
			state.subFormDetail = [];
			state.subFormDeleteMessage = '';
			state.subFormDeleteError = '';
			state.updateFormError = '';
			state.subFormAddError = '';
		},
		resetSubFormErrors:(state) => {
			state.subFormDetailError = '';
			state.subFormAddError = '';
			state.subFormUpdateError = '';
			state.subFormDeleteError = '';
		},
		changeSubFormList: (state, { payload }) => {
			if (payload.mode == 'edit') {
				state.formHistoryEditResponse['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
			else if (payload.mode == 'add') {
				state.formFields['form_fields'][payload.fieldindex]['value'] = payload.values;
			}
		},
		resetSaveTimerForm : (state) => {
			state.saveTimerDataResponse = false;
			state.saveTimerError = '' ;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getSubFormFields.pending, (state) => {
			state.Loading = true;
			state.subFormDetailError = '';
			state.subFormDetail = {};
		});
		builder.addCase(getSubFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.subFormDetail = action.payload?.response.data.data;
		});
		builder.addCase(getSubFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.subFormDetailError = err?.payload?.message;
		});

		builder.addCase(addSubFormFields.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(addSubFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.subFormAddResponse = action.payload?.response.data.data;
		});
		builder.addCase(addSubFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.subFormAddError = err?.payload?.message;
		});

		builder.addCase(getRoleForms.pending, (state) => {
			state.Loading = true;
			state.roleForms = {};
			state.roleFormsError = '';
		});
		builder.addCase(getRoleForms.fulfilled, (state, action) => {
			state.Loading = false;
			state.roleForms = action.payload?.response.data.data;
		});
		builder.addCase(getRoleForms.rejected, (state, err) => {
			state.Loading = false;
			state.roleFormsError = err?.payload?.message;
		});

		builder.addCase(getFormFields.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.formFields = action.payload?.response.data.data;
		});
		builder.addCase(getFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.formFieldsError = err?.payload?.message;
		});

		builder.addCase(storeForm.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(storeForm.fulfilled, (state, action) => {
			state.Loading = false;
			state.storeFormResponse = action.payload?.response.data.data;
		});
		builder.addCase(storeForm.rejected, (state, err) => {
			state.Loading = false;
			state.storeFormError = err?.payload?.message;
		});

		builder.addCase(getFormHistory.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getFormHistory.fulfilled, (state, action) => {
			state.Loading = false;
			state.formHistoryResponse = action.payload?.response.data.data;
		});
		builder.addCase(getFormHistory.rejected, (state, err) => {
			state.Loading = false;
			state.formHistroyError = err?.payload?.message;
		});

		builder.addCase(getFormHistoryView.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getFormHistoryView.fulfilled, (state, action) => {
			state.Loading = false;
			state.formHistoryViewResponse = action.payload?.response.data.data;
		});
		builder.addCase(getFormHistoryView.rejected, (state, err) => {
			state.Loading = false;
			state.formHistoryViewError = err?.payload?.message;
		});

		builder.addCase(getFormHistoryEdit.pending, (state) => {
			state.Loading = true;
			state.updateFormResponse = {};
			state.updateFormError = '';
		});
		builder.addCase(getFormHistoryEdit.fulfilled, (state, action) => {
			state.Loading = false;
			state.formHistoryEditResponse = action.payload?.response.data.data;
		});
		builder.addCase(getFormHistoryEdit.rejected, (state, err) => {
			state.Loading = false;
			state.formHistoryEditError = err?.payload?.message;
		});

		builder.addCase(updateForm.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updateForm.fulfilled, (state, action) => {
			state.Loading = false;
			state.updateFormResponse = action.payload?.response.data.data;
		});
		builder.addCase(updateForm.rejected, (state, err) => {
			state.Loading = false;
			state.updateFormError = err?.payload?.message;
		});
		builder.addCase(storeHandoverForm.fulfilled, (state, action) => {
			state.storeHandoverFormResponse = action.payload?.response.data.message;
		});
		builder.addCase(storeHandoverForm.rejected, (state, err) => {
			state.storeHandoverFormError = err?.payload?.message;
		});
		builder.addCase(getSubFormList.pending, (state) => {
			state.subFormLoading = true;
			state.subFormDeleteMessage ='';
			state.subFormUpdateError='';
			state.subFormAddError = '';
		});
		builder.addCase(getSubFormList.fulfilled, (state, action) => {
			state.subFormLoading = false;
			state.subFormList = action.payload?.response.data.data;
		});
		builder.addCase(getSubFormList.rejected, (state, err) => {
			state.subFormLoading = false;
			state.subFormListError = err?.payload?.message;
		});

		builder.addCase(getEditSubFormFields.pending, (state) => {
			state.Loading = true;
			state.subFormDetailError = '';
			state.subFormDetail = {};
		});
		builder.addCase(getEditSubFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.subFormDetail = action.payload?.response.data.data;
		});
		builder.addCase(getEditSubFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.subFormDetailError = err?.payload?.message;
		});

		builder.addCase(updateSubFormFields.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(updateSubFormFields.fulfilled, (state, action) => {
			state.Loading = false;
			state.subFormUpdateResponse = action.payload?.response.data.data;
		});
		builder.addCase(updateSubFormFields.rejected, (state, err) => {
			state.Loading = false;
			state.subFormUpdateError = err?.payload?.message;
		});

		builder.addCase(deleteSubFormRecord.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(deleteSubFormRecord.fulfilled, (state, action) => {
			state.Loading = false;
			state.subFormDeleteMessage = action.payload?.response.data.message;
		});
		builder.addCase(deleteSubFormRecord.rejected, (state, err) => {
			state.Loading = false;
			state.subFormDeleteError = err?.payload?.message;
		});
		builder.addCase(signInHandoverForm.fulfilled, (state, action) => {
			state.signInHandoverFormResponse = action.payload?.response.data.message;
		});
		builder.addCase(signInHandoverForm.rejected, (state, err) => {
			state.signInHandoverFormError = err?.payload?.message;
		});

		builder.addCase(storeDocumentForm.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(storeDocumentForm.fulfilled, (state, action) => {
			state.Loading = false;
			state.storeDocumentFormResponse = action.payload?.response.data.data;
		});
		builder.addCase(storeDocumentForm.rejected, (state, err) => {
			state.Loading = false;
			state.storeDocumentFormError = err?.payload?.message;
		});

		builder.addCase(getFormDocumentHistory.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(getFormDocumentHistory.fulfilled, (state, action) => {
			state.Loading = false;
			state.formDocumentHistoryResponse = action.payload?.response.data.data;
		});
		builder.addCase(getFormDocumentHistory.rejected, (state, err) => {
			state.Loading = false;
			state.formDocumentHistroyError = err?.payload?.message;
		});

		builder.addCase(deleteDocument.pending, (state) => {
			state.Loading = true;
			state.formDocumentDeletedSuccess = false;
			state.formDocumentDeleteErrorMessage = '';
		});
		builder.addCase(deleteDocument.fulfilled, (state) => {
			state.Loading = false;
			state.formDocumentDeletedSuccess = true;
		});
		builder.addCase(deleteDocument.rejected, (state, err) => {
			state.Loading = false;
			state.formDocumentDeleteErrorMessage = err?.payload?.message;
		});

		builder.addCase(downloadFormRecord.pending, (state) => {
			state.Loading = true;
		});
		builder.addCase(downloadFormRecord.fulfilled, (state, action) => {
			state.Loading = false;
			state.downloadFormRecordDetail = action.payload?.response.data.data;
		});
		builder.addCase(downloadFormRecord.rejected, (state, err) => {
			state.Loading = false;
			state.downloadFormRecordDetailError = err?.payload?.message;
		});

		builder.addCase(saveTimerData.pending, (state) => {
			state.Loading = true;
			state.saveTimerDataResponse = false;
		});
		builder.addCase(saveTimerData.fulfilled, (state) => {
			state.Loading = false;
			state.saveTimerDataResponse = true;
		});
		builder.addCase(saveTimerData.rejected, (state, err) => {
			state.Loading = false;
			state.saveTimerDataResponse = false;
			state.saveTimerError = err?.payload?.message;
		});

		builder.addCase(getMedicinesHistory.pending, (state) => {
			state.Loading = true;
			state.medicinesHistory = {};
			state.medicinesHistoryError = '';
		});
		builder.addCase(getMedicinesHistory.fulfilled, (state, action) => {
			state.Loading = false;
			state.medicinesHistory = action.payload?.response.data.data;
			state.medicinesHistoryError = '';
		});
		builder.addCase(getMedicinesHistory.rejected, (state, err) => {
			state.Loading = false;
			state.medicinesHistory = {};
			state.medicinesHistoryError = err?.payload?.message;
		});

		builder.addCase(getDailyAssessmentHistory.pending, (state) => {
			state.dailyAssessmentHistoryLoading = true;
			state.dailyAssessmentHistoryResponse = {};
			state.dailyAssessmentHistoryError = '';
		});
		builder.addCase(getDailyAssessmentHistory.fulfilled, (state, action) => {
			state.dailyAssessmentHistoryLoading = false;
			state.dailyAssessmentHistoryResponse = action.payload?.response.data.data;
		});
		builder.addCase(getDailyAssessmentHistory.rejected, (state, err) => {
			state.dailyAssessmentHistoryLoading = false;
			state.dailyAssessmentHistoryError = err?.payload?.message;
		});
	},
});
export const { reset, changeSubFormList, resetSubForm, resetSaveTimerForm, resetSubFormErrors } = formSlice.actions;

export default formSlice.reducer;
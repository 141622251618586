import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { t } from 'i18next';

function BackButton({ onBack }) {
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};
	return (
		<Button title={t('GENERAL.BACK')} size='sm' onClick={onBack?onBack:goBack}><FaChevronLeft /> {t('GENERAL.BACK')}</Button>
	);
}

export default BackButton;
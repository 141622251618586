import React from 'react';
import { t } from 'i18next';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { FaUserMd, FaCalendarAlt, FaUserAlt } from 'react-icons/fa';
import { HiDownload } from 'react-icons/hi';
import { getFormattedDateTime } from '../../utils/helper';

function PatientInfo(props) {
	const { currentPatient, currentPatientDetail } = props;
	return (
		<Accordion className='mb-2'>
			<Accordion.Item eventKey="0" className='shadow rounded'>
				<Accordion.Button style={{ backgroundColor: '#dff0d8' }} className='py-2 shadow rounded'>
					{!isEmpty(currentPatient) ?
						<span>
							<FaUserAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.NAME')} : {currentPatient.patient_name}, <FaCalendarAlt className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.DOA')} : {getFormattedDateTime(currentPatientDetail?.admission_info?.date_of_admission)}, <FaUserMd className='text-success' style={{ fontSize: '13px', marginBottom: '2px', marginRight: '5px' }} /> {t('GENERAL.ATTDR')} : {currentPatientDetail?.admission_info?.attending_consultant_info?.length && currentPatientDetail?.admission_info?.attending_consultant_info?.map(user => `${user.first_name} ${user.last_name}`).join(', ')}
						</span> :
						<span>
							{t('GENERAL.PATIENTINFO')}
						</span>}
				</Accordion.Button>
				<Accordion.Body>
					<Row xs={1} md={2} lg={3}>
						<Col><h6>{t('GENERAL.IPNO')} : {currentPatientDetail?.patient_info?.panel_title?.ip_no}</h6></Col>
						{!isEmpty(currentPatientDetail) && currentPatientDetail?.patient_info?.panel_details?.map((field, index) => {
							return (
								<Col key={index}>
									<h6>{field.title} : {field.value}</h6>
								</Col>);
						})}
					</Row>
					<Table bordered responsive>
						<thead>
							<tr>
								{currentPatientDetail?.patient_info?.panel_upload_info?.header?.map((title, index) => (
									<th key={index}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentPatientDetail?.patient_info?.panel_upload_info?.body?.length ? currentPatientDetail?.patient_info?.panel_upload_info?.body.map((value, index) => (
								<tr key={index}>
									<td>{value[0]}</td>
									<td>{<a title={t('GENERAL.DOWNLOAD')} className='btn btn-sm btn-success' href={value[1]} target='_blank' rel="noreferrer" download>
										<HiDownload />
									</a>}</td>
								</tr>
							)) :
								<tr>
									<td colSpan={2} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
								</tr>
							}
						</tbody>
					</Table>
					<Table bordered responsive>
						<thead>
							<tr>
								{currentPatientDetail?.patient_info?.panel_shiftlog?.header?.map((title, index) => (
									<th key={index}>{title}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentPatientDetail?.patient_info?.panel_shiftlog?.body?.length ? currentPatientDetail?.patient_info?.panel_shiftlog?.body.map((value, index) => (
								<tr key={index}>
									<td>{value[0]}</td>
									<td>{value[1]}</td>
									<td>{value[2]}</td>
									<td>{value[3]}</td>
									<td>{value[4]}</td>
								</tr>
							)) :
								<tr>
									<td colSpan={5} className='text-center'>{t('GENERAL.NORECORDFOUND')}</td>
								</tr>
							}
						</tbody>
					</Table>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default PatientInfo;
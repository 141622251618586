import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { t } from 'i18next';
import { Formik } from 'formik';
import moment from 'moment';
import { isEmpty, findIndex, includes, trim, isArray, isObject, isString, split } from 'lodash';
import { Button, Card, Form, OverlayTrigger, Popover, Row, Stack, Table } from 'react-bootstrap';
import { HiPlus, HiOutlinePencilAlt, HiOutlineTrash, HiDownload, HiInformationCircle } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { DefaultEditor } from 'react-simple-wysiwyg';
import Loader from '../SplashScreen/loader';
import DrugAdminOrders from './drug-admin-orders';
import SubFormModal from './subform-modal';
import RequiredAstric from './required-astric';
import { callGetAPI } from '../../services/axios';
import { getSubFormList } from '../../redux/forms';
import { resetSubFormErrors } from '../../redux/forms/slice';
import { generateYupSchema, getAge, getDefaultFormID, getFormattedDate, getFormattedDateTime, getMedicine } from '../../utils/helper';
import useQuery from '../../utils/useQuery';
function handleAadharCard(e, setFieldValue) {
	e.preventDefault();
	const aadharnumber = e.currentTarget.value.replace(/\D+/g, '').match(/.{1,4}/g);
	setFieldValue(e.currentTarget.name, aadharnumber ? aadharnumber.join(' ') : '');
}
function DynamicForm({ initialValues, fields, handleCancel, handleSubmit, parentformuniqueid, isPatientForm, isClinicalDischargeForm, viewMode, handoverUsersData, handleUpdateSubFormList, ReInitialize, defaultFormId, loading, action }) {
	const [modalShow, setModalShow] = useState(false);
	const [subFormField, setSubFormField] = useState({});
	const [subFormAction, setSubFormAction] = useState('');
	const [hiddenFields, setHiddenFields] = useState(() => {
		let hiddArr = [];
		fields.map((field) => {
			let rule = field.field_rule;
			let fieldrule = rule && rule.split('|');
			let defaultValue = field?.default_values;
			let currentValue = initialValues[field.field_name] || defaultValue;
			if (fieldrule && includes(fieldrule, 'med_freq_type') && fieldrule.length > 2 && fieldrule[2] != currentValue) {
				let hfields = fieldrule[1].split(',');
				hiddArr = [...hiddArr, ...hfields];
			}
		});
		return hiddArr;
	});
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { currentPatient } = useSelector(state => state.hospital);
	const { currentPatientDetail } = useSelector(state => state.dischargeClinical);
	const { patientId } = useQuery();
	const { subFormList, subFormLoading } = useSelector(state => state.forms);
	let submitBtnDisabled = true;
	const getFormData = values => {
		submitBtnDisabled = true;
		if ((JSON.stringify(values) !== JSON.stringify(initialValues)) || (includes([getDefaultFormID('Handover Form MO'), getDefaultFormID('Handover Form Nurse')], defaultFormId) && viewMode) || isPatientForm) {
			submitBtnDisabled = false;
		}
	};
	useEffect(() => {
		// commented bcz after last subform record deleted subForm List will be empty
		// if (!isEmpty(subFormList)) {
		let fieldindex = findIndex(fields, { 'field_name': subFormField.field_name });
		if (fieldindex > -1) {
			handleUpdateSubFormList(subFormList, fieldindex);
		}
		// }
		setSubFormField({});
	}, [subFormList]);
	const openSubFormModal = (field, action, subfromdata = {}, index = -1) => {
		if (index > -1) {
			setSubFormField(subfromdata);
		}
		else {
			setSubFormField(field);
		}
		setSubFormAction(action);
		setModalShow(true);
	};
	const closeSubFormModal = (field) => {
		// Getting modal form blank so bellow line put as in comment.
		// dispatch(resetSubForm());
		dispatch(resetSubFormErrors());
		if (field.subform?.id || field?.id) {
			dispatch(getSubFormList({ form_id: field.subform?.id || field?.form_id, hospital_id: currentHospital.value, form_unique_id: parentformuniqueid, sub_form_unique_id: field.sub_form_unique_id, field_name: field.field_name }));
		}
		setModalShow(false);
	};

	const fetchOptions = (searchValue, callback, field) => {
		let fieldrule = field?.field_rule?.split('|') || [];
		let filterOptions = [];
		return callGetAPI({
			route: 'hospital/form/autocomplete/field',
			params: { hospital_id: currentHospital.value, field_name: field.field_name, search: searchValue, p_id: patientId || currentPatientDetail?.patient_info?.panel_title?.id || currentPatient?.p_id }
		}).then((data) => {
			filterOptions = data?.data?.data.map((option) => {
				return { value: (fieldrule && includes(fieldrule, 'medicine')) ? option.split('|')[0].trim() : option.trim(), label: option };
			});
			return filterOptions;
		});
		callback(filterOptions);
	};

	const renderSubFormTbodyTableData = (subformfield) => {
		if (subformfield['type'] === 'file') {
			return (
				<td key={subformfield['field_name']}>
					{subformfield['file_url'] && (
						<a
							title={t('GENERAL.DOWNLOAD')}
							className='btn btn-sm btn-success'
							href={subformfield['file_url']}
							target='_blank'
							rel="noreferrer"
							download
						>
							<HiDownload />
						</a>
					)}
				</td>
			);
		}

		if (subformfield['type'] === 'datetime') {
			return (
				<td key={subformfield['field_name']}>
					{!isEmpty(subformfield['value']) && getFormattedDateTime(subformfield['value'])}
				</td>
			);
		}

		if (subformfield['type'] === 'date') {
			return (
				<td key={subformfield['field_name']}>
					{!isEmpty(subformfield['value']) && getFormattedDate(subformfield['value'])}
				</td>
			);
		}

		if (!includes(['separator', 'subform', 'formdata', 'regno'], subformfield['type'])) {
			return <td key={subformfield['field_name']}>{subformfield['value']}</td>;
		}

		return null;
	};
	return (
		<Formik enableReinitialize={ReInitialize ? true : false} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={generateYupSchema(fields)}>
			{(formikProps) => {
				getFormData(formikProps.values);
				return (
					<Form noValidate onSubmit={formikProps.handleSubmit}>
						<fieldset disabled={viewMode}>
							{action == 'add' && <Form.Group className="mb-3" controlId='formGridDateTime'>
								<Form.Label>
									{t('GENERAL.DATETIME')} <RequiredAstric />
								</Form.Label>
								<Form.Control
									required
									type="datetime-local"
									name='date_time'
									value={formikProps.values['date_time']}
									min={moment(currentPatient?.date_of_admission).format(moment.HTML5_FMT.DATETIME_LOCAL)}
									max={moment(currentPatient?.current_date).format(moment.HTML5_FMT.DATETIME_LOCAL)}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur} />
							</Form.Group>}
							{fields &&
								fields.map((field, index) => {
									let rule = field.field_rule;
									let fieldrule = rule && rule.split('|');
									let defaultValue = {};
									if (field.type == 'select' && formikProps.values[field.field_name] && (includes(fieldrule, 'att_consultant_drp') || includes(fieldrule, 'ref_consultant_drp'))) {
										let element = {};
										if (field.possible_values != '*') {
											if (!isObject(formikProps.values[field.field_name])) {
												element = field.consultants_drp.find(item => item.id == formikProps.values[field.field_name]);
												defaultValue = { value: element?.id, label: element?.first_name + ' ' + element?.last_name };
											}
										}
										else {
											if (isString(formikProps.values[field.field_name])) {
												let ids = split(formikProps.values[field.field_name], ',');
												element = field.consultants_drp.filter(item => includes(ids, item.id.toString()));
												defaultValue = element.map((ele) => {
													return { value: ele.id, label: ele.first_name + ' ' + ele.last_name };
												});
											}
											else if (!isObject(formikProps.values[field.field_name])) {
												let ids = split(formikProps.values[field.field_name], ',');
												ids = ids.map(id => parseInt(id));
												element = field.consultants_drp.filter(item => includes(ids, item.id.toString()));
												defaultValue = element.map((ele) => {
													return { value: ele.id, label: ele.first_name + ' ' + ele.last_name };
												});
											}
											else if (isArray(formikProps.values[field.field_name])) {
												let ids = formikProps.values[field.field_name];
												element = field.consultants_drp.filter(item => includes(ids, item.id.toString()));
												defaultValue = element.map((ele) => {
													return { value: ele.id, label: ele.first_name + ' ' + ele.last_name };
												});
											}
										}
									}
									else if (field.type == 'select' && formikProps.values[field.field_name] && (!includes(fieldrule, 'att_consultant_drp') && !includes(fieldrule, 'ref_consultant_drp'))) {
										if (field.possible_values != '*') {
											if (!isObject(formikProps.values[field.field_name])) {
												defaultValue = { value: formikProps.values[field.field_name], label: formikProps.values[field.field_name] };
											}
										}
									}
									return (
										<div key={index}>
											{field.type == 'label' && !includes(hiddenFields, field.field_name) &&
												<h5>{field.title}</h5>
											}
											{field.type == 'note' && !includes(hiddenFields, field.field_name) &&
												<span>{field.value || field.default_values}</span>
											}
											{field.type == 'separator' && !includes(hiddenFields, field.field_name) &&
												<hr />
											}
											{field.type == 'text' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													{field.autocomplete == '0' ?
														<Form.Control
															type={includes(fieldrule, 'email') ? 'email' : field.type}
															placeholder={field.placeholder}
															name={field.field_name}
															defaultValue={formikProps.values.default_values}
															value={formikProps.values[field.field_name]}
															onChange={
																includes(fieldrule, 'aadhar')
																	? (e) => handleAadharCard(e, formikProps.setFieldValue)
																	: formikProps.handleChange
															}
															onBlur={formikProps.handleBlur}
															required={field.required ? true : false}
															pattern={
																includes(fieldrule, 'aadhar') ? '[0-9]{4} [0-9]{4} [0-9]{4}' : undefined
															}
															title={includes(fieldrule, 'aadhar') ? field.sub_title : undefined}
															maxLength={includes(fieldrule, 'aadhar') ? 14 : undefined}
															isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														/> :
														<AsyncCreatableSelect
															isClearable={true}
															isDisabled={viewMode}
															cacheOptions
															required={field.required ? true : undefined}
															name={field.field_name}
															//defaultValue={{ value: formikProps.values.default_values, label: formikProps.values.default_values }}
															value={field.possible_values != '*' ?
																{ value: formikProps.values[field.field_name], label: formikProps.values[field.field_name] } :
																isArray(formikProps.values[field.field_name]) ? (formikProps.values[field.field_name].map((dValue) => {
																	return { value: dValue, label: dValue };
																})) : (formikProps.values[field.field_name].split(',').map(dValue => {
																	return !isEmpty(dValue) ? { value: dValue, label: dValue } : null;
																}))
															}
															loadOptions={(searchValue, callback) => fetchOptions(searchValue, callback, field)}
															isMulti={field.possible_values == '*'}
															onChange={(e) => {
																if (fieldrule && includes(fieldrule, 'medicine') && !isEmpty(e)) {
																	let medicineArr = e.value ? getMedicine(e.label) : '';
																	let otherFields = field.possible_values && field.possible_values.split('|');
																	otherFields.map((field, findex) => {
																		formikProps.setFieldValue(field, medicineArr[findex + 1]);
																	});
																}
																formikProps.setFieldValue(
																	field.field_name,
																	field.possible_values == '*'
																		? (e).map((item) => item.value)
																		: (isObject(e) ? (e?.value) : e || '')
																);
															}}
														/>
													}
													{formikProps.errors[field.field_name] && formikProps.touched[field.field_name] &&
														<span className='text-danger'>
															{formikProps.errors[field.field_name]}
														</span>}
												</Form.Group>
											}
											{field.type == 'select' && (includes(fieldrule, 'att_consultant_drp') || includes(fieldrule, 'ref_consultant_drp')) && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Select
														isClearable={true}
														isDisabled={viewMode}
														type={field.type}
														isMulti={field.possible_values == '*'}
														placeholder={field.placeholder}
														name={field.field_name}
														onBlur={formikProps.handleBlur}
														required={field.required ? true : false}
														isSearchable
														defaultValue={isEmpty(defaultValue) ? null : defaultValue}
														options={
															field.consultants_drp?.length &&
															field.consultants_drp.map((consultant) => {
																return { label: consultant.first_name + ' ' + consultant.last_name, value: consultant.id };
															})}
														onChange={(e) => {
															formikProps.setFieldValue(
																field.field_name, e
															);
														}}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
													/>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'select' && includes(fieldrule, 'split_pipe') && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<AsyncSelect
														isClearable={true}
														isDisabled={viewMode}
														cacheOptions
														required={field.required ? true : undefined}
														name={field.field_name}
														value={field.possible_values != '*' ?
															{ value: formikProps.values[field.field_name], label: formikProps.values[field.field_name] } :
															isArray(formikProps.values[field.field_name]) ? (formikProps.values[field.field_name].map((dValue) => {
																return { value: dValue, label: dValue };
															})) : (formikProps.values[field.field_name].split('|').map(dValue => {
																return !isEmpty(dValue) ? { value: dValue, label: dValue } : null;
															}))
														}
														loadOptions={(searchValue, callback) => fetchOptions(searchValue, callback, field)}
														isMulti={field.possible_values == '*'}
														onChange={(e) => {
															if (fieldrule && includes(fieldrule, 'medicine') && !isEmpty(e)) {
																let medicineArr = e.value ? getMedicine(e.label) : '';
																let otherFields = field.possible_values && field.possible_values.split('|');
																otherFields.map((field, findex) => {
																	formikProps.setFieldValue(field, medicineArr[findex + 1]);
																});
															}
															formikProps.setFieldValue(
																field.field_name,
																field.possible_values == '*'
																	? (e).map((item) => item.value)
																	: (isObject(e) ? (e?.value) : e || '')
															);
														}}
													/>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'select' && (!includes(fieldrule, 'att_consultant_drp') && !includes(fieldrule, 'ref_consultant_drp') && !includes(fieldrule, 'split_pipe')) && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Select
														isClearable={true}
														isDisabled={viewMode}
														type={field.type}
														isMulti={field.possible_values == '*'}
														placeholder={field.placeholder}
														name={field.field_name}
														onBlur={formikProps.handleBlur}
														required={field.required ? true : false}
														isSearchable
														defaultValue={isEmpty(defaultValue) ? null : defaultValue}
														options={
															field.possible_values.split(',')?.length &&
															field.possible_values.split(',').map((val) => {
																return { label: val, value: val };
															})}
														onChange={(e) => {
															formikProps.setFieldValue(field.field_name, e);
														}}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
													/>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'number' && !includes(fieldrule, 'mobile') && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type={field.type}
														placeholder={field.placeholder}
														name={field.field_name}
														value={formikProps.values[field.field_name]}
														defaultValue={formikProps.values.default_values}
														onChange={(e) => {
															if (includes(fieldrule, 'age')) {
																const value = e.target.value;
																if (/^\d*$/.test(value)) {
																	formikProps.handleChange(e);
																}
															} else {
																formikProps.handleChange(e);
															}
														}}
														onBlur={formikProps.handleBlur}
														required={field.required ? true : false}
														// readOnly={(includes(fieldrule, 'age') ? true : false)}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
													/>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'number' && includes(fieldrule, 'mobile') && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type='tel'
														placeholder={field.placeholder}
														name={field.field_name}
														value={formikProps.values[field.field_name]}
														defaultValue={formikProps.values.default_values}
														onChange={(e) => {
															formikProps.setFieldValue(field.field_name, e.target.value.replace(/[^0-9.]/g, ''));
														}}
														onBlur={formikProps.handleBlur}
														required={field.required ? true : false}
														minLength='10'
														maxLength='10'
														pattern='[6-9][0-9]{9}'
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
													/>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'date' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type={field.type}
														placeholder={field.placeholder}
														name={field.field_name}
														value={formikProps.values[field.field_name]}
														defaultValue={formikProps.values.default_values}
														{...((fieldrule && fieldrule[0] == 'dob') && { 'max': moment().format('YYYY-MM-DD') })}
														onChange={(e) => {
															if (fieldrule && fieldrule[0] === 'dob') {
																let age = e.target.value ? getAge(e.target.value) : 0;
																formikProps.setFieldValue(fieldrule[1], age);
															}
															formikProps.setFieldValue(field.field_name, e.target.value);
														}}
														onBlur={formikProps.handleBlur}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														required={field.required ? true : false} />
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'time' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type={field.type}
														placeholder={field.placeholder}
														name={field.field_name}
														value={formikProps.values[field.field_name]}
														defaultValue={formikProps.values.default_values}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														required={field.required ? true : false} />
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'datetime' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type="datetime-local"
														placeholder={field.placeholder}
														name={field.field_name}
														value={formikProps.values[field.field_name]}
														defaultValue={formikProps.values.default_values}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														required={field.required ? true : false} />
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'file' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<Form.Control
														type={field.type}
														placeholder={field.placeholder}
														name={field.field_name}
														onChange={(event) => {
															formikProps.setFieldValue(field.field_name, event.currentTarget.files[0]);
														}}
														onBlur={formikProps.handleBlur}
														accept={includes(fieldrule, 'accept_files') ? field.possible_values : undefined}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														required={field.required ? true : undefined} />
													<Form.Text className="text-muted">
														{field.value}
													</Form.Text>
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'textarea' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													{field.theme_type == 0 ?
														<Form.Control
															as="textarea"
															rows={3}
															placeholder={field.placeholder}
															name={field.field_name}
															value={formikProps.values[field.field_name]}
															defaultValue={formikProps.values.default_values}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
															required={(field.required ? 'required' : null)} />
														: <DefaultEditor
															disabled={viewMode}
															required={(field.required ? 'required' : null)}
															name={field.field_name}
															id={field.field_name}
															onBlur={formikProps.handleBlur}
															defaultValue={formikProps.values.default_values}
															value={formikProps.values[field.field_name]}
															onChange={formikProps.handleChange} />}
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'radio' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="form-group mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<div className="mb-3">
														{field.possible_values.split(',').map((option, radioIndex) => {
															return (
																<Form.Check key={index + '_' + radioIndex}
																	inline
																	checked={(isEmpty(formikProps.values[field.field_name]) && !isEmpty(field?.default_values)) ? field?.default_values === option : formikProps.values[field.field_name] === option}
																	label={option}
																	value={option}
																	name={field.field_name}
																	type='radio'
																	onChange={(e) => {
																		formikProps.setFieldValue(field.field_name, e.target.value);
																		if (!fieldrule) {
																			return;
																		}
																		if (includes(fieldrule, 'med_freq_type') && fieldrule.length > 2 && fieldrule[2] != e.target.value) {
																			let tmpfields = fieldrule[1].split(',');
																			tmpfields.map((tfield) => formikProps.setFieldValue(tfield, ''));
																			setHiddenFields(fieldrule[1].split(','));
																		}
																		else {
																			setHiddenFields([]);
																		}
																	}}
																	id={`radio-inline-${field.field_name}-${radioIndex}`}
																	isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
																/>
															);
														})
														}
													</div>
													{formikProps.errors[field.field_name] && formikProps.touched[field.field_name] && <span className='text-danger'>
														{formikProps.errors[field.field_name]}
													</span>}
												</Form.Group>
											}
											{field.type == 'checkbox' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="form-group mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														{trim(field.sub_title) && ` (${field.sub_title})`}
													</Form.Label>
													<div className="mb-3">
														{field.possible_values.split(',').map((option, checkBoxIndex) => {
															return (
																<Form.Check key={index + '_' + checkBoxIndex}
																	inline
																	checked={includes(formikProps.values[field.field_name], option)}
																	label={option}
																	value={option}
																	name={field.field_name}
																	type='checkbox'
																	onChange={formikProps.handleChange}
																	isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
																	id={`checkbox-inline-${field.field_name}-${checkBoxIndex}`}
																/>
															);
														})
														}
													</div>
													{formikProps.errors[field.field_name] && formikProps.touched[field.field_name] && <span className='text-danger'>
														{formikProps.errors[field.field_name]}
													</span>}
												</Form.Group>
											}
											{field.type == 'lab-report-values' && !includes(hiddenFields, field.field_name) &&
												<Form.Group key={index} className="mb-3" controlId={`formGrid${field.title?.replace(/ /g, '')}`}>
													<Form.Label>
														{field.title}{field.required ? <RequiredAstric /> : null}
														<OverlayTrigger
															key={`formGrid${field.title?.replace(/ /g, '')}`}
															placement='right'
															overlay={
																<Popover id={`popover-${`formGrid${field.title?.replace(/ /g, '')}`}`}>
																	<Popover.Header as="h3">{t('GENERAL.LABREPORTVALUE')}</Popover.Header>
																	<Popover.Body dangerouslySetInnerHTML={{ __html: field.sub_title }}>
																	</Popover.Body>
																</Popover>
															}
														>
															<Button title={t('GENERAL.LABREPORTVALUE')} variant="link" style={{ padding: '0px 0px 5px 5px' }}><HiInformationCircle /></Button>
														</OverlayTrigger>
													</Form.Label>
													<Form.Control
														type={field.type}
														placeholder={field.placeholder}
														name={field.field_name}
														defaultValue={formikProps.values.default_values}
														value={formikProps.values[field.field_name]}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														isInvalid={formikProps.errors[field.field_name] && formikProps.touched[field.field_name]}
														required={field.required ? true : false} />
													<Form.Control.Feedback type="invalid">
														{formikProps.errors[field.field_name]}
													</Form.Control.Feedback>
												</Form.Group>
											}
											{field.type == 'subform' && !includes(hiddenFields, field.field_name) && defaultFormId != getDefaultFormID('Drug Administration Orders(Nurse)') &&
												<Card className='mb-3' text='dark' bg='white'>
													<Card.Header className='text-uppercase d-flex justify-content-between'>
														<span>{field.title}</span>
														{!viewMode && field.field_rule != 'hospitalization_data' && <Button title={t('GENERAL.ADD')} size={'sm'} onClick={() => openSubFormModal(field, 'add')} >
															<HiPlus />
														</Button>}
													</Card.Header>
													<Card.Body>
														{subFormLoading && (subFormField.field_name == field.field_name) &&
															<Row className='d-flex justify-content-center mt-4'>
																<Loader />
															</Row>
														}
														{!(subFormLoading && subFormField.field_name == field.field_name) && <Table bordered responsive hover size="sm">
															<thead>
																<tr>
																	{/* <th>#</th> */}
																	{field.subform?.form_fields.map((subfromfield, index) => {
																		if (!includes(['separator', 'subform', 'formdata', 'regno'], subfromfield.type))
																			return (
																				<th key={index}>{subfromfield.title} <span dangerouslySetInnerHTML={{ __html: subfromfield?.sub_title?.trim() ? `<br>(${subfromfield.sub_title})` : '' }}></span></th>
																			);
																	})}
																	{!viewMode && field.field_rule != 'hospitalization_data' && <th>{t('GENERAL.ACTION')}</th>}
																</tr>
															</thead>
															<tbody>
																{!isEmpty(field?.value) && field.value.map((subfromdata, index) => {
																	return (
																		<tr key={index}>
																			{/* <td>{subfromdata.id}</td> */}
																			{subfromdata?.subform_data && subfromdata?.subform_data.map((subformfield) => {
																				return renderSubFormTbodyTableData(subformfield);
																			})}
																			{!viewMode && field.field_rule != 'hospitalization_data' && <td>
																				<Stack direction="horizontal" gap={2}>
																					<Button title={t('GENERAL.EDIT')} size='sm' variant="primary" onClick={() => openSubFormModal(field, 'edit', subfromdata, index)}>
																						<HiOutlinePencilAlt />
																					</Button>
																					<Button title={t('GENERAL.DELETE')} size='sm' variant="danger" onClick={() => openSubFormModal(field, 'delete', subfromdata, index, 'delete')}>
																						<HiOutlineTrash />
																					</Button>
																				</Stack>
																			</td>}
																		</tr>
																	);
																})}
															</tbody>
														</Table>}
													</Card.Body>
												</Card>
											}
											{field.type == 'subform' && !includes(hiddenFields, field.field_name) && defaultFormId == getDefaultFormID('Drug Administration Orders(Nurse)') &&
												<Card className='mb-3' text='dark' bg='white'>
													<Card.Header className='text-uppercase d-flex justify-content-between'>
														{field.title}
													</Card.Header>
													<Card.Body>
														{subFormLoading && (subFormField.field_name == field.field_name) &&
															<Row className='d-flex justify-content-center mt-4'>
																<Loader />
															</Row>
														}
														{!(subFormLoading && subFormField.field_name == field.field_name) &&
															<DrugAdminOrders field={field} onHide={(drugfield) => closeSubFormModal(drugfield)
															} />
														}
													</Card.Body>
												</Card>
											}
											{/* {field.type == 'formdata' &&
												<h6>{field.title} <span className='text-primary'>(Form Data : WIP)</span></h6>
											} */}
											{/* {field.type == 'regno' &&
												<h6>{field.title} <span className='text-info'>(Regno : WIP)</span></h6>
											} */}
										</div>
									);
								})
							}
						</fieldset>
						{fields && handoverUsersData &&
							<Form.Group controlId="formGridHandover">
								<Form.Label>{t('HANDOVER.HANDOVER_GIVEN_TO')}</Form.Label>
								<Form.Select
									disabled={viewMode}
									placeholder={t('HANDOVER.HANDOVER_GIVEN_TO')}
									aria-label={t('HANDOVER.HANDOVER_GIVEN_TO')}
									name="given_to" id="given_to"
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									value={formikProps.values['given_to']}>
									<option value={''}>{t('HANDOVER.HANDOVER_SELECT')}</option>
									{!isEmpty(handoverUsersData) && handoverUsersData.map((val, key) => {
										return <option key={key} value={val.id}>{val.first_name} {val.last_name} ({val.mobile_number})</option>;

									})}
								</Form.Select>
							</Form.Group>
						}
						{(!viewMode || handoverUsersData) && defaultFormId != getDefaultFormID('Drug Administration Orders(Nurse)') && <hr />}
						{fields && defaultFormId != getDefaultFormID('Drug Administration Orders(Nurse)') && (!viewMode || handoverUsersData) && <Stack direction='horizontal' gap={2}>
							<Button title={handoverUsersData && viewMode ? t('HANDOVER.SIGN_IN') : t('GENERAL.SUBMIT')} variant="primary" type="submit" disabled={loading ? loading : submitBtnDisabled} >
								{handoverUsersData && viewMode ? t('HANDOVER.SIGN_IN') : (defaultFormId != getDefaultFormID('Investigation & Treatment Orders - MO') ? t('GENERAL.SUBMIT') : t('GENERAL.SAVESEND'))}
							</Button>
							<Button title={t('GENERAL.CANCEL')} variant="danger" onClick={handleCancel}>{t('GENERAL.CANCEL')}</Button>
						</Stack>}
						{subFormField && <SubFormModal
							show={modalShow}
							field={subFormField}
							parentformuniqueid={parentformuniqueid}
							onHide={() => closeSubFormModal(subFormField)}
							isPatientForm={isPatientForm}
							isClinicalDischargeForm={isClinicalDischargeForm}
							action={subFormAction}
						/>}
					</Form>
				);
			}
			}
		</Formik >
	);
}
DynamicForm.propTypes = {
	isPatientForm: bool,
	isClinicalDischargeForm : bool,
	viewMode: bool
};
DynamicForm.defaultProps = {
	isPatientForm: false,
	isClinicalDischargeForm : false,
	viewMode: false,
};
export default DynamicForm;
import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import DataTable from 'react-data-table-component';
import { Alert, Button, Card, Col, Container, Form, Image, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';
import { HiPencilAlt, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty, upperFirst, has } from 'lodash';
import { Formik } from 'formik';
import { GiCombinationLock } from 'react-icons/gi';
import PasswordInput from '../../components/Common/password-input';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import Loader from '../../components/SplashScreen/loader';
import { getUsers, deleteUser, adminResetPassword, getModulePermission, updatePermissions } from '../../redux/users';
import { reset } from '../../redux/users/slice';
import { resetPasswordValidationSchema } from '../../utils/validation';
import useDebounce from '../../utils/useDebounceHook';
import { UserPlaceholder } from '../../images';

const Users = () => {
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [id, setId] = useState(null);
	const [deleteRecord, setDeleteRecord] = useState(null);
	const [resetPasswordRecord, setResetPasswordRecord] = useState(null);
	const [displayResetPasswordModal, setDisplayResetPasswordModal] = useState(false);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [columnSort, setColumnSort] = useState({});
	const [filterText, setFilterText] = useState('');
	const [key, setKey] = useState('users');
	const [activeRole, setActiveRole] = useState('doctor');
	const [permissions, setPermissions] = useState([]);
	const [permissionsPayload, setPermissionsPayload] = useState([]);
	const dispatch = useDispatch();
	const { currentHospital } = useSelector(state => state.auth);
	const { Loading, userData, isUserDeletedSuccess, isUserDeleteErrorMessage, isResetSuccess, resetErrorMessage, modulePermission, updatePermissionsResponse, updatePermissionsError } = useSelector(state => state.users);
	const { roles } = useSelector(state => state.hospital);
	const debouncedSearchTerm = useDebounce(filterText, 500);
	const initialValues = {
		'password': '',
		'password_confirmation': ''
	};

	useEffect(() => {
		return () => {
			dispatch(reset());
		};
	}, []);

	useEffect(() => {
		if (!isEmpty(activeRole)) {
			setSuccessMessage('');
			setErrorMessage('');
			dispatch(getModulePermission({ hospital_id: currentHospital.value, role: activeRole }));
		}
	}, [activeRole]);

	useEffect(() => {
		if (!isEmpty(modulePermission)) {
			setPermissions(getUniquePermissions(modulePermission));
			const payload = modulePermission.reduce((acc, item) => {
				acc[item.key] = item.permissions;
				return acc;
			}, {});
			setPermissionsPayload(payload);
		}
	}, [modulePermission]);

	useEffect(() => {
		if (!isEmpty(userData)) {
			setTotalRows(userData.total);
			setPerPage(userData.per_page);
			setLoading(false);
		}
	}, [userData]);

	useEffect(() => {
		if (!isEmpty(updatePermissionsResponse)) {
			setSuccessMessage(updatePermissionsResponse?.message);
		}
		if (!isEmpty(updatePermissionsError)) {
			setErrorMessage(updatePermissionsError);
		}
	}, [updatePermissionsResponse, updatePermissionsError]);
	useEffect(() => {
		if (isUserDeletedSuccess) {
			setSuccessMessage(`The '${deleteRecord.first_name}' user was deleted successfully.`);
			setDisplayConfirmationModal(false);
			dispatch(getUsers({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (isUserDeleteErrorMessage) {
			setErrorMessage(isUserDeleteErrorMessage?.payload?.message);
			setDisplayConfirmationModal(false);
			dispatch(getUsers({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		if (isResetSuccess) {
			hideResetPasswordModal();
		}
	}, [isUserDeletedSuccess, isUserDeleteErrorMessage, isResetSuccess]);

	useEffect(() => {
		setLoading(true);
		if (!isEmpty(currentHospital) && debouncedSearchTerm == '') {
			dispatch(getUsers({ page: 1, per_page: perPage, hospital_id: currentHospital.value, ...columnSort }));
		}
		else if (!isEmpty(currentHospital) && debouncedSearchTerm !== '') {
			dispatch(getUsers({ page: 1, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	}, [currentHospital, debouncedSearchTerm, columnSort]);

	const handleResetPassword = (values, { resetForm }) => {
		dispatch(adminResetPassword({ user_id: resetPasswordRecord?.id, hospital_id: currentHospital.value, ...values }));
		resetForm();
	};

	const actions = (
		<Link title={t('USERS.BUTTON.ADDUSER')} className='btn btn-success btn-sm' to='add'>
			<HiPlus /> {t('USERS.BUTTON.ADDUSER')}
		</Link>
	);

	const userAction = (row) => {
		if (currentHospital.is_admin !== 'yes') {
			return null;
		}
		return (
			<>
				<Col className='col-4'>
					<Button title={t('GENERAL.DELETE')} variant="danger" size="sm" onClick={() => showDeleteModal(row)}>
						<FaRegTrashAlt />
					</Button>
				</Col>
				<Col className='col-4'>
					<Button title={t('GENERAL.EDIT')} as={Link} to={`edit/${row.id}`} variant="warning" size="sm" className='text-white'>
						<HiPencilAlt />
					</Button>
				</Col>
				<Col className='col-4'>
					<Button title={t('GENERAL.CHNGPASS')} variant="primary" size="sm" onClick={() => showResetPasswordModal(row)}>
						<GiCombinationLock />
					</Button>
				</Col>
			</>
		);
	};

	const columns = [
		{
			name: t('USERS.COLUMNS.PROFILE'),
			selector: row => row.profile_picture,
			width: '80px',
			cell: row => <Image roundedCircle height='35px' width='35px' alt={row.first_name} src={row.profile_picture || UserPlaceholder} />,
		},
		{
			name: t('USERS.COLUMNS.FIRSTNAME'),
			selector: row => row.first_name,
			sortable: true,
			sortField: 'first_name',
		},
		{
			name: t('USERS.COLUMNS.LASTNAME'),
			selector: row => row.last_name,
			sortable: true,
			sortField: 'last_name',
		},
		{
			name: t('USERS.COLUMNS.USERNAME'),
			selector: row => row.username,
			sortable: true,
			sortField: 'username',
			grow: '1'
		},
		{
			name: t('USERS.COLUMNS.EMAIL'),
			grow: '2',
			selector: row => row.email,
			sortable: true,
			sortField: 'email',
		},
		{
			name: t('USERS.COLUMNS.MOBILE'),
			selector: row => row.mobile_number,
			sortable: true,
			sortField: 'mobile_number',
		},
		{
			name: t('USERS.COLUMNS.ROLE'),
			selector: row => upperFirst(row.role_name),
		},
		{
			name: t('USERS.COLUMNS.STATUS'),
			selector: row => upperFirst(row.status),
		},
		{
			name: t('GENERAL.ACTION'),
			cell: row => userAction(row),
		},

	];
	const columnsForms = [
		{
			name: t('FORMS.COLUMNS.ROLENAME'),
			selector: row => row.title,
		},
		{
			name: t('GENERAL.ACTION'),
			cell: row => (
				<Button title={t('FORMS.FORM_PERMISSION')} as={Link} size='sm' to={'/users/assign/' + row.key} state={{ role: row.title }}>
					{t('FORMS.FORM_PERMISSION')}
				</Button>
			),
		},
	];
	const handlePageChange = page => {
		if (!isEmpty(currentHospital)) {
			dispatch(getUsers({ page: page, per_page: perPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};
	const handlePerRowsChange = async(newPerPage, page) => {
		if (!isEmpty(currentHospital)) {
			dispatch(getUsers({ page: page, per_page: newPerPage, hospital_id: currentHospital.value, search: debouncedSearchTerm, ...columnSort }));
		}
	};

	const showDeleteModal = (data) => {
		setId(data.id);
		setDeleteRecord(data);
		setSuccessMessage(null);
		setDeleteMessage(`Are you sure you want to delete the record '${data.first_name}'?`);
		setDisplayConfirmationModal(true);
	};

	const showResetPasswordModal = (data) => {
		setResetPasswordRecord(data);
		setDisplayResetPasswordModal(true);
	};
	// Hide the modal
	const hideResetPasswordModal = () => {
		setResetPasswordRecord({});
		setDisplayResetPasswordModal(false);
	};
	// Hide the modal
	const hideConfirmationModal = () => {
		setDisplayConfirmationModal(false);
	};

	// Handle the actual deletion of the item
	const submitDelete = (deleteRecord) => {
		dispatch(deleteUser({ user_id: deleteRecord.id, hospital_id: currentHospital.value }));
	};

	const handleSort = async(column, sortDirection) => {
		setColumnSort({ column_name: column.sortField, order: sortDirection });
	};
	const getRoleData = () => {
		let rolArr = [];
		Object.entries(roles).map(([key, val], index) => {
			let roleObj = { id: index + 1, key: key, title: val };
			if (key != 'admin') {
				rolArr[index] = roleObj;
			}
		});
		return rolArr;
	};
	const subHeaderComponentMemo = useMemo(() => {
		return (
			<Form.Group as={Row} controlId="formGridSearchText">
				<Form.Control
					type="text"
					placeholder={t('GENERAL.SEARCHTEXT')}
					name="search_text"
					value={filterText}
					onChange={(e) => {
						setFilterText(e.target.value);
					}} />
			</Form.Group>
		);
	}, [filterText]);

	const savePermission = (role) => {
		setErrorMessage('');
		setSuccessMessage('');
		dispatch(updatePermissions({ hospital_id: currentHospital.value, role, permissions : permissionsPayload }));
	};
	const getUniquePermissions = (arr) => {
		let permissions = new Set();
		arr && arr.forEach(item => {
			if (item?.permissions) {
				Object.keys(item.permissions).forEach(permission => {
					permissions.add(permission);
				});
			}
		});
		return Array.from(permissions);
	};
	return (
		<Container className='mt-4 p-0'>
			{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
			{successMessage && <Alert variant="success">{successMessage}</Alert>}
			<div className='border border-2 border-top-0 shadow'>
				<Tabs
					activeKey={key}
					onSelect={(k) => setKey(k)}
					className="mb-3">
					<Tab eventKey="users" title={t('USERS.TITLE')}>
						<DataTable
							title={t('USERS.TITLE')}
							actions={actions}
							columns={columns}
							data={userData.data}
							progressPending={loading}
							progressComponent={<Loader />}
							highlightOnHover
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							subHeader
							subHeaderComponent={subHeaderComponentMemo}
							onSort={handleSort}
							sortServer
						/>
					</Tab>
					<Tab eventKey="roles" title={t('FORMS.FORM_PERMISSION')}>
						<DataTable
							title={t('FORMS.ROLE')}
							columns={columnsForms}
							data={getRoleData()}
							highlightOnHover
						/>
					</Tab>
					<Tab eventKey="module_permission" title={t('FORMS.ASSIGN_PERMISSION')}>
						<Card className='m-3'>
							<Card.Body>
								<Tabs activeKey={activeRole} onSelect={(k) => setActiveRole(k)} id="tabs-roles-permission">
									{getRoleData().map((role, index) => {
										return (
											<Tab key={index} eventKey={role.key} title={role.title}>
												<Container className='p-3'>
													<Table bordered responsive striped hover className='text-center'>
														<thead>
															<tr>
																<th className='text-start'>{t('FORMS.MODULE')}</th>
																<th colSpan={permissions.length}>{t('FORMS.PERMISSIONS')}</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td></td>
																{permissions.map((permission, permissionIndex) =>
																	<td key={permissionIndex} className='text-capitalize'>{permission}</td>
																)}
															</tr>
															{modulePermission.map((module, moduleIndex) =>
																<tr key={moduleIndex}>
																	<td className='text-start'>{module.name}</td>
																	{permissions.map((permission, permissionIndex) =>
																		<td key={permissionIndex} >
																			{has(permissionsPayload, module.key) && has(permissionsPayload[module.key], permission) && <Form.Check
																				checked={permissionsPayload[module.key][permission]}
																				onChange={(event) => {
																					setPermissionsPayload(prevState => ({
																						...prevState,
																						[module.key]: {
																							...prevState[module.key],
																							[permission]: event.target.checked
																						}
																					}));
																				}}
																			/>}
																		</td>
																	)}
																</tr>
															)}
														</tbody>
													</Table>
													<Button title={t('GENERAL.SUBMIT')} disabled={Loading} variant="success" onClick={() => savePermission(role.key)}>
														{Loading && <Loader variant='light' size="sm" />} {t('GENERAL.SUBMIT')}
													</Button>
												</Container>
											</Tab>
										);
									})}
								</Tabs>
							</Card.Body>
						</Card>
					</Tab>
				</Tabs>
			</div>
			<DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} id={id} deleteRecord={deleteRecord} message={deleteMessage} title={t('GENERAL.DELETETITLE')} buttonText={t('GENERAL.DELETE')} />
			<Modal show={displayResetPasswordModal} onHide={hideResetPasswordModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('RESETPASSWORD.RESETPASSWORD_BUTTON')} : {resetPasswordRecord?.first_name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{resetErrorMessage &&
						<Alert variant="danger">
							<ul className='mb-0'>
								{resetErrorMessage.split('|').map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</Alert>}
					<Formik initialValues={initialValues} validationSchema={resetPasswordValidationSchema(t)} onSubmit={handleResetPassword}>
						{({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group className="mb-3" as={Col} controlId="formGridPassword">
									<Form.Label>{t('RESETPASSWORD.FIELDS.PASSWORD')}</Form.Label>
									<PasswordInput
										placeholder={t('RESETPASSWORD.PLACEHOLDER.PASSWORD')}
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={errors.password && touched.password} />
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group className="mb-3" as={Col} controlId="formGridPasswordConfirm">
									<Form.Label>{t('RESETPASSWORD.FIELDS.PASSWORDCONFIRM')}</Form.Label>
									<PasswordInput
										placeholder={t('RESETPASSWORD.PLACEHOLDER.PASSWORDCONFIRM')}
										name="password_confirmation"
										value={values.password_confirmation}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={errors.password_confirmation && touched.password_confirmation} />
									<Form.Control.Feedback type="invalid">
										{errors.password_confirmation}
									</Form.Control.Feedback>
								</Form.Group>
								<Button title={t('RESETPASSWORD.RESETPASSWORD_BUTTON')} variant="primary" type="submit">
									{t('RESETPASSWORD.RESETPASSWORD_BUTTON')}
								</Button>
								<Button title={t('GENERAL.CLOSE')} className='ms-1' variant="secondary" onClick={hideResetPasswordModal}>
									{t('GENERAL.CLOSE')}
								</Button>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default Users;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { callGetAPI, callPostAPI  } from '../../services/axios';


export const getDischargeClinicalList = createAsyncThunk(
	'getDischargeClinicalList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/discharge/clinical/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDischargeSummaryFormFields = createAsyncThunk(
	'getDischargeSummaryFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/create', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const addDischargeSummary = createAsyncThunk(
	'addDischargeSummary',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/clinical/store', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getPatientInfoDischarge = createAsyncThunk(
	'getPatientInfoDischarge',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/patient/info', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);


export const getDischargeSummaryInfo = createAsyncThunk(
	'getDischargeSummaryInfo',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/discharge/clinical/edit', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);


export const updateDischargeSummary = createAsyncThunk(
	'updateDischargeSummary',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/clinical/update', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const dischargePatient = createAsyncThunk(
	'dischargePatient',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/clinical/confirm', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const cancelDischargePatient = createAsyncThunk(
	'cancelDischargePatient',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/clinical/cancel', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const verifyDischargePatient = createAsyncThunk(
	'verifyDischargePatient',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/clinical/verify', body: params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);
export const getDischargeAdminList = createAsyncThunk(
	'getDischargeAdminList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/discharge/admin/list', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDischargeCheckListFormFields = createAsyncThunk(
	'getDischargeCheckListFormFields',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/form/create', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const dischargeCheckList = createAsyncThunk(
	'dischargeCheckList',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callPostAPI({ route: 'hospital/discharge/admin/store', body: params, headersProps: { 'Content-Type': 'multipart/form-data' }, });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);

export const getDischargeSummaryDownload = createAsyncThunk(
	'getDischargeSummaryDownload',
	async(params, { rejectWithValue }) => {
		try {
			const response = await callGetAPI({ route: 'hospital/discharge/clinical/download', params });
			return { response };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	},
);